<template>
	<div class="con-main">
		<ul class="leftMenu">
			<template v-for="(item,index) in menuList">
				<li>
					<p>
						<img :src="item.cate_pic" style='width: 16px;height: 16px;margin-right: 10px;vertical-align: middle;' alt="">
						{{item.cate_name}}
					</p>
					<ul class="secondMenuList">
						<template v-for="children in item.son">
							<li class="secondMenu" :class="children.id == activeMenuId ? 'activeMenu' :'' "
								@click="changeCurIndex(index,children.id)">
								<img :src="children.cate_pic" style='width: 16px;height: 16px;margin-right: 10px;vertical-align: middle;' alt="">
								{{children.cate_name}}
							</li>
						</template>
					</ul>
				</li>
			</template>

		</ul>
		<div class="rightContent">
			<ul class="menuList" v-if="menuList.length>0">
				<template v-for="(item,index) in menuList[itemIndex].son">
					<li :class="item.id ==activeMenuId ? 'activeLi':''"
						@click="changeCurIndex(itemIndex,item.id)">
						{{item.cate_name}}
					</li>
				</template>
			</ul>
			<div v-if='activeMenuId != 9' class="content" v-html="context"></div>
			<div v-else style='padding: 30px;'>
				<div style='margin-bottom: 10px;'>
					<el-select v-model="value" placeholder="请选择">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.label">
					    </el-option>
					  </el-select>
				</div>
				<div>
					<textarea placeholder="请输入投诉/建议内容" style="border: 1px solid #f2f2f2;width: 100%;height:400px;padding: 20px;border-radius: 10px;" name="" v-model="jianyiTxt" id=""></textarea>
				</div>
				<!-- <div>
					<input @change="fileChange" type="file" >
				</div> -->
				<el-upload
				  ref='uploadInput'
				  class="upload-demo"
				  drag 
				  :data='uploaddata'
				  action="/api/Common/uploadPic"
				  multiple :on-success='onsuccess' :on-remove='removeFile' name='file'
  list-type="picture">
				  <i class="el-icon-upload"></i>
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
				</el-upload>
				
				<div style='padding-top: 10px;'>
					<el-button type="primary" @click="tousujianyi">提交</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	import md5 from '../../utils/md5.js'
	export default {
		data() {
			return {
				options: [{
				  value: '1',
				  label: '投诉'
				}, {
				  value: '2',
				  label: '建议'
				}],
				value:'',
				activeMenuId: 1,
				itemIndex: 0,
				menuList: [],
				context:'',
				jianyiTxt:'',
				picList:[],
				uploaddata:{}
			}
		},
		methods: {
			onsuccess(e){
				console.log(e)
				this.picList.push(e.data.full_path)
			},
			removeFile(file, fileList){
				console.log(file, fileList)
				if(this.picList.indexOf(file.response.data.full_path) >= 0){
					this.picList.splice(this.picList.indexOf(file.response.data.full_path),1)
				}
			},
			getaboutEssay(id){
				if(id == 9){
					return false
				}
				this.context = ''
				this.$post('/News/aboutEssay',{id:id}).then(res=>{
					console.log(res)
					if(res.status == 200){
						this.context = res.data
					}else{
						this.context = ''
					}
				})
			},
			uploadPic(){
				console.log('http://audio.guiqv.com/api/Common/uploadPic')
			},
			tousujianyi(){
				let data = {
					title:this.value,
					content:this.jianyiTxt,
					pic:this.picList
				}
				this.$post('/MemberInfo/complaint',data).then(res=>{
					console.log(res)
					this.jianyiTxt = ''
					this.picList = []
					this.$refs.uploadInput.clearFiles();
					this.$message({
						type:'success',
						message:res.mess
					})
				})
			},
			getAboutCate(){
				this.$post('/News/aboutCate',{}).then(res=>{
					console.log(res)
					this.menuList = res.data
					
					if (this.$route.query.parentIndex != null && this.$route.query.parentIndex != '' && this.$route.query.parentIndex != undefined) {
						
					}else{
						this.itemIndex = 0;
						this.activeMenuId = this.menuList[0].son[0].id;
					}
					this.getaboutEssay(this.activeMenuId)
				})
			},
			changeCurIndex(parentIndex, menuid) {
				if(menuid == 9){
					this.itemIndex = parentIndex;
					this.activeMenuId = menuid;
					this.$router.push({
						path: '/about',
						query: {
							parentIndex,
							menuid
						}
					})
					goTop()
					return false
				}
				this.itemIndex = parentIndex;
				this.activeMenuId = menuid;
				this.getaboutEssay(menuid)
				this.$router.push({
					path: '/about',
					query: {
						parentIndex,
						menuid
					}
				})
				goTop()
			},
			formartDate(){
				const date = new Date()
				const year = date.getFullYear()
				const month = parseInt(date.getMonth()) + 1 > 9 ? parseInt(date.getMonth()) + 1 : '0' + (parseInt(date
				.getMonth()) + 1)
				const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
			
				return year + '-' + month + '-' + day
			}
		},
		mounted() {
			let paramobj = {
				client_id:1,
				api_token:md5.md5('api/Common/uploadPic'+this.formartDate()+'yiling6670238160ravntyoneapp7926'),
				token:localStorage.getItem('token')?localStorage.getItem('token'):''
			}
			this.uploaddata = paramobj
			console.log('api/Common/uploadPic'+this.formartDate()+'yiling6670238160ravntyoneapp7926')
			//console.log("131321",this.$route.query.parentIndex);
			if (this.$route.query.parentIndex != null && this.$route.query.parentIndex != '' && this.$route.query.parentIndex != undefined) {
				this.itemIndex = this.$route.query.parentIndex;
				this.activeMenuId = this.$route.query.menuid;
				console.log('***********************************')
				console.log(this.itemIndex)
				console.log(this.activeMenuId)
			}
			this.getAboutCate()
		},
		watch: {
			$route: { //监听路由变化，路由改变触发函数
				handler: function(oldValue,newValue) {
					// console.log(this.$route.path)
					this.itemIndex = this.$route.query.parentIndex;
					this.activeMenuId = this.$route.query.menuid;
				},
				// 深度观察监听
				deep: true
			}
		},

	}
</script>

<style scoped>
	.con-main {
		width: 1400px;
		margin: 20px auto;
		overflow: hidden;
	}

	.con-main .leftMenu {
		float: left;
		width: 20%;
		max-width: 200px;
		border-radius: 10px;
		background-color: #fff;
		padding-bottom: 40px;
	}

	.con-main .leftMenu>li {
		cursor: pointer;
	}

	.con-main .leftMenu>li>p {
		font-size: 18px;
		font-weight: 600;
		padding: 50px 20px 20px;
		box-sizing: border-box;
	}

	.con-main .leftMenu .iconfont {
		margin-right: 10px;
	}

	.con-main .leftMenu>li>.secondMenuList>.secondMenu {
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.con-main .leftMenu>li>.secondMenuList>.secondMenu.activeMenu {
		color: #fff;
		background-color: #ee3a3b;
	}
	.con-main .leftMenu .secondMenuList .secondMenu:hover,.con-main .rightContent .menuList>li:hover{
		color:#ee3a3b;
	}
	.con-main .rightContent {
		width: calc(80% - 20px);
		float: left;
		margin-left: 20px;
		background-color: #fff;
		border-radius: 10px;
		overflow-y: auto;
	}

	.con-main .rightContent .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
	}

	.con-main .rightContent .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
	}

	.con-main .rightContent .menuList>li.activeLi{
		color: #fff;
		background-color: #ff1414;
		border-radius: 5px;
	}

	.con-main .rightContent .content {
		padding: 0 20px;
	}

	@media only screen and (max-width: 1680px) {
		.con-main {
			width: 1200px;
		}
	}

	@media only screen and (max-width:1440px) {
		.con-main {
			width: 1000px
		}

		.con-main .case-list {
			grid-template-columns: repeat(3, 1fr);
		}
	}
</style>
