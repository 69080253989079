<template>
	<div class="rankMuisc" id='yinyuerenBox'>
		<div class="title-wrap">
			<div class="title">
				<p>排行榜<span>·</span>LEADERBOARD</p>
			</div> <a href="javaScript:viod(0)" @click="goDetail()" class="more">查看更多
				&gt;&gt;</a>
		</div>
		<div class="bgwhite flex">
			<div class="side-wrap" :style="{backgroundImage:`url(${bgimg})`}">
				<div class="nav-box">
					<ul class="rankMuisc-cycle">
						<li class="rankMuisc-cycle-item cross-cen" :class="activeItemIndex==0 ? 'active':''"
							@click="changeActiveItemIndex(0)">
							<div class="info-box dir-c main-btw">
								<p class="title"><i class="iconfont icon-yinle1"></i>热门排行榜</p>
							</div>
						</li>
						<li class="rankMuisc-cycle-item cross-cen" :class="activeItemIndex==1 ? 'active':''"
							@click="changeActiveItemIndex(1)">
							<div class="info-box dir-c main-btw">
								<p class="title"><i class="iconfont icon-paiming"></i>销量排行榜</p>
							</div>
						</li>
						<li class="rankMuisc-cycle-item cross-cen" :class="activeItemIndex==2 ? 'active':''"
							@click="changeActiveItemIndex(2)">
							<div class="info-box dir-c main-btw">
								<p class="title"><i class="iconfont icon-nan1"></i>男生排行榜</p>
							</div>
						</li>
						<li class="rankMuisc-cycle-item cross-cen" :class="activeItemIndex==3 ? 'active':''"
							@click="changeActiveItemIndex(3)">
							<div class="info-box dir-c main-btw">
								<p class="title"><i class="iconfont icon-nv1"></i>女生排行榜</p>
							</div>
						</li>
						<li class="rankMuisc-cycle-item cross-cen" :class="activeItemIndex==4 ? 'active':''"
							@click="changeActiveItemIndex(4)">
							<div class="info-box dir-c main-btw">
								<p class="title"><i class="iconfont icon-waiyu1"></i>外语排行榜</p>
							</div>
						</li>
						<li class="rankMuisc-cycle-item cross-cen" :class="activeItemIndex==5 ? 'active':''"
							@click="changeActiveItemIndex(5)">
							<div class="info-box dir-c main-btw">
								<p class="title"><i class="iconfont icon-icon-test"></i>新人排行榜
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="page-ranking-music">
				<div class="audio-content-head">
					<div class="num-box"></div>
					<div class="info-box">样品名称</div>
					<div class="tags-box">声音类型</div>
					<div class="dur-box">收藏&nbsp;&nbsp;&nbsp;下载&nbsp;&nbsp;&nbsp;购买</div>
					<div class="down-box"></div>
				</div>
				<div class="audio-content-wrap">
					<div class="__vuescroll" style="height:100%;width:100%;padding:0;position:relative;">
						<div class="__panel __hidebar"
							style="position: relative; box-sizing: border-box; height: 100%; overflow-y:auto; margin-right: -10px;">
							<div class="__view"
								style="position:relative;box-sizing:border-box;min-width:100%;min-height:100%;">
								<ul class="audio-list">
									<template v-for="(item,index) in audioList">
										<li class="audio-item">
											<div class="audio-info">
												<div class="num-box main-cen">
													<div v-if="index<3" class="num" style="color:#ed4f42">
														{{index+1}}
													</div>
													<div v-else class="num" style="color:#999">
														{{index+1}}
													</div>
													<div
														style="width: 30px;display: flex;justify-content:center;align-items: center;">
														<i class="iconfont icon-diyi" v-if="index+1==1"></i>
														<i class="iconfont icon-xiajiang" v-if="index > 3"></i>
														<i class="iconfont icon-shangsheng"
															v-else-if="index+1!=1 && index<=3"></i>
													</div>

												</div>
												<div class="info-box">
													<div class="audio-cover"
														@click="changeAudioSrc(item.audio,item.headimgurl,item)">
														<img class="cover tran" :data-src="item.headimgurl"
															:src="item.headimgurl" lazy="loaded"
															style='width:70px;height: 70px;'>
														<button class="btn-play" v-if="item.audio">
															<i v-if="audioFlag==true && audioId==item.audio.id&& $store.state.musicLoading"
																class="iconfont icon-jiazai"></i>
															<i v-else-if="audioFlag==true && audioId==item.audio.id"
																class="iconfont iconzanting6"></i>
															<i v-else class="iconfont iconweibiaoti-2"></i>
														</button>
													</div>
													<div class="audio-title" @click="todetail(item.id)">
														<div class="title-box"><a v-if="item.audio"
																href="javaScript:viod(0)"
																class="title text-overflow">{{item.audio.title}}</a>
															<i v-if="item.is_hot==1" class="iconfont icon-remen"></i>
														</div>
														<div class="author-box text-overflow">
															<img :src="item.headimgurl" />
															<p>by
																<a rel="nofollow" href="javaScript:viod(0)"
																	class="author goAuthorP">
																	{{item.nickname}} </a>
															</p>
														</div>
													</div>
												</div>
												<div class="tags-box cross-cen">
													<ul class="audio-tags-list">
														<template v-for="tagName in item.like" style="float:left">
															<li class="audio-tag-item">
																<a class="link-tags"
																	style='margin-right:4px'>{{tagName}}</a>
															</li>
														</template>
													</ul>
												</div>
												<div class="btn-box">
													<div class="btn-collect-box">
														<template v-if="!item.is_sc == 1">
															<i class="iconfont icon-shoucang_quxiaoshoucang"
																@click="shoucangDef(item.id,1)"></i>
															<div class="tip-top">添加收藏</div>
														</template>
														<template v-if="item.is_sc == 1">
															<i class="iconfont icon-shoucang_shoucang"
																@click="shoucangDef(item.id,0)"></i>
															<div class="tip-top">取消收藏</div>
														</template>

													</div>
													<div class="btn-trail-box" @click="handleDown(item.audio)"><i class="iconfont icon-yunxiazai_o
"></i>
														<div class="tip-top">下载音频</div>
													</div>
													<div class="btn-collect-box" @click="todetail(item.id)"><i
															class="iconfont icon-gouwuche"></i>
														<div class="tip-top">立即购买</div>
													</div>
												</div>
												<div @click="showKefu(item)" class="down-box"><button
														class="btn-download btn-wf-333-ea4-icon">
														<!-- <span class="icon icon-dubbing"></span> -->
														<i class="iconfont icon-kehufuwukefu"></i>
														在线联系
													</button>
												</div>
											</div>
										</li>
									</template>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import goTop from '../../utils/goTop.js'
	import {
		captchaCode
	} from "@/request/api.js";
	export default {
		components:{
			teacherDialog
		},
		data() {
			return {
				activeItemIndex: 0,
				audio_idClass: 'footer-wavesurfer tran',
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				audioId: '',
				audioFlag: false,
				paihangtype: 2,
				audioList: [],
				kefuObj: {
					qq: '',
					wx: ''
				},
				kefuBoxShow: false,
				bgimg: ''
			}
		},
		mounted() {
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
			this.getRanking()
			this.getAdByTag()
		},
		methods: {
			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_list_leftImg'
				}).then(res => {
					this.bgimg = res.data[0].ad_pic
				})
			},
			showKefu(item) {
				// if( localStorage.getItem('loginFlag')){
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data;
						this.kefuObj.headimgurl=item.headimgurl;
						this.kefuObj.nickname=item.nickname;
						this.kefuObj.on_line=item.on_line;
						this.kefuBoxShow = true
					})
				// }else{
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }
				
			},
			todetail(id) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: id
					}
				})
				goTop()
			},
			shoucangDef(id, state) {
				let data = {
					id: id,
					type: 1,
					state: state
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					this.getRanking()
				})
			},
			getRanking() {
				let data = {
					type: this.paihangtype
				}
				this.$post('/Ranking/index', data).then(res => {
					console.log(res)
					this.audioList = res.data
				})
			},

			goDetail() {
				this.$router.push({
					path: '/sampleAudition'
				})
				goTop()
			},
			// 改变左侧列表
			changeActiveItemIndex(index) {
				var typeList=[2,3,5,4,7,6];
				this.activeItemIndex = index;
				this.paihangtype = typeList[index]
				this.getRanking()
			},
			// 收藏、取消收藏
			changeLikeIconfont(flag, audio_Id) {
				console.log(flag, audio_Id)
				// 调用收藏接口，列表接口
			},
			changeAudioSrc(audioDetail, img,item) {
				// 音乐信息存缓存
				console.log(audioDetail)
				let audioInfo = {};
				audioInfo.id = audioDetail.id;
				audioInfo.audioSrc = audioDetail.audio;
				audioInfo.audioImage = img
				audioInfo.audioName = audioDetail.title;
				audioInfo.audioPeoName=item.nickname;
				audioInfo.audioPeoId=item.id;
				console.log('播放')
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeStatus(val, flag) {
				// 获取播放的id,总状态
				console.log(1111111111111, val, flag)
				this.audioId = val;
				this.audioFlag = flag
			},
			handleDown(audio) {
				// 使用获取到的blob对象创建的url
				const filePath = audio.audio // mp3的地址
				fetch(filePath).then(res => res.blob()).then(blob => {
					const a = document.createElement('a')
					document.body.appendChild(a)
					a.style.display = 'none'
					// 使用获取到的blob对象创建的url
					const url = window.URL.createObjectURL(blob)
					a.href = url
					// 指定下载的文件名
					a.download = audio.title + '.mp3'
					a.click()
					document.body.removeChild(a)
					// 移除blob对象的url
					window.URL.revokeObjectURL(url)
				})
			},
		}
	}
</script>

<style scoped>
	.rankMuisc .side-wrap,.rankMuisc .bgwhite{
		border-radius: 15px 0 0 15px;
	}
	.goAuthorP:hover{
		color: #ea4335;
		font-weight: 600;
	}
	/* @import url("../../assets/iconfont/iconfont.css"); */

	.dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
			border-radius: 20px !important;
			padding: 0 !important;
			background-color: transparent;
		}
		/deep/.dialogBox {
			border-radius: 50px 40px;
		}
</style>
