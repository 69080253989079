<template>
	<div>
		<div class="Dialog-log clrafix">
			<el-form ref="form" :model="userInfo" label-width="80px">
				<el-form-item label="昵称">
					<el-input v-model="userInfo.name"></el-input>
				</el-form-item>
				<el-form-item label="头像">
					<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="userInfo.image" :src="userInfo.image" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="equit()">取 消</el-button>
				<el-button type="primary" @click="submitFn()">确 定</el-button>
			</span>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				userInfo: {
					name: '张三',
					image: ''
				}
			}
		},
		props:[
			"changeInfoDialog"
		],
		methods: {
			equit(){
				this.$emit('update:changeInfoDialog', false);
			},
			submitFn(){
				this.$emit('update:changeInfoDialog', false);
			},
			handleAvatarSuccess(res, file) {
				this.userInfo.image = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>

<style>
	.Dialog-log{
		height: 330px;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader{
		text-align: left;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
