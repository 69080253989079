<template>
	<div class="budding-modal">
		<div class="modal-small" @click="bigShow=true">
			<img class="open-icon" src="@/assets/image/aiDubbing/open-icon.png" alt="">
			<div class="head">
				<img class="head-img" :src="currentSelectDubbing.profilePhoto" alt="">
				<img v-if="currentSelectDubbing.svip === 1" class="vip-img" src="@/assets/image/aiDubbing/zsvip-icon.png" alt="">
			</div>
			<div class="name">
				<p>{{currentSelectDubbing.nickName}} &nbsp;&nbsp; 情绪:{{this.style}}</p>
				<p class="speed">朗诵速度：{{speedText}}</p>
			</div>
		</div>
		<div class="modal-big" v-if="bigShow">
			<div class="header">
				<span>{{ showDubbingList?'主播列表':'主播信息' }}</span>
				<img @click="bigShow=false" src="@/assets/image/aiDubbing/modal-close-icon.png" alt="">
			</div>
			<div class="box">
				<transition name="el-fade-in-linear">
					<div class="left-list" v-if="showDubbingList">
					<div class="search">
						<img src="@/assets/image/aiDubbing/search.png" alt="">
						<input v-model="form.nickName" type="text" placeholder="请输入主播名字搜索" @keydown.enter="searchData">
					</div>
					<div class="budding-list">
						<dubbing-class @selectClass="selectClass" color="#fff"/>
					</div>
					<div class="res-list" v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.1)">
						<budding-info-small v-for="item of voiceList" :key="item.id" :itemData="item"/>
					</div>
					<!--简易分页-->
					<div class="pagination" v-if="voiceList.length">
						<span @click="changePage(1)" :class="{dis: form.pageNum === 1}">< 上一页</span>
						<span @click="changePage(2)" :class="{dis: form.pageNum === totalPage}">下一页 ></span>
					</div>
				</div>
				</transition>
				<div class="indo">
					<div class="current-user">
						<div class="head">
							<img class="head-img" :src="currentSelectDubbing.profilePhoto" alt="">
							<img v-if="currentSelectDubbing.svip === 1" class="vip-img" src="@/assets/image/aiDubbing/zsvip-icon.png" alt="">
						</div>
						<div class="name">
							<p>{{currentSelectDubbing.nickName}}</p>
							<p class="speed">朗诵速度：默认</p>
						</div>
						<span class="switch" @click="showDubbingList = !showDubbingList">更换主播</span>
					</div>
<!--					<p class="trial-listening">-->
<!--						<img src="@/assets/image/aiDubbing/start-icon.png" alt="">-->
<!--						<span>试听情绪</span>-->
<!--					</p>-->
					<div class="type-list">
						<div class="item" v-for="(item,index) of styleList">
							<span
									class="icon"
									:class="{play: index === playIndex, active: item.label === style}"
									:style="{background: item.rgb}"
									@click="play(item,index)"
							>
								<span>{{item.label.substring(0,1)}}</span>
								<i class="el-icon-loading"></i>
							</span>
							<span class="text">{{item.label}}</span>
						</div>
					</div>
					<div class="speed-set">
						<p class="title">朗诵速度</p>
						<div class="speed">
							慢 <van-slider
								style="margin: 0 20px;flex: 1; height: 4px;"
								button-size="14"
								active-color="#E93A3A"
								v-model="speed" :max="2"
								:min="0.5"
								:step="0.1"
								@change="slideChange"
						>
							<template #button>
								<div class="custom-button">{{ speed }}</div>
							</template>
						</van-slider> 快
							<span @click="resetValue('speed')">默认</span>
						</div>
						<p class="title">配音人音量</p>
						<div class="speed">
							小 <van-slider
								style="margin: 0 20px;flex: 1;height: 4px;"
								button-size="14"
								active-color="#E93A3A"
								v-model="volume"
								:max="1"
								:min="0.1"
								:step="0.1"
								@change="slideChange"
						>
							<template #button>
								<div class="custom-button">{{ volume }}</div>
							</template>
						</van-slider> 大
							<span @click="resetValue('volume')">默认</span>
						</div>
						<p class="title">语调调节</p>
						<div class="speed">
							小 <van-slider
								style="margin: 0 20px;flex: 1;height: 4px;"
								button-size="14"
								active-color="#E93A3A"
								v-model="pitch"
								:max="10"
								:min="-10"
								:step="1"
								@change="slideChange"
						>
							<template #button>
								<div class="custom-button">{{ pitch }}</div>
							</template>
						</van-slider> 大
							<span @click="resetValue('pitch')">默认</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 试听情绪	-->
		<audio-play ref="audioPlay" @playEnd="playEnd"/>
	</div>
</template>

<script>
import buddingInfoSmall from "@/components/aiDubbing/buddingInfoSmall.vue";
import 'vant/lib/slider/style';
import { Slider } from "vant";
import {mapGetters} from "vuex";
import {getRandomColorRGB} from "@/utils/func";
import dubbingClass from "@/views/aiDubbing/components/dubbingClass.vue";
import {voicePageList} from "@/request/aiDubbing";
import AudioPlay from "@/components/aiDubbing/audioPlay.vue";

export default {
	props:{
		showBigModal:{
			type:Boolean,
			default:false
		},
		trialListeningStatus:{
			type:Boolean,
			default:false
		}
	},
	components:{AudioPlay, dubbingClass, VanSlider:Slider, buddingInfoSmall},
	data(){
		return {
			value: 0,
			bigShow:false,
			form:{
				pageNum: 1,
				pageSize: 10,
				nickName:''
			},
			totalPage: 0,
			dataTotal: 0,
			totalStyle:0,
			voiceList:[],
			listLoading:false,
			showDubbingList:false,
			//
			speed:1,
			volume:1,
			pitch:0,
			style:'默认',
			defaultConfig:{
				speed:1,
				volume:1,
				pitch:0,
				style:'默认'
			},
			playIndex:null,
		}
	},
	watch:{
		showBigModal(val){
			this.bigShow = val
		},
		currentSelectDubbing:{
			handler:function(){
				this.style = '默认'
				this.playIndex = null
				this.$refs.audioPlay.stopAudio()
			},
			deep: true
		}
	},
	computed:{
		...mapGetters(['currentSelectDubbing']),
		styleList(){
			return this.currentSelectDubbing.styleList.map(item => {
				return {
					label: item,
					rgb: this.getRandomColorRGB()
				}
			})
		},
		speedText(){
			if(this.speed === 1) return '默认'
			if(this.speed < 1) return `慢${(1/this.speed).toFixed(1)}倍`
			return `快${this.speed.toFixed(1)}倍`
		}
	},
	mounted() {
		this.searchData()
		const config = localStorage.getItem('dubbingConfig')
		if(config){
			const {speed, volume, pitch, style} = JSON.parse(config)
			this.speed = speed || 1
			this.volume = volume || 1
			this.pitch = pitch || 0
			this.style = style || '默认'
		}
	},
	methods:{
		getRandomColorRGB,
		playEnd(){
			this.playIndex = null
			this.$refs.audioPlay.stopAudio()
		},
		play(item,index){
			if(this.trialListeningStatus){ // ssml编辑器正在试听
				//this.playIndex = index
				this.style = item.label
				const config = localStorage.getItem('dubbingConfig')
				if(config){
					let configObj = JSON.parse(config)
					configObj.style = item.label
					localStorage.setItem('dubbingConfig', JSON.stringify(configObj))
				}else{
					localStorage.setItem('dubbingConfig', JSON.stringify({
						speed: this.speed,
						volume: this.volume,
						pitch: this.pitch,
						style: item.label
					}))
				}
				this.$emit('switchEmotion')
				return
			}
			if(this.playIndex != null) { // 停止当前情绪试听的播放
				this.playEnd()
				return
			}
			// 更新情绪配置，并试听
			this.playIndex = index
			this.style = item.label
			const config = localStorage.getItem('dubbingConfig')
			if(config){
				let configObj = JSON.parse(config)
				configObj.style = item.label
				localStorage.setItem('dubbingConfig', JSON.stringify(configObj))
			}else{
				localStorage.setItem('dubbingConfig', JSON.stringify({
					speed: this.speed,
					volume: this.volume,
					pitch: this.pitch,
					style: item.label
				}))
			}
			this.$refs.audioPlay.getAudio({
				speakerId: this.currentSelectDubbing.id,
				style: item.label
			})
		},
		resetValue(type){
			switch (type) {
				case 'speed':
					this.speed = this.defaultConfig.speed
					break;
				case 'volume':
					this.volume = this.defaultConfig.volume
					break;
				case 'pitch':
					this.pitch = this.defaultConfig.pitch
					break;
			}
		},
		slideChange(){
			const data = {
				speed: this.speed,
				volume: this.volume,
				pitch: this.pitch
			}
			localStorage.setItem('dubbingConfig', JSON.stringify(data))
		},
		selectClass({mainItem:mainClass, classItem:childClass}){
			this.form.pageNum = 1
			switch (mainClass.type) {
				case 1:
					if (childClass.value === "") {
						delete this.form.scopesName;
						break;
					}
					this.form.scopesName = childClass.value;
					break;
				case 2:
					if (childClass.value === "") {
						delete this.form.language;
						break;
					}
					this.form.language = childClass.value;
					break;
				case 3:
					if (childClass.value === "") {
						delete this.form.style;
						break;
					}
					this.form.style = childClass.value;
					break;
				case 4:
					if (childClass.value === "") {
						delete this.form.timbreType;
						break;
					}
					this.form.timbreType = childClass.value;
					break;
				case 5:
					if (childClass.value === "") {
						delete this.form.gender;
						break;
					}
					this.form.gender = childClass.value;
					break;
				case 6:
					if (childClass.value === "") {
						delete this.form.svip;
						break;
					}
					this.form.svip = childClass.value;
					break;
			}
			this.searchData()
		},
		async searchData(){
			try{
				if(this.listLoading) return
				this.listLoading = true
				const {data} = await voicePageList(this.form)
				if(data && data.list){
					this.dataTotal = data.total
					this.totalStyle = data.totalStyle
					this.totalPage = data.pages
					this.voiceList = data.list
				}
				this.listLoading = false
			}catch (e) {
				console.log(e)
				this.listLoading = false
			}
		},
		changePage(type){
			//上一页
			if(type === 1){
				if(this.form.pageNum === 1){
					return
				}
				this.form.pageNum--
				this.searchData()
			}
			// 下一页
			if(type === 2){
				if(this.form.pageNum === this.totalPage){
					return
				}
				this.form.pageNum++
				this.searchData()
			}
		}
	}
}
</script>

<style scoped lang="scss">
.budding-modal{
	
}
.modal-small{
	cursor: pointer;
	position: fixed;
	top: 95px;
	right: 0;
	min-width: 230px;
	height: 60px;
	background: #ED4F42;
	border-radius: 9px 0 0 9px;
	display: flex;
	align-items: center;
	.open-icon{
		width: 22px;
		height: 22px;
		margin-left: 10px;
		margin-right: 10px;
	}
	.head{
		position: relative;
		width: 50px;
		height: 50px;
		border: 1px solid #ccc;
		border-radius: 50%;
		margin-right: 10px;
		.head-img{
			width: 50px;
			height: 50px;
			border-radius: 100%;
		}
		.vip-img{
			position: absolute;
			width: 16px;
			height: 12px;
			right:0;
			bottom: 0;
		}
	}
	.name{
		color: #fff;
		padding-right: 10px;
		.speed{
			font-size: 12px;
			margin-top: 4px;
		}
	}
}
.modal-big{
	position: fixed;
	top: 95px;
	right: 0;
	z-index: 9;
	background: #811B33;
	border-radius: 10px;
	color: #fff;
	max-width: 900px;
	.header{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #99233F;
		padding: 20px;
		span{
			flex: 1;
		}
		img{
			width: 18px;
			height: 19px;
			cursor: pointer;
		}
	}
	.box{
		display: flex;
		padding: 14px 18px;
		box-sizing: border-box;
		.left-list{
			display: flex;
			flex-direction: column;
			.search{
				display: flex;
				align-items: center;
				height: 26px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 20px;
				padding: 0 10px;
				margin-bottom: 20px;
				img{
					width: 14px;
					height: 14px;
					margin-right: 8px;
				}
			}
			.budding-list{
				.list-item{
					display: flex;
					line-height: 30px;
					.label{
						margin-right: 5px;
					}
					p{
						flex: 1;
						.text{
							margin-right: 12px;
							white-space: nowrap;
						}
						.active{
							color: #E93A3A;
						}
					}
				}
			}
		}
		.res-list{
			flex: 1;
			overflow-y: auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 460px;
			margin-top: 20px;
			max-height: 450px;
		}
	}
	.indo{
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		box-sizing: border-box;
		min-width: 360px;
	}
	.current-user{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #99233F;
		padding: 0 20px 20px;
		box-sizing: border-box;
		.head{
			position: relative;
			width: 50px;
			height: 50px;
			flex: 0 0 50px;
			border: 1px solid #ccc;
			border-radius: 50%;
			margin-right: 10px;
			.head-img{
				width: 50px;
				height: 50px;
				border-radius: 100%;
			}
			.vip-img{
				position: absolute;
				width: 16px;
				height: 12px;
				right:0;
				bottom: 0;
			}
		}
		.name{
			flex: 1;
			color: #fff;
			.speed{
				font-size: 12px;
				margin-top: 4px;
			}
		}
		.switch{
			width: 100px;
			flex: 0 0 100px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #E93A3A;
			color: #E93A3A;
			border-radius: 2px;
			cursor: pointer;
			&:hover{
				background: #E93A3A;
				color: #fff;
			}
		}
	}
	.trial-listening{
		margin: 16px 0 28px 16px;
		width: 100px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #E93A3A;
		color: #E93A3A;
		border-radius: 2px;
		img{
			width: 7px;
			height: 11px;
			margin-right: 5px;
		}
	}
	.type-list{
		width: 330px;
		display: flex;
		flex-wrap: wrap;
		padding-left: 16px;
		margin-right: -34px;
		border-bottom: 1px solid #99233F;
		padding-top: 20px;
		.item{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 34px;
			margin-bottom: 28px;
		}
		.icon{
			width: 26px;
			height: 26px;
			color: #fff;
			background: #CD9044;
			border-radius: 50%;
			text-align: center;
			line-height: 26px;
			font-size: 12px;
			margin-bottom: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			user-select: none;
			i{
				display: none;
			}
			&.play{
				span{
					display: none;
				}
				i{
					display: inline-block;
					font-size: 20px;
				}
			}
			&.active{
				border: 3px solid #fee2e2;
			}
		}

		.text{
			font-size: 12px;
		}
	}
	.speed-set{
		padding: 10px 20px 10px 10px;
		.title{
			margin: 10px;
		}
		.speed{
			display: flex;
			align-items: center;
			margin-left: 10px;
			margin-bottom: 24px;
			span{
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}
}
.custom-button {
	width: 18px;
	height: 18px;
	color: #fff;
	font-size: 10px;
	line-height: 18px;
	text-align: center;
	background-color: #E93A3A;
	border-radius: 100%;
}
.pagination{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	span{
		cursor: pointer;
		&:hover{
			color: #E93A3A;
		}
		&.dis{
			color: #ccc;
			cursor: not-allowed;
		}
	}
	span:nth-child(1){
		margin-right: 20px;
	}
}
</style>

<style>
/* 为滚动条本身设置样式 */
.res-list::-webkit-scrollbar {
	width: 5px; /* 滚动条的宽度 */
}

/* 滚动条的滑块（即用户可以拖动的部分） */
.res-list::-webkit-scrollbar-thumb {
	background: none; /* 滑块的颜色 */
	border-radius: 10px; /* 滑块的圆角 */
}

/* 滚动条的轨道（滑块之外的区域） */
.res-list::-webkit-scrollbar-track {
	background: none; /* 轨道的颜色 */
	border-radius: 10px; /* 轨道的圆角 */
}

/* 当鼠标悬停在滑块上时 */
.res-list::-webkit-scrollbar-thumb:hover {
	background: #555; /* 滑块的颜色（悬停时） */
}
</style>