<template>
  <span :style="{background:bgColor, color:color}" @click.stop="$emit('click')"><slot></slot></span>
</template>

<script>
export default {
  props:{
    bgColor:String,
    color:String,
  }
}
</script>

<style scoped>
span{
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}
</style>