<template>
	<div class="using-statistics">
			<div class="modal">
				<div class="header">
					<span class="title">使用统计</span>
					<span class="close" @click="$emit('close')">
						<i class="el-icon-close"></i>
					</span>
				</div>
				<div class="content">
					<el-table :data="tableData" border>
						<el-table-column prop="index" label="序号" width="100" align="center">
							<template slot-scope="scope">
								{{ (form.pageNum - 1) * form.pageSize + scope.$index + 1 }}
							</template>
						</el-table-column>
						<el-table-column label="ID" align="center" prop="id"></el-table-column>
						<el-table-column label="播音员" align="center" prop="speakerName"></el-table-column>
						<el-table-column label="消耗字数" align="center" prop="tokenCount"></el-table-column>
					</el-table>
				</div>
				<div class="pagination">
					<pagination ref="pagination" :totalPage="totalPage" :limit="form.pageSize" @changePage="changePage"/>
				</div>
				<p class="count">
					<!--		总字数，超过1万用万做单位			-->
					<span>已使用总计：<span class="num">{{ formatNumber(this.totalUsedToken || 0)}}</span></span>
				</p>
			</div>
	</div>
</template>

<script>
import {getMyRecord} from "@/request/aiDubbing";
import Pagination from "@/components/pagination.vue";

export default {
	components: {Pagination},
	data(){
		return{
			tableData:[],
			form:{
				pageNum: 1,
				pageSize: 25
			},
			totalPage: 0,
			dataTotal: 0,
			totalUsedToken:0,
		}
	},
	mounted() {
		this.getList()
	},
	methods:{
		formatNumber(number){
			if(number > 10000){
				return `${(number/10000).toFixed(1)}万字`
			}else{
				return `${number}字`
			}
		},
		async getList(){
			const {data} = await getMyRecord(this.form)
			if(data && data.list){
				this.dataTotal = data.total
				this.totalPage = data.pages
				this.tableData = data.list
				this.totalUsedToken = data.totalUsedToken
			}
		},
		changePage(){
			this.form.pageNum = arguments[0]
			this.getList()
		}
	}
}
</script>

<style scoped lang="scss">
.using-statistics{
	position: fixed;
	top:0;
	left:0;
	right: 0;
	bottom: 0;
	z-index: 9;
	background: rgba(0,0,0,.5);
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal{
	width: 700px;
	background: #fff;
	border-radius: 10px;
}
.header{
	padding: 20px;
	border-bottom: 1px solid #eee;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: bold;
	.el-icon-close{
		font-size:24px;
		cursor: pointer;
	}
}
.count{
	padding: 20px;
	text-align: right;
	.num{
		font-weight: bold;
	}
}
</style>