// 回到顶部
export default function goTop() {
	let top = document.documentElement.scrollTop;
	// 实现滚动效果
	const timeTop = setInterval(() => {
		document.documentElement.scrollTop = top -= 50;
		if (top <= 0) {
			clearInterval(timeTop); //清除定时器
		}
	}, 10);
};
