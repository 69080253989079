import aiAxios from "./aihttp";

// 授权
export const getAIdubbingAuth = (params) => {
  return aiAxios.get(
    "authLogin?userId=188594097076965376&timestamp=123&userName=phpUser&signature=bdc1a97573e95f3d5bf7595929424fa2",
    { params }
  );
};
// 声音查询参数（类型，语言，音色，性别)
export const getVoiceSetting = (params) => {
  return aiAxios.get("/stw/voice/getVoiceSetting", {}, params);
};
// 分页声音列表
export const voicePageList = (params) => {
  return aiAxios.get("/stw/voice/hotPage", { params });
};
// 分页声音列表
export const getSpeakerById = (params) => {
  return aiAxios.get("/stw/voice/getSpeakerById", { params });
};
// 分页声音列表
export const getPriceList = (params) => {
  return aiAxios.get("/stw/memberPrice/getPriceList", { params });
};
// 微信支付产生订单
export const wxCreateOrder = (params) => {
  return aiAxios.post("/stw/pay/wxCreateOrder", params);
};
// 支付宝产生订单
export const aliCreateOrder = (params) => {
  return aiAxios.post("/stw/pay/aliCreateOrder", params);
};
// 支付宝产生订单
export const queryOrder = (params) => {
  return aiAxios.post("/stw/pay/queryOrder", params);
};
// 首页搜索-关键词搜索
export const searchPage = (params) => {
  return aiAxios.get("/stw/voice/searchPage", { params });
};
// 试听情绪
export const audition = (params) => {
  return aiAxios.post("/stw/voiceRecord/audition", params);
};
// ssml配音
export const speakerSsml = (params) => {
  return aiAxios.post("/stw/voiceRecord/speakerSsml", params);
};
// 收藏主播
export const concernSpeaker = (params) => {
  return aiAxios.post("/stw/voice/concernSpeaker", params);
};
// 保存或更新草稿
export const draftAddOrUpdate = (params) => {
  return aiAxios.post("/stw/draft/addOrUpdate", params);
};
// 根据id删除草稿
export const draftDeleteById = (params) => {
  return aiAxios.post("/stw/draft/deleteById", params);
};
// 根据id查询草稿
export const draftGetById = (params) => {
  return aiAxios.post("/stw/draft/getById", params);
};
// 分页查询草稿
export const draftPage = (params) => {
  return aiAxios.get("/stw/draft/draftPage", { params });
};
// 分页查询配音记录-使用统计
export const getMyRecord = (params) => {
  return aiAxios.post("/stw/voiceRecord/getMyRecord", params);
};
// 分页查询配音记录-使用统计
export const searchByFilePage = (params) => {
  return aiAxios.post("/stw/voice/searchByFilePage", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// 查询用户信息
export const getUserInfo = (params) => {
  return aiAxios.post("/system/user/getUserInfo", params);
};
// 分页查询我的合成配音列表
export const getMySynthPage = (params) => {
  return aiAxios.post("/stw/synthetize/getMySynthPage", params);
};
// 删除合成配音
export const deleteSynthById = (params) => {
  return aiAxios.post("/stw/synthetize/deleteSynthById", params);
}
// 我的订单
export const getMyOrderPage = (params) => {
  return aiAxios.post("/stw/order/getMyOrderPage", params);
}
// 我的主播收藏列表
export const colletrPage = (params) => {
  return aiAxios.post("/stw/collect/colletrPage", params);
}
// 我的主播收藏列表
export const getFileByType = (params) => {
  return aiAxios.get("/stw/fss/getFileByType", {params});
}