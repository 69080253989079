<template>
<div class="pause-select" :style="{top: `${top}px`, left:`${left}px`}" @click.stop>
   <p>
     <span @click="selectPause('0ms')" :class="{active: activeValue ==='0ms'}"><i class="iconfont">&#xe661;</i> 0ms</span>
     <span @click="selectPause('100ms')" :class="{active: activeValue ==='100ms'}"><i class="iconfont">&#xe661;</i> 100ms</span>
     <span @click="selectPause('150ms')" :class="{active: activeValue ==='150ms'}"><i class="iconfont">&#xe661;</i> 150ms</span>
     <span @click="selectPause('200ms')" :class="{active: activeValue ==='200ms'}"><i class="iconfont">&#xe661;</i> 200ms</span>
   </p>
   <p>
     <span @click="selectPause('300ms')" :class="{active: activeValue ==='300ms'}"><i class="iconfont">&#xe661;</i> 300ms</span>
     <span @click="selectPause('400ms')" :class="{active: activeValue ==='400ms'}"><i class="iconfont">&#xe661;</i> 400ms</span>
     <span @click="selectPause('600ms')" :class="{active: activeValue ==='600ms'}"><i class="iconfont">&#xe661;</i> 600ms</span>
     <span @click="selectPause('自定义')">
       <i class="iconfont">&#xe661;</i> <b v-if="!customInput">自定义</b>
       <input v-if="customInput" type="number" v-model="customValue" @change="customValueChange">
     </span>
   </p>
</div>
</template>

<script>
import Toast from 'vue-toast-mobile';
require("vue-toast-mobile/lib/index.css")

export default {
  props:{
    top: {
      type:Number,
      default:0
    },
    left: {
      type:Number,
      default:0
    }
  },
  data(){
    return {
      activeValue:'',
      customValue:'',
      customInput:false,
    }
  },
  methods:{
    selectPause(pause){
      if(pause === '自定义'){
        this.customInput = true
        return
      }
      this.customInput = false
      this.activeValue = pause
      this.$emit('selectPause', pause)
    },
    customValueChange(){
      if(this.customValue){
        if(Number(this.customValue) < 50 || Number(this.customValue) >5000){
          Toast('停顿时长只能在50-5000之间！')
          if(this.customValue>5000){
            this.customValue = 5000
          }
          if(this.customValue < 50){
            this.customValue = 50
          }
        }
        this.$emit('selectPause', this.customValue)
      }
    }
  }
}
</script>

<style scoped>
.pause-select{
  position: fixed;
  background: #fff;
  padding: 15px 8px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ddd;
  font-size: 14px;
  max-width: 420px;
  z-index: 10;
}
span{
  margin-right: 20px;
  display: inline-block;
  width: 70px;

}
p:nth-child(1){
  margin-bottom: 10px;
}

p{
  display: flex;
}

span{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
}
span.active{
  background: #ddd;
}
span:hover{
  background: #ddd;
}
span i{
  margin-right: 4px;
}
span b{
  font-style: normal;
}
span input{
  width: 45px;
  border: 1px solid #666;
}
p span:last-child{
  margin-right: 0;
}
</style>