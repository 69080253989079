<template>
	<div id="banner" class="banner pr" :style="{backgroundImage:`url(${bgimg})`}">
		<h2 class="slogan">声探网&nbsp;影视人都在用的配音服务平台</h2>
		<div class="search-wrap flex">
			<div class="search-box pr">
				<div class="search-input">
					<input type="text" v-model="keyword" placeholder="搜索关键词,让配音更高效" value="" class="flex-auto">
				</div>
				<div class="search-type-box">
					<div class="swiper-bg cross-cen"><i class="iconfont iconsousuo"></i></div>
					<div class="swiper-container search-swiper swiper-container-initialized swiper-container-vertical">
						<div class="swiper-wrapper" :style="styleCount">
							<div class="swiper-slide swiper-slide-duplicate" @click="slideTo(0)" data-index="0"
								data-swiper-slide-index="0" :class="activeIndex==0 ? 'whiteFont':''">
								搜老师
							</div>
							<div class="swiper-slide swiper-slide-duplicate" @click="slideTo(1)" data-index="1"
								data-swiper-slide-index="1" :class="activeIndex==1 ? 'whiteFont':''">
								搜样音
							</div>
							<div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
								@click="slideTo(2)" data-index="2" data-swiper-slide-index="2"
								:class="activeIndex==2 ? 'whiteFont':''">
								搜案例
							</div>
							<div class="swiper-slide swiper-slide-duplicate-active" @click="slideTo(0)" data-index="0"
								data-swiper-slide-index="0" :class="activeIndex==0 ? 'whiteFont':''">
								搜老师
							</div>
							<div class="swiper-slide swiper-slide-duplicate-next" @click="slideTo(1)" data-index="1"
								data-swiper-slide-index="1" :class="activeIndex==1 ? 'whiteFont':''">
								搜样音
							</div>
							<div class="swiper-slide swiper-slide-prev" data-index="2" @click="slideTo(2)"
								data-swiper-slide-index="2" :class="activeIndex==2 ? 'whiteFont':''">
								搜案例
							</div>
							<div class="swiper-slide swiper-slide-duplicate swiper-slide-active" @click="slideTo(0)"
								data-index="0" data-swiper-slide-index="0" :class="activeIndex==0 ? 'whiteFont':''">
								搜老师
							</div>
							<div class="swiper-slide swiper-slide-duplicate swiper-slide-next" @click="slideTo(1)"
								data-index="1" data-swiper-slide-index="1" :class="activeIndex==1 ? 'whiteFont':''">
								搜样音
							</div>
							<div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
								@click="slideTo(2)" data-index="2" data-swiper-slide-index="2"
								:class="activeIndex==2 ? 'whiteFont':''">
								搜案例
							</div>
						</div> <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
					</div>
				</div>
				<p class="hot-search flex-wrap">
					<a class="hot-item" @click="slideTo2(activeIndex,item)" v-for="item in searchTextList">{{item}}</a>
				</p>
			</div>
			<span class="text-or">或</span>
			<button class="btn-search-audio btn-lg-ff2" @click="showKefu()">咨询客服</button>
		</div>
		<div class="guangGaoImgBox">
			<a :href="guangGaoUrl" target="_blank">
				<div class="flipper" :class="{'flipped': isFlipped}">
					<div class="front">
						<img :src="guangGaoImg" style="width: 224px;">
					</div>
					<div class="back">
						<img :src="guangGaoImg" style="width: 224px;">
					</div>
				</div>
			</a>
		</div>
		<el-dialog custom-class='customclass' :visible.sync="dialogVisible_content" width="22%" :show-close='false'
			style="min-width: 350px;" class="kefuBox" :append-to-body='true'>
			<keFuDialog :dialogVisible_content.sync="dialogVisible_content" :kefuObj="kefuObj"></keFuDialog>
		</el-dialog>
		<el-dialog :visible.sync="keFuDialogThree_dialogVisible" width="480px" :show-close='false' class="kefuBox3"
			:modal-append-to-body='false'>
			<keFuDialogThree :dialogVisible_content.sync="keFuDialogThree_dialogVisible" :kefuObj='kefuObj3'>
			</keFuDialogThree>
		</el-dialog>
	</div>
</template>

<script>
	// 引入swiper插件的js
	import Swiper from "swiper"
	import goTop from '../../utils/goTop.js'
	import keFuDialog from "../keFuDialog.vue"
	import keFuDialogThree from "@/components/keFuThree.vue"
	export default {
		name: 'banner',
		components: {
			keFuDialog,
			keFuDialogThree
		},
		data() {
			return {
				isFlipped: false, // 图片是否翻转
				styleCount: 'transition-duration: 5ms; transform: translate3d(0px, -150px, 0px);',
				preActiveIndex: 0,
				activeIndex: 1,
				keyword: '',
				kefuObj: {
					qq: '',
					wx: ''
				},
				dialogVisible_content: false,
				searchTextList: [],
				dialogAAAAA: {
					background: '#4f80f6',
					borderRadius: '20px'
				},
				bgimg: '',
				guangGaoImg: '',
				guangGaoUrl: '',
				keFuDialogThree_dialogVisible: false,
				kefuObj3: {
					type: '',
					image: ''
				},
			}
		},
		created() {
			this.getAdByTag()
		},
		mounted() {
			// 每隔1s自动翻转
			setInterval(() => {
				this.isFlipped = !this.isFlipped;
			}, 4000);
			this.myswiper()
			if (localStorage.getItem("searchTextList")) {
				this.searchTextList = JSON.parse(localStorage.getItem("searchTextList"))
			}
			this.getSearchHotList()

		},
		methods: {
			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_index_banner'
				}).then(res => {
					this.bgimg = res.data[0].ad_pic
				})
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_right_banner'
				}).then(res => {
					console.log("/Ad/getAdByTag-->", res.data)
					this.guangGaoImg = res.data[0].ad_pic;
					this.guangGaoUrl = res.data[0].ad_url;
				})
			},
			getSearchHotList() {
				this.$post('/Ad/getHotSearch').then(res => {
					this.searchTextList = res.data
				})
			},
			showKefu() {
				var that = this;
				this.$post('/Index/getKfCodeImg', {
					ename: 'kf_code'
				}).then(res => {
					console.log(res)
					that.keFuDialogThree_dialogVisible = true;
					that.kefuObj3 = {
						type: 3,
						image: res.data.code
					}
				})
				// this.dialogVisible_content = true
				// this.$post('/Index/getcKfCode', {}).then(res => {
				// 	this.kefuObj = res.data
				// 	this.dialogVisible_content = true;
				// 	console.log(res.data)
				// })
				// this.$parent.$parent.$refs.headerRef.showKefu
				// console.log(this.$parent.$parent.$refs)
			},
			myswiper: function() {
				this.swiper = new Swiper('.swiper-container', {
					pagination: {
						el: '.swiper-pagination',
					},
				});
			},
			slideTo2(type, keytext) {
				sessionStorage.setItem('keyword', keytext)
				switch (type) {
					case 0:
						this.$router.push({
							path: '/dubbingTeacher',
						})
						goTop()
						break;
					case 1:
						this.$router.push({
							path: '/sampleAudition',
						})
						goTop()
						break;
					case 2:
						this.$router.push({
							path: '/dubbingCase',
						})
						goTop()
						break;
					default:
						break;
				}
				return;
			},
			slideTo(type) {
				console.log("下一个" + type + "当前的" + this.activeIndex + "前一个" + this.preActiveIndex)
				if (this.activeIndex == type) {
					sessionStorage.setItem('keyword', this.keyword)
					if (this.searchTextList.length < 10 && this.keyword != '') {
						this.searchTextList.push(this.keyword)
						localStorage.setItem("searchTextList", JSON.stringify(this.searchTextList))
					}

					switch (type) {
						case 0:
							this.$router.push({
								path: '/dubbingTeacher',
							})
							goTop()
							break;
						case 1:
							this.$router.push({
								path: '/sampleAudition',
							})
							goTop()
							break;
						case 2:
							this.$router.push({
								path: '/dubbingCase',
							})
							goTop()
							break;
						default:
							break;
					}
					return;
				} else {
					this.preActiveIndex = this.activeIndex
					this.activeIndex = type
					if (type == 0) {
						this.styleCount = 'transition-duration: 5ms; transform: translate3d(0px, -250px, 0px);'
					} else if (type == 1) {
						this.styleCount = 'transition-duration: 5ms; transform: translate3d(0px, -150px, 0px);'

					} else if (type == 2) {
						this.styleCount = 'transition-duration: 5ms; transform: translate3d(0px, -200px, 0px);'
					}
				}
			}
		}
	}
</script>

<style scoped>
	.banner{
		height: 400px;
		padding-top: 130px;
	}
	.el-dialog__wrapper {
		z-index: 9999 !important;
		/* 修改为更高的层级 */
	}

	.el-dialog__body {
		background-color: #fff;
		border-radius: 20px !important;
	}

	.kefuBox .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}

	.guangGaoImgBox {
		width: 224px;
		position: fixed;
		right: 45px;
		top: 80px;
	}

	.flipper {
		width: 100%;
		height: 100%;
		position: relative;
		transform-style: preserve-3d;
		transition: all 0.8s ease-in-out;
	}

	.front,
	.back {
		position: absolute;
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
	}

	.back {
		transform: rotateY(180deg);
	}

	.flipped {
		transform: rotateY(180deg);
	}

	.whiteFont {
		color: #fff !important;
		font-size: 16px !important;
		font-weight: 600 !important;
	}

	.swiper-slide {
		/* background-color: rgba(255, 0, 0, 0.1); */
	}

	/* /deep/.dialogBox {
		background: #4f80f6;
		border-radius: 20px;
		text-align: center;
	}
 */
	.guangGaoImgBox img {
		cursor: pointer;
	}
</style>
