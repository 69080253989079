<template>
	<div class="rightContentBox">
		<ul class="menuList">
			<li class="rightItemActive" :class="{active: tabActive === 0}" @click="tabActive=0">
				配音订单
			</li>
			<li class="rightItemActive" :class="{active: tabActive === 1}" @click="tabActive=1">
				AI配音订单
			</li>
		</ul>
		<div v-if="tabActive === 0" class="content">
			<div>
				<el-date-picker v-model="form.time" type="daterange" align="right" unlink-panels range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
				<el-input v-model="form.content" placeholder="请输入订单编号"></el-input>
				<el-select v-model="form.status" placeholder="请选择订单状态" style="margin-left: 20px;" class="order_select">
					<el-option v-for="item in selectOptions" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
				<br />
				<el-button type="primary" @click="getConsumeLog()">查询</el-button>
				<el-button @click="reset()">重置</el-button>
			</div>
			<p class="recordHeader">
				<span style="width: 50%;">服务</span>
				<span style="width: 20%;">订单时间</span>
				<span style="width: 20%;">金额</span>
				<span style="width: 10%;">状态</span>
			</p>
			<ul class="recordList">
				<template v-for="item in recordList">
					<li class="recordLi">
						<p class="recordTit">
							<span>{{item.addtime}}</span>
							<span>{{item.order_sn}}</span>
						</p>
						<div class="recordContent">
							<div style="width: 50%;" class="detail">
								<img :src="item.headimgurl" style="width: 100px;height: 100px" />
								<div class="rightBox">
									<p class="text-overflow" style="width: 100%;">{{item.nickname}}</p>
									<ul>
										<!-- <template v-for="tagName in item.tags">
											<li>{{tagName}}</li>
										</template> -->
										{{item.speciality}}
									</ul>
								</div>
							</div>
							<p style="width: 20%;" class="deliveryTime">{{item.addtime}}</p>
							<p style="width: 20%;" class="price">￥{{item.amount}}</p>
							<div style="width: 10%;" class="optionsBox">
								<el-button :type="item.status === 0 ? 'primary' : item.status === 1? 'success':item.status === 4?'info':'warning'" @click="selPayTypeBoxFn(item)">
									{{item.status == 0?'等待支付':item.status == 1?'支付成功':item.status == 4?'等待处理':'交易完成'}}
								</el-button>

								<el-button @click="showKefu(item)">
									咨询制作
								</el-button>
								<!-- <el-button @click="delDef(item.id)">
									删除订单
								</el-button> -->
								<el-button v-if="item.status == 1" @click="tuiKuaiDef(item)">
									申请退款
								</el-button>
								<el-button v-if='item.status == 2'
									@click="fapiaoBox=true;selOrderNum = item.id;formLabelAlign.price=item.amount">
									发票申请
								</el-button>
								<el-button v-if='item.status == 3'>
									已开票
								</el-button>
								<!-- <el-button v-if='item.status == 4'>
									申请退款中
								</el-button> -->
							</div>
						</div>
					</li>
				</template>
			</ul>
			<div class="paging-box qt-pagination" v-if='totalPage >0'>
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
						:page-size="page.limit" :current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>


			</div>
			<div style="text-align: center;" v-else-if='totalPage == 0'>
				<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
				<p style="text-align: center;color: #999;margin-bottom: 20px;">暂无收藏内容，快去收藏吧</p>
			</div>
		</div>
		<div  class="content" v-if="tabActive === 1">
			<el-table :data="orderData" style="width: 100%">
					<el-table-column prop="id" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="payTime" label="下单时间" align="center"></el-table-column>
					<el-table-column prop="orderAmount" label="订单金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.orderAmount}}元</span>
						</template>
					</el-table-column>
					<el-table-column prop="month" label="购买会员时长" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.month}}个月</span>
						</template>
					</el-table-column>
					<el-table-column prop="payType" label="支付方式" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.payType == 1">微信支付</span>
							<span v-else-if="scope.row.payType == 2">支付宝支付</span>
						</template>
					</el-table-column>
					<el-table-column prop="priceType" label="购买类型" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.priceType == 1">普通会员</span>
							<span v-else-if="scope.row.priceType == 2">高级会员</span>
						</template>
					</el-table-column>
			</el-table>
		</div>
		<el-dialog title="申请发票" :visible.sync="fapiaoBox">
			<el-form label-position="right" label-width="80px" :model="formLabelAlign">
				<el-form-item label="发票类型">
					<!-- <el-select v-model="formLabelAlign.region" placeholder="抬头类型">
						<el-option label="个人" :value="1"></el-option>
						<el-option label="企业" :value="2"></el-option>
					</el-select> -->
					<el-radio-group v-model="formLabelAlign.region" class="ml-4">
						<el-radio :label="1" size="large">个人</el-radio>
						<el-radio :label="2" size="large">企业</el-radio>
					</el-radio-group>

				</el-form-item>
				<el-form-item label="发票抬头">
					<el-input v-model="formLabelAlign.taitou"></el-input>
				</el-form-item>
				<el-form-item label="接收邮箱">
					<el-input v-model="formLabelAlign.email"></el-input>
				</el-form-item>
				<el-form-item label="联系方式">
					<el-input v-model="formLabelAlign.tel"></el-input>
				</el-form-item>
				<el-form-item label="地址">
					<el-input v-model="formLabelAlign.address"></el-input>
				</el-form-item>
				<el-form-item label="公司税号" v-show='formLabelAlign.region == 2'>
					<el-input v-model="formLabelAlign.shuihao"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号" v-show='formLabelAlign.region == 2'>
					<el-input v-model="formLabelAlign.cardNum"></el-input>
				</el-form-item>
				<el-form-item label="开户行" v-show='formLabelAlign.region == 2'>
					<el-input v-model="formLabelAlign.openBankName"></el-input>
				</el-form-item>
				<el-form-item label="发票备注">
					<el-input v-model="formLabelAlign.remark"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交申请</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
		<el-dialog :visible.sync="selPayTypeBox" top='35vh' class="selPayTypeBox" @close='closeDef' width="600px">
			<div
				style="height: 100px;display: flex;background-color:#f7d79e;padding:20px 20px;box-sizing: border-box;border-radius: 20px 20px 0 0;">
				<img :src="userInfo.headimgurl"
					style="width: 60px;height: 60px;border-radius: 30px;margin-right: 20px;" />
				<div style="flex:1;">
					<p style='font-size:18px;font-weight:600'>{{userInfo.user_name}}</p>
					<p
						style='font-weight:600;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;'>
						扫码下单，支持开具发票。
						<span style="color:#a56a00;cursor: pointer;"
							@click="cdkListBox(selPayPrice- selCdkItem.dec_price)"><i
								class="iconfont icon-gerenzhongxin-duihuanzhongxin"
								style='margin-left:10px;'></i>优惠券</span>
					</p>
				</div>
			</div>
			<div class="payBottom" style='margin-top: 20px;'>
				<div class="leftBox">
					<p class="payP">选择支付方式</p>
					<p :class="['methodsP',{'selected':payMethods ==0 }]" @click="changePayMethods(0)">
						<img src="../../assets/image/dubbingteacher/wx.png" />
						<img src="../../assets/image/dubbingteacher/zhifubao.png" />
						<span>线上付款</span>
					</p>
					<p :class="['methodsP',{'selected':payMethods ==1 }]" @click="changePayMethods(1)">
						<!-- <img src="../../assets/image/dubbingteacher/zhifubao.png" /> -->
						<img src="../../assets/image/dubbingteacher/yueIcon.png" alt="">
						<span>余额支付</span>
					</p>
				</div>
				<div class="rightBox">
					<!-- <p class="priceP">应付金额：<span>{{wordCountPrice*teacherInfo.min_price - selCdkItem.dec_price}}</span>元</p> -->
					<div class='chargeBox' v-if='payMethods == 0'>
						<div class="oneCharge">
							<iframe :srcdoc="alipayForm.zfb" frameborder="0" marginwidth="0" marginheight="0"
								scrolling="no" width="150" height="150">
							</iframe>
							<p>支付宝支付</p>

						</div>
						<div class="oneCharge">
							<div id="qrCode" ref="qrCodeDiv" style="margin: 0 auto;width: 150px;height: 150px;"></div>
							<p>微信支付</p>

						</div>
					</div>
					<div class='chargeBox' v-if='payMethods == 1'>
						<div style="text-align: center;">
							<div style="line-height: 80px;">使用余额支付</div>
							<button @click="payTeacher(3)"
								style="width: 100px;height: 40px;border: 1px solid #f2f2f2;background-color: #eb3637;color: white">立即下单</button>
						</div>
					</div>
					<div class='chargeBox' v-if='payMethods == 2'>
						<p style="line-height: 80px;">对公转账/汇款账号：12345698900877</p>
					</div>
				</div>

			</div>
		</el-dialog>
		<el-dialog :visible.sync="showcdkListBox" width="900" :modal-append-to-body='false'>
			<div>
				<div style="text-align: center;font-size: 20px;font-weight: bold;color: #eb3637;padding-bottom: 10px;">
					我的优惠券</div>
				<el-table :data="usedList" height="250" border :header-cell-style="{'text-align':'center'}"
					:cell-style="{'text-align':'center'}" style="width: 100%">
					<el-table-column prop="dec_price" label="金额" width="100"> </el-table-column>
					<el-table-column prop="man_price" label="使用条件" width="100"> </el-table-column>
					<el-table-column prop="time" label="到期日期" width="200"></el-table-column>
					<el-table-column prop="stauts" label="状态">
						<template slot-scope="scope">
							<el-button size="mini" @click="selCdk(scope.$index, scope.row)"
								style="background-color: #eb3637;color: white;">使用</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import teacherDialog from "@/components/teacherDialog.vue"
	import {getMyOrderPage} from "@/request/aiDubbing";
	var qrcode = null;
	var timer = null
	export default {
		data() {
			return {
				tabActive:0,
				orderData:[],
				userInfo: {
					headimgurl: '',
					user_name: ''
				},
				totalPage: null,
				page: {
					currentPage: 1,
					limit: 5,
				},
				pagePrev: true,
				pageNext: false,
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				form: {
					time: '',
					content: "",
					status: ''
				},
				recordList: [],
				fapiaoBox: false,
				payMethods: 0,
				selOrderNum: '',
				formLabelAlign: {
					region: 1,
					price: '',
					taitou: '',
					email: '',
					tel: '',
					address: '',
					shuihao: '',
					cardNum: '',
					openBankName: '',
					remark: ''
				},
				selPayTypeBox: false,
				alipayForm: {
					wx: '',
					zfb: ''
				},
				recordId: null,
				kefuBoxShow: false,
				selectOptions: [{
						value: '',
						label: '所有订单',
					},
					{
						value: '1',
						label: '未支付',
					},
					{
						value: '2',
						label: '已支付',
					},
					{
						value: '3',
						label: '申请关闭',
					},
					{
						value: '4',
						label: '已完成',
					}
				],
				kefuObj: {},
				options: [{
						value: '',
						label: '所有订单',
					},
					{
						value: '1',
						label: '未支付',
					},
					{
						value: '2',
						label: '已支付',
					},
					{
						value: '3',
						label: '申请关闭',
					},
					{
						value: '4',
						label: '已完成',
					}
				],
				showcdkListBox: false,

				usedList: [], //优惠券列表
				selCdkItem: {
					dec_price: 0
				},
			}
		},
		components: {
			teacherDialog
		},
		mounted() {
			if (localStorage.getItem("userInfo")) {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
			}
			this.getConsumeLog()
			this.getOrderData()
		},
		deactivated() {
			this.closeDef()
		},
		methods: {
			async getOrderData(){
				try{
					const {data} = await getMyOrderPage({
						pageNum:1,
						pageSize: 200,
					})
					if(data && data.list){
						this.orderData = data.list
					}
				}catch (e) {
					console.log(e)
				}
			},
			reset() {
				this.form = {
					time: '',
					content: "",
					status: ''
				}
				this.getConsumeLog()
			},
			showKefu(item) {
				this.$post('/Index/getcKfCode', {}).then(res => {
					console.log(res, item)
					this.kefuObj = res.data
					this.kefuObj.nickname = item.nickname;
					this.kefuObj.headimgurl = item.headimgurl;
					this.kefuObj.on_line = item.on_line
					this.kefuBoxShow = true
				})
			},
			bindQRCode(data) {
				console.log(this.$refs.qrCodeDiv)
				if (qrcode != null) {
					this.$refs.qrCodeDiv.innerHTML = ''
				}
				qrcode = new QRCode(this.$refs.qrCodeDiv, {
					text: data,
					width: 150,
					height: 150,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})
			},
			closeDef() {
				this.selCdkItem = {
					dec_price: 0
				}
				console.log('关闭定时器')
				clearInterval(timer)
			},
			changePayMethods(index) {
				this.payMethods = index;
				if (index == 0) {
					this.$nextTick(function() {
						this.bindQRCode(this.alipayForm.wxUrl);
					});
				}

			},
			selPayTypeBoxFn(item) {
				if (item.status != 0) {
					return;
				}
				console.log(item);
				this.selPayPrice = item.amount
				this.recordId = item.id;
				this.selPayTypeBox = true;
				this.payTeacher(1);
			},
			payTeacher(type) {
				let data = {
					id: this.recordId,
					type: type,
					coupon_id: ''
				}
				this.$post('/Order/waitPay', data).then(res => {
					console.log(res)
					if (type == 1) {
						timer = setInterval(() => {
							this.selectOrder(res.data.wxpay.order_id)
						}, 2000)
						this.alipayForm.wxUrl = res.data.wxpay.url;
						this.alipayForm.zfb = res.data.zfbpay
						this.$nextTick(function() {
							this.bindQRCode(res.data.wxpay.url);
						})
					} else {
						this.selPayTypeBox = false
						this.$message({
							message: res.mess,
							type: 'success'
						})
					}
				})
			},
			selectOrder(id) {
				let data = {
					orderid: id
				}
				this.$post('/Order/selectOrder', data).then(res => {
					console.log(res)
					if (res.data == 1) {
						this.selPayTypeBox = false
						this.$message({
							type: 'success',
							message: '支付成功'
						})
						this.getConsumeLog()
					}
				})
			},
			tuiKuaiDef(item) {
				if (confirm('您是否提交退款申请？')) {
					let data = {
						order_id: item.id
					}
					this.$post('/MemberInfo/requestRefund', data).then(res => {
						console.log(res)
						this.$message({
							message: '申请退款成功',
							type: 'success'
						})
						this.getConsumeLog()
					})
				}
			},
			onSubmit() {
				let data = {
					order_id: this.selOrderNum,
					price: this.formLabelAlign.price,
					mailbox: this.formLabelAlign.email,
					header_type: this.formLabelAlign.region,
					header: this.formLabelAlign.taitou,
					number: this.formLabelAlign.shuihao,
					bank: this.formLabelAlign.openBankName,
					bank_num: this.formLabelAlign.cardNum,
					address: this.formLabelAlign.address,
					tel: this.formLabelAlign.tel,
					remark: this.formLabelAlign.remark
				}
				this.$post('/MemberInfo/invoice', data).then(res => {
					console.log(res)

					this.formLabelAlign = {
						region: '',
						taitou: '',
						email: '',
						tel: '',
						shuihao: '',
						cardNum: '',
						openBankName: '',
						qiyeAddress: ''
					}
					this.fapiaoBox = false
					this.getConsumeLog()
					this.$message({
						type: 'success',
						message: res.mess
					})
				})
			},
			delDef(id) {
				let data = {
					id: id
				}
				let question = confirm('是否确认删除此记录？')
				if (question != "0") {
					this.$post('/MemberInfo/consumeLogDel', data).then(res => {
						console.log(res)
						this.$message({
							message: '删除成功',
							type: 'success'
						})
						this.getConsumeLog()
					})
				}

			},
			formtime(time) {
				let date = new Date(time)
				var y = date.getFullYear()
				var m = date.getMonth() + 1
				m = m < 10 ? '0' + m : m
				var d = date.getDate()
				d = d < 10 ? '0' + d : d
				var h = date.getHours()
				h = h < 10 ? '0' + h : h
				var minute = date.getMinutes()
				minute = minute < 10 ? '0' + minute : minute
				var second = date.getSeconds()
				second = second < 10 ? '0' + second : second
				return y + '-' + m + '-' + d
			},
			getConsumeLog() {
				console.log('********************')
				console.log(this.formtime(this.form.time[0]))
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					status: this.form.status,
					start_time: this.form.time == '' ? '' : this.formtime(this.form.time[0]),
					end_time: this.form.time == '' ? '' : this.formtime(this.form.time[1]),
					order_sn: this.form.content
				}
				this.$post('/MemberInfo/consumeLog', data).then(res => {
					console.log(res)
					this.totalPage = res.data.count
					this.recordList = res.data.list;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= res.data.count / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getConsumeLog()
			},
			selCdk(e, data) {
				console.log('/*********************')
				console.log(e, data)
				this.selCdkItem = data
				this.showcdkListBox = false
				if (this.payMethods == 0) {
					this.payTeacher(1)
					// this.payTeacher(1)
				}
			},
			cdkListBox(price) {
				console.log(price)
				let data = {
					price: price
				}
				this.$post('/Order/coupon', data).then(res => {
					this.showcdkListBox = true
					this.usedList = res.data
				})
			},
		}
	}
</script>

<style scoped>
	/deep/ .m-2 {
		z-index: 9999 !important;
	}

	/deep/ .el-popper {
		z-index: 9999 !important;
	}

	/deep/.el-dialog {
		width: 600px;
		border-radius: 20px !important;
	}

	/deep/.selPayTypeBox .el-dialog__body {
		padding: 0 !important;
		border-radius: 20px;
	}

	.selPayTypeBox .payBottom {
		margin-top: 40px;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
	}

	.selPayTypeBox .payBottom .leftBox {
		margin-right: 50px;
	}

	.selPayTypeBox .payBottom .leftBox .methodsP {
		margin: 20px 0;
		width: 150px;
		height: 50px;
		border-radius: 10px;
		border: 1px solid #dbdbdb;
		line-height: 50px;
		text-align: center;
	}

	.selPayTypeBox .payBottom .leftBox .methodsP.selected {
		border: 1px solid #398ffe;
	}

	.selPayTypeBox .payBottom .leftBox .methodsP img {
		width: 20px;
		height: 20px;
		margin: 0 5px;
	}

	.selPayTypeBox .payBottom .rightBox {
		flex: 1;
	}

	.selPayTypeBox .payBottom .rightBox .priceP {
		text-align: center;
	}

	.selPayTypeBox .payBottom .rightBox .priceP span {
		color: #e30000;
		font-size: 24px;
		font-weight: 600;
		margin: 0 5px;
	}

	.selPayTypeBox .payBottom .rightBox .chargeBox {
		display: flex;
		justify-content: center;
		margin-top: 20px;

	}

	.selPayTypeBox .payBottom .rightBox .chargeBox .oneCharge {
		margin-right: 20px;
	}

	.selPayTypeBox .payBottom .rightBox .chargeBox .oneCharge p {
		text-align: center;
	}

	.rightContentBox .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		overflow: hidden;
	}

	.rightContentBox .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		color: #8c8c8c
	}

	.rightContentBox .menuList>li.rightItemActive {
		color: #2c3e50
	}

	.rightContentBox .menuList>li.rightItemActive.active:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 4px;
		left: 10%;
		bottom: -15px;
		background-color: #e83233;
	}

	.recordList {}

	.recordList .recordLi {
		padding: 0 20px;
		border: 1px solid #e1e1e1;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
		margin-top: 10px;
	}

	.recordList .recordTit {
		height: 40px;
		display: flex;
		align-items: center;
		border-bottom: 1px dashed #e1e1e1;
	}

	.recordList .recordTit span {
		margin: 0 20px;
	}

	.recordContent {
		display: flex;
		align-items: center;
		padding: 30px 10px;
	}

	.recordContent .detail {
		display: flex;
		align-items: center;
	}

	.recordContent .detail img {
		width: 140px;
		border-radius: 10px;
		margin-right: 20px;
	}

	.recordContent .detail .rightBox {
		width: calc(100% - 160px);
	}

	.recordContent .detail .rightBox ul {
		display: flex;
		align-items: center;
		margin-top: 10px;
		flex-wrap: wrap;
	}

	.recordContent .detail .rightBox ul li {
		margin-right: 10px;
		background-color: #ebebeb;
		padding: 2px 5px;
		color: #a6a6a6;
		margin-bottom: 5px;
	}

	.recordLi .recordContent .optionsBox .el-button {
		padding: 5px 10px;
	}

	.recordLi .recordContent .optionsBox .el-button.el-button--default {
		margin-left: 0px !important;
	}

	.el-range-editor.el-input__inner:active,
	.el-range-editor.el-input__inner:focus {
		border-color: #DCDFE6 !important;
	}

	.el-range-editor.is-active,
	.el-range-editor.is-active:hover,
	.el-select .el-input.is-focus .el-input__inner {
		border-color: #DCDFE6 !important;
	}

	.el-button--primary,
	.el-button--primary:hover {
		background-color: #e83233;
		border: none;
		margin-left: 50px;
	}

	.el-button--default:hover,
	.el-button--default:focus,
	.el-button--default:active {
		background-color: transparent !important;
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}

	.content .el-input,
	.content .el-textarea {
		width: 30%;
		margin-left: 20px;
	}

	.el-button {
		margin-top: 20px;
		border-radius: 15px;
	}

	.el-button--primary,
	.el-button--primary:hover {
		margin-left: 0px;
	}

	.recordHeader {
		padding: 0 20px;
		box-sizing: border-box;
		margin-top: 20px;
		height: 40px;
		display: flex;
		align-items: center;
		background-color: rgba(232, 168, 170, 0.2);
	}
	@media only screen and (max-width: 1440px) {
		.order_select{
			margin-top: 20px;
			margin-left: 0px !important;
		}
	}
</style>
