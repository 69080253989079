<template>
	<div class="rightContentBox">
		<ul class="menuList">
			<li class="rightItemActive">
				我的消息
			</li>
		</ul>
		<div class="content">
			<ul class="messageUl">
				<template v-for="item in messageList">
					<li>
						<p class="timeTit">{{item.time}}</p>
						<div class="messageDiv">
							<div class="contentTit">
								<p class="contentTit-tit">
									{{item.title}}
								</p>
								<p class="options" v-if="item.is_read == 0">
									<span>
										<el-badge is-dot class="item">
											未读
										</el-badge>
									</span>

									<span @click="optionsFn(0,item.id)">标记已读</span>
								</p>
								<p class="options" v-else>
									<span>已读</span>
									<span @click="optionsFn(1,item.id)">&times;</span>
								</p>
							</div>
							<p class="message">{{item.content}}</p>
						</div>
					</li>
				</template>
			</ul>
			<div class="paging-box qt-pagination" v-if='totalPage >0'>
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
						:page-size="page.limit" :current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>


			</div>
			<div style="text-align: center;" v-else-if='totalPage == 0'>
				<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
				<p style="text-align: center;color: #999;margin-bottom: 20px;">暂无收藏内容，快去收藏吧</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				totalPage: null,
				page: {
					currentPage: 1,
					limit: 5,
				},
				pagePrev: true,
				pageNext: false,
				messageList: []
			}
		},
		mounted() {
			this.getMyNews()
		},
		methods: {

			getMyNews() {
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
				}
				console.log("this.page.currentPage--》", this.page.currentPage)
				this.$post('/MemberInfo/myNews', data).then(res => {
					this.messageList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= res.data.count / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			optionsFn(type, messageId) {
				if (type == 0) {
					let data = {
						id: messageId
					}
					this.$post('/MemberInfo/newsRead', data).then(res => {
						this.$message({
							message: '标记已读',
							type: 'success'
						})
						this.$post('/MemberInfo/newNum', {}).then(res => {
							this.$store.state.newsNum = res.data
						})
						this.getMyNews()
					})
				} else {
					// 删除
					this.$message({
						message: '已删除',
						type: 'success'
					})
				}
			},
			// 切换分页
			changePageData(count) {
				this.page.currentPage = this.page.currentPage + count;
				this.getMyNews()
			},
		}
	}
</script>

<style scoped>
	.rightContentBox .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		overflow: hidden;
	}

	.rightContentBox .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		color: #8c8c8c
	}

	.rightContentBox .menuList>li.rightItemActive {
		color: #2c3e50
	}

	.rightContentBox .menuList>li.rightItemActive:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 4px;
		left: 10%;
		bottom: -15px;
		background-color: #e83233;
	}

	.timeTit {
		line-height: 30px;
		height: 30px;
		color: #a39797
	}

	.content {
		padding: 20px;
		cursor: pointer;
	}

	.contentTit {
		display: flex;
		align-content: center;
		justify-content: space-between;
	}

	.contentTit .contentTit-tit {
		font-weight: 600;
		font-size: 16px;
	}

	.contentTit .options {
		width: 10%;
		min-width: 100px;
		display: flex;
		justify-content: space-between;
	}

	.messageUl li {
		margin: 0 0 20px 0;

	}

	.messageUl li .messageDiv {
		border-radius: 5px;
		padding: 10px;
		box-sizing: border-box;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1)
	}

	.messageUl li span:nth-of-type(2) {
		opacity: 0;
	}

	.messageUl li:hover span:nth-of-type(2) {
		opacity: 1;
	}

	.messageUl li .message {
		color: #333333;
		margin: 10px 0;
	}
</style>
