import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/store.js";
import ElementUI from "element-ui";
import jquery from 'jquery';
import '@/assets/iconfont/iconfont.css'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import { post } from "./request/http";
import { toTask } from "./request/http";
// animate动画
import 'animate.css/animate.min.css'
import axios from 'axios'
Vue.prototype.axios = axios
Vue.prototype.$post = post
Vue.prototype.$toTask = toTask
    /* 引入aos动画库相关文件 */
import AOS from 'aos'
import 'aos/dist/aos.css'
// 粘贴板
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

//个人信息  地址三级联动
//  import { pca, pcaa } from 'area-data';
import VueAreaLinkage from 'vue-area-linkage'
Vue.use(VueAreaLinkage)
    /* aos动画初始化 */
AOS.init({
    duration: 1000,
    easing: "ease-in-out-back"
});
import "element-ui/lib/theme-chalk/index.css";
Vue.prototype.bus = new Vue();
Vue.use(vueMiniPlayer);
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI);
Vue.config.productionTip = false;

// 全局登录变量
// Vue.prototype.dialogVisible_login=false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");