<template>
	<div style="">
		<!-- <div class="Dialog-log clrafix">
			<div class="dl-right">
				<div class="dlr-logWay" style="">
					<div class="dlr-phone">
						<ul class="dlr-phone-lists">
							<li class="dlr-phone-items"><label class="p">手机号</label>
								<div class="dlr-input el-input el-input--suffix">
									<input v-model="phone" type="tel" autocomplete="off" placeholder="请输入您的手机号" maxlength="11"
										class="el-input__inner">
								</div>
							</li>
							<li class="dlr-phone-items"><label class="p">验证码</label>
								<div class="clearfix">
									<div class="dlr-input dlr-input-code fl el-input ">
										<input v-model="smsCode" type="text" autocomplete="off" placeholder="请输入验证码"
											class="el-input__inner">
									</div>
									<button @click="sendSmsCode()" id="captchaBtn" type="button"
										class="btn-getCode fr hov-f2f-btn" v-if="panDuan == 0">获取验证码</button>
									<button type="button" class="btn-getCode fr hov-f2f-btn" v-else>{{ remainingTime }}s</button>
								</div>
							</li>
							<li class="dlr-phone-items"><button type="button"
									class="btn-goLog lg-f2f-btn" @click="loginFn()">立即登录</button></li>
						</ul>
					</div>
				</div>
				<p class="Dialog-p" style="text-align: left;width: 340px;margin: 10px auto;">使用手机号快速登录，无账号将自动登录</p>
				<p class="Dialog-p"><input type="checkbox" checked="checked" class="input-agree">
					我已阅读并同意
					<a href="javaScript:viod(0)" target="_blank" class="p">《声探配音网络服务使用协议》</a>和
					<a href="javaScript:viod(0)" target="_blank" class="p">《声探配音用户隐私条款》</a>
				</p>

			</div>
		</div> -->
		<div style="display: flex;margin-bottom: 20px;">
			<div style="font-weight: 30px;color: #000;font-size: 20px;font-weight: bold">
				<img :src="logoImgUrl" style="width: 100px;" alt="">
				<span style="border-left: 2px solid #000;margin-left: 20px;padding-left: 20px">配音服务创作平台</span>
			</div>
		</div>

		<div style="padding-bottom: 20px;">
			<div style="width: 100px;height: 10px"></div>
			<span
				style="color: #0054d3;border-bottom: 2px solid #0054d3;font-size: 17px;padding: 20px 0 0 0;">快捷免密登录</span><br>
			<div style="width: 100px;height: 10px"></div>
			<span>未注册的手机号登录后将自动创建账号</span>
		</div>

		<div>
			<div class="loginVal">
				<div style="width: 60px;text-align: center">
					<img src="../../public/img/phoneIcon.png"
						style="width: 25px;height: 25px;position: relative;top: 10px;" alt="">
				</div>
				<div style="width: 80%;"><input type="text" v-model="phone" style="width: 100%;height: 50px;"
						placeholder="请输入手机号码"></div>
			</div>
			<div class="loginVal" style="border: none;">
				<div style="width: 75%;display: flex;border:1px solid #333333;border-radius: 10px;">
					<div style="width: 60px;text-align: center">
						<img src="../../public/img/yzmIcon.png"
							style="width: 25px;height: 25px;position: relative;top: 10px;" alt="">
					</div>
					<div style="width: 80%;"><input type="text" v-model="picCode" style="width: 100%;height: 50px;"
							placeholder="请输入右侧验证码"></div>
				</div>
				<img :src='codeSrc' @click="getCodeSrcFn" style="width: 25%;padding-left: 10px;" />
			</div>
			<div class="loginVal">
				<div style="width: 60px;text-align: center">
					<img src="../../public/img/dxyzmIcon.png"
						style="width: 25px;height: 25px;position: relative;top: 10px;" alt="">
				</div>
				<div style="width: 60%;">
					<input type="text" v-model="smsCode" style="width: 100%;height: 50px;" placeholder="请输入短信验证码">
				</div>
				<div style="cursor: pointer;color: #0054d3;width: 30%;text-align: center;line-height: 50px;">
					<span @click="sendSmsCode()" id="captchaBtn" v-if="panDuan == 0">获取验证码</span>
					<span v-else>{{ remainingTime }}s</span>
				</div>
			</div>
		</div>
		<p class="Dialog-p" style="margin-top: 20px;"><input type="checkbox" checked="checked" class="input-agree">
			我已阅读并同意
			<a href="javaScript:viod(0)" @click="jumpPage(0)" class="p">《用户协议》</a>和
			<a href="javaScript:viod(0)" @click="jumpPage(1)" class="p">《隐私协议》</a>
		</p>
		<button type="button" class="btn-goLog lg-f2f-btn" style="width: 100%;margin-top: 20px;"
			@click="loginFn()">同意协议并登录</button>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex';
	export default {
		data() {
			return {
				userInfo: {},
				phone: '',
				smsCode: '',
				timer: null,
				remainingTime: 60,
				panDuan: 0,
				picCode: '',
				logoImgUrl: '',
				codeSrc: '',
				uniqid: ''
			}
		},
		props: [
			"dialogVisible",
			"userid"
		],
		mounted() {
			this.$post('/Index/getConfigValue', {
				name: 'web_logos'
			}).then(res => {
				this.logoImgUrl = res.data
			})
			this.getCodeSrcFn()
		},
		methods: {
			jumpPage(e) {
				console.log(e);
				if (e == 0) {
					this.setDialogVisibleLogin(false);
					this.$router.push({
						path: '/about',
						query: {
							parentIndex: 2,
							menuid: 17
						},

					});
				} else {
					this.setDialogVisibleLogin(false);
					this.$router.push({
						path: '/about',
						query: {
							parentIndex: 2,
							menuid: 16
						}
					});
				}
			},
			...mapMutations(['setDialogVisibleLogin']),
			creatDaw() {
				this.show_num = [];
				this.draw(this.show_num);
			},
			getCodeSrcFn() {
				var that = this;
				this.$post('/captcha/get_captcha').then(res => {
					console.log("res.data", res);
					that.codeSrc = res.data.src;
					that.uniqid = res.data.uniqid;
				})
			},
			start() {
				this.timer = setInterval(() => {
					if (this.remainingTime == 0) {
						clearInterval(this.timer)
						this.timer = null
						this.panDuan = 0
					} else {
						this.remainingTime -= 1
					}
				}, 1000);
			},
			sendSmsCode() {
				let data = {
					phone: this.phone,
					type: 1
				}
				this.$post('/SmsCode/send', data).then(res => {
					this.panDuan = 1
					this.start()
					this.$message({
						type: 'success',
						message: '验证码已发送'
					})
				})
			},
			loginFn() {
				if (this.phone == '') {
					this.$message({
						type: 'error',
						message: '请输入手机号'
					})
					return;
				} else if (this.picCode == '') {
					this.$message({
						type: 'error',
						message: '请输入图片验证码'
					})
					return;
				} else if (this.smsCode == '') {
					this.$message({
						type: 'error',
						message: '请输入验证码'
					})
					return;
				}
				localStorage.removeItem('token');
				let data = {
					phone: this.phone,
					sms_code: this.smsCode,
					code: this.picCode,
					uniqid: this.uniqid,
					inviter: this.userid
				}
				var that = this;
				this.$post('/Login/login', data).then(res => {
					that.$message({
						type: 'success',
						message: '登录成功',
						onClose() {
							that.setDialogVisibleLogin(false);
							localStorage.setItem('token', res.data.token)
							if(res.data.Authorization) localStorage.setItem('Authorization', res.data.Authorization)
							that.getUserInfo()
							that.$post('/MemberInfo/newNum', {}).then(res => {
								that.$store.state.newsNum = res.data
							})
						}
					})

				}).catch(() => {
					that.getCodeSrcFn()
				})

			},
			getUserInfo() {
				this.$post('/MemberInfo/readprofile', {}).then(res => {
					console.log(res)
					this.userInfo = res.data
					console.log(this.userInfo)
					localStorage.setItem(
						"userInfo",
						JSON.stringify(this.userInfo)
					);
					localStorage.setItem(
						"loginFlag",
						true
					);
					this.$router.go(0)
				})
			}

		}
	}
</script>

<style>
	.el-dialog__header {
		display: none;
	}

	.loginVal {
		display: flex;
		border: 1px solid #333333;
		border-radius: 10px;
		height: 50px;
		margin-bottom: 10px;
	}

	#canvas {
		vertical-align: middle;
		box-sizing: border-box;
		cursor: pointer;
	}
</style>
