<template>
	<div class="aside-wrap">
		<aside id="aside-nav" class="aside-nav">
			<div class="aside-box dir-c">
				<ul class="aside-lists">
					<li class="aside-items">
						<div class="aside-item">
							<a rel="nofollow" href="javaScript:void(0)" @click="goPage(0)" class="aside-item-content">
								<i class="iconfont icon-chongzhi1"></i>
								<span>配音充值</span>
							</a>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item"><a rel="nofollow"
								class="aside-item-content" @click="goPage(5)">
								<i class="iconfont icon-daikexiadan"></i>
								<span>意见反馈</span></a>
						</div>
					</li>
					
					<li class="aside-items" @mouseenter="changeAsideItemShowFlag">
						<div :class="asideItemShowFlag ? 'aside-item aside-item-show':'aside-item'">
							<div class="aside-item-content">
								<i class="iconfont icon-kefu1"></i>
								<span>联系<br>客服</span>
							</div>
							<div class="drop-aside">
								<div class="drop-aside-content content-community">
									<div class="qr-code main-btw">
										<div><img
												:src="kefuObj.wx">
											<span style="color: #FF3916;font-weight: bolder;">扫码添加客服，免费试音！</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item">
							<div class="aside-item-content">
								<i class="iconfont icon-jiaruwomen"></i>
								<span>加入<br>我们</span>
							</div>
							<div class="drop-aside">
								<div class="drop-aside-content content-service" style="background-color: white;">
									<div style="padding: 0px 10px 20px 10px;">
										<img hz-lazy-tgt="aside-items-group"
											alt="官方微信群"
											:src="kefuimg">
										<span style='font-size: 15px;color: #666666;'>
											工作时间 <br>
											<span style="font-weight: bold;padding-top: 3px;">{{custObj.working}}</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="aside-items aside-items-activity">
						<div class="aside-item">
							<a rel="nofollow" href="javaScript:void(0)" @click="goPage(1)" class="aside-item-content">
								<i class="iconfont icon-jifenhaoli"></i>
								<span>积分<br>好礼</span>
							</a>

						</div>
					</li>
					<li class="aside-items aside-items-point">
						<div class="aside-item">
							<div class="aside-item-content" @click="goPage(4)"><i class="iconfont icon-qiandao"></i>
								<span>签到</span>
							</div>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item"><a href="javaScript:void(0)" @click="goPage(2)" class="aside-item-content"><i
									class="iconfont icon-laxinfanxian"></i>
								<span>拉新返现</span></a>
						</div>
					</li>
					<li class="aside-items">
						<!-- <div class="aside-item">
							<a href="javascript:window.external.AddFavorite" class="aside-item-content">
								<i class="iconfont icon-shoucang_shoucang"></i>
								<span>收藏</span>
							</a>
						</div> -->
						<div class="aside-item">
							<div class="aside-item-content">
								<i class="iconfont icon-shoucang_shoucang"></i>
								<span>收藏</span>
							</div>
							<div class="drop-aside drop-aside-community">
								<div class="drop-aside-content content-community" style="background-color: #FF3916;width: 190px;">
									<div class="qr-code">
										<div style="display: flex;font-size: 22px;font-weight: bold;color: #FF3916;width: 100%;">
											<ul style="width: 100%;">
												<li style="float: left;background-color: white;width: 50px;height: 40px;line-height: 40px;text-align: center;border-radius: 10px;">Ctrl</li>
												<li style="float: left;color:white;width:40px;height: 40px;line-height: 40px;text-align: center;border-radius: 10px;">+</li>
												<li style="float: left;background-color: white;width:40px;height: 40px;line-height: 40px;text-align: center;border-radius: 10px;">D</li>
											</ul>
											<div style="width: 100%;height: 50px"></div>
											<div style="color: white;font-size: 22px;text-align: left;">收藏本站</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="aside-items" @click="goTop()">
						<div class="aside-item">
							<div class="aside-item-content">
								<i class="iconfont icon-huidaodingbu1"></i>
								<span>回到顶部</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</aside>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	export default {
		name: "",
		components: {},
		data() {
			return {
				displayNone: '',
				kefuObj:{},
				custObj:{},
				kefuimg:'',
			}
		},
		props:[
			'asideItemShowFlag'
		],
		methods: {
			changeAsideItemShowFlag(){
				this.$store.state.asideItemShowFlag = false;
			},
			getEmail(){
				let data = {}
				this.$post('/Index/getcCustomer',data).then(res=>{
					console.log(res)
					this.custObj = res.data
				})
			},
			showKefu(){
				this.$post('/Index/contactUs',{}).then(res=>{
					this.kefuObj = res.data
				})
			},
			getkefuImg(){
				this.$post('/Index/getcKfCode',{}).then(res=>{
					this.kefuimg = res.data.code
				})
			},
			goPage(type){
				// if(!localStorage.getItem("userInfo")){
				// 	this.$message({
				// 		type:'error',
				// 		message:'请先登录'
				// 	})
				// 	return false
				// }
				if(type==0){
					this.$router.push({
					    path: '/recharge'
					})
				}else if(type==1){
					this.$router.push({
					    path: '/integral'
					})
				}else if(type==2){
					this.$router.push({
					    path: '/recommendedCashBack'
					})
				}else if(type==3){
					// /person?itemid=1
					this.$router.push({
					    path: '/person',
						query:{
							itemid:1
						}
					})
				}else if(type==4){
					if(localStorage.getItem("loginFlag")){
						this.$router.push({
						    path: '/person?itemid=0'
						})
					}else{
						this.$message({
							type:'error',
							message:'请先登录'
						})
					}
				}else if(type==5){//意见反馈
					this.$router.push({
					    path: '/about',
						query:{
							parentIndex:0,
							menuid:9
						}
					})
				}
				goTop()
			},
			handleScroll() {
				// let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
				// if (top >= 0) {
				// 	this.displayNone = ""
				// } else {
				// 	this.displayNone = ""
				// }
				// this.$emit('changeAsideItemShowFlag')
				// console.log("handleScroll---》")
			},
			// 回到顶部
			goTop() {
				let top = document.documentElement.scrollTop;
				// 实现滚动效果
				const timeTop = setInterval(() => {
					document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop); //清除定时器
					}
				}, 10);
			}
		},
		mounted() {
			this.showKefu()
			this.getEmail()
			this.getkefuImg()
			window.addEventListener("scroll", this.handleScroll);
		}
	};
</script>

<style scoped>
	.displayNone {
		display: none;
	}
</style>
