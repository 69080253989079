<template>
	<div class="draft-list">
		<div class="btn trial-listening" @click="reload">
			<span>刷新</span>
		</div>
		<div class="listBox">
			<el-table height="100%" :data="tableData" header-cell-class-name="draft-table-header-cell" header-row-class-name="draft-table-header-row">
				<el-table-column label="序号" align="center" prop="index">
					<template slot-scope="scope">
						{{ (form.pageNum - 1) * form.pageSize + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="合成ID" align="center" prop="id"></el-table-column>
				<el-table-column label="播音员" align="center" prop="speakerName"></el-table-column>
				<el-table-column label="风格" align="center" prop="voiceStyle"></el-table-column>
				<el-table-column label="领域" align="center" prop="voiceScopes"></el-table-column>
				<el-table-column label="更新时间" align="center" prop="updateTime">
					<template slot-scope="scope">
						{{ formatDateTime(scope.row.createTime) }}
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" prop="id">
					<template slot-scope="scope">
						<el-button type="text" @click="downloadAudio(scope.row)" style="margin-right: 10px">下载音频</el-button>
						<el-popconfirm
								confirm-button-text='删除'
								cancel-button-text='取消'
								icon="el-icon-info"
								icon-color="red"
								title="确定要删除该草稿？"
								@confirm="delItem(scope.row)"
						>
							<el-button type="text"   slot="reference" style="color: #E93A3A">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination">
			<pagination ref="pagination" :totalPage="totalPage" :limit="form.pageSize" @changePage="changePage"/>
		</div>
	</div>
</template>

<script>
import {deleteSynthById, draftDeleteById, getMySynthPage} from "@/request/aiDubbing";
import formatDateTime, {downloadFile} from "@/utils/func";
import Pagination from "@/components/pagination.vue";

export default {
	components: {Pagination},
	data(){
		return {
			tableData:[],
			form:{
				pageNum: 1,
				pageSize: 10
			},
			totalPage: 0,
			dataTotal: 0,
		}
	},
	mounted() {
		this.getList()
	},
	methods:{
		formatDateTime,
		async getList(){
			try{
				const {data} = await getMySynthPage(this.form)
				if(data && data.list){
					this.dataTotal = data.total
					this.totalPage = data.pages
					this.tableData = data.list
				}
			}catch (e) {
				console.log(e)
			}
		},
		reload(){
			this.form.pageNum = 1
			this.getList()
		},
		async delItem(row){
			try{
				const {data} = await deleteSynthById({id: row.id})
				this.$message.success('删除成功')
				await this.getList()
			}catch (e) {
				console.log(e)
			}
		},
		changePage(page){
			this.form.pageNum = page
			this.getList()
		},
		downloadAudio(row){
			if(!row.voiceUrl){
				this.$message.warning('未找到音频文件')
				return
			}
			downloadFile(row.voiceUrl, `${row.speakerName}-${row.id}.mp3`)
		}
	}
}
</script>

<style scoped lang="scss">
.draft-list{
	flex: 1;
	padding: 20px;
	display: flex;
	flex-direction: column;
}
.trial-listening{
	margin: 16px 0 18px 16px;
	width: 100px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #E93A3A;
	color: #ffffff;
	border-radius: 2px;
	background: #E93A3A;
}
.listBox {
	flex: 1;
	border-radius: 10px;
	background-color: #fff;
	margin: 10px 0 20px;
	box-shadow: 0 0 0 3px rgba(0, 0, 0, .01);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

</style>

<style lang="scss">
.draft-table-header-cell{
	font-size: 18px;
	font-weight: bold;
	padding: 20px 0 !important;
}
.draft-table-header-row{

}
</style>