export function generateSpeechText(input) {
    let results = [];
    if (input.includes('.')) {
        //处理小数或日期
        let parts = input.split('.');
        results.push({
            speak: `${parts[0]}点${parts[1]}`,
            interpretAs: "cardinal"
        });
    } else if (input.includes(':')) {
        //比例
        let parts = input.split(':');
        results.push({
            speak: `${parts[0]}比${parts[1]}`,
            interpretAs: "fraction"
        });
    } else if (input.includes('-')) {
        //范围
        let parts = input.split('-');
        results.push({
            speak: `${parts[0]}到${parts[1]}`,
            interpretAs: "range"
        });
        results.push({
            speak: `${parts[0]}减${parts[1]}`,
            interpretAs: "range"
        });
    }else if (input.includes('+')) {
        //范围
        let parts = input.split('+');
        results.push({
            speak: `${parts[0]}加${parts[1]}`,
            interpretAs: "range"
        });
    } else {
        results.push({
            speak: convertToChineseNumerals(input),
            interpretAs: "digits"
        });
        results.push({
            speak: input,
            interpretAs: "value"
        });
    }

    return results;
}

export function validateInput(input) {
    // 验证普通数字
    const normalNumPattern = /^\d+$/;
    // 验证日期或小数
    const dateOrDecimalPattern = /^\d+\.\d+$/;
    // 验证比例
    const ratioPattern = /^\d+:\d+$/;
    // 验证范围
    const rangePattern = /^\d+-\d+$/;
    // 验证+
    const rangePuls = /^\d+\+\d+$/;

    if (normalNumPattern.test(input) ||
        dateOrDecimalPattern.test(input) ||
        ratioPattern.test(input) ||
        rangePattern.test(input) ||
        rangePuls.test(input))
    {
        return true;
    } else {
        return false;
    }
}

function convertToChineseNumerals(input) {
    let numberWords = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    let units = ["", "拾", "佰", "仟", "万", "拾", "佰", "仟", "亿"];
    let output = "";
    let zeroFlag = false; // 标记连续零的情况
    let length = input.length;

    for (let i = 0; i < length; i++) {
        let digit = parseInt(input.charAt(i));
        let unitPos = length - 1 - i; // 当前位

        if (digit === 0) {
            zeroFlag = true;
        } else {
            if (zeroFlag) {
                output += numberWords[0]; // 插入一个 "零"
                zeroFlag = false;
            }
            output += numberWords[digit] + units[unitPos];
        }
    }

    // 处理类似 "壹亿零" 这样的情况为 "壹亿"
    if (output.endsWith(numberWords[0])) {
        output = output.substring(0, output.length - 1);
    }

    return output;
}

// 获取元素的直接文本内容
export function getDirectTextContent(element) {
    let textContent = '';
    for (let i = 0; i < element.childNodes.length; i++) {
        const node = element.childNodes[i];
        if (node.nodeType === Node.TEXT_NODE) {
            // 累加文本节点的文本内容
            textContent += node.nodeValue;
        }
    }
    return textContent
}