<template>
	<div id="content" class="content sound-content">
		<div class="sound-top-box bannner" :style="{'background': 'url('+bannerImg+') 50%/cover'}">
			<text style="color: red;">{{bannerImg}}111</text>
			<h3 class="bannerTit" data-aos="zoom-in-up">声探配音-推荐立享返现行动</h3>
			<p class="bannerP" data-aos="zoom-in-up">1、推荐新好友，最高可享<span
					style="color:#f7f597;font-style: oblique;">10%</span>返现奖励;</p>
			<p class="bannerP" data-aos="zoom-in-up">2、推荐奖励，实时到账，满100元提现，奖励上不封顶</p>
			<button class="joinus" data-aos="zoom-in-up" @click="joinUsFn()">加入声探</button>
		</div>
		<div id="content" class="t_de_content">
			<div class="banner_top">
				<div class="banner_top_case">
					<img src="../../assets/image/recommended/icon-5.png" />
					<p class="text-center">加入声探</p>
					<p class="text-center fontSize-12">注册登录即可加入时长</p>
				</div>
				<img class="icon-img" src="../../assets/image/recommended/icon-1.png" />
				<div class="banner_top_case">
					<img src="../../assets/image/recommended/icon-2.png" />
					<p class="text-center">分享链接</p>
					<p class="text-center fontSize-12">复制你的专属链接，发送给好友</p>
				</div>
				<img class="icon-img" src="../../assets/image/recommended/icon-1.png" />
				<div class="banner_top_case">
					<img src="../../assets/image/recommended/icon-3.png" />
					<p class="text-center">新用户注册</p>
					<p class="text-center fontSize-12">下单即可获得返现奖励</p>
				</div>
				<img class="icon-img" src="../../assets/image/recommended/icon-1.png" />
				<div class="banner_top_case">
					<img src="../../assets/image/recommended/icon-4.png" />
					<p class="text-center">奖励发放</p>
					<p class="text-center fontSize-12">推荐成功，实时发放奖励</p>
				</div>
				<img class="icon-img" src="../../assets/image/recommended/icon-1.png" />
			</div>
			<h2 class="recommendedTit">现在推荐声探配音网，享受更多福利优惠</h2>
			<div class="t_de_content_top">
				<div class="contentBox">
					<div class="contentBox_left">
						<p style="font-size: 20px;font-weight: 600;color:#333">推荐福利</p>
						<p class="spanList"><span></span><span></span><span></span></p>
						<p>1、推荐新好友，最高可享10%返现奖励；</p>
						<p>2、推荐奖励，实时到账，满100元提现，奖励上不封顶；</p>
						<p>3、无期限限制，成功推荐即永久享受奖励；</p>
						<p>4、推荐成功，实时发放奖励。</p>
					</div>
					<img src="../../assets/image/recommended/tuijian.png" />
				</div>
			</div>
			<h2 class="recommendedTit">去哪里邀请和分享</h2>
			<div class="listBox">
				<p class="listBoxTit">分享给身边需要配音的小伙伴~</p>
				<el-table :data="listData" border style="width: 100%">
					<el-table-column prop="changjing" label="适合用户人群及场景" min-width="50%">
					</el-table-column>
					<el-table-column prop="gongneng" label="主要功能" min-width="50%">
					</el-table-column>
				</el-table>
				<img class="listImg" src="../../assets/image/recommended/yq.png" />
			</div>
			<div class="fxBox">
				<div class="fxBox_left">
					<img class="fxImg" src="../../assets/image/recommended/fx-1.png" />
				</div>
				<div class="fxBox_right">
					<p class="fxBoxTit">分享到您的社交圈中</p>
					<p>您的朋友圈、微信群、QQ群等一些社交媒体圈，邀请更多的人来使用声探网系列产品，您就可以坐等收益,</p>
				</div>
			</div>
			<div class="fxBox">
				<div class="fxBox_left">
					<p class="fxBoxTit">社交平台中邀请</p>
					<p>在短视频或评论中加入您的邀请链接，在拍摄剪辑或视频录制的教程中加入您的专属链接，吸引更多的人通过您的链接来使用声探网系列产品:在您的微信群、
						QO群，抖音、快手B站，小红书。微博，公众号等社交媒体一切皆可邀</p>
				</div>
				<div class="fxBox_right">
					<img class="fxImg" src="../../assets/image/recommended/fx-2.png" />
				</div>
			</div>
			<div class="fxBox">
				<div class="fxBox_left">
					<img class="fxImg" src="../../assets/image/recommended/fx-3.png" />
				</div>
				<div class="fxBox_right">
					<p class="fxBoxTit">邀请您的客户来购买</p>
					<p>如果您的客户需要声探网系列产品软件，邀请他们来购买，您获得自推最高35%+间推5%的返现。</p>
				</div>
			</div>
			<div class="bottomBox">
				<p class="text-center tit">准备好躺着赚钱了吗?</p>
				<p class="text-center content">推荐的人越多，获得现金越多，多劳多得,上不封顶,快来试试吧!</p>
				<button @click="joinUsFn()">立即赚钱</button>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" width="30%" center :modal="false">
			<h2>您的邀请链接</h2>
			<div class="dialogBox">
				<p>复制以下链接转发好友，赚取奖励</p>
				<button @click="copyText($event)">一键复制</button>
			</div>

			<el-input readonly :value="yqm"></el-input>
		</el-dialog>
	</div>
</template>
<script>
	import goTop from '../../utils/goTop.js'
	import copyText from "@/utils/copyText";
	export default {
		data() {
			return {
				dialogVisible: false,
				listData: [{
						changjing: '影视公司、商务制片、导演、制片、摄影、后期制作等需要接单的人员',
						gongneng: 'TVC、宣传片、微电影、MG动画接单平台;'
					},
					{
						changjing: '自媒体创作者、短视频达人、影视解说、政企宣传、广告促销、产品介绍、有声读物、手机彩铃、游戏动漫、新闻资讯、情感电台、搞笑娱乐等;',
						gongneng: '媲美真人情感的AI智能配音工具，近干种男、女、童声音色，支持中文、芽文等各种语种。支持单、多人配音，WAV音质，SRT字幕自动生成，可商用:输入文字1秒完成配音;'
					},
					{
						changjing: '自媒体创作者、短视频达人、个人视频收藏者、企业、影视公司从业者等一切有视频下载需求等用户;',
						gongneng: '提供无水印、无压缩、无限速下载软件服务，1秒在线提取，免安装，扫码即用'
					},
					{
						changjing: '音乐人、短视频创作者、视频制作人、音乐爱好者等方面的用户.',
						gongneng: '支持人声，伴奉，乐器分离(鼓点，贝斯、电吉他，原声吉他，钢琴。音响合成器)，可上传视频、音频或在线视频分离，WAV无损输出，支持MP3、AAC、MOV、MP4、AVI等常见格式;'
					},
					{
						changjing: '个人、企业、影视、广告传媒等公司的用户;',
						gongneng: '严选行业1%策划师，致力于为企业、影视。广告传媒等公司，提供高效，高品质的品牌策划.宣传片/TVC/微电影/动策划、招标方案、活动策划等四大品类服务'
					},
				],
				yqm: '',
				bannerImg: ''
			}
		},
		mounted() {
			if (localStorage.getItem("userInfo")) {
				this.yqm = window.location.origin + '#/?userid=' + JSON.parse(localStorage.getItem("userInfo")).id
			}
			this.getAdByTag()
		},
		methods: {
			// ...mapMutations(['setDialogVisibleLogin']),
			// 加入我们 
			joinUsFn() {
				var token = localStorage.getItem('token')
				var userInfo = localStorage.getItem("userInfo")
				 console.log(token,userInfo);
				if (token && userInfo) {
					this.dialogVisible = true;
				} else {
					this.$store.commit('setDialogVisibleLogin', true);
				}
			},
			copyText($event) {

				if (sessionStorage.getItem('taskid')) {
					this.$toTask()
				}
				copyText($event, this.yqm);
			},
			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_tuijian_banner'
				}).then(res => {
					this.bannerImg = res.data[0].ad_pic
				})
			},
		}
	}
</script>

<style scoped>
	.content {
		padding: 0 !important;
	}

	/deep/ .el-dialog {
		margin-top: 30vh !important;
		border-radius: 20px;
		padding: 20px 0;
	}

	/deep/.el-dialog--center .el-dialog__body {
		padding: 0 20px 30px;
	}

	/deep/ .el-dialog__body h2 {
		color: #333;
		font-weight: 600;

	}

	/deep/ .el-input__inner {
		background-color: #f5f5f5;
		outline-style: none;
	}

	/deep/ .el-dialog__body .dialogBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 10px 0;
	}

	/deep/ .el-dialog__body .dialogBox p {
		color: #999;
		font-size: 18px;
		font-weight: 600;
	}

	/deep/ .el-dialog__body .dialogBox button {
		width: 100px;
		height: 40px;
		border-radius: 5px;
		background-color: #fc4439;
		color: #fff
	}

	@font-face {
		font-family: 'al-Bold';
		src: url('../../assets/fonts/al-Bold.ttf');
	}

	.bannner {
		height: 420px;
		padding: 70px 400px 50px;
		margin-top: 0;
	}

	.bannner .bannerTit {
		font-size: 50px;
		color: #fff;
		font-style: oblique;
		margin-bottom: 20px;
		font-family: "al-Bold";
	}

	.bannner .bannerP {
		font-size: 20px;
		color: #f0f0f0;
		margin-top: 15px;
		font-family: "al-Bold";
	}

	.bannner .joinus {
		width: 120px;
		height: 40px;
		background-color: #fbe373;
		color: #fc4439;
		font-size: 16px;
		font-weight: 600;
		border-radius: 5px;
		margin-top: 35px;
		animation: joinUs .5s linear .5s infinite normal;
	}

	/* .bannner .joinus:hover {
		transform: scale(1.03);
	} */
	.t_de_content,
	.listBox,
	.banner_top,
	.fxBox,
	.bottomBox {
		width: 1400px;
		margin: 20px auto;

	}

	.banner_top {
		margin: -110px auto 0px;
		height: 180px;
		border-radius: 10px;
		background-color: #fff;
		display: flex;
		padding: 0 120px;
		align-items: center;
		justify-content: space-between;
	}

	.banner_top .banner_top_case,
	.banner_top .icon-img {
		margin: 0 10px;
	}

	.banner_top .banner_top_case img {
		display: block;
		margin: 0 auto;
		width: 72px;
		height: 72px;
		transition: all .3s;
		cursor: pointer;
	}

	.banner_top .banner_top_case img:hover {
		transform: translateY(-10px);
	}

	.banner_top .banner_top_case>p {
		margin: 3px 0;
	}

	.text-center {
		text-align: center;
	}

	.banner_top .banner_top_case .fontSize-12 {
		color: #999;
		font-size: 12px;
	}

	.recommendedTit {
		font-size: 28px;
		font-weight: 600;
		line-height: 140px;
		text-align: center;
	}

	.banner_top .tuijianBox {
		border-radius: 10px;
		background-color: #fff;
		margin-bottom: 60px;
	}

	.t_de_content .t_de_content_top {
		margin: 0 auto;
		background-image: linear-gradient(to right, #fff, #feeded, #fff);
		border-radius: 10px;
		display: flex;
		padding: 65px 200px;
	}

	.t_de_content .t_de_content_top .contentBox {
		display: flex;
		justify-content: space-between;
	}

	.t_de_content .t_de_content_top .contentBox .contentBox_left {
		color: #747474;
		margin-right: 250px;
	}

	.t_de_content .t_de_content_top .contentBox .contentBox_left p {
		margin-bottom: 20px;
	}

	.t_de_content .t_de_content_top .contentBox img {
		width: 240px;
		border-radius: 10px;
		transition: all .3s;
		cursor: pointer;
	}

	.t_de_content .t_de_content_top .contentBox img:hover {
		transform: scale(1.02);
	}

	.t_de_content .listBox {
		border-radius: 10px;
		background-image: linear-gradient(to top, #fff, #feeded);
		margin-top: 20px;
		padding: 40px 60px;
		position: relative;
	}

	.t_de_content .listBox .listBoxTit {
		font-size: 18px;
		line-height: 40px;
	}

	.t_de_content .listBox .listImg {
		width: 300px;
		position: absolute;
		right: 50px;
		top: -100px
	}

	/deep/ .el-table {
		margin-top: 20px;
	}

	/deep/ .el-table--enable-row-transition .el-table__body td.el-table__cell {
		cursor: pointer;
	}

	/deep/ .el-table th.el-table__cell>.cell {
		text-align: center;
	}

	.fxBox {
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 65px 200px;
		background-image: linear-gradient(to top, #fff, #feeded);
	}

	.fxBox .fxBox_left {
		margin-right: 250px;
	}

	.fxBox .fxBoxTit {
		font-size: 20px;
		margin: 10px 0;
	}

	.fxBox .fxImg {
		width: 340px;
		cursor: pointer;
		transition: all .3s;
	}

	.fxBox .fxImg:hover {
		transform: translateY(-10px);
	}

	.bottomBox {
		background: url('../../assets/image/recommended/bottom.png') 50%/cover;
		padding: 45px 0;
		border-radius: 10px;
		overflow: hidden;
		margin-bottom: 70px;
	}

	.bottomBox .tit {
		font-size: 30px;
		color: #fff;
		font-family: "al-Bold";
		font-style: oblique;
	}

	.bottomBox .content {
		margin: 20px 0 30px;
		color: #948a9a;
		font-size: 20px;
	}

	.bottomBox button {
		width: 130px;
		display: block;
		margin: 0 auto;
		height: 50px;
		border-radius: 5px;
		background-color: #d2ac7f;
		color: #333333;
		line-height: 50px;
		animation: joinUs .5s linear .5s infinite normal;
	}

	@keyframes joinUs {
		0% {
			transform: scale(1);
		}

		50% {
			transform: scale(1.03);
		}

		100% {
			transform: scale(1);
		}
	}

	@media only screen and (max-width: 1680px) {

		.t_de_content,
		.listBox,
		.banner_top,
		.fxBox,
		.bottomBox {
			width: 1200px;
		}

		.bannner,
		.fxBox {
			padding: 50px 200px;
		}

		.t_de_content .t_de_content_top .contentBox .contentBox_left,
		.fxBox .fxBox_left {

			margin-right: 150px;
		}

	}

	@media only screen and (max-width:1440px) {

		.t_de_content,
		.listBox,
		.banner_top,
		.fxBox,
		.bottomBox {
			width: 1000px
		}

		.t_de_content .t_de_content_top,
		.fxBox {

			padding: 65px 100px;
		}

	}
</style>
