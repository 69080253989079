<template>
	<div class="content">
		<el-upload
		  ref='uploadInput' 
		  class="avatar-uploader"
		  :show-file-list="false"  
		  :data='uploaddata'
		  action="/api/Common/uploadPic"
		  :on-success='onsuccess'>
		  <img style="width: 100px;height:100px;border-radius: 100px;margin-left: 70px;margin-bottom: 30px;" :src="form.headimgurl"/>
		</el-upload>
		
		<el-form ref="form" :model="form" label-width="80px">
			<el-form-item label="名称">
				<el-input v-model="form.user_name"></el-input>
			</el-form-item>
			<el-form-item label="性别">
				<el-radio v-model="form.sex" label="1" >男</el-radio>
				<el-radio v-model="form.sex" label="2" >女</el-radio>
			</el-form-item>
			<el-form-item label="手机">
				<el-input v-model="form.phone"></el-input>
			</el-form-item>
			<el-form-item label="QQ">
				<el-input v-model="form.qqnum"></el-input>
			</el-form-item>
			<el-form-item label="微信">
				<el-input v-model="form.wxnum"></el-input>
			</el-form-item>
			<el-form-item label="地址" v-model="form.address">
				<checkAddress v-if="form.address.province != '' || isclickR" ref="address" v-model="form.address" :form="form.address" @getAddress='getAddress'/>
			</el-form-item>
			<el-form-item label="签名">
				<el-input type="textarea" v-model="form.sign"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">更新</el-button>
				<el-button @click="onReset()">重填</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import checkAddress from './checkAddress'
	import md5 from '../../utils/md5.js'
	export default {
		data() {
			var validatePass = (rule, value, callback) => {

				if (this.ruleForm.address.province == '') {
					callback(new Error('请输入完整地址'));
				}
				callback();
			};
			return {
				isclickR:false,
				form: {
					user_name: '',
					sex: '',
					phone: '',
					qqnum: '',
					wxnum: '',
					headimgurl:'',
					address: {
						// 省
						province: "",
						city: ""
					},
					sign: ''
				},
				proid:0,
				cityid:0,
				rules: {
					address: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}, ]
				},
				uploaddata:{}
			}
		},
		components: {
			checkAddress
		},
		mounted(){
			let paramobj = {
				client_id:1,
				api_token:md5.md5('api/Common/uploadPic'+this.formartDate()+'yiling6670238160ravntyoneapp7926'),
				token:localStorage.getItem('token')?localStorage.getItem('token'):''
			}
			this.uploaddata = paramobj
			this.getprofile()
		},
		
		methods: {
			formartDate(){
				const date = new Date()
				const year = date.getFullYear()
				const month = parseInt(date.getMonth()) + 1 > 9 ? parseInt(date.getMonth()) + 1 : '0' + (parseInt(date
				.getMonth()) + 1)
				const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
			
				return year + '-' + month + '-' + day
			},
			onsuccess(res, file) {
				console.log('*********************')
				console.log(res)
				this.form.headimgurl = res.data.full_path
			},
			
			
			getAddress(e){
				if(e.pro_id){
					this.proid = e.pro_id
				}else{
					this.cityid = e.city_id
				}
			},
			getprofile(){
				let data = {}
				this.$post('/MemberInfo/getprofile',data).then(res=>{
					this.$nextTick(()=>{
						this.form = {
							user_name: res.data.user_name,
							sex: res.data.sex+'',
							phone: res.data.phone,
							qqnum: res.data.qqnum,
							wxnum: res.data.wxnum,
							headimgurl:res.data.headimgurl,
							address: {
								// 省
								province: res.data.pro,
								city: res.data.city
							},
							sign: res.data.summary
						}
					})
					
					this.proid = res.data.pro
					this.cityid = res.data.city
				})
			},
			editprofile(){
				let data = {
					user_name: this.form.user_name,
					sex: this.form.sex,
					phone: this.form.phone,
					qqnum: this.form.qqnum,
					wxnum: this.form.wxnum,
					pro:this.proid,
					city:this.cityid,
					area:'',
					summary: this.form.sign,
					headimgurl:this.form.headimgurl
				}
				this.$post('/MemberInfo/editprofile',data).then(res=>{
					console.log(res)
					if(sessionStorage.getItem('taskid')){
						this.$toTask()
					}
					localStorage.setItem(
					  "userInfo",
					  JSON.stringify(res.data.info)
					);
					this.$message({
						 message: '修改成功',
						 type:'success'
					})
					window.location.reload()
				})
			},
			
			
			onReset() {
				this.isclickR = true
				this.form = {
					user_name: '',
					sex: '',
					phone: '',
					qqnum: '',
					wxnum: '',
					address: {
						// 省
						province: '',
						city: ''
					},
					sign: ''
				}
			},
			onSubmit() {
				console.log(this.form)
				this.editprofile()
			}

		},
		watch: {
			ruleForm: {
		 	// deep:true,
				form() {
					this.form.address = this.$refs.address.form
					console.log(this.form.address)
				}
			}
		},
	}
</script>

<style>
	.avatar-uploader .el-upload{
		border: none;
	}
	.content {
		padding: 20px;
	}

	.content .el-input,
	.content .el-textarea {
		width: 60%;
	}
	.el-radio__input.is-checked .el-radio__inner,.el-button--primary {
	    border-color: #ea4335 !important;
	    background: #ea4335 !important;
	}
	.el-radio__input.is-checked+.el-radio__label{
		color: #ea4335 !important;
	}
	.el-input__inner:focus,.el-input__inner:active,.el-textarea__inner:focus,.el-textarea__inner:active{
		border:1px solid #DCDFE6 !important
	}
	.el-select-dropdown__item.selected {
		color: #ea4335 !important;
		font-weight: 700 !important;
	}
	.el-button--default:hover{
		background-color: transparent !important;
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}
</style>
