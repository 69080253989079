<template>
	<div id="content" class="content t_de_content sound-content">
		<div class="sound-top-box">
			<div class="sound-top">
				<div class="filter-and-sort-wrap">
					<div class="filter-and-sort-item" v-for='(item,index) in labelList'
						>
						<div :class="labelListHeight[index].class" ref='filter_item'>
							<a aria-current="page" @click="sellabel2(item,index)" class="item-name"
								:class="selTrTypeList[index].length>0?'':' nuxt-link-exact-active nuxt-link-active active'"
								style="border-radius: 10px;">{{item.name}}</a>
							<a class="item-name" style="border-radius: 10px;" @click="sellabel(label,index)"
								:class="JSON.stringify(tagsList).indexOf(JSON.stringify(label))>=0?' nuxt-link-exact-active nuxt-link-active active':''"
								v-for='label in item.child'>{{label.name}}</a>
							<!-- <span style='position: absolute;
															right: 0px;top:0px;bottom: 0px;margin: auto;cursor: pointer;color:#ea4335'
								@click='changeHeight(index)'>更多</span> -->
						</div>
					</div>

					<div class="filter-and-sort-item">
						<div class="item-name-box">
							<a href="avaScript:voide(0)" aria-current="page"
								:class="orderType == 0 ?'item-name nuxt-link-exact-active nuxt-link-active active' :'item-name nuxt-link-exact-active'"
								@click="orderType = 0;getAudiodemo()" style="border-radius: 10px;">综合排序</a>
							<a href="avaScript:voide(0)" style="border-radius: 10px;" class="item-name"
								:class="orderType == 1?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 1;getAudiodemo()">热门</a>
							<a href="avaScript:voide(0)" style="border-radius: 10px;" class="item-name"
								:class="orderType == 2?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 2;getAudiodemo()">最新</a>
							<a href="avaScript:voide(0)" style="border-radius: 10px;" class="item-name"
								:class="orderType == 3?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 3;getAudiodemo()">价格</a>
							<div class="teachOnline">
								<!-- -->
								<div style="margin-right: 5px;">当前在线</div>
								<el-switch v-model="on_line" @change='onlineChange' active-color="#ff4949"
									inactive-color="#ccc">
								</el-switch>
							</div>
						</div>
				<!-- 		<div class="other-entr main-cen">
							在线老师
							<el-switch v-model="on_line" @change='onlineChange' active-color="#ff4949"
								inactive-color="#ccc">
							</el-switch>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="selected-tags-wrap-Box">
			<div class="selected-tags-wrap flex">
				<div class="leftBox">
					<p>
						为您找到{{totalPage}}个样音
					</p>
					<el-tag class="text-overflow" v-for="(tag,index) in tagsList" :key="tag.id" closable
						:type="tag.type" @close='delTag(index)'>
						{{tag.name}}
					</el-tag>
				</div>
				<button class="btn-clear" @click="delAllDef()">清空标签</button>
			</div>
		</div>
		<div class="listBox" v-if='totalPage >0'>
			<div class="listTit">
				<p style="text-align: center;">序号</p>
				<p style="padding-left: 30px;text-align: left;">标题</p>
				<p>下载</p>
				<p>分享</p>
				<p>收藏</p>
				<p>配音老师</p>
				<p>音色类型</p>
			</div>

			<ul class="songList">
				<template v-for="(item,index) in audioSongList">
					<li>
						<p class="songListIndex">{{index+1}}</p>
						<div class="leftBox">
							<template v-if="audioFlag==true && audioId==item.id&& $store.state.musicLoading">
								<i class="iconfont icon-jiazai" style="width: 30px;"
									@click="changeAudioSrc(item)"></i>
							</template>
							<template v-else-if="audioFlag==true && audioId==item.id">
								<i class="iconfont icon-zanting3" style="width: 30px;"
									@click="changeAudioSrc(item)"></i>
							</template>
							<template v-else style="width:100px">
								<i class="iconfont icon-bofang1" style="width: 30px;" @click="changeAudioSrc(item)"></i>
							</template>

							<p class="audioTit text-overflow" style="text-align: left;">{{item.title}}</p>
						</div>

						<div class="btn-trail-box">
							<i class="iconfont icon-yunxiazai_o" @click="handleDown(item)"></i>
							<div class="tip-top">下载音频</div>
						</div>
						<div class="btn-trail-box">
							<i class="iconfont iconfenxiang2" @click="onCopy($event,item.audio,item.nickname )"></i>
							<div class="tip-top">分享音频</div>
						</div>
						<div class="btn-trail-box">
							<i v-if='item.is_collect!=1' class="iconfont icon-shoucang_quxiaoshoucang"
								@click="changeLikeIconfont(item)"></i>
							<i v-if='item.is_collect==1' class="iconfont icon-shoucang_shoucang" style="color: red;"
								@click="changeLikeIconfont(item)"></i>

							<div class="tip-top">收藏音频</div>
						</div>
						<p class="fontSize_14 color_999 text-overflow teachClass" style="cursor: pointer;"
							@click="toTeach(item.uid)">{{item.nickname}}</p>
						<p class="fontSize_14 color_999 text-overflow">{{item.label}}</p>
					</li>
				</template>
			</ul>
			<div class="paging-box qt-pagination">
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" :page-size="page.limit"
						@current-change='changePageData(0)' :current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>
			</div>
		</div>
		<div style="text-align: center;" v-else-if='totalPage == 0'>
			<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
			<p style="text-align: center;color: #999;">暂无符合条件的筛选结果，请尝试更改筛选条件</p>
		</div>
		<rightAside :asideItemShowFlag='$store.state.asideItemShowFlag'></rightAside>
	</div>
</template>

<script>
	import {
		download
	} from "@/request/http";
	import copyText from "@/utils/copyText";
	import rightAside from "@/components/index/rightAside.vue";
	export default {
		data() {
			return {
				audio_idClass: 'footer-wavesurfer tran',
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				audioId: '',
				audioFlag: false,
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				value: true,
				pagePrev: true,
				pageNext: false,
				totalPage: null,
				page: {
					currentPage: 1,
					limit: 10,
				},
				labelList: [],
				labelListHeight: [],
				tagsList: [],
				audioSongList: [],
				keyword: '',
				on_line: false,
				orderType: 0,
				selTrTypeList: []
			}
		},
		mounted() {
			if (sessionStorage.getItem('keyword')) {
				this.keyword = sessionStorage.getItem('keyword')
				this.tagsList.push({
					id: 0,
					name: this.keyword
				})
			}
			this.getLable()
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
		},
		components: {
			rightAside
		},
		methods: {
			changeHeight(index) {
				console.log(index)
				this.labelListHeight[index].height = this.labelListHeight[index].height == "56px" ? "auto" : "56px";
				this.labelListHeight = JSON.parse(JSON.stringify(this.labelListHeight));
			},
			// 分享
			onCopy(event, audioSrc,nickname) {
				copyText(event, nickname+audioSrc)
			},
			delAllDef() {
				this.selTrTypeList = []
				for (let i = 0; i < this.labelList.length; i++) {
					this.selTrTypeList.push([])
				}
				this.tagsList = [];
				this.getAudiodemo()
			},
			// 收藏
			changeLikeIconfont(item) {
				let data = {
					id: item.id,
					type: 3,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					this.getAudiodemo()
				})
			},

			onlineChange(e) {
				console.log(e)
				this.on_line = e
				this.page.currentPage = 1
				this.getAudiodemo()
			},

			sellabel2(item, index) {
				this.page.currentPage = 1;
				for (let i = 0; i < item.child.length; i++) {
					for (let k = 0; k < this.tagsList.length; k++) {
						if (JSON.stringify(item.child[i]).indexOf(JSON.stringify(this.tagsList[k])) >= 0) {
							this.selTrTypeList[index].splice(0, 1)
							this.tagsList.splice(k, 1)
						}
					}
				}
				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				if (typeids != '') {
					typeids = '?typeLis=' + typeids
				}
				const newUrl = '#' + this.$route.path + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)
				this.getAudiodemo()
			},
			// 选择标签
			sellabel(item, index) {
				this.page.currentPage = 1;
				if (this.selTrTypeList[index].length > 0) {
					for (let i = 0; i < this.tagsList.length; i++) {
						if (this.tagsList[i].id == this.selTrTypeList[index][0]) {
							this.tagsList.splice(i, 1)
						}
					}
					this.selTrTypeList[index] = []
					this.selTrTypeList[index].push(item.id)
					this.tagsList.push(item)
				} else {
					this.selTrTypeList[index].push(item.id)
					this.tagsList.push(item)
				}

				// console.log(item)
				// let isDel = 0

				// for(let i = 0;i<this.tagsList.length;i++){
				// 	if(item.id == this.tagsList[i].id){
				// 		this.selTrTypeList[index].splice(0,1)
				// 		this.tagsList.splice(i,1)
				// 		isDel = 1
				// 	}
				// }
				// this.page.currentPage = 1
				// if(isDel == 0){
				// 	this.selTrTypeList[index].push(1)
				// 	this.tagsList.push(item)
				// }

				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				if (typeids != '') {
					typeids = '?typeLis=' + typeids
				}
				const newUrl = '#' + this.$route.path + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)
				this.getAudiodemo()
			},
			// 删除标签
			delTag(index) {
				sessionStorage.removeItem('keyword')
				this.keyword = ''
				console.log(index)

				for (let i = 0; i < this.labelList.length; i++) {
					if (JSON.stringify(this.labelList[i]).indexOf(JSON.stringify(this.tagsList[index])) >= 0) {
						this.selTrTypeList[i].splice(0, 1)
					}
				}


				this.tagsList.splice(index, 1)

				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				if (typeids != '') {
					typeids = '?typeLis=' + typeids
				}
				const newUrl = '#' + this.$route.path + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)
				this.getAudiodemo()
			},

			getAllLable() {
				this.$post('/Label/allList', {}).then(res => {
					this.alllabelList = res.data

					console.log(window.location.href)
					let newtagsList = []
					if (window.location.href.indexOf('typeLis=') >= 0) {
						let typeLisStr = window.location.href.split('typeLis=')[1]
						if (typeLisStr != '') {
							if (typeLisStr.indexOf('_') == -1) {
								newtagsList.push({
									id: parseInt(typeLisStr),
									name: ''
								})
							} else {
								let typeArr = typeLisStr.split('_')
								for (let i = 0; i < typeArr.length; i++) {
									newtagsList.push({
										id: parseInt(typeArr[i]),
										name: ''
									})
								}
							}
						}
					}

					for (let i = 0; i < this.alllabelList.length; i++) {
						for (let k = 0; k < newtagsList.length; k++) {
							if (this.alllabelList[i].id == newtagsList[k].id) {
								newtagsList[k].name = this.alllabelList[i].name
							}
						}
					}
					this.tagsList = newtagsList

					for (let i = 0; i < this.labelList.length; i++) {
						for (let k = 0; k < this.labelList.length; k++)
							if (JSON.stringify(this.labelList[i]).indexOf(JSON.stringify(this.tagsList[k])) >= 0) {
								this.selTrTypeList[i].push(1)
							}
					}
					console.log(this.tagsList)
					this.getAudiodemo()
				})
			},
			// 获取分类
			getLable() {
				var that = this;
				this.$post('/Label/index', {}).then(res => {
					this.labelList = res.data
					for (let i = 0; i < res.data.length; i++) {
						this.selTrTypeList.push([]);
						var obj = {
							height: '56px',
							class: 'item-name-box'
						}
						that.labelListHeight.push(obj);
					}
					setTimeout(function() {
						for (var i = 0; i < that.$refs.filter_item.length; i++) {
							if (that.$refs.filter_item[i].offsetWidth >= 1200) {
								console.log(i)
								that.labelListHeight[i].class = 'item-name-box item-name-box-hidden'
							} else {
								that.labelListHeight[i].class = 'item-name-box'
							}
						}
					}, 10)
					if (window.location.href.indexOf('typeLis=') >= 0) {
						this.getAllLable()
					} else {
						this.getAudiodemo()
					}
				})
			},
			toTeach(id) {
				console.log(id)
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: id
					}
				})
				goTop()
			},
			// 获取样音
			getAudiodemo() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					if (this.tagsList[i].id != 0) {
						labelidStr.push(this.tagsList[i].id)
					}
				}
				console.log(labelidStr)
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					label: labelidStr,
					keyword: this.keyword,
					on_line: this.on_line ? 1 : 0,
					order: this.orderType
				}
				this.$post('/Audio/demo', data).then(res => {
					console.log(res)
					this.audioSongList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= this.totalPage / 10) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},

			//下载音频
			handleDown(audio) {
				// 使用获取到的blob对象创建的url
				const filePath = audio.audio // mp3的地址
				fetch(filePath).then(res => res.blob()).then(blob => {
					const a = document.createElement('a')
					document.body.appendChild(a)
					a.style.display = 'none'
					// 使用获取到的blob对象创建的url
					const url = window.URL.createObjectURL(blob)
					a.href = url
					// 指定下载的文件名
					a.download = audio.title + '.mp3'
					a.click()
					document.body.removeChild(a)
					// 移除blob对象的url
					window.URL.revokeObjectURL(url)
				})
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getAudiodemo()
			},
			changeAudioSrc(audioDetail) {
				console.log(audioDetail)
				var audioInfo = {};
				audioInfo.id = audioDetail.id;
				audioInfo.audioSrc = audioDetail.audio;
				audioInfo.audioImage = audioDetail.headimgurl
				audioInfo.audioName = audioDetail.title;
				audioInfo.audioPeoName = audioDetail.nickname;
				audioInfo.audioPeoId = audioDetail.uid
				// 音乐信息存缓存
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioDetail);
			},
			changeStatus(val, flag) {
				console.log("dubbingTeacher___________", val, flag)
				this.audioId = val;
				this.audioFlag = flag
			}
		}
	}
</script>
<style scoped>
	::-webkit-scrollbar{
		height: 5px;
	}
	.item-name-box span {
		display: none;
	}

	.item-name-box-hidden span {
		display: block;
	}

	.filter-and-sort-item {
		position: relative;
		padding-right: 50px;
		box-sizing: border-box;
		overflow: hidden;
	}
	.teachOnline {
		margin-top: 3px;
		display: flex;
	}
</style>
<style>
	@import "../../assets/css/0731103.css";
	@import "../../assets/css/7de01d2-dT2.css";
	@import "../../assets/css/5774750-dT3.css";

	.fontSize_14 {
		font-size: 14px;
	}

	.color_999 {
		color: #999999
	}

	.paging-box {
		padding: 20px 0 50px;
	}

	.t_de_content .listBox {
		width: 1400px;
		border-radius: 10px;
		background-color: #fff;
		margin: 20px auto 50px;
	}

	.t_de_content .listBox .listTit {
		padding: 0 40px;
		box-sizing: border-box;
		align-items: center;
		display: grid;
		text-align: center;
		border-bottom: 1px solid #f5f5f5;
		grid-template-columns: 5% 55% 5% 5% 5% 9% 12%;
	}

	.t_de_content .listBox .listTit p {
		line-height: 100px;
		font-size: 20px;
		font-weight: 600;
	}

	.t_de_content .listBox .songList {
		padding: 0 40px;
		box-sizing: border-box;
	}

	.t_de_content .listBox .songList li {
		height: 70px;
		border-bottom: 1px solid #f5f5f5;
		box-sizing: border-box;
		text-align: center;
		align-items: center;
		display: grid;
		border-bottom: 1px solid #f5f5f5;
		grid-template-columns: 5% 55% 5% 5% 5% 9% 12%;

	}

	.t_de_content .listBox .songList li:hover {
		background: #f8f8fa
	}

	.t_de_content .listBox .songList li .songListIndex {
		text-align: center;
	}

	.t_de_content .listBox .songList li .leftBox {
		display: flex;
	}

	.t_de_content .listBox .songList li .leftBox i {
		margin-right: 10px;
		cursor: pointer;
	}

	.t_de_content .listBox .songList li .btn-trail-box {
		text-align: center;
		position: relative;
	}

	.t_de_content .listBox .songList li .btn-trail-box .tip-top {
		display: none
	}

	.t_de_content .listBox .songList li .btn-trail-box i {
		cursor: pointer;
	}

	.t_de_content .listBox .songList li .btn-trail-box:hover i {
		color: #eb3637
	}

	.t_de_content .listBox .songList li .btn-trail-box:hover>.tip-top {
		display: block
	}

	@media only screen and (max-width: 1680px) {

		.t_de_content .listBox {
			width: 1200px;
		}

		.t_de_content .listBox .songList li .leftBox {
			width: 50%;
		}

		.t_de_content .listBox .songList li .rightBox {
			width: 18%;
		}
	}

	@media only screen and (max-width:1440px) {
		.t_de_content .listBox {
			width: 1000px
		}

		.tuijianBox .songList li .rightBox {
			width: 22% !important;
		}

	}

	.teachClass:hover {
		color: #eb3637;
		font-weight: bold;
	}
</style>
