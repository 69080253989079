<template>
	<div class="copyright-music" id="copyright-music">
		<div class="label-collect flex">
			<ul class="label-ul flex-wrap">
				<li>
					<a href="javaScript:viod(0)" class="kind-title">
						<i class="iconfont icon-yinpin"></i>人气榜单<span>|</span>
					</a>
					<ul class="flex-wrap">
						<li class="label-list text-overflow" @click="todetail(item.id)" v-for="item in headRankArr1">
							<a href="javaScript:viod(0)" style="position: relative;">
								<div class='lookBox'>查看</div>
								<img :src="item.headimgurl" style="width: 30px;height: 30px"/>
							</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="javaScript:viod(0)" class="kind-title">
						<i class="iconfont icon-paiming"></i>热门排行<span>|</span>
					</a>
					<ul class="flex-wrap">
						<li class="label-list text-overflow" @click="todetail(item.id)" v-for="item in headRankArr2">
							<a href="javaScript:viod(0)" style="position: relative;">
								<div class='lookBox'>查看</div>
								<img :src="item.headimgurl" style="width: 30px;height: 30px"/>
							</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="javaScript:viod(0)" class="kind-title">
						<i class="iconfont icon-jiaoyiliang-rijun"></i>订单排行<span>|</span>
					</a>
					<ul class="flex-wrap">
						<li class="label-list text-overflow" @click="todetail(item.id)" v-for="item in headRankArr3">
							<a href="javaScript:viod(0)" style="position: relative;">
								<div class='lookBox'>查看</div>
								<img :src="item.headimgurl" style="width: 30px;height: 30px"/>
							</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="javaScript:viod(0)" class="kind-title">
						<i class="iconfont icon-nv1"></i>精选女生<span>|</span>
					</a>
					<ul class="flex-wrap">
						<li class="label-list text-overflow" @click="todetail(item.id)" v-for="item in headRankArr4">
							<a href="javaScript:viod(0)" style="position: relative;">
								<div class='lookBox'>查看</div>
								<img :src="item.headimgurl" style="width: 30px;height: 30px"/>
							</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="javaScript:viod(0)" class="kind-title">
						<i class="iconfont icon-nan1"></i>精选男生<span>|</span>
					</a>
					<ul class="flex-wrap">
						<li class="label-list text-overflow" @click="todetail(item.id)" v-for="item in headRankArr5">
							<a href="javaScript:viod(0)" style="position: relative;">
								<div class='lookBox'>查看</div>
								<img :src="item.headimgurl" style="width: 30px;height: 30px"/>
							</a>
						</li>
					</ul>
				</li>
				<li>
					<a href="javaScript:viod(0)" class="kind-title">
						<i class="iconfont icon-waiyu1"></i>精选外语<span>|</span>
					</a>
					<ul class="flex-wrap">
						<li class="label-list text-overflow" @click="todetail(item.id)" v-for="item in headRankArr6">
							<a href="javaScript:viod(0)" style="position: relative;">
								<div class='lookBox'>查看</div>
								<img :src="item.headimgurl" style="width: 30px;height: 30px"/>
							</a>
						</li>
					</ul>
				</li>
			</ul>
			<div class="vertical-line"></div>
			<div class="labei-img">
				<a rel="nofollow" href="javaScript:viod(0)" class="svip">
					<img alt="" src="../../assets/image/index/order-img2.png">
					<p style="font-size: 20px;">
						<countTo :startVal='0' :endVal='orderInfo.month' :duration='5000'></countTo>单
					</p>
					<p>本月交易</p>
				</a> 
				<a rel="nofollow" href="javaScript:viod(0)" class="free" style="text-align: center;">
					<img alt="" src="../../assets/image/index/order-img.png">
					<p style="font-size: 20px;">
						<countTo :startVal='0' :endVal='orderInfo.day' :duration='5000'></countTo>单
					</p>
					
					<p>今日成交</p>
				</a>
			</div>
		</div>
		<el-dialog
		  title="提示"
		  :visible.sync="centerDialogVisible"
		  width="30%"
		  center>
		  <div style="text-align: center;">
			  <img :src="teacherInfo.headimgurl" style="width: 80px;height: 80px;border-radius: 80px;" alt="">
			  <div style="font-size: 18px;font-weight: 600">{{teacherInfo.nickname}}</div>
			  <div style="padding-top: 10px;">
				  <span style="color: red;font-size: 16px;font-weight: bold">￥{{teacherInfo.price}}</span>/100字 
				   <span style="color: red;font-size: 16px;font-weight: bold">￥{{teacherInfo.min_price}}</span>/起配 
			  </div>
			  <div style="width: 100%;height: 2px;background-color: #F5DFA7;margin: 10px 0;"></div>
			  <div>
				  <div style="display: flex;padding: 10px 0 10px 0;" v-for="item in audioSongList">
					  <div style="width: 80%;text-align:left;padding-left: 20px;">{{item.title}}</div>
					  <div style="width: 5%;cursor: pointer;">
						  <i v-if="audioFlag==true && audioId==item.id&& $store.state.musicLoading"
						  	class="iconfont icon-jiazai"
						  	@click="changeAudioSrc(item,item.id,item.title,item.audio)"></i>
						  <i v-else-if="audioFlag==true && audioId==item.id"
						  	class="iconfont icon-zanting3"
						  	@click="changeAudioSrc(item,item.id,item.title,item.audio)"></i>
						  <i v-else class="iconfont icon-bofang1"
						  	@click="changeAudioSrc(item,item.id,item.title,item.audio)"></i>
					  </div>
					  <div style="width: 5%;cursor: pointer;">
						  <i v-if="item.is_collect==1" :class="likeIconfontShouCang"
						  	@click="changeLikeIconfont(item)" style="color: red;"></i>
						  <i v-else :class="likeIconfont" 
						  	@click="changeLikeIconfont(item)"></i>
					  </div>
				  </div>
			  </div>
			  <div style="padding-top: 30px;">
				  <button class="buttonClass" style="background-color: #0B449D;" @click="todetail2(teacherInfo.id)">
					  <i class="el-icon-s-home" style="font-size: 20px;margin-right: 5px;"></i>进入主页
				  </button>
				  <button class="buttonClass" style="background-color: red;" @click="showKefu(teacherInfo)">
					   <svg t="1693274299441" style="font-size: 18px;margin-right: 5px;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6083" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z" fill="#ffffff" p-id="6084"></path><path d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z" fill="#ffffff" p-id="6085"></path></svg>在线联系
				  </button>
			  </div>
		  </div>
		  
		 
		</el-dialog>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
		<!-- <el-dialog :visible.sync="kefuBoxShow" :modal='false' width="20%">
			<div>
				<img :src="kefuObj.code" alt=""><br>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import countTo from 'vue-count-to';
	export default {
		data() {
			return {
				orderInfo:{
					day:0,
					month:0
				},
				audioFlag:false,
				headRankArr1:[],
				headRankArr2:[],
				headRankArr3:[],
				headRankArr4:[],
				headRankArr5:[],
				headRankArr6:[],
				centerDialogVisible:false,
				likeIconfont:'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang:'iconfont icon-shoucang_shoucang',
				teacherInfo:'',
				audioSongList:[],
				kefuBoxShow:false,
				kefuObj:{},
				audioId:0,
				kefuObj2: {},
			}
		},
		components: { countTo ,teacherDialog},
		mounted() {
			this.getOrderInfo()
			this.getHeadRanking()
			this.bus.$on("changeStatus",(val,flag)=>{
				this.changeStatus(val,flag)
			});
		},
		methods:{
			showKefu(item){
				this.centerDialogVisible=false;
				if( localStorage.getItem('loginFlag')){
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data
						this.kefuBoxShow = true;
						if (item) {
							this.kefuObj.nickname = item.nickname;
							this.kefuObj.headimgurl = item.headimgurl;
							this.kefuObj.on_line = item.on_line;
						}
						console.log(this.kefuObj)
					})
				}else{
					this.$store.commit('setDialogVisibleLogin', true);
				}
			},
			changeAudioSrc(audioDetail,id,name,src){
				console.log(audioDetail)
				var audioInfo={};
				audioInfo.id=id;
				audioInfo.audioSrc=src;
				audioInfo.audioName=name;
				audioInfo.audioImage= this.teacherInfo.headimgurl
				audioInfo.audioPeoName= this.teacherInfo.nickname;
				sessionStorage.setItem('audioDetail',JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc",audioInfo);
			},
			getOrderInfo(){
				this.$post('/Ranking/orderInfo',{}).then(res=>{
					console.log(res)
					this.orderInfo = res.data
				})
			},
			getHeadRanking(){
				this.$post('/Ranking/headRanking',{}).then(res=>{
					console.log(res)
					this.headRankArr1 = res.data[0]
					this.headRankArr2 = res.data[1]
					this.headRankArr3 = res.data[2]
					this.headRankArr4 = res.data[3]
					this.headRankArr5 = res.data[4]
					this.headRankArr6 = res.data[5]
				})
			},
			// 收藏、取消收藏
			changeLikeIconfont(item){
				let data = {
					id:item.id,
					type:3,
					state:item.is_collect==0?1:0
				}
				this.$post('/MemberInfo/collect',data).then(res=>{
					console.log(res)
					this.getTeacherAaudio(this.teacherInfo.id)
				})
			},
			todetail2(id){
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: id
					}
				})
				goTop()
			},
			todetail(id){
				this.getTeacherAaudio(id)
				let data = {
					id:id
				}
				this.$post('/Teacher/teacherInfo',data).then(res=>{
					console.log(res)
					this.teacherInfo = res.data;
					this.teacherInfo.price = this.teacherInfo.price.replace(".00", "");
					this.teacherInfo.min_price = this.teacherInfo.min_price.replace(".00", "");
					this.centerDialogVisible = true
				})
			},
			// 获取老师音频
			getTeacherAaudio(id){
				let data = {
					page:1,
					limit:3,
					id:id,
					label:''
				}
				this.$post('/Teacher/teacherAaudio',data).then(res=>{
					console.log(res)
					this.audioSongList = res.data.list
				})
			},
			 changeStatus(val,flag){
				console.log("dubbing___1111________",val,flag)
				 this.audioId=val;
				 this.audioFlag=flag
			 }
		}
	}
</script>
<style scoped>
	
	/deep/.dialogBox{
		border-radius: 20px !important;
	}
</style>
<style>
	.buttonClass{
		color: white;
		width: 40%;height: 40px;
		border-radius: 40px;
		margin: 10px;
	}
	.lookBox{
		z-index: 999;width:40px;height: 40px;text-align:center;position: absolute;left:-5px;top:3px;
		line-height:40px;background-color: rgba(0,0,0,0.5);color: white;border-radius: 40px;opacity: 0;
	}
	.lookBox:hover{
		opacity: 1;
	}
</style>
