<template>
	<div class="Authorize-case-box">
		<h3>为1000万用户提供配音服务</h3>
		<p class="subtitle">众多知名企业选择声探配音</p>
		<div class="Authorize-case-main flex-wrap main-btw">
			<el-row :gutter="24">
				<el-col :xs='24' :sm='24' :md='12' style="margin-bottom: 45px;">
					<img :src="imageList[0]"/>
				</el-col>
				<el-col :xs='24' :sm='24' :md='12' style="margin-bottom: 45px;">
					<img :src="imageList[1]"/>
				</el-col>
			</el-row>
			<!-- <div class="Authorize-l"><img alt="" class="Authorize-logo" src="@/assets/image/index/bilibili.png"> <i class="comma"></i>
				<p class="Authorize-p">
					声探配音提供给的音乐内容质量良好，无杂音无失真，纯音乐的音乐风格适用于剪辑，所有音乐中覆盖的不同音乐类型齐全，整体使用感觉较好。
				</p>
			</div>
			<div class="Authorize-l"><img alt="" class="Authorize-logo" src="@/assets/image/index/youdao.png">
				<i class="comma"></i>
				<p class="Authorize-p">曲库音乐类型比较全面，授权范围很广泛，能满足日常的使用需求，授权操作很便捷。</p>
			</div> -->
			<div class="allLogo">
				<div class="allLogoBox" style="width: 90%;margin: 0 auto;">
					<template v-for="item in custPicList1">
						<a :href='item.ad_url' target="_blank">
							<el-image fit="contain" :src="item.ad_pic"></el-image>
						</a>
					</template>
				</div>
				<div class="allLogoBox" style="width: 80%;margin: 0 auto;">
					<template v-for="item in custPicList2">
						<a :href='item.ad_url' target="_blank">
							<el-image fit="contain" :src="item.ad_pic"></el-image>
						</a>
					</template>
				</div>
				<div class="allLogoBox" style="width: 70%;margin: 0 auto;">
					<template v-for="item in custPicList3">
						<a :href='item.ad_url' target="_blank">
							<el-image fit="contain" :src="item.ad_pic"></el-image>
						</a>
					</template>
				</div>
				<div class="allLogoBox" style="width: 60%;margin: 0 auto;">
					<template v-for="item in custPicList4">
						<a :href='item.ad_url' target="_blank">
							<el-image fit="contain" :src="item.ad_pic"></el-image>
						</a>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'banner',
		data() {
			return {
				custPicList1: [],
				custPicList2: [],
				custPicList3: [],
				custPicList4: [],
				imageList: []
			}
		},
		created() {

		},
		mounted() {
			this.getAdByTag();
			this.getImage()
		},
		methods: {
			getImage() {
				var that = this;
				this.$post('/Index/getServiceImg').then(res => {
					console.log(res);
					that.imageList = res.data;
				})
			},
			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_customer_banner'
				}).then(res => {
					console.log('**********************')
					console.log(res)
					for (let i = 0; i < res.data.length; i++) {
						if (i < 8) {
							this.custPicList1.push(res.data[i])
						} else if (i < 15) {
							this.custPicList2.push(res.data[i])
						} else if (i < 21) {
							this.custPicList3.push(res.data[i])
						} else {
							this.custPicList4.push(res.data[i])
						}
					}

				})
			}
		}
	}
</script>

<style scoped>
	.Authorize-case-box {
		padding: 0;
	}

	.Authorize-case-box>.Authorize-case-main .allLogo .allLogoBox /deep/ img {
		cursor: pointer;
		transition: all .3s;
	}

	.Authorize-case-box>.Authorize-case-main .allLogo .allLogoBox /deep/ img:hover {

		transform: translateY(-10px);
	}
</style>
