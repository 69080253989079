<template>
	<div class="budding-editor">
		<div class="left-menu">
			<router-link to="/aiDubbing"><img class="logo" src="@/assets/image/dubbingAI/logo.png" alt=""></router-link>
			<div class="menus">
				<router-link to="/">
					<p>
						<img src="@/assets/image/aiDubbing/menu1.png" alt="">
						<span>声探首页</span>
					</p>
				</router-link>
				<router-link to="/soundList">
					<p :class="{active: currentPath === '/soundList'}">
						<img src="@/assets/image/aiDubbing/menu2.png" alt="">
						<span>声音列表</span>
					</p>
				</router-link>
				<router-link to="/buddingEditor">
					<p :class="{active: currentPath === '/buddingEditor'}">
						<img src="@/assets/image/aiDubbing/menu3.png" alt="">
						<span>配音编辑</span>
					</p>
				</router-link>
				<router-link to="/draftList">
				<p :class="{active: currentPath === '/draftList'}">
					<img src="@/assets/image/aiDubbing/menu4.png" alt="">
					<span>我的草稿</span>
				</p>
				</router-link>
				<router-link to="/compoundList">
					<p :class="{active: currentPath === '/compoundList'}">
						<img src="@/assets/image/aiDubbing/hc-list-menu.png" alt="">
						<span>我的合成</span>
					</p>
				</router-link>
				<span class="line">-----------</span>
				<p @click="toDubbingTeacher">
					<img src="@/assets/image/aiDubbing/menu5.png" alt="">
					<span>真人配音</span>
				</p>
			</div>
			<div class="kefu" v-if="kfQrCode">
				<img :src="kfQrCode" alt="">
				<span>首席服务官</span>
			</div>
			<div class="other-btn">
				<div class="btn" @click="checkCount">
					<img src="@/assets/image/aiDubbing/tj-icon.png" alt="">
					<span>使用统计</span>
				</div>
				<div class="btn" @click="toUserPage">
					<img src="@/assets/image/aiDubbing/zsvip-icon.png" alt="">
					<span>会员中心</span>
				</div>
			</div>
			<div class="user" @click="toUserPage">
				<img class="head" :src="userHead" alt="">
				<span>{{userName}}</span>
				<img class="sz" src="@/assets/image/aiDubbing/sz-icon.png" alt="">
			</div>
		</div>
		<div class="editor">
			<keep-alive >
				<router-view v-if="$route.meta.keepAlive"/>
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive"/>
		</div>
		<!--	使用统计	-->
		<using-statistics v-if="countShow" @close="countShow=false"/>
		<!-- 登录组件	-->
		<el-dialog custom-class='customclass' :visible.sync="dialogVisible_login" width="26%" :show-close='false'
		           @close='closeDialogVisible_login' style="min-width: 350px;" class="loginBox" :append-to-body='true'>
			<loginDialog :dialogVisible.sync="dialogVisible_login" :userid='userid'></loginDialog>
		</el-dialog>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import usingStatistics from "@/views/aiDubbing/components/usingStatistics.vue";
import defaultUserHead from "@/assets/image/aiDubbing/user-head.png"
import {getFileByType} from "@/request/aiDubbing";
import loginDialog from "@/components/loginDialog.vue"

export default {
	components: {usingStatistics, loginDialog},
	data(){
		return {
			currentPath: '',
			countShow: false,
			kfQrCode:'',
			userid:''
		}
	},
	mounted() {
		this.currentPath = this.$route.path
		this.getAiUserInfo()
		this.getQrcode()
	},
	watch:{
		// 监听页面路由变化
		$route(to, from) {
			this.currentPath = to.path
		}
	},
	computed:{
		...mapGetters(['currentSelectDubbing', 'userinfo']),
		...mapState(['dialogVisible_login']),
		userHead(){
			if(this.userinfo && this.userinfo.headimgurl){
				return this.userinfo.headimgurl
			}else{
				return 'https://kangmey.com//uploads/user/default.jpg'
			}
		},
		userName(){
			if(this.userinfo && this.userinfo.user_name){
				return this.userinfo.user_name
			}else{
				return '游客'
			}
		}
	},
	methods:{
		...mapActions(['getAiUserInfo']),
		...mapMutations(['setDialogVisibleLogin']),
		closeDialogVisible_login() {
			this.setDialogVisibleLogin(false);
		},
		toUserPage(){
			if(this.userinfo && this.userinfo.user_name){
				this.$router.push('/person')
			}else{
				this.setDialogVisibleLogin(true);
			}
		},
		toDubbingTeacher(){
			if(this.userinfo && this.userinfo.user_name){
				this.$router.push('/dubbingTeacher')
			}else{
				this.setDialogVisibleLogin(true);
			}
		},
		async getQrcode(){
			try{
				const {data} = await getFileByType({type:1})
				this.kfQrCode = data.url
			}catch (e) {
				console.log(e)
			}
		},
		checkCount(){
			if(this.userinfo && this.userinfo.user_name){
				this.countShow = true
			}else{
				this.setDialogVisibleLogin(true);
			}
		}
	}
}
</script>

<style scoped lang="scss">
.budding-editor{
	display: flex;
	background: linear-gradient(#fee9e9, #FFFFFF 20%);
	height: 100vh;
}
.left-menu{
	flex: 0 0 242px;
	padding: 32px 46px 0 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	.logo{
		width: 145px;
		height: 52px;
		margin-bottom: 50px;
		margin-left: 22px;
	}
	.menus{
		flex: 1;
		p{
			display: flex;
			align-items: center;
			height: 58px;
			padding-left: 22px;
			border-radius: 2px;
			cursor: pointer;
			&.active{
				background: #fee2e2;
				span{
					color: #ED4F42;
				}
			}
			img{
				width: 22px;
				height: 22px;
				margin-right: 8px;
			}
			span{
				color: #333333;
			}
		}
		.line{
			display: inline-block;
			padding: 30px 0 30px 0;
			margin-left: 55px;
			color: #999;
		}
	}
	.kefu{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 22px;
		background: #FA5F5E;
		width: 133px;
		border-radius: 3px;
		img{
			width: 100px;
			height: 100px;
			margin: 12px 0 5px 0;
			border-radius: 5px;
		}
		span{
			color: #fff;
			margin-bottom: 10px;
		}
	}
	.other-btn{
		margin-left: 22px;
		margin-top: 16px;
		.btn{
			cursor: pointer;
			width: 130px;
			height: 34px;
			background: #ED4F42;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px;
			margin-bottom: 5px;
			img{
				margin-right: 6px;
			}
		}
	}
	.user{
		display: flex;
		align-items: center;
		padding: 14px 0 14px 22px;
		cursor: pointer;
		border-top: 1px solid #D1CFCF;
		margin-top: 10px;
		.head{
			width: 30px;
			height: 30px;
			margin-right: 3px;
			border-radius: 100%;
		}
		span{
			margin-right: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.editor{
	flex: 1;
	padding: 0;
	display: flex;
	overflow-y: auto;
}
</style>