<template>
  <div class="home" ref="homeRef">
	  <banner></banner>
	  <copyRight></copyRight>
		<dubbing></dubbing>
		<serviceCase></serviceCase>
		<leaderBoard></leaderBoard>
		<programme></programme>
		<dubber></dubber>
		<customerVoice></customerVoice>
		<rightAside :asideItemShowFlag='false'></rightAside>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import banner from '@/components/index/banner'
import copyRight from '@/components/index/copyRight'
import dubbing from '@/components/index/dubbing'
import serviceCase from '@/components/index/serviceCase'
import leaderBoard from '@/components/index/leaderBoard'
import programme from '@/components/index/programme'
import dubber from '@/components/index/dubber'
import customerVoice from '@/components/index/customerVoice'
import rightAside from "@/components/index/rightAside.vue";
export default {
  name: "Home",
  components: {
	  banner,
	  copyRight,
	  dubbing,
	  serviceCase,
	  leaderBoard,
	  programme,
		dubber,
		customerVoice,
		rightAside
    // HelloWorld,
  },
  mounted() {
	console.log("window.location.href",window.location.href.split("=")[1]);
	let source=window.location.href.split("=")[1];
	// let source='cs1234'
	if(source){
		console.log('res');
		this.$post('/index/getLeadSource',{source:source}).then(res=>{
			console.log(res)
		})
	}
	
  },
  methods:{
	
  }
};

// window.onload = function(){
// 	console.log(document.getElementById('HeaderBox'))
// 	document.getElementById('HeaderBox').style.background = 'transparent'
// 	document.getElementById('Header').style.background = 'transparent'
// 	document.getElementsByTagName('main')[0].style.position = 'relative'
// 	document.getElementsByTagName('main')[0].style.top = '-60px'
// }
</script>
<style scoped>
	/deep/#HeaderBox{
		background-color: transparent;
	}
</style>
