<template>
<div class="voice-convert-modal" :style="{top: `${top}px`, left:`${left}px`}" @click.stop>
  <p class="title">所选内容</p>
  <p class="input jy"><input type="text" :value="selelctText" disabled style="color: #999"></p>
  <p class="title">替换发音</p>
  <p class="input"><input type="text" v-model="voiceConvertText"></p>
  <p class="btns">
    <editor-button bg-color="#ccc" style="margin-right: 10px" @click="cancel">取 消</editor-button>
    <editor-button bg-color="#fdeded" @click="confirm">确 定</editor-button>
  </p>
</div>
</template>

<script>
import Toast from 'vue-toast-mobile';
require("vue-toast-mobile/lib/index.css")
import EditorButton from "@/components/Editor/editorButton.vue";

export default {
  components: {EditorButton},
  props:{
    top: {
      type:Number,
      default:0
    },
    left: {
      type:Number,
      default:0
    },
    selelctText:String
  },
  data(){
    return {
      voiceConvertText:''
    }
  },
  methods:{
    cancel(){
      this.$emit('close')
    },
    confirm(){
      if(!this.voiceConvertText){
        Toast('请输入替换发音')
        return
      }
      this.$emit('voiceConvertConfirm', this.voiceConvertText)
    }
  }
}
</script>

<style scoped>
.voice-convert-modal{
  position: fixed;
  background: #fff;
  padding: 15px 8px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ddd;
  font-size: 14px;
  max-width: 420px;
  z-index: 10;
}
.title{
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}
.input{
  margin-bottom: 10px;
}
.input  input{
  height: 26px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 200px;
  padding: 5px;
}
.btns{
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
</style>