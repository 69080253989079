<template>
	<div class="dialogBox">
		<div class="tit">
			<div class="leftBox" style="margin-right: 30px;">
				<div class="dotBox">
					<img :src='kefuObj.headimgurl' />
					<span :class="['dot',{'dot_true':kefuObj.on_line == 1}]"></span>
				</div>
			</div>
			<div class="rightBox">
				1对1在线沟通，<span>免费</span>试音
			</div>

		</div>
		<div class="text" style="margin-top: 5px;">
			{{kefuObj.nickname}}
		</div>
		<div class="imageBox">
			<img :src='kefuObj.code' />
		</div>
		<p class="p1">微信扫一扫，沟通更有效</p>
		<p class="p2">与配音老师实时沟通您的需求</p>
	</div>
</template>

<script>
	export default {
		props: ['kefuObj']
	}
</script>

<style scoped>
	.tit {
		display: flex;
		align-items: center;
	}
	.tit .leftBox{
		/* display: flex;
		align-items: center; */
		
	}
	.tit .leftBox .text{
		width: 80px;
		line-height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
.tit .dotBox {
	width: 50px;
	height: 50px;
	position: relative;
	margin-right: 5px;
}
	.tit .leftBox img {
		display: inline-block;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		vertical-align: middle;
	}

	.tit .rightBox {
		margin-left: 10px;
		font-size: 23px;
		font-weight: 600;
		color: #262626
	}

	.tit .rightBox span {
		color: #df2424;
	}

	.imageBox {
		width: 200px;
		height: 200px;
		padding: 20px;
		border-radius: 20px;
		box-sizing: border-box;
		background-color: #f5f5f5;
		margin: 20px auto ;
	}

	.p1 {
		color: #8c8c8c;
		font-size: 22px;
		font-weight: 600;
		text-align: center;
	}

	.p2 {
		color: #a8a8a8;
		font-size: 18px;
		text-align: center;
		margin-top: 10px;
	}
	.dot{
		position: absolute;
		right: 2px;
		bottom: 0px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: #ccc;
	}
	.dot_true{
		background-color: #5eba10
	}
</style>
