import * as binconv from 'binconv'
import {Message } from "element-ui"
import download from 'downloadjs'

export function getRandomColorRGB() {
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);

    // 确保RGB值在0-255之间（包括0和255）
    r = (r < 0) ? 0 : ((r > 255) ? 255 : r);
    g = (g < 0) ? 0 : ((g > 255) ? 255 : g);
    b = (b < 0) ? 0 : ((b > 255) ? 255 : b);

    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}

// 下载OSS文件
export async function downloadFile(url, fileName='download.mp3') {
    // 使用 fetch API 获取文件
    const ossuRL = url.replace('https://stai.fss-my.addlink.cn', '/aiApic')
    const response = await fetch(ossuRL);
    if (!response.ok) {
        Message.error(`HTTP error! status: ${response.status}`)
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob()
    console.log(blob)
    // 创建一个指向 Blob 对象的 URL
    const urlCreator = window.URL || window.webkitURL;
    const blobUrl = urlCreator.createObjectURL(blob);

    // 创建一个 a 标签并设置其属性
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName; // 设置下载文件的名称
    a.style.display = 'none';

    // 将 a 标签添加到文档
    document.body.appendChild(a);

    // 触发 a 标签的点击事件
    a.click();

    // 然后移除 a 标签
    document.body.removeChild(a);

    // 释放 Blob URL 对象
    urlCreator.revokeObjectURL(blobUrl);
}

// 时间日期格式化
export default function formatDateTime(dateStr, format = 'yyyy-MM-dd HH:mm:ss') {
    let date = new Date(dateStr)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // 根据传入的格式字符串进行替换
    return format.replace('yyyy', year)
        .replace('MM', month)
        .replace('dd', day)
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('ss', seconds);
}

// 复制
export function copyTextToClipboard(text) {
    if (!text) return
    console.log(text)
    // 创建一个临时的 textarea
    const textarea = document.createElement('textarea')
    // 防止自动滚动（可选）
    textarea.style.position = 'fixed'
    textarea.style.top = '-99999px'
    textarea.style.left = '0'
    // 设置为不可见
    textarea.style.opacity = '0'
    // 设置 textarea 的值
    textarea.value = text
    // 将 textarea 添加到 body 中
    document.body.appendChild(textarea)
    // 选择文本
    textarea.focus()
    textarea.select()
    try {
        // 尝试复制选中的文本
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        if (msg === 'successful') {
            return true
        } else {
            return false
        }
    } catch (err) {
        return false
    } finally {
        // 清理
        document.body.removeChild(textarea)
    }
}