<template>
	<div class="aside-wrap aside-wrap-left" :class="displayNone" style="height: 590px;" :style='{"top":margintop+"px"}'>
		<aside id="aside-nav" class="aside-nav">
			<div class="aside-box dir-c">
				<ul class="aside-lists">
					<li class="aside-items">
						<div class="aside-item">
							<i class="iconfont icon-yinpin" style="margin-top: 20px;font-size: 20px;color:#ff0000"></i>
							<a rel="nofollow" class="aside-item-content" @click="pageScroll('teacher')">
								<i class="iconfont icon-fenlei-remen"></i>
								<span>热门</span>
							</a>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item"><a rel="nofollow" @click="pageScroll('authorize')" 
								class="aside-item-content">
								<i class="iconfont icon-al"></i>
								<span>案例</span></a>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item">
							<a @click="pageScroll('yinyuerenBox')">
								<div class="aside-item-content">
									<i class="iconfont icon-icon-huatong"></i>
									<span>样音</span>
								</div>
							</a>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item">
							<a @click="pageScroll('fanganBox')">
								<div class="aside-item-content">
									<i class="iconfont icon-fangan"></i>
									<span>方案</span>
								</div>
							</a>	
						</div>
					</li>
					<li class="aside-items aside-items-activity">
						<div class="aside-item">
							<a rel="nofollow" href="javaScript:void(0)" @click="goPage(4)" class="aside-item-content">
								<i class="iconfont icon-bangzhu"></i>
								<span>帮助</span>
							</a>

						</div>
					</li>
					<li class="aside-items aside-items-point">
						<div class="aside-item">
							<div class="aside-item-content" @click="goPage(5)"><i
									class="iconfont icon-weibiaoti556"></i>
								<span>翻译</span>
							</div>
						</div>
					</li>
					<li class="aside-items">
						<div class="aside-item"><a href="javaScript:void(0)" @click="goPage(6)"
								class="aside-item-content"><i class="iconfont icon-guanyuwomen"></i>
								<span>关于</span></a>
						</div>
					</li>
				</ul>
			</div>
		</aside>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	export default {
		name: "",
		components: {},
		data() {
			return {
				displayNone: '',
				margintop:340
			}
		},
		methods: {
			pageScroll(name){
				// document.getElementById(name).scrollIntoView({ block: 'center', behavior: 'smooth' })
				this.$emit('goScroll',name);
			},
			goPage(type) {
				if (type == 0) {
					this.$router.push({
						path: '/'
					})
				} else if (type == 1) {
					this.$router.push({
						path: '/dubbingCase'
					})
				} else if (type == 2) {
					this.$router.push({
						path: '/sampleAudition'
					})
				} else if (type == 3) {
					this.$router.push({
						path: '/dubbingScheme'
					})
				} else if (type == 4) {
					// about?parentIndex=2&menuid=10
					this.$router.push({
						path: '/about',
						query: {
							parentIndex: 2,
							menuid: 10
						}
					})
				} else if (type == 5) {
					this.$router.push({
						path: '/humanTranslation'
					})
				} else if (type == 6) {
					this.$router.push({
						path: '/about'
					})
				}
				goTop()
			},
			handleScroll() {
				let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
				// console.log("top--》",top)
				if (top <= 200) {
					this.margintop = 340
				}if (top > 200 && top<=3100) {
					this.margintop = 220
				}else if(top>3100){
					this.margintop = 110
				}
			},
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		}
	};
</script>

<style scoped>
	.displayNone {
		display: none;
	}
</style>
