<template>
	<div>
		<div class="content">
			<p>温馨提示:支付宝账号或姓名错误将无法到账，请检查确认后再提交!提现时系统会扣除1%支付宝手续费，2-5个工作日内到账!</p>
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="提现支付宝:" style="min-width: 250px;">
					<el-input v-model="form.zhifubao"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="身份证号">
					<el-input v-model="form.identy"></el-input>
				</el-form-item>
				<el-form-item label="提现金额">
					<el-input v-model="form.money" style="width: 30%;"></el-input>&nbsp;&nbsp;&nbsp;元
					<span style="color:#fa210d;cursor: pointer;margin: 0 10px;" @click="makeTotal()">全部提现</span>
					<span>预计到账:</span>
				</el-form-item>
			</el-form>
			<div style="display: flex;align-items: center;justify-content: center;">
				<span slot="footer" class="dialog-footer">
					<el-button @click="closeDialog()">取 消</el-button>
					<el-button type="primary" @click="submit()">确定提现</el-button>
				</span>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				userInfo: {},
				form: {
					name: '',
					identy: '',
					money: '',
					zhifubao: ''
				}
			}
		},
		props: [
			"tixianDialogFlag",
			"totalMoney"
		],
		methods: {
			resetForm(){
				this.form={
					name: '',
					identy: '',
					money: '',
					zhifubao: ''
				}
			},
			makeTotal(){
				this.form.money=this.$props.totalMoney
			},
			closeDialog (){
				this.resetForm()
				// this.$emit('update:tixianDialogFlag', false);
				this.$emit('update',false)
			},
			submit() {
				console.log(this.tixianDialogFlag)
				// this.closeDialog();
				// this.$message({
					
				// })
				// localStorage.setItem("token", res.data.token);
				this.tixianDef()
			},
			tixianDef(){
				let data = {
					number:this.form.zhifubao,
					zs_name:this.form.name,
					id_code:this.form.identy,
					price:this.form.money
				}
				this.$post('/MemberInfo/Withdrawal',data).then(res=>{
					this.$message({
						 message: res.mess,
						 type:'success'
					})
					this.closeDialog()
				})
			},
		}
	}
</script>

<style scoped>
	.content {
		padding: 0px 70px;
	}
	/deep/.el-form-item__content{
		margin-left: 110px !important;
	}
	/deep/.el-form-item__label{
		min-width: 110px !important;
		text-align: left;
	}
	.el-form{
		margin-top: 20px;
	}
	/deep/ .el-input__inner:focus{
		border:1px solid #DCDFE6
	}
	/deep/ .el-button--primary,/deep/ .el-button--primary:hover,.el-button--primary:active,.el-button--primary:focus{
		background-color: #fa210d;
		border: none;
	}
	/deep/ .el-button.el-button--default:hover{
		border:1px solid #DCDFE6;
		background-color: transparent;
		color:#606266
	}
</style>
