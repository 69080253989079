<template>
	<div class="rightContentBox sound-content">
		<ul class="menuList">
			<template v-for="(item,index) in menuList">
				<li :class="activeLiIndex == index ?'rightItemActive':''" @click="changeActiveLiIndex(index)">
					{{item.itemname}}
				</li>
			</template>
		</ul>
		<div class="notUsed" v-if="activeLiIndex == 0">
			<el-input placeholder="请输入优惠券码" v-model="cdkCode" style="cdkCode"></el-input>
			<el-button type="primary" @click="couponExchange">领取</el-button>
			<div style="width: 100%;height: 20px;"></div>
			<el-table
			    :data="usedList"
			    height="250"
			    border
				:header-cell-style="{'text-align':'center'}"
				:cell-style="{'text-align':'center'}"
			    style="width: 100%">
			    <el-table-column
			      prop="dec_price"
			      label="金额"
			      width="200">
			    </el-table-column>
			    <el-table-column
			      prop="man_price"
			      label="使用条件"
			      width="200">
			    </el-table-column>
			    <el-table-column
			      prop="time"
			      label="到期日期"
			      width="300">
			    </el-table-column>
			    <el-table-column
			      prop="stauts"
			      label="状态">
				  未使用
			    </el-table-column>
			  </el-table>
		</div>
		<div class="used" v-if="activeLiIndex == 1">
			<el-table
			    :data="usedList"
			    height="250"
			    border
				:header-cell-style="{'text-align':'center'}"
				:cell-style="{'text-align':'center'}"
			    style="width: 100%">
			    <el-table-column
			      prop="dec_price"
			      label="金额"
			      width="300">
			    </el-table-column>
			    <el-table-column
			      prop="time"
			      label="使用日期"
			      width="300">
			    </el-table-column>
			    <el-table-column
			      prop="stauts"
			      label="状态">
				  已使用
			    </el-table-column>
			  </el-table>
			
		</div>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	import copyText  from "@/utils/copyText";
	export default {
		data() {
			return {
				activeLiIndex: 0,
				cdkCode:'',
				usedList:[]
			}
		},
			
		props: [
			"menuList"
		],
		mounted() {
			this.getmyCoupon(1)
		},
		methods:{
			getmyCoupon(type){
				let data = {
					type:type
				}
				this.$post('/MemberInfo/myCoupon',data).then(res=>{
					this.usedList = res.data
				})
			},
			couponExchange(){
				if(this.cdkCode == ''){
					this.$message({
						 message: '请输入兑换码',
						 type:'error'
					})
					return false
				}
				let data = {
					code:this.cdkCode
				}
				this.$post('/MemberInfo/couponExchange',data).then(res=>{
					this.getmyCoupon(1)
					this.$message({
						 message: res.mess,
						 type:'success'
					})
				})
			},
			changeActiveLiIndex(index){
				this.activeLiIndex = index
				this.getmyCoupon(index+1)
			},
			
		}
	}
</script>

<style scoped>
	.notUsed,.used{
		padding: 20px;
	}
	.notUsed .el-button--primary,.el-button--primary:hover{
		background-color: #e83233;
		border: none;
		margin-left: 50px;
	}
	.notUsed .el-input{
		width: 45%;
	}
	/deep/.notUsed .el-input__inner:focus,/deep/.notUsed .el-input__inner:active{
		border:1px solid #DCDFE6
	}
	.rightContentBox .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		overflow: hidden;
	}

	.rightContentBox .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		color: #8c8c8c
	}

	.rightContentBox .menuList>li.rightItemActive {
		color: #2c3e50
	}

	.rightContentBox .menuList>li.rightItemActive:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 4px;
		left: 10%;
		bottom: -15px;
		background-color: #e83233;
	}
	.content{
		padding: 20px;
		box-sizing: border-box;
	}
	
	@media only screen and (max-width: 1680px){
		
	}
	@media only screen and (max-width: 1440px){
		
	}
</style>
