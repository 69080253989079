<template>
	<div id="app">
		<template v-if="filterRouter.includes(currentPath)">
			<router-view/>
		</template>
		<template v-else>
			<!-- Lucky -->
			<el-container>
				<el-header style="padding:0;height: 60px;">
					<Header  ref='header' :elImage_logoIndex='elImage_logoIndex' @changeElImage_logoIndex='changeElImage_logoIndex'></Header>
				</el-header>
				<img v-if="guangGaoImgFlag && guangGaoImg!=''" :src="guangGaoImg" style="height: 80px;width: 100%;"/>
				<el-main style="padding:0; overflow: hidden;min-height: 100vh;min-width: 1350px;padding-bottom: 60px"><router-view/></el-main>
				<el-footer style="padding:0;min-width: 1350px;">
					<Footer></Footer>
				</el-footer>
			</el-container>
			<!-- Lucky -->
		</template>
	</div>
</template>
<script>
import goTop from '@/utils/goTop.js'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {mapState} from "vuex";

export default {
	name: "app",
	components: {
		Header,
		Footer
	},
	data(){
		return {
			guangGaoImg:'',
			guangGaoImgFlag:false,
			elImage_logoIndex:0,
			guangGaoOpen:true,
			filterRouter:['/buddingEditor','/soundList','/draftList','/compoundList'], // 过滤不需要头尾的路由
		}
	},
	computed:{
		...mapState(['currentPath'])
	},
	mounted() {
		// const url='http://kangmey.com/h5/#/?source=cs12'
		// console.log("url.includes('?')",url.includes('userid'));
		if(this.$route.query.userid || this.$route.path.includes('source') || this.$route.path == '/'){
			this.guangGaoImgFlag=false;
			this.elImage_logoIndex=0;
			this.$refs.header.changeTextColor("#fff");
			document.getElementById('HeaderBox').className='HeaderBox2'
			// document.getElementById('Header').style.background = 'transparent'
			document.getElementsByTagName('main')[0].style.position = 'relative'
			document.getElementsByTagName('main')[0].style.top = '-60px'
			// document.getElementsByClassName('aside-wrap')[0].style.display = 'block'
		}else{
			this.guangGaoImgFlag=true;
			this.elImage_logoIndex=1;
			this.$refs.header.changeTextColor("#333");
			document.getElementById('HeaderBox').className='HeaderBox1'
			// document.getElementById('HeaderBox').style.background = 'whtie'
			document.getElementsByTagName('main')[0].style.position = 'relative'
			document.getElementsByTagName('main')[0].style.top = '0px'
			// document.getElementsByClassName('aside-wrap')[0].style.display = 'none'
		}
		this.getAdByTag();
		// this.getWebInfo();
	},
	watch: {
		'$route'(to, from) {
			console.log('to, from', to, from)
			setTimeout(()=>{
				goTop()
				if(this.$route.path.includes('userid') || this.$route.path.includes('source') || this.$route.path == '/'){
					this.elImage_logoIndex=0;
					this.$refs.header.changeTextColor("#fff");
					this.guangGaoImgFlag=false;
					document.getElementById('HeaderBox').className='HeaderBox2'
					// document.getElementById('Header').style.top = '0px'
					// document.getElementById('HeaderBox').style.background = 'transparent'
					// document.getElementById('Header').style.background = 'transparent'
					document.getElementsByTagName('main')[0].style.position = 'relative'
					document.getElementsByTagName('main')[0].style.top = '-60px'
					// document.getElementsByClassName('aside-wrap')[0].style.display = 'block'
				}else{
					this.guangGaoImgFlag=true;
					this.elImage_logoIndex=1;
					this.$refs.header.changeTextColor("#333");
					document.getElementById('HeaderBox').className='HeaderBox1'
					// document.getElementById('Header').style.top = '80px'
					// document.getElementById('HeaderBox').style.background = 'whtie'
					// document.getElementById('Header').style.background = 'whtie'
					document.getElementsByTagName('main')[0].style.position = 'relative'
					document.getElementsByTagName('main')[0].style.top = '0px'
					// document.getElementsByClassName('aside-wrap')[0].style.display = 'none'
				}
			},200)
		}
	},
	methods: {
		changeElImage_logoIndex(index){
			this.elImage_logoIndex=index;
		},
		getAdByTag() {
			this.$post('/Ad/getAdByTag', {
				tag: 'pc_top_banner'
			}).then(res => {
				if(res.data.length!=0){
					this.guangGaoImg = res.data[0].ad_pic
				}
			})
		},
		// getWebInfo(){
		// 	this.$post('/config/web_icon', {
		// 	}).then(res => {
		// 		console.log(res)
		// 		const link = document.querySelector("link[rel='icon']");
		// 		link.href =res.data.icon_img;
		// 		document.title = res.data.icon_name;
		// 	})
		// }
	}
};

window.onload = function(){
	if(window.location.href.split('/#/')[1] == '' || window.location.href.includes('source')|| window.location.href.includes('userid')){
		this.elImage_logoIndex=0;
		document.getElementById('HeaderBox').className='HeaderBox2'
		document.getElementsByTagName('main')[0].style.position = 'relative'
		document.getElementsByTagName('main')[0].style.top = '-60px'
		// document.getElementsByClassName('aside-wrap')[0].style.display = 'block'
	}else{
		document.getElementById('HeaderBox').className='HeaderBox1'
		document.getElementsByTagName('main')[0].style.position = 'relative'
		document.getElementsByTagName('main')[0].style.top = '0px'
		// document.getElementsByClassName('aside-wrap')[0].style.display = 'none'
	}
}
</script>
<style>
@import url('assets/css/responsive.css');
body,html{
	height: 100%;
}
* {
	margin: 0;
	padding: 0;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;
	height: 100% !important;
}
@font-face {
	font-family: 'iconfont';  /* Project id 4489276 */
	src: url('https://at.alicdn.com/t/c/font_4489276_h9z1xzslnbs.woff2?t=1711982011600') format('woff2'),
	url('https://at.alicdn.com/t/c/font_4489276_h9z1xzslnbs.woff?t=1711982011600') format('woff'),
	url('https://at.alicdn.com/t/c/font_4489276_h9z1xzslnbs.ttf?t=1711982011600') format('truetype');
}
.iconfont{
	font-family:"iconfont" !important;
	font-size:16px;font-style:normal;
	-webkit-font-smoothing: antialiased;
	-webkit-text-stroke-width: 0.2px;
	-moz-osx-font-smoothing: grayscale;
}
.title button{
	font-size: 13px;
	font-weight: normal;
}
</style>
