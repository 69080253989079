<template>
	<div class="musician-wrap module" id='yinyuerenBox' style='background-color: #f2f2f2;'>
		<div class="title-wrap">
			<div class="title">
				<p>推荐配音员<span>·</span>MUSICIAN</p>
			</div>
			<div class="arrow-box fsz0">
				<button class="arrow-left iconfont iconleft" @click="prevFn(1)"></button>
				<button class="arrow-right iconfont iconright" @click="prevFn(2)"></button>
			</div>
		</div>
		<div class="musician-box">
			<div class="swiper-box pr">
				<div class="swiper-container backgroundfff swiper-container-dubber">
					<div class="swiper-wrapper">
						<div @mouseenter="showJianjie(index)" @click="goDubbingTeacher(item.id)" @mouseleave="hideJianjie(index)" class="swiper-slide swiper-slide-visible swiper-slide-active swiper-no-swiping" style='position: relative;cursor: pointer;' v-for="(item,index) in teacherList">
							<a href="javaScript:viod(0)"
								class="link-banner swiper-lazy swiper-lazy-loaded"
								:style="{'background-image': 'url('+item.music_img+')'}">
								<div class="musician-tr"  style="min-height: 70px;background-color: white;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;padding:10px 30px;z-index:3;position: absolute;bottom: 0px;left: 0px;height: auto;">
									<p class="musician-name" style='color: #333333;'>{{item.nickname}}</p>
								</div>
								<transition name="jianjie">
									<div v-show='index == selteach' style="position: absolute;width: 100%;height: 100%;top:0;left: 0px;z-index:2;box-sizing: border-box;">
										<div style='color: white;padding: 9px;background:rgba(0, 0, 0, 0.7);height:256px;border-top-left-radius: 10px;border-top-right-radius: 10px;'>
											简介：{{item.blurb}}
										</div>
									</div>
								</transition>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from "swiper"
	export default {
		data() {
			return {
				teacherList:[],
				selteach:-1
			}
		},
		created() {

		},
		mounted() {
			this.getTeacherLst()
		},
		methods: {
			
			showJianjie(index){
				this.selteach = index
				console.log(index)
			},
			hideJianjie(index){
				console.log(index)
				this.selteach = -1
			},
			
			// 配音老师详情页
			goDubbingTeacher(audioTeacherId){
				this.$router.push({
				    path: '/dubbingTeacher/teacherDetail',
				    query: {
				        audioTeacherId
				    }
				})
				goTop()
			},
			// 获取老师列表
			getTeacherLst(){
				
				let data = {
					page:1,
					limit:100,
				}
				this.$post('/Teacher/music',data).then(res=>{
					console.log(res)
					this.teacherList = res.data.list
					this.myswiper_dubber();
				})
			},
			myswiper_dubber() {
				this.swiper_dubber = new Swiper('.swiper-container-dubber', {
					slidesPerView : 5,
				});
			},
			prevFn(type){
				if(type==1){
					this.swiper_dubber.slidePrev();
				}else{
					this.swiper_dubber.slideNext();
				}
			}
		}
	}
</script>

<style>
	.jianjie-enter-active, .jianjie-leave-active {
	  transition: opacity 0.6s;
	}
	.jianjie-enter, .jianjie-leave-to  {
	  opacity: 0;
	}
</style>
