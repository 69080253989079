<template>
	<div class="copyright-music-Box module" id='teacher'>
		<div>
			<div class="sheet_music">
				<div class="title-wrap">
					<div class="title">
						<p>配音老师<span>·</span>Dubbing teacher</p>
						<button type="button" @click="selLabelDef(-1)"
							:class="selLabelindex==-1?'activity':''">全部</button>
						<button type="button" @click="selLabelDef(index)" :class="selLabelindex==index?'activity':''"
							v-for='(item,index) in labelArr'>{{item.name}}</button>
					</div>
					<a href="javaScript:void(0)" class="more" style="display:block;" @click="goMore()">查看更多 &gt;&gt;</a>
				</div>
				<ul class="case-list" v-if="audioList.length>0">
					<template v-for="(item,index) in audioList">
						<li>
							<a href="javaScript:void(0)" @click="goDubbingTeacher(item.id)" class="cover">
								<div class="peoDetail">
									<div class="imgBox" style="min-width: 52px;">
										<img :src="item.headimgurl" />
										<span v-if="item.on_line == 1" class="login"></span>
										<span v-else class="nologin"></span>
									</div>
									<div class="peoName" >
										<el-tooltip placement="top">
											<template #content> {{item.nickname}}</template>
											<p class="peoName_name">{{item.nickname}}</p>
										</el-tooltip>
										<p>销量：{{item.volume}}</p>
									</div>
									<button @click.stop="showKefu(item)">
										<svg t="1693274299441" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6083" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z" fill="#ffffff" p-id="6084"></path><path d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z" fill="#ffffff" p-id="6085"></path></svg>
										在线联系
									</button>
								</div>
								<p class="audioDetailP">{{item.blurb}}</p>

								<i v-if="item.is_recommend == 1" class="iconfont icon-tuijian"></i>
								<!-- <i class="iconfont icon-remen"></i> -->
							</a>
							<div class="peoCaseBox">
								<ul style="height: 120px;">
									<template v-for="(audio,audioindex) in item.audio">
										<li>
											<p>{{audio.title}}</p>
											<div>
												<i v-if="audioFlag==true && audioId==audio.id && $store.state.musicLoading"
													class="iconfont icon-jiazai"
													@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item.headimgurl)"></i>
												<i v-else-if="audioFlag==true && audioId==audio.id"
													class="iconfont icon-zanting3"
													@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item.headimgurl)"></i>
												
												<i v-else class="iconfont icon-bofang1"
													@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item.headimgurl)"></i>
												<i class="iconfont icon-fenxiang"
													@click="onCopy($event,audio.audio, item)"></i>
											</div>
										</li>
									</template>
								</ul>
								<div class="peoPriceBox">
									<div class="peoPrice">
										<p><span class="redFont"
												style="font-size: 18px;">￥{{item.price.replace('.00','')}}/</span><span>100字</span>
										</p>
										<p><span class="redFont"
												style="font-size: 18px;">￥{{item.min_price.replace('.00','')}}/</span><span>起配</span>
										</p>
									</div>
									<i v-if="item.is_collect==1" :class="likeIconfontShouCang"
										@click="changeLikeIconfont(item)"></i>
									<i v-else :class="likeIconfont" @click="changeLikeIconfont(item)"></i>
								</div>
							</div>
						</li>
					</template>
				</ul>
				<button class="content-kefu" @click="showKefu2()">
					<i class="iconfont icon-kefu"></i>
					联系客服更省心
				</button>
			</div>
		</div>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
		<el-dialog custom-class='customclass' :visible.sync="dialogVisible_content" width="24%" :show-close='false'
			style="min-width: 350px;" class="kefuBox" :append-to-body='true'>
			<keFuDialog :dialogVisible_content.sync="dialogVisible_content" :kefuObj='kefuObj2'></keFuDialog>

		</el-dialog>
		<el-dialog :visible.sync="keFuDialogThree_dialogVisible" width="480px" :show-close='false' class="kefuBox3"
			:modal-append-to-body='false'>
			<keFuDialogThree :dialogVisible_content.sync="keFuDialogThree_dialogVisible" :kefuObj='kefuObj3'>
			</keFuDialogThree>
		</el-dialog>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	import copyText from "@/utils/copyText";
	import keFuDialog from "@/components/keFuDialog.vue"
	import teacherDialog from "@/components/teacherDialog.vue"
	import keFuDialogThree from "@/components/keFuThree.vue"
	export default {
		components: {
			teacherDialog,
			keFuDialog,
			keFuDialogThree
		},
		data() {
			return {
				audio_idClass: 'footer-wavesurfer tran',
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				audioId: '',
				audioFlag: false,
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				audioList: [],
				tagsList: [],
				labelArr: [],
				selLabelindex: -1,
				kefuBoxShow: false,
				kefuObj: {},
				dialogVisible_content: false,
				kefuObj2: {},
				keFuDialogThree_dialogVisible: false,
				kefuObj3: {
					type: '',
					image: ''
				},
			}
		},
		mounted() {
			this.getTeacherLst()
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
			this.getShowCate()
		},
		methods: {

			showKefu(item) {
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data
						this.kefuBoxShow = true;
						if (item) {
							this.kefuObj.nickname = item.nickname;
							this.kefuObj.headimgurl = item.headimgurl;
							this.kefuObj.on_line = item.on_line;
						}
						console.log(this.kefuObj)
					})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }

			},
			showKefu2() {
				if (localStorage.getItem('loginFlag')) {
					var that = this;
					this.$post('/Index/getKfCodeImg', {ename:'kf_code'}).then(res => {
						// console.log(res)
						that.keFuDialogThree_dialogVisible = true;
						that.kefuObj3 = {
							type: 3,
							image: res.data.code
						}
					})
					// this.$post('/Index/getcKfCode', {}).then(res => {
					// 	this.kefuObj2 = res.data;
					// 	this.dialogVisible_content = true;
					// })
				} else {
					this.$store.commit('setDialogVisibleLogin', true);
				}

			},
			selLabelDef(index) {
				this.selLabelindex = index
				this.tagsList = []
				if (index != -1) {
					this.tagsList.push(this.labelArr[index])
				}
				this.getTeacherLst()
			},
			// 获取分类筛选标签
			getShowCate() {
				let data = {
					limit: 5
				}
				this.$post('/Label/showCates', data).then(res => {
					// console.log(res)
					this.labelArr = res.data
				})
			},
			// 获取老师列表
			getTeacherLst() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					labelidStr.push(this.tagsList[i].id)
				}
				let data = {
					page: 1,
					limit: 10,
					label: labelidStr,
					on_line: 1
				}
				this.$post('/Teacher/lst', data).then(res => {
					// console.log(res)
					// this.audioList = [...res.data.list,...res.data.list]
					this.audioList = res.data.list
				})
			},
			onCopy(event, audioSrc, item) {
				copyText(event, item.nickname + audioSrc)
			},
			// 配音老师详情页
			goDubbingTeacher(audioTeacherId) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId
					}
				})
				goTop()
			},
			// 进入更多
			goMore() {
				this.$router.push({
					path: '/dubbingTeacher',
				})
				goTop()
			},
			// 收藏、取消收藏
			changeLikeIconfont(item) {
				let data = {
					id: item.id,
					type: 1,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					//console.log(res)
					//console.log(sessionStorage.getItem('taskid'), item.is_collect)
					if (sessionStorage.getItem('taskid') && item.is_collect == 0) {
						this.$toTask()
					}

					this.getTeacherLst()
				})
			},
			changeAudioSrc(audioDetail, id, name, src, img) {
				var audioInfo = {};
				audioInfo.id = id;
				audioInfo.audioSrc = src;
				audioInfo.audioName = name;
				audioInfo.audioImage = img
				audioInfo.audioPeoName = audioDetail.nickname;
				audioInfo.audioPeoId = audioDetail.id;
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeStatus(val, flag) {
				this.audioId = val;
				this.audioFlag = flag
			}
		}
	}
</script>

<style scoped>
	.copyright-music-Box .case-list li .cover .peoDetail .peoName {
		max-width: 126px;
		margin: 0 0% 0 2%;
		flex: 1;
	}

	.copyright-music-Box .case-list li .cover .peoDetail .peoName .peoName_name {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	@media screen and (max-width: 1680px) {

		.copyright-music-Box .case-list li:nth-of-type(10),
		.copyright-music-Box .case-list li:nth-of-type(9) {
			display: none;
		}
	}

	@media screen and (max-width: 1440px) {

		.copyright-music-Box .case-list li:nth-of-type(8),
		.copyright-music-Box .case-list li:nth-of-type(7) {
			display: none;
		}
	}

	.dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}
	
	.sheet_music .title-wrap .title button {
		/* height: 30px;
		line-height: 30px;
		padding: 0; */
	}

	/deep/.dialogBox {
		border-radius: 50px 40px;
	}
</style>
