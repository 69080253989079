<template>
	<div id="content" class="content sound-content">
		<div class="sound-top-box bannner" :style="{ backgroundImage: `url(${bannerBg})`}">
			<!-- <p class="bannerTit" data-aos="fade-down">配音解决方案</p>
			<p class="bannerP" data-aos="fade-down">动漫配音/小语种配音/纪录片配音/专题片配音/宣传片配音/游戏配音</p> -->
		</div>
		<div class="con-main">
			<div style="display: flex;">
				<h3>配音方案</h3>
				<ul style="display: flex;padding-top: 5px;">
					<li class="keyTextBox" :class="selLabelIndex==-1?'selLabelActive':''" @click="searchList(-1)">全部
					</li>
					<li v-for='(item,index) in labelArr' class="keyTextBox"
						:class="selLabelIndex==index?'selLabelActive':''" @click="searchList(index)">{{item.name}}</li>
				</ul>
			</div>
			<div class="sound-main authorize pr">
				<ul class="case-list">
					<template v-for="item in audioTeacherList">
						<li @click="gotoCaseDetail(item.id)">
							<a href="javaScript:voide(0)" @click="gotoCaseDetail(item.id)" class="cover"
								:style="'background-image: url('+item.img+') !important;'"></a>
							<div class="caseBox">
								<p class="caseTit text-overflow">{{item.name}}</p>
								<p class="caseContent text-overflow3">{{item.blurb}}</p>
							</div>
						</li>
					</template>
				</ul>
			</div>
		</div>

		<div class="paging-box qt-pagination" v-if='totalPage >0'>
			<div class="el-pagination is-background">
				<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
					:page-size="page.limit" :current-page.sync="page.currentPage">
				</el-pagination>
				<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
					<span class="suffix" style="padding: 0 10px">上一页</span>
				</button>
				<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
					<span class="suffix" style="padding: 0 10px">下一页</span>
				</button>
			</div>


		</div>
		<div style="text-align: center;" v-else-if='totalPage == 0'>
			<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
			<p style="text-align: center;color: #999;margin-bottom: 50px;">暂无符合条件的筛选结果，请尝试更改筛选条件</p>
		</div>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	export default {
		data() {
			return {
				audio_idClass: 'footer-wavesurfer tran',
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				value: false,
				pagePrev: true,
				pageNext: false,
				totalPage: null,
				page: {
					currentPage: 1,
					limit: 25,
				},
				audioTeacherList: [],
				labelArr: [],
				selLabelIndex: -1,
				bannerBg:''
			}
		},
		mounted() {
			this.getkind()
			this.getShowCate();
			this.getbannerBg();
		},
		methods: {
			getbannerBg(){
				var that=this;
				this.$post('/Index/getOptionBackImg').then(res => {
					that.bannerBg = res.data.img
				})
			},
			searchList(index) {
				this.selLabelIndex = index
				this.getkind()
			},
			// 获取分类筛选标签
			getShowCate() {
				let data = {
					limit: 5
				}
				this.$post('/Label/showSorts', data).then(res => {
					console.log(res)
					this.labelArr = res.data
				})
			},

			// 获取配音方案
			getkind() {
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					label: this.selLabelIndex == -1 ? '' : this.labelArr[this.selLabelIndex].id
				}
				this.$post('/Audio/kind', data).then(res => {
					console.log(res)
					this.audioTeacherList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= this.totalPage / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			// 配音老师详情页
			gotoCaseDetail(id) {
				this.$router.push({
					path: '/dubbingScheme/schemeDetail',
					query: {
						caseId: id
					}
				})
				goTop();
			},

			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getkind()
			},
		}
	}
</script>

<style scoped>
	@import "../../assets/css/0731103.css";
	@import "../../assets/css/7de01d2-dT2.css";
	@import "../../assets/css/5774750-dT3.css";
	.sound-content .con-main .case-list>li>.cover{
			background-color: transparent !important;
		}
	.content {
		padding: 0 !important;
	}

	.authorize {
		padding: 20px 0 0 0;
	}

	.bannner {
		height: 300px;
		background: url(../../assets/image/index/banner-bg.jpg) 50%/cover;
		/* padding: 70px 0 50px; */
		margin-top: 0;
	}

	.bannner .bannerTit {
		font-size: 50px;
		font-weight: 600;
		color: #fff;
		text-align: center;
		font-style: oblique;
	}

	.bannner .bannerP {
		font-size: 16px;
		color: #f0f0f0;
		text-align: center;
		margin-top: 40px;
	}

	.con-main h3 {
		height: 40px;
		line-height: 40px;
		font-size: 20px;
		font-weight: 600;
	}

	.sound-content .con-main .case-list {
		margin: 0 auto;
	}

	.sound-content .con-main .case-list .caseBox {
		padding: 20px 10px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.sound-content .con-main .case-list .caseTit {

		font-size: 16px;
		font-weight: 800;
		width: 100%;
		margin-bottom: 10px;
	}

	.sound-content .con-main .case-list .caseContent {
		width: 100%;
	}

	.music i.icon-shoucang_shoucang {
		color: #ea4335 !important
	}

	.authorize {
		background-color: transparent !important;
	}

	.sound-content .con-main .case-list>li>.cover {
	}

	.keyTextBox {
		border-radius: 5px;
		height: 30px;
		padding: 0px 10px 0px 10px;
		border: 1px solid #ea4335;
		line-height: 30px;
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		color: #ea4335
	}

	.selLabelActive {
		background-color: #ea4335;
		color: white;
	}
</style>
