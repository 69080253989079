<template>
	<div class="dubbing-class">
		<div class="class-item" v-for="(item,index) of list" :key="item.id">
			<span class="label" :style="{color: color||'auto'}">{{item.desc}}:</span>
			<p :class="{open: item.open}">
				<span
						class="item"
						:style="{color: color||'auto'}"
						:class="{active: select.find(selectItem=>selectItem.type === item.type && selectItem.value === '')}"
						@click="selectClass(item,{label:'全部',value:''})"
				>
					全部
				</span>
				<span
						class="item"
						:style="{color: color||'auto'}"
						:class="{active: select.find(selectItem=>selectItem.type === item.type && selectItem.value === classItem.value)}"
						v-for="classItem of item.data"
						:key="classItem.value"
						@click="selectClass(item,classItem)"
				>
					{{ classItem.label }}
				</span>
			</p>
			<span v-if="item.data.length > 20" @click="checkMore(index)" class="more">更多 <img :class="{open: item.open}" src="@/assets/image/aiDubbing/down-icon.png" alt=""></span>
		</div>
	</div>
</template>

<script>
import {getVoiceSetting} from "@/request/aiDubbing";

export default {
	props:{
		selectData:{
			type:Object,
			default:()=>{}
		},
		color:{
			type:String,
			default:''
		}
	},
	data(){
		return {
			list:[],
			select:[]
		}
	},
	mounted(){
		// 从缓存取出voiceSetting
		this.list = JSON.parse(localStorage.getItem('voiceSetting')) || []
		this.getData()
	},
	computed:{

	},
	methods:{
		clearSelect(){
			this.select = []
		},
		checkMore(index){
			const open = !this.list[index].open
			this.$set(this.list[index],'open',open)
		},
		async getData(){
			try{
				const {data} = await getVoiceSetting()
				if(data && data.length) {
					this.list = data
					if(data.length) localStorage.setItem('voiceSetting', JSON.stringify(data))
					this.select = this.list.map(item=>{
						return {
							type: item.type,
							value: ''
						}
					})
				}
			}catch (e) {
				console.log(e)
			}
		},
		selectClass(mainItem,classItem){
			const index = this.select.findIndex(item => item.type === mainItem.type)
			if(index !== -1){
				this.select[index].value = classItem.value
			}else{
				this.select.push({
					type: mainItem.type,
					value: classItem.value
				})
			}
			this.$emit('selectClass',{mainItem,classItem})
		}
	}
}
</script>

<style scoped lang="scss">
.dubbing-class{
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 1460px;
}
.class-item{
	flex: 1;
	line-height: 32px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	p{
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		margin-right: 14px;
		white-space: nowrap;
		transition: all .3s;
		height: 30px;
		&.open{
			height: auto;
		}
		&.hidemore{
			height: 30px;
			overflow: hidden;
		}
	}
	.label{
		font-size: 14px;
		color: #333333;
		font-weight: bold;
	}
	.item{
		padding: 0 10px;
		color: #999999;
		cursor: pointer;
		white-space: nowrap;
		&.active{
			color: #FD2C01 !important;
		}
		&:hover{
			color: #FD2C01 !important;
			font-weight: bold !important;
		}
	}
	.more{
		display: flex;
		align-items: center;
		color: #FD2C01;
		cursor: pointer;
		img{
			width: 12px;
			height: 12px;
			transition: all .3s;
			&.open{
				transform: rotate(180deg);
			}
		}
	}
}
</style>