import axios from 'axios'; // 引入axios
import QS from 'qs';

import store from '../store/store'
import router from '../router/index';
import { Message, Loading } from 'element-ui'
import md5 from '../utils/md5.js'
const SECRETKEY = 'yiling6670238160ravntyoneapp7926'

// 这是测试
// 环境切换
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/api';
}

// 请求超时
// axios.defaults.timeout = 60000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const formartDate = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = parseInt(date.getMonth()) + 1 > 9 ? parseInt(date.getMonth()) + 1 : '0' + (parseInt(date
        .getMonth()) + 1)
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()

    return year + '-' + month + '-' + day
}


// 请求拦截器
axios.interceptors.request.use(
    config => {
        var token = localStorage.getItem("token")
        return config;

    },
    error => {
        Message.error({ message: '请求超时!' });
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.status === 200) {
            return Promise.resolve(response);
        } else {
            if (response.data.status == 499) { //未登录
                // Message.error(response.data.mess);
                store.commit('setDialogVisibleLogin', true);
            } else if (response.data.status == 401) {
                // Message.error('请先登录');
                store.commit('setDialogVisibleLogin', true);
            } else if (response.data.status == 403) {
                // Message.error('登录过期，请重新登录');
                localStorage.removeItem('token');
                store.commit('loginSuccess', null);
                store.commit('setDialogVisibleLogin', true);
            } else if (response.data.status == 503) {
                Message.error(response.data.mess);
            } else {
                Message.error(response.data.mess);
            }
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {

        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    router.push({ path: "/" })
                    break;
                case 403:
                    Message.error('登录过期，请重新登录');
                    localStorage.removeItem('token');
                    store.commit('loginSuccess', null);
                    store.commit('setAiBuddingUserInfo', null);
                    store.commit('setDialogVisibleLogin', true);
                    break;
                case 404:
                    Message.error('网络请求不存在');
                    break;
                default:
                    Message.error(error.response.data.message);
            }
            return Promise.reject(error.response);
        }
    }
);


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */


export function toTask() {
    let data = {
        id: sessionStorage.getItem("taskid")
    }
    this.$post('/IntegralShop/toTask', data).then(res => {
        sessionStorage.removeItem('taskid')
        if (res.status == 200) {
            this.$message({
                message: res.mess,
                type: 'success'
            })
        }
    })
}

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    let paramobj = {
        ...params,
        client_id: 1,
        api_token: md5.md5('api' + url + formartDate() + SECRETKEY),
        token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
    }
    let severUrl = '' //
    return new Promise((resolve, reject) => {
        axios.post(severUrl + url, QS.stringify(paramobj))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
 * 图片上传post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function upload(url, params) {
    return new Promise((resolve, reject) => {

        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Delete(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
                data: params
            })
            .then(res => {
                resolve(res.data);

            })
            .catch(err => {
                reject(err.data)
            })
    });
}


/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
// 通用下载方法
export function download(url, params, filename) {
    const downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.1)", })
    return axios.post(url, params, {
        transformRequest: [(params) => { return tansParams(params) }],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob'
    }).then(data => {
        const isLogin = blobValidate(data);
        if (isLogin) {
            const blob = new Blob([data])
            saveAs(blob, filename)
        } else {
            const resText = data.text();
            const rspObj = JSON.parse(resText);
            const errmess = errorCode[rspObj.code] || rspObj.mess || errorCode['default']
            Message.error(errmess);
        }
        downloadLoadingInstance.close();
    }).catch((r) => {
        Message.error('下载文件出现错误，请联系管理员！')
        downloadLoadingInstance.close();
    })
}