<template>
<div class="pause-select" :style="{top: `${top}px`, left:`${left}px`}" @click.stop>
   <div class="slider">
     慢 <van-slider style="margin: 0 10px" v-model="value" :max="2" :min="0.5" :step="0.1"/> 快
   </div>
  <p class="confirm">
    <span style="color: #666">当前值：{{value}}</span>
    <editor-button bg-color="#fdeded" @click="confirm">确认</editor-button>
  </p>
</div>
</template>

<script>
import {Slider} from "vant";
import EditorButton from "@/components/Editor/editorButton.vue";

export default {
  components: {EditorButton, VanSlider:Slider},
  props:{
    top: {
      type:Number,
      default:0
    },
    left: {
      type:Number,
      default:0
    }
  },
  data(){
    return {
      value:0,
    }
  },
  methods:{
    confirm(){
      this.$emit('setSpeedValue', this.value)
    }
  }
}
</script>

<style scoped>
.pause-select{
  position: fixed;
  background: #fff;
  padding: 15px 8px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ddd;
  font-size: 14px;
  max-width: 420px;
  z-index: 10;
}
.slider{
  width: 300px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.confirm{
  display: flex;
  justify-content: space-between;
}
</style>