<template>
	<div class="">
		<div class="bannner">
			<el-carousel trigger="click" height="300px">
				<el-carousel-item v-for="(item, index) in lunboList" :key="index">
					<img :src="item.ad_pic" class="bannerImg" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="content con-main">
			<ul class="leftMenu">
				<template v-for="(item,index) in menuList" >
					<li :class="itemIndex == index ? 'activeLi':''" @click="changeItemIndex(index)">
						<p>
							<i class="iconfont" :class="item.icon"></i>
							{{item.item}}
						</p>
					</li>
				</template>

			</ul>
			<div class="rightContent">
				<ul class="menuList">
					<li v-for="(item,index) in menuList[itemIndex].children" :key="index"
						:class="rightItemIndex == index ? 'rightItemActive':''" @click="changeRightIndex(index)">
						{{item}}
					</li>
				</ul>
				<div class="content">
					<ul v-if="itemIndex==0" class="duihaunUl">
						<template v-for="item in duihuanList">
							<li @click="goIntegralDetail(item.id)">
								<div class="imageBox">
									<el-image style="height: 250px;width: 100%;margin: 0 auto;border-radius: 10px;"
										:src="item.img"></el-image>
								</div>
								<p class="duihuanTit">{{item.goods_name}}</p>
								<p class="duihuanjifen">{{item.integral}}个积分</p>
								<p class="duihuanBtn">立即兑换</p>
							</li>
						</template>
					</ul>
					<table v-if="itemIndex==1 && rightItemIndex==0" class="record-content">
						<thead>
							<tr>
								<td>发生时间</td>
								<td>收入/支出</td>
								<td>收支信息</td>
							</tr>
						</thead>
						<tbody>
							<template v-for="item in jifenList">
								<tr>
									<td>{{item.addtime}}</td>
									<td v-if="item.class==0">+{{item.integral}}</td>
									<td v-if="item.class==1">-{{item.integral}}</td>
									<td>{{item.type==3?'后台修改':item.task_name}}</td>
								</tr>
							</template>
						</tbody>
					</table>
					<table v-if="itemIndex==1 && rightItemIndex==1" class="record-content">
						<thead>
							<tr>
								<td>发生时间</td>
								<td>兑换商品</td>
								<td>兑换积分</td>
							</tr>
						</thead>
						<tbody>
							<template v-for="item in duihuanLogList">
								<tr>
									<td>{{item.addtime}}</td>
									<td>{{item.goods_name}}</td>
									<td>-{{item.integral}}</td>
								</tr>
							</template>
						</tbody>
					</table>
					<ul v-if="itemIndex==2" class="get-points-content">
						<template>
							<li v-for="(item, index) in taskListlist" :key="index">
								<img alt="积分" :src='item.img'>
								<p class="title">
									{{item.cate_id == 1?'新手任务':item.cate_id == 2?'每日任务':'长期任务'}}（{{item.done}}/{{item.number}}）
								</p>
								<p class="task">
									{{item.task_name}}
									<span>+{{item.integral}}积分</span>
									<a href="javaScript:viod(0)" @click="tofinish(item.id)" v-if="item.cate_id == 1"
										class="go-complete lg-f2f-btn">{{item.done==0?'去完成':'已完成'}}</a>
									<a href="javaScript:viod(0)" @click="tofinish(item.id)" v-if="item.cate_id == 2"
										class="go-complete lg-f2f-btn">{{item.id == 6?'签到':'去完成'}}</a>
									<a href="javaScript:viod(0)" @click="tofinish(item.id)" v-if="item.cate_id == 3"
										class="go-complete lg-f2f-btn">去完成</a>
								</p>
								<!-- <p class="tip">例如，您邀请一位用户在猴子音悦注册并消费了199元，您就可以获得100积分（199除以2然后四舍五入取整）</p> -->
							</li>
						</template>
					</ul>

					<div v-if="itemIndex==0 || itemIndex==1" class="paging-box qt-pagination">
						<div class="el-pagination is-background">
							<el-pagination background layout="pager" :total="totalPage"
								@current-change='changePageData(0)' :current-page.sync="page.currentPage">
							</el-pagination>
							<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
								<span class="suffix" style="padding: 0 10px">上一页</span>
							</button>
							<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
								<span class="suffix" style="padding: 0 10px">下一页</span>
							</button>
						</div>


					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	export default {
		data() {
			return {
				lunboList: [],
				itemIndex: 0,
				rightItemIndex: 0,
				menuList: [{
						item: '兑换中心',
						icon: 'icon-gerenzhongxin-duihuanzhongxin',
						children: ['兑换中心'],
					},
					{
						item: '积分记录',
						icon: 'icon-jifenjilu',
						children: ['积分记录', '兑换记录']
					},
					{
						item: '获取积分',
						icon: 'icon-dakahuoqujifen',
						children: ['获取积分']
					},
				],
				duihuanList: [],
				totalPage: 1000,
				page: {
					currentPage: 1,
					limit: 3,
				},
				pagePrev: true,
				pageNext: false,
				jifenList: [],
				taskListlist: [],
				duihuanLogList: []
			}
		},
		mounted() {
			// this.getIntegralRecordList()
			this.getTaskList()
			this.getIntegralGoods()
			this.getAdByTag()

			if (this.$route.query.ismx == 1) {
				this.changeItemIndex(1)
			}
		},
		methods: {

			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_jifen_banner'
				}).then(res => {
					this.lunboList = res.data
				})
			},
			getIntegralGoods() {
				let data = {
					page: this.page.currentPage,
					limit: 9
				}
				this.$post('/IntegralShop/goods', data).then(res => {
					this.duihuanList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= res.data.count / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			tofinish(id) {
				console.log(id)
				sessionStorage.setItem("taskid", id)
				switch (id) {
					case 1: //浏览老师
						this.$router.push({
							path: '/dubbingTeacher'
						})
						goTop();
						break;
					case 2: //收藏老师
						this.$router.push({
							path: '/dubbingTeacher'
						})
						goTop();
						break;
					case 3: //收藏音频
						this.$router.push({
							path: '/dubbingCase'
						})
						goTop();
						break;
					case 4: //成功下单
						this.$router.push({
							path: '/recommendedCashBack'
						})
						goTop();
						break;
					case 5: //完善资料
						this.$router.push({
							path: '/person?itemid=5'
						})
						goTop();
						break;
					case 6: //每日签到
						if (sessionStorage.getItem('taskid')) {
							this.$toTask()
							this.getTaskList()
						}
						break;
					case 8: //邀请好友
						this.$router.push({
							path: '/recommendedCashBack'
						})
						goTop();
						break;
					default:
						break;
				}
			},
			getTaskList() {
				this.$post('/IntegralShop/getTaskList', {}).then(res => {
					this.taskListlist = res.data
				})
			},
			getExchangeLog() {
				let data = {
					page: this.page.currentPage,
					limit: 9
				}
				this.$post('/IntegralShop/exchangeLog', data).then(res => {
					this.duihuanLogList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= res.data.count / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			getIntegralRecordList() {
				let data = {
					page: this.page.currentPage,
					limit: 20
				}
				// if( localStorage.getItem('loginFlag')){
				this.$post('/IntegralShop/getIntegralRecordList', data).then(res => {
					this.totalPage = res.data.count
					this.jifenList = res.data.list;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= res.data.count / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
				// }
			},


			changeItemIndex(index) {
				this.itemIndex = index;
				this.rightItemIndex = 0;
				if (index == 1) {
					this.getIntegralRecordList()
				}
			},
			changeRightIndex(index) {
				console.log(index)
				this.page.currentPage = 1
				if (index == 0) {
					this.getIntegralRecordList()
				} else {
					this.getExchangeLog()
				}
				this.rightItemIndex = index;
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;

				if (this.itemIndex == 1) {
					if (this.rightItemIndex == 0) {
						this.getIntegralRecordList()
					} else {
						this.getExchangeLog()
					}
				} else if (this.itemIndex == 0) {
					this.getIntegralGoods()
				}

			},
			// 配音老师详情页
			goIntegralDetail(integralDetailId) {
				this.$router.push({
					path: '/integral/integralDetail',
					query: {
						integralDetailId
					}
				})
				goTop()
			},
		}
	}
</script>

<style scoped>
	.bannner {}

	/deep/ .el-carousel__item .bannerImg {
		width: 100%;
		height: 100%;
		display: block;
	}

	.con-main {
		width: 1400px;
		margin: 20px auto;
		overflow: hidden;
	}

	.con-main .leftMenu {
		float: left;
		width: 20%;
		max-width: 200px;
		border-radius: 10px;
		background-color: #fff;
	}

	.con-main .leftMenu>li {
		cursor: pointer;
		padding: 20px 0;
	}

	.con-main .leftMenu>li>p {
		font-size: 18px;
		height: 40px;
		line-height: 40px;
		font-weight: 600;
		position: relative;
	}

	.con-main .leftMenu>li>p:before {
		content: "";
		position: absolute;
		height: 40px;
		width: 4px;
		background-color: #fff;
	}

	.con-main .leftMenu>li.activeLi>p:before {
		content: "";
		position: absolute;
		height: 40px;
		width: 4px;
		background-color: #e83233;
	}

	.con-main .leftMenu>li.activeLi>p,
	.con-main .leftMenu>li:hover>p {
		color: #e83233
	}

	.con-main .leftMenu .iconfont {
		margin: 0 20px;
	}

	.con-main .rightContent {
		width: calc(80% - 20px);
		float: left;
		margin-left: 20px;
		background-color: #fff;
		border-radius: 10px;
		overflow-y: auto;
	}

	.con-main .rightContent .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
	}

	.con-main .rightContent .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		color: #8c8c8c
	}

	.con-main .rightContent .menuList>li.rightItemActive {
		color: #2c3e50
	}

	.con-main .rightContent .menuList>li.rightItemActive:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 4px;
		left: 10%;
		bottom: -16px;
		background-color: #e83233;
	}

	.con-main .rightContent .content {
		padding: 40px 40px 50px 40px;
	}

	.con-main .rightContent .content .duihaunUl {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	.con-main .rightContent .content .duihaunUl li {
		background-color: #f4f4f4;
		border-radius: 10px;
		text-align: center;
		cursor: pointer;
		margin: 10px;
		position: relative;
		overflow: hidden;
	}

	.con-main .rightContent .content .duihaunUl li .imageBox {
		padding: 5px;
	}

	.con-main .rightContent .content .duihaunUl li .imageBox .el-image__inner {
		transition: all .3s;
	}

	/deep/ .con-main .rightContent .content .duihaunUl li .imageBox .el-image__inner:hover {
		transform: scale(1.03);
	}

	.con-main .rightContent .content .duihaunUl li .duihuanTit,
	.con-main .rightContent .content .duihaunUl li .duihuanjifen {
		height: 40px;
		line-height: 40px;
		font-weight: 600;
		font-size: 18px;
	}

	.con-main .rightContent .content .duihaunUl li .duihuanjifen {
		color: #e83233;
		font-size: 20px;
	}

	.con-main .rightContent .content .duihaunUl li .duihuanjifen:hover+.duihuanBtn,
	.con-main .rightContent .content .duihaunUl li .duihuanBtn:hover {
		transform: translateY(0px);
	}

	.con-main .rightContent .content .duihaunUl li .duihuanBtn {
		width: 100%;
		height: 40px;
		line-height: 40px;
		position: absolute;
		bottom: 0px;
		transform: translateY(100px);
		left: 0px;
		background-color: #fd4443;
		color: #fff;
		font-size: 20px;
		font-weight: 600;
		transform: all .3s;
	}

	.con-main .rightContent .content table.record-content {
		width: 100%;
		padding-bottom: 60px;
		border-collapse: collapse;
		table-layout: fixed;
	}

	.con-main .rightContent .content table.record-content>thead {
		background-color: #f0f0f0;
		border-radius: 4px;
		overflow: hidden;
	}

	.con-main .rightContent .content table.record-content>thead>tr {
		height: 50px;
	}

	.con-main .rightContent .content table.record-content>thead>tr>td {
		color: #272727;
		text-align: center;
	}

	.con-main .rightContent .content table.record-content>tbody>tr {
		height: 60px;
		border-bottom: 1px solid #f0f0f0;
	}

	.con-main .rightContent .content table.record-content>tbody>tr>td {
		color: #272727;
		text-align: center;
	}

	.con-main .rightContent .content .get-points-content {}

	.con-main .rightContent .content ul.get-points-content>li {
		padding: 0px 0 20px 60px;
		border-bottom: 1px solid #f0f0f0;
		position: relative;
	}

	.con-main .rightContent .content ul.get-points-content>li>img {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 23px;
		left: 5px;
	}

	.con-main .rightContent .content ul.get-points-content>li>p.title {
		margin-top: 5px;
		font-size: 18px;
		font-weight: 700;
		color: #272727;
	}

	.con-main .rightContent .content ul.get-points-content>li>p.task {
		height: 26px;
		margin-top: 20px;
		font-size: 14px;
		color: #272722;
		line-height: 26px;
		position: relative;
	}

	.con-main .rightContent .content ul.get-points-content>li>p.task>span {
		display: inline-block;
		height: 26px;
		font-size: 14px;
		color: #f12c21;
		line-height: 26px;
		position: absolute;
		top: 0;
		right: 140px;
	}

	.con-main .rightContent .content ul.get-points-content>li>p.task>a {
		width: 100px;
		height: 26px;
		font-size: 12px;
		line-height: 26px;
		position: absolute;
		top: 0;
		right: 0;
	}

	.con-main .rightContent .content ul.get-points-content>li>p.tip {
		margin-top: 10px;
		font-size: 12px;
		color: #8c8c8c;
	}

	@media only screen and (max-width: 1680px) {
		.con-main {
			width: 1200px;
		}
	}

	@media only screen and (max-width:1440px) {
		.con-main {
			width: 1000px
		}
	}
</style>
