import { render, staticRenderFns } from "./pinyinSelect.vue?vue&type=template&id=3156eaa0&scoped=true"
import script from "./pinyinSelect.vue?vue&type=script&lang=js"
export * from "./pinyinSelect.vue?vue&type=script&lang=js"
import style0 from "./pinyinSelect.vue?vue&type=style&index=0&id=3156eaa0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3156eaa0",
  null
  
)

export default component.exports