<template>
	<div id="content" class="content sound-content">
		<div class="sound-top-box">
			<div class="sound-top">
				<!-- <div class="page-bread-crumbs">
					<i class="iconfont icondingwei"></i>
					<a href="/" class="crumb link nuxt-link-active">首页</a>
					<a class="crumb link nuxt-link-active">配音老师</a>
				</div> -->
				<div class="filter-and-sort-wrap">
					<!-- <div class="filter-and-sort-item" v-for="(item,index) in labelList" :key='index'
						:style="{ height: `${labelListHeight[index].height}`}"> -->
						<!-- <div :class="labelListHeight[index].class" ref='filter_item'> -->
					<div class="filter-and-sort-item" v-for="(item,index) in labelList" :key='index'>
						<div class="item-name-box" ref='filter_item'>
							<a aria-current="page" class="item-name" @click="sellabel2(item,index)"
								:class="selTrTypeList[index].length>0?'':' nuxt-link-exact-active nuxt-link-active active'"
								style="border-radius: 10px;">{{item.name}}</a>
							<a class="item-name" style="border-radius: 10px;" @click="sellabel(label,index)"
								:class="JSON.stringify(tagsList).indexOf(JSON.stringify(label))>=0?' nuxt-link-exact-active nuxt-link-active active':''"
								v-for='label in item.child'>{{label.name}}</a>
							<!-- <span style='position: absolute;
							right: 0px;top:0px;bottom: 0px;margin: auto;cursor: pointer;color:#ea4335'
								@click='changeHeight(index)'>更多</span> -->
						</div>
					</div>

					<div class="filter-and-sort-item">
						<div class="item-name-box">
							<a href="avaScript:voide(0)" aria-current="page"
								:class="orderType == 0 ?'item-name nuxt-link-exact-active nuxt-link-active active' :'item-name nuxt-link-exact-active'"
								style="border-radius: 10px;" @click="orderType = 0;getTeacherLst()">综合排序</a>
							<a href="avaScript:voide(0)" class="item-name" style="border-radius: 10px;"
								:class="orderType == 1?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 1;getTeacherLst()">热门</a>
							<a href="avaScript:voide(0)" class="item-name" style="border-radius: 10px;"
								:class="orderType == 2?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 2;getTeacherLst()">最新</a>
							<a href="avaScript:voide(0)" class="item-name" style="border-radius: 10px;"
								:class="orderType == 3?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 3;getTeacherLst()">价格</a>
							<div class="teachOnline">
								<!-- -->
								<div style="margin-right: 5px;">当前在线</div>
								<el-switch v-model="on_line" @change='onlineChange' active-color="#ff4949"
									inactive-color="#ccc">
								</el-switch>
							</div>
						</div>
						<!-- 			<div class="other-entr main-cen">
							在线老师
							<el-switch v-model="on_line" @change='onlineChange' active-color="#ff4949"
								inactive-color="#ccc">
							</el-switch>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="selected-tags-wrap-Box">
			<div class="selected-tags-wrap flex">
				<div class="leftBox">
					<p>
						为您找到{{countT}}个配音师
					</p>
					<el-tag class="text-overflow" v-for="(tag,index) in tagsList" :key="tag.id" closable
						:type="tag.type" @close='delTag(index)'>
						{{tag.name}}
					</el-tag>
				</div>
				<button class="btn-clear" @click="delAllDef()">清空标签</button>
			</div>
		</div>
		<div class="con-main">
			<div class="sound-main pr">
				<ul class="case-list">
					<template v-for="(item,index) in audioList">
						<li>
							<a href="javaScript:void(0)" @click="goDubbingTeacher(item.id)" class="cover">
								<div class="peoDetail">
									<div class="imgBox">
										<img :src="item.headimgurl" style="min-width: 52px;" />
										<span v-if="item.on_line" class="login"></span>
										<span v-else class="nologin"></span>
									</div>
									<div class="peoName">
										<el-tooltip placement="top">
											<template #content> {{item.nickname}}</template>
											<p class="peoName_name">{{item.nickname}}</p>
										</el-tooltip>
										<p>销量：{{item.volume}}</p>
									</div>
									<button @click.stop="showKefu(item)">
										<svg t="1693274299441" class="icon" viewBox="0 0 1024 1024" version="1.1"
											xmlns="http://www.w3.org/2000/svg" p-id="6083"
											xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200">
											<path
												d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z"
												fill="#ffffff" p-id="6084"></path>
											<path
												d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z"
												fill="#ffffff" p-id="6085"></path>
										</svg>
										在线联系
									</button>
								</div>
								<p class="audioDetailP">{{item.blurb}}</p>
								<i v-if="item.is_recommend==1" class="iconfont icon-tuijian"></i>
								<!-- <i class="iconfont icon-remen"></i> -->
							</a>
							<div class="peoCaseBox">
								<ul>
									<template v-for="(audio,audioindex) in item.audio">
										<li>
											<p>{{audio.title}}</p>
											<div>
												<i v-if="audioFlag==true && audioId==audio.id && $store.state.musicLoading"
													class="iconfont icon-jiazai"
													@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item)"></i>
												<i v-else-if="audioFlag==true && audioId==audio.id"
													class="iconfont icon-zanting3"
													@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item)"></i>
												<i v-else class="iconfont icon-bofang1"
													@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item)"></i>
												<i class="iconfont icon-fenxiang"
													@click="onCopy($event,audio.audio, item)"></i>
											</div>
										</li>
									</template>
								</ul>
								<div class="peoPriceBox">
									<div class="peoPrice">
										<p><span class="redFont"
												style="font-size: 18px;">￥{{item.price.replace('.00','')}}/</span><span>100字</span>
										</p>
										<p><span class="redFont"
												style="font-size: 18px;">￥{{item.min_price.replace('.00','')}}/</span><span>起配</span>
										</p>
									</div>
									<i v-if="item.is_collect==1" :class="likeIconfontShouCang"
										@click="changeLikeIconfont(item)"></i>
									<i v-else :class="likeIconfont" @click="changeLikeIconfont(item)"></i>
								</div>
							</div>
						</li>
					</template>
				</ul>
			</div>
			<div class="paging-box qt-pagination" v-if='totalPage >0'>
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
						:page-size="page.limit" :current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>
			</div>
			<div style="text-align: center;" v-else-if='totalPage == 0'>
				<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
				<p style="text-align: center;color: #999;">暂无符合条件的筛选结果，请尝试更改筛选条件</p>
			</div>
		</div>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
		<rightAside :asideItemShowFlag='$store.state.asideItemShowFlag'></rightAside>
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import goTop from '../../utils/goTop.js'
	import copyText from "@/utils/copyText";
	import rightAside from "@/components/index/rightAside.vue";
	export default {
		components: {
			teacherDialog,
			rightAside
		},
		data() {
			return {
				audio_idClass: 'footer-wavesurfer tran',
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				audioId: '',
				audioFlag: false,
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				on_line: false,
				pagePrev: true,
				pageNext: false,
				totalPage: null,
				countT: 0,
				orderType: '',
				page: {
					currentPage: 1,
					limit: 25,
				},
				tagsList: [], // 选择的标签列表
				labelList: [], // 所有分类列表
				labelListHeight: [],
				audioList: [],
				keyword: '',
				kefuBoxShow: false,
				kefuObj: {
					qq: '',
					wx: ''
				},
				selTrTypeList: [],
			}
		},
		mounted() {
			console.log(sessionStorage.getItem('taskid'))
			if (sessionStorage.getItem('taskid') == 1) {
				this.$toTask()
			}
			if (sessionStorage.getItem('keyword')) {
				this.keyword = sessionStorage.getItem('keyword')
				this.tagsList.push({
					id: 0,
					name: this.keyword
				})
			}

			this.getLable()
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
		},
		methods: {
			changeHeight(index) {
				console.log(index)
				this.labelListHeight[index].height = this.labelListHeight[index].height == "56px" ? "auto" : "56px";
				this.labelListHeight = JSON.parse(JSON.stringify(this.labelListHeight));
			},
			showKefu(item) {
				console.log(item)
				// if (localStorage.getItem('loginFlag')) {
				this.$post('/Index/getcKfCode', {}).then(res => {
					this.kefuObj = res.data;
					this.kefuObj.headimgurl = item.headimgurl;
					this.kefuObj.nickname = item.nickname;
					this.kefuObj.on_line = item.on_line;
					this.kefuBoxShow = true
				})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }

			},
			delAllDef() {
				this.selTrTypeList = []
				for (let i = 0; i < this.labelList.length; i++) {
					this.selTrTypeList.push([])
				}
				this.tagsList = [];
				this.getTeacherLst()
			},
			onlineChange(e) {
				console.log(e)
				this.on_line = e
				this.page.currentPage = 1
				this.getTeacherLst()
			},
			sellabel2(item, index) {
				this.page.currentPage = 1;
				for (let i = 0; i < item.child.length; i++) {
					for (let k = 0; k < this.tagsList.length; k++) {
						if (JSON.stringify(item.child[i]).indexOf(JSON.stringify(this.tagsList[k])) >= 0) {
							this.selTrTypeList[index].splice(0, 1)
							this.tagsList.splice(k, 1)
						}
					}
				}
				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				if (typeids != '') {
					typeids = '?typeLis=' + typeids
				}
				const newUrl = '#' + this.$route.path + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)
				this.getTeacherLst()
			},
			// 选择标签
			sellabel(item, index) {
				this.page.currentPage = 1;
				console.log(this.selTrTypeList)
				if (this.selTrTypeList[index].length > 0) {
					for (let i = 0; i < this.tagsList.length; i++) {
						if (this.tagsList[i].id == this.selTrTypeList[index][0]) {
							this.tagsList.splice(i, 1)
						}
					}
					this.selTrTypeList[index] = []
					this.selTrTypeList[index].push(item.id)
					this.tagsList.push(item)
				} else {
					this.selTrTypeList[index].push(item.id)
					this.tagsList.push(item)
				}

				// console.log(item)
				// let isDel = 0

				// for(let i = 0;i<this.tagsList.length;i++){
				// 	if(item.id == this.tagsList[i].id){
				// 		this.selTrTypeList[index].splice(0,1)
				// 		this.tagsList.splice(i,1)
				// 		isDel = 1
				// 	}
				// }
				// this.page.currentPage = 1
				// if(isDel == 0){
				// 	this.selTrTypeList[index].push(1)
				// 	this.tagsList.push(item)
				// }
				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				if (typeids != '') {
					typeids = '?typeLis=' + typeids
				}
				const newUrl = '#' + this.$route.path + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)
				this.getTeacherLst()
			},
			// 删除标签
			delTag(index) {
				sessionStorage.removeItem('keyword')
				this.keyword = ''
				console.log(index)

				for (let i = 0; i < this.labelList.length; i++) {
					if (JSON.stringify(this.labelList[i]).indexOf(JSON.stringify(this.tagsList[index])) >= 0) {
						this.selTrTypeList[i].splice(0, 1)
					}
				}

				this.tagsList.splice(index, 1)

				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				const newUrl = '#' + this.$route.path + '?typeLis=' + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)

				this.getTeacherLst()
			},
			getAllLable() {
				var that = this;
				this.$post('/Label/allList', {}).then(res => {
					this.alllabelList = res.data
					console.log(window.location.href)
					let newtagsList = []
					if (window.location.href.indexOf('typeLis=') >= 0) {
						let typeLisStr = window.location.href.split('typeLis=')[1]
						if (typeLisStr != '') {
							if (typeLisStr.indexOf('_') == -1) {
								newtagsList.push({
									id: parseInt(typeLisStr),
									name: ''
								})
							} else {
								let typeArr = typeLisStr.split('_')
								for (let i = 0; i < typeArr.length; i++) {
									newtagsList.push({
										id: parseInt(typeArr[i]),
										name: ''
									})
								}
							}
						}
					}

					for (let i = 0; i < this.alllabelList.length; i++) {
						for (let k = 0; k < newtagsList.length; k++) {
							if (this.alllabelList[i].id == newtagsList[k].id) {
								newtagsList[k].name = this.alllabelList[i].name
							}
						}
					}
					this.tagsList = newtagsList

					for (let i = 0; i < this.labelList.length; i++) {
						for (let k = 0; k < this.labelList.length; k++) {
							if (JSON.stringify(this.labelList[i]).indexOf(JSON.stringify(this.tagsList[k])) >= 0) {
								this.selTrTypeList[i].push(1)
							}
						}
					}


					console.log(this.tagsList)
					this.getTeacherLst()
				})
			},
			// 获取分类
			getLable() {
				var that = this;
				this.$post('/Label/index', {}).then(res => {
					this.labelList = res.data;
					for (let i = 0; i < res.data.length; i++) {
						this.selTrTypeList.push([])
						var obj = {
							height: '56px',
							class: 'item-name-box'
						}
						that.labelListHeight.push(obj);
					}
					setTimeout(function() {
						for (var i = 0; i < that.$refs.filter_item.length; i++) {
							if (that.$refs.filter_item[i].offsetWidth >= 1100) {
								console.log(i)
								that.labelListHeight[i].class = 'item-name-box item-name-box-hidden'
							} else {
								that.labelListHeight[i].class = 'item-name-box'
							}
						}
					}, 10)
					if (window.location.href.indexOf('typeLis=') >= 0) {
						this.getAllLable()
					} else {
						this.getTeacherLst()
					}
				})
			},
			// 获取老师列表
			getTeacherLst() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					if (this.tagsList[i].id != 0) {
						labelidStr.push(this.tagsList[i].id)
					}
				}
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					label: labelidStr,
					keyword: this.keyword,
					on_line: this.on_line ? 1 : 0,
					order: this.orderType
				}
				this.$post('/Teacher/lst', data).then(res => {
					console.log(res)
					this.audioList = res.data.list
					this.totalPage = res.data.count;
					this.countT = res.data.number
					if (this.page.currentPage == 1) {

						this.pagePrev = true

					} else {

						this.pagePrev = false

					}

					if (this.page.currentPage >= this.totalPage / this.page.limit) {

						this.pageNext = true

					} else {

						this.pageNext = false

					}
				})
			},
			onCopy(event, audioSrc, item) {
				copyText(event, item.nickname + audioSrc)
			},
			// 配音老师详情页
			goDubbingTeacher(audioId) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: audioId
					}
				})
				goTop()
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getTeacherLst()
			},

			// 收藏
			changeLikeIconfont(item) {
				let data = {
					id: item.id,
					type: 1,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					console.log(sessionStorage.getItem('taskid'), item.is_collect)
					if (sessionStorage.getItem('taskid') && item.is_collect == 0) {
						this.$toTask()
					}

					this.getTeacherLst()
				})
			},
			changeAudioSrc(audioDetail, id, name, src, item) {
				console.log(src)
				var audioInfo = {};
				audioInfo.id = id;
				audioInfo.audioSrc = src;
				audioInfo.audioImage = audioDetail.headimgurl
				audioInfo.audioName = name;
				audioInfo.audioPeoName = item.nickname;
				audioInfo.audioPeoId = item.id;
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeStatus(val, flag) {
				console.log("dubbingTeacher___________", val, flag)
				this.audioId = val;
				this.audioFlag = flag
			}
		}
	}
</script>
<style scoped>
	::-webkit-scrollbar{
		height: 5px;
	}
	.item-name-box {
		
		
	}

	.item-name-box span {
		display: none;
	}

	.item-name-box-hidden span {
		display: block;
	}
	.filter-and-sort-item {
		position: relative;
		padding-right: 50px;
		box-sizing: border-box;
		overflow: hidden;
		/* display: flex !important;
		align-items: center !important; */
	}

	.dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}

	.teachOnline {
		margin-top: 3px;
		display: flex;
	}

	/deep/.dialogBox {
		border-radius: 50px 40px;
	}

	.other-entr {
		margin-top: 2.5px;
	}

	.sound-content .con-main .case-list li .cover .peoDetail .peoName {
		flex: 1 !important;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
</style>
<style>
	@import "../../assets/css/0731103.css";
	@import "../../assets/css/7de01d2-dT2.css";
	@import "../../assets/css/5774750-dT3.css";

	.leftBox .el-tag {
		margin: 0 2px;
	}

	.sound-content .con-main .case-list>li>.cover {
		padding: 20px 10px;
	}
</style>
