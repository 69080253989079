<template>
	<div id="audio_id" :class="audio_idClass"><button class="btn-fold both-cen" @click="zhedie()"><i
				class="iconfont iconleft"></i></button>
		<ul class="musicItem wavesurfer-musicItem main-btw cross-cen">
			<li class="fw-audio-infor">
				<div class="audio-logo"><img :src="audioDetail.audioImage" alt="" class="tran">
					<div class="play-button-wrapper" @click="startPlayOrPause()"><button
							:class="play_button_wrapper_i"></button></div>
				</div>
				<div class="audio-title pr"><a class="audio-title-val text-overflow">{{audioDetail.audioName}}</a>
					<p class="text-overflow">by <span class="author" style="cursor: pointer;"
							@click="toTeach(audioDetail)">{{audioDetail.audioPeoName}}</span></p>
				</div>
			</li>
			<li class="control-box cross-cen">
				<audio ref="music" :src="audioDetail.audioSrc"></audio>
				<div class="music__main__timeBar">
					<span class="time">{{ realMusicTime }}</span>
					<div class="bar" ref="bar" @click="handClickBar">
						<div class="bar__slid" ref="slid" @click="handClickBar"></div>
					</div>
					<span class="time">{{ totalMusicTime }}</span>
					<div class="audio-volume" @click="audio_volumeShow()" style="position: relative;">
						<div class="audio-volumeBox" :class="audio_set_volume"
							style="position: absolute;bottom: 20px;right:-5px">
							<el-slider v-model="audio_sound" @input="changeSound" vertical height="100px">
							</el-slider>

						</div>
					</div>

				</div>
				<!-- <audio ref="audio" style="width: 700px;"
				           @pause="onPause"
				           @play="onPlay"
				           @timeupdate="onTimeupdate"
				           @loadedmetadata="onLoadedmetadata"
				           :src="audioDetail.audioSrc"
				           controls="controls"
						   controlsList="nodownload"
						   crossOrigin="anonymous"
						   type="audio/mpeg">
				</audio> -->
			</li>
			<li class="fw-audio-cart cart_li">
				<a @click="showKefu()" class="btn-download btn-e4a" target="_blank">
					<i class="iconfont icon-kehufuwukefu"></i>
					在线联系
				</a>
			</li>
		</ul>

		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
	</div>
</template>

<script>
	import goTop from '@/utils/goTop.js'
	import teacherDialog from "@/components/teacherDialog.vue"
	// 将整数转换成 时：分：秒的格式
	function realFormatSecond(second) {
		var secondType = typeof second

		if (secondType === 'number' || secondType === 'string') {
			second = parseInt(second)

			var hours = Math.floor(second / 3600)
			second = second - hours * 3600
			var mimute = Math.floor(second / 60)
			second = second - mimute * 60

			return hours + ':' + ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
		} else {
			return '0:00:00'
		}
	}
	export default {
		components: {
			teacherDialog
		},
		data() {
			return {
				audio_idClass: 'footer-wavesurfer tran',
				audio: {
					// 该字段是音频是否处于播放状态的属性
					playing: false,
					// 音频当前播放时长
					currentTime: 0,
					// 音频最大播放时长
					maxTime: 0
				},
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				audioSureFlag: false, //缓存中是否有音乐信息；
				nowAudioSrc: '/', //新的播放地址
				nowAudioId: '/', //新的播放地址
				audioDetail: '',
				audioId: "/",
				isPlay: false,
				realMusicTime: "00:00",
				totalMusicTime: "00:00",
				audio_set_volume: 'audio-set-volume', //声音控件显示隐藏
				audio_sound: 100,
				kefuBoxShow: false,
				kefuObj: {
					qq: '',
					wx: ''
				},
			}
		},
		mounted() {
			var that = this;
			this.music = this.$refs.music;
			this.getaudioSureFlag()
			this.bus.$on("getAudioSrc", (audioDetail) => {
				console.log('***************')
				console.log(audioDetail)
				var that = this;
				console.log(this.audioId, audioDetail.id, this.audioId == audioDetail.id)
				if (audioDetail.id == this.audioId) {
					console.log("当前的")
					this.audio.isPlay = this.audio.isPlay

				} else {
					this.audio.isPlay = false;
					console.log("换新歌");
					this.$nextTick(function() {
						that.switchMusic(audioDetail.audio)
					})
					// this.switchMusic(audioDetail.audioSrc)
				}
				this.audioDetail = audioDetail
				this.audioId = audioDetail.id;
				this.$nextTick(function() {
					that.getaudioSureFlag()
					that.getAudioSrc(audioDetail.audio)
				})
			})

		},
		methods: {
			toTeach(item) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: item.audioPeoId
					}
				})
				goTop()
			},
			showKefu() {
				var that = this;
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						console.log(that.audioDetail)
						that.$post('/Video/VideoTeacher', {
							id: that.audioDetail.id
						}).then(res2 => {
							console.log(res2);
							that.kefuObj = {
								code: res.data.code,
								nickname: res2.data.nickname,
								headimgurl: res2.data.headimgurl,
								on_line: res2.data.on_line,
							}
							console.log(that.kefuObj);
							that.$nextTick(function() {
								that.kefuBoxShow = true;
								console.log(that.kefuBoxShow);
							})
						})
					})
				// }else{
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }
			},
			// audio_volumeShow()
			// 改变音量
			changeSound(val) {
				// console.log(val);
				this.music.volume = val / 100;
			},
			audio_volumeShow() {
				if (this.audio_set_volume == 'audio-set-volume audio-show-volume') {
					this.audio_set_volume = 'audio-set-volume'
				} else {
					this.audio_set_volume = 'audio-set-volume audio-show-volume'
				}
			},
			play() {
				if (this.music.paused) {
					this.music.play();
					this.isPlay = true;
					this.play_button_wrapper_i = 'iconfont icongedanzanting';
				} else {
					this.music.pause();
					this.isPlay = false;
					this.play_button_wrapper_i = 'iconfont iconweibiaoti-2';
				}
				var that = this;
				this.$nextTick(function() {
					console.log("))))))", that.isPlay)
					that.bus.$emit("changeStatus", that.audioId, that.isPlay)
				})
			},
			// 处理时间
			handlMusicTime() {
				//用秒数来显示当前播放进度
				let timeDisplay = Math.floor(this.music.currentTime); //获取实时时间
				//分钟
				let minute = parseInt(timeDisplay / 60);
				if (minute < 10) {
					minute = "0" + minute;
				}
				//秒
				let second = Math.round(timeDisplay % 60);
				if (second < 10) {
					second = "0" + second;
				}
				this.realMusicTime = minute + ":" + second;
			},
			// 处理进度条
			handMusicBar() {
				let slid = this.$refs.slid;
				let duration = this.music.duration;
				let x = ((this.music.currentTime / duration) * 100).toFixed(2) + "%";
				slid.style.width = x;
			},
			// 处理点击进度条事件
			handClickBar(e) {

				const barTotalWidth = this.bar.offsetWidth; // bar 总宽度
				const rect = e.target.getBoundingClientRect(); // 元素右边距离页面边距的距离 返回上下左右
				let length = e.pageX - rect.left;
				this.music.currentTime = length / barTotalWidth * this.music.duration; // 计算播放时间 位置百分比*总时间
				var that = this;
				that.isPlay = true;
				this.play_button_wrapper_i = 'iconfont icongedanzanting';
				this.$nextTick(() => {
					that.music.play();
					that.bus.$emit("changeStatus", that.audioId, true)
				})
			},
			// 切换歌曲
			switchMusic(audioSrc) {
				let slid = this.$refs.slid;
				let x = ((0) * 100).toFixed(2) + "%";
				slid.style.width = x;
				this.realMusicTime = '00:00';

				this.isPlay = false;
				this.audioDetail.audioSrc = audioSrc;
				this.music.load();
				this.$store.state.musicLoading=true;
				if (Boolean(navigator.userAgent.match(
					/iphone|ipod|iOS/ig))) { // 解决 ios点两次才播放问题，测试相关13版本的ios手机可以直接播放，不需要再次执行当前操作
					var that = this;
					setTimeout(function() {
						that.music.play();
						that.isPlay = true;
					}, 10)
				}
				// 文件下载完毕，如果不用等到全部下载完毕，可以用canplay事件
				this.music.addEventListener("canplaythrough", () => {
					this.music.play();
					this.$store.state.musicLoading=false;
					this.isPlay = true;
				});

			},
			watchMusicTime() {
				this.music = this.$refs.music;
				this.bar = this.$refs.bar;
				this.music.addEventListener(
					"timeupdate",
					() => {
						this.handlMusicTime();
						this.$nextTick(() => {
							this.handMusicBar();
						})
					},
					false
				);
				// 播放完毕
				// this.music.addEventListener("ended", () => {
				// 	this.switchMusic(); // 自动播放
				// });
				// 捕获音频文件已准备完毕
				// 当媒体文件可以播放的时候会触发oncanplay事件,也可以用oncanplay
				this.music.oncanplaythrough = () => {
					let time = this.music.duration;
					//分钟
					let minutes = parseInt(time / 60);
					if (minutes < 10) {
						minutes = "0" + minutes;
					}
					//秒
					let seconds = Math.round(time % 60);
					if (seconds < 10) {
						seconds = "0" + seconds;
					}
					this.totalMusicTime = minutes + ":" + seconds;
				};
			},
			// 一进入页面判断是否音乐有缓存
			getaudioSureFlag() {
				if (sessionStorage.getItem('audioDetail')) {
					this.audioSureFlag = true;
					this.audioId = JSON.parse(sessionStorage.getItem('audioDetail')).id;
					this.audio_idClass = 'footer-wavesurfer fixedBottom tran';
					this.audioDetail = JSON.parse(sessionStorage.getItem('audioDetail'));
					this.watchMusicTime();
				} else {
					this.audioSureFlag = false
					this.audio_idClass = 'footer-wavesurfer tran'
				}
			},
			// 下面播放音乐栏目展开折叠
			zhedie() {
				if (this.audio_idClass == 'footer-wavesurfer fixedBottom tran') {
					this.audio_idClass = 'footer-wavesurfer fixedBottom tran on'
					this.audio_set_volume = 'audio-set-volume'
				} else if (this.audio_idClass == 'footer-wavesurfer fixedBottom tran on') {
					this.audio_idClass = 'footer-wavesurfer fixedBottom tran'
				}
			},
			// 改变播放地址
			getAudioSrc(newSrc) {
				this.audioSrc = " ";
				this.audioSrc = newSrc;
				this.startPlayOrPause()
			},
			// 控制音频的播放与暂停
			startPlayOrPause() {
				// console.log("执行播放",this.audio.playing);
				// return this.audio.playing ? this.pause() : this.play()
				this.play()
			},
		},
		filters: {
			// 使用组件过滤器来动态改变按钮的显示
			transPlayPause(value) {
				return value ? '暂停' : '播放'
			},
			// 将整数转化成时分秒
			formatSecond(second = 0) {
				return realFormatSecond(second)
			}
		}
	};
</script>

<style scoped>
	.dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}

	/deep/.dialogBox {
		border-radius: 50px 40px;
	}

	/deep/.el-slider.is-vertical .el-slider__bar {
		background-color: #ea4335 !important;
	}

	/deep/ .el-slider__button {
		border: none
	}

	.audio-volume {
		width: 16px;
		height: 16px;
		background-image: url('https://peiyin.6pian.cn/Public/new_voice/static/audio1/images/volume.png');
		background-size: 100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		margin-left: 20px;
	}

	.audio-set-volume {
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		bottom: 66px;
		width: 26px;
		height: 118px;
		right: 215px;
		z-index: 999;
		display: none;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}

	.audio-show-volume {
		display: flex;
	}

	.volume-box {
		width: 5px;
		margin: auto;
		display: block;
		position: relative;
		background-color: #333;
		border-radius: 2.5px;
		height: 100px;
		bottom: -10px
	}

	.volume-box i {
		position: absolute;
		width: 100%;
		border-radius: 2.5px;
		background-color: #ea4335;
		height: 0;
		display: block;
		bottom: 0;
	}

	.control-box {
		width: 65%;
	}

	.control-box .music__main__timeBar {
		width: 100%;
		display: flex;
		color: #fff;
		justify-content: space-between;
		align-items: center;
	}

	.control-box .music__main__timeBar .time {
		width: 8%;
		max-width: 50px;
		text-align: center;
	}

	.control-box .music__main__timeBar .bar {
		width: 75%;
		flex: 1;
		height: 8px;
		background-color: #fbfbfb;
		border-radius: 8px;
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		cursor: pointer;
	}

	.control-box .music__main__timeBar .bar .bar__slid {
		position: absolute;
		left: 0;
		top: 0;
		background-color: #ea4335;
		height: 100%;
		width: 0;
		transition: width 0.3s;
	}
</style>
