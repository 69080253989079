<template>
	<div id="HeaderBox" :class="[fixed == 'fixed1' ? 'HeaderBox1' :fixed == 'fixed3' ? 'HeaderBox3':'HeaderBox2']" style="color: #333">
		<div style="width: 100%;height: 3px;z-index: 10;background-color: transparent;position: absolute;top: 0;"
			:style="{'opacity':widthX==0?0:1}">
			<div style="height: 3px;background-color: rgb(255, 20, 20);transition: width 0.4s;"
				:style="{'width':widthX+'%'}"></div>
		</div>
		<div id="Header" :style="{'background-color':'transparent'}">
			<div @click="goToHome()">
				<img id='elImage_logo'  style="height: 50px;margin-top: 5px; cursor: pointer;" :src='logoImgUrl[elImage_logoIndex]'></img>
			</div>
			<el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="transparent"
				:text-color="textColor" active-text-color="#ff1414" :router="true" style="border-bottom: none;"
				:default-active="$route.path">
				<el-menu-item index="/">首页</el-menu-item>
				<el-menu-item index="/dubbingTeacher">配音老师</el-menu-item>
				<el-menu-item index="/dubbingCase">配音案例</el-menu-item>
				<el-menu-item index="/sampleAudition">样音试听</el-menu-item>
				<el-menu-item index="/dubbingScheme">配音方案</el-menu-item>
				<el-menu-item index="/humanTranslation">人工翻译</el-menu-item>
				<el-menu-item index="/aiDubbing">AI主播</el-menu-item>
				<el-menu-item index="/buddingEditor">软件配音</el-menu-item>
<!--				<el-menu-item index="/about">关于我们</el-menu-item>-->
			</el-menu>
			<div class="user-info">
				<div style="width: 40%; height: 40px; margin-top: 10px; display: flex;">
					<div style="display: flex;align-items: center;" @click="gotoMyInfo(1)"
						:style="{'color': textColor}">
						<svg t="1684459411732" style="font-size: 20px;" class="icon" viewBox="0 0 1024 1024"
							version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2416" width="200" height="200">
							<path
								d="M544 161.536a330.666667 330.666667 0 0 1 298.666667 329.130667h-0.341334c0.213333 1.493333 0.341333 2.986667 0.341334 4.565333v219.434667h39.68a32 32 0 0 1 0 64h-212.053334a160 160 0 0 1-316.586666 0H141.909333a32 32 0 1 1 0-64h39.424v-219.434667c0-1.578667 0.128-3.072 0.341334-4.565333H181.333333a330.666667 330.666667 0 0 1 298.666667-329.130667V128a32 32 0 1 1 64 0v33.536z m-298.666667 553.130667h533.333334v-219.434667c0-1.578667 0.128-3.072 0.341333-4.565333h-0.341333a266.666667 266.666667 0 1 0-533.333334 0h-0.341333c0.213333 1.493333 0.341333 2.986667 0.341333 4.565333v219.434667z m359.765334 64H418.901333a96 96 0 0 0 186.197334 0z"
								:fill="textColor" p-id="2417"></path>
						</svg>
						<a href="javaScript:void(0)" class="nav-item" style="margin-left: 6px;">消息</a>
						<div v-show="$store.state.newsNum > 0"
							style="width: 20px;height: 20px;background-color: red;color: white;border-radius: 20px;text-align: center;border-radius: 20px;line-height: 20px;position: relative;left: -45px;top:-10px;font-size: 12px;">
							{{$store.state.newsNum}}
						</div>
					</div>
					<div style="display: flex;align-items: center; margin-left: 20px;" @click="gotoMyInfo(2)"
						:style="{'color': textColor}">
						<i class="iconfont icon-gerenzhongxin-duihuanzhongxin"></i>
						<a href="javaScript:void(0)" class="nav-item" style="margin-left: 6px;">兑换码</a>
					</div>
				</div>
				<div class="more both-cen"><i class="iconfont icongengduo" :style="{'color': textColor}"></i>
					<div class="drop-more">
						<p>更多服务</p>
						<div class="nav-list flex-wrap">
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(0)">
								<span class="icon icon-fanxian"></span>
								推荐返现
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(1)">
								<span class="icon icon-chongzhi"></span>
								配音充值
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(2)">
								<span class="icon icon-jifen"></span>
								积分好礼
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(7)">
								<span class="icon icon-gongju"></span>
								创作工具
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(3)">
								<span class="icon icon-youhuiduihuan"></span>
								优惠兑换
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(8)">
								<span class="icon icon-shangwuhezuo"></span>
								商务合作
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goAbout(0,9)">
								<span class="icon icon-tousujianyi"></span>
								投诉建议
							</a>
							<a href="javaScript:void(0)" class="nav-item" @click="goToPage(9)">
								<span class="icon icon-rengongfuwu"></span>
								人工服务
							</a>
						</div>
					</div>
				</div>
				<div class="before-login fl login-btn">
					<div v-if="!loginFlag" class="btn-login" @click="loginDialogFn()"
						style="background-color: transparent;border-color: #000;">
						<div class="btn-Box" :style="{'border-color': textColor}">
							<a class="btn-log" :style="{'color': textColor}">
								登录
							</a>
							<span :style="{'color': textColor}"> | </span>
							<a class="btn-register" :style="{'color': textColor}">
								注册
							</a>
						</div>
						<p class="showTips">登录免费试音哦</p>
					</div>
					<div v-else class="img_bottom_Box">
						<img style="width: 40px;height: 40px;" :src="userInfo.headimgurl"
							class="userInfoImage icongengduo" @click="goToPerson()" />
						<ul class="drop-more img_ul">
							<li @click="goToPerson()">个人中心</li>
							<li @click="goToPage(4)">配音订单</li>
							<li @click="goToPage(5)">CDK兑换</li>
							<li @click="goToPage(6)">账号信息</li>
							<li @click="goToPage(1)">配音充值</li>
							<li @click="goLoginOut()">退出登录</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<el-dialog custom-class='customclass' :visible.sync="dialogVisible_login" width="26%" :show-close='false'
			@close='closeDialogVisible_login' style="min-width: 350px;" class="loginBox" :append-to-body='true'>
			<loginDialog :dialogVisible.sync="dialogVisible_login" :userid='userid'></loginDialog>
		</el-dialog>
		<el-dialog custom-class='customclass' :visible.sync="dialogVisible_content" width="24%" :show-close='false'
			style="min-width: 350px;" class="kefuBox" :append-to-body='true'>
			<keFuDialogTwo :dialogVisible_content.sync="dialogVisible_content" :kefuObj='kefuObj'></keFuDialogTwo>

		</el-dialog>
		<el-dialog :visible.sync="keFuDialogThree_dialogVisible" width="480px" :show-close='false' class="kefuBox3"
			:modal-append-to-body='false'>
			<keFuDialogThree :dialogVisible_content.sync="keFuDialogThree_dialogVisible" :kefuObj='kefuObj3'>
			</keFuDialogThree>

		</el-dialog>
	</div>

</template>
<script src="../assets/js/ac99ef1f9cb69e708163.js"></script>
<script src="../assets/js/1ef9ab399e4d2a7ed62d.js"></script>
<script src="../assets/js/380d3aa305f176da4c39.js"></script>
<script src="../assets/js/73b390b017176f36469d.js"></script>
<script src="../assets/js/fb7cca09538db6fcb411.js"></script>
<script>
	import Vue from 'vue';
	import keFuDialogThree from "./keFuThree.vue"
	import keFuDialogTwo from "./keFuDialogTwo.vue"
	import loginDialog from "./loginDialog.vue"
	import goTop from '../utils/goTop.js'
	import {
		mapState,
		mapMutations
	} from 'vuex';
	export default {
		name: "Header",
		// headerBgColor:'#fff',
		// textColor:"#333",
		// fixed:'fixed'
		props: ['elImage_logoIndex'],
		components: {
			loginDialog,
			keFuDialogTwo,
			keFuDialogThree
		},
		computed: {
			...mapState(['dialogVisible_login'])
		},
		data() {
			return {
				newsCount: 0,
				ishome: 0,
				textColor: '#fff',
				fixed: '',
				logoSrc: require("@/assets/image/index/logo2.png"),
				loginFlag: false,
				userInfo: {},
				userid: '',
				logoImgUrl: [],
				iconColor: '',
				kefuObj: {
					qq: '',
					wx: ''
				},
				kefuBoxShow: false,
				widthX: 0,
				dialogVisible_content: false,
				keFuDialogThree_dialogVisible: false,
				kefuObj3: {
					type: '',
					image: ''
				},
			}
		},
		watch: {
			$route(to, from) {
				this.widthX = 100
				let _this = this
				setTimeout(function() {
					_this.widthX = 0
				}, 500)
			}
		},
		beforeCreate() {
			var that = this;
			this.$post('/Index/getConfigLogo', {}).then(res => {
				that.logoImgUrl = res.data;
			})

			if (localStorage.getItem('loginFlag')) {
				this.$post('/MemberInfo/newNum', {}).then(res => {
					this.$store.state.newsNum = res.data
				})
			}


		},
		methods: {
			...mapMutations(['setDialogVisibleLogin','aiUserLogout']),
			closeDialogVisible_login() {
				this.setDialogVisibleLogin(false);
			},
			goToHome() {
				this.$router.push('/');
			},
			goLoginOut() {
				this.aiUserLogout()
				localStorage.clear();
				sessionStorage.clear();
				// this.$router.go(0);
				this.$router.replace('/');
				this.$store.state.newsNum = 0
				this.loginSuccessFn();
			},
			showKefu() {
				this.$post('/Index/getcKfCode', {}).then(res => {
					this.kefuObj = res.data;
					this.dialogVisible_content = true;
				})


			},
			// 个人中心
			goToPerson() {
				this.$router.push({
					path: '/person',
					query: {
						itemid: 0
					}
				})
			},
			gotoMyInfo(type) {
				if (this.loginFlag) {
					if (type == 1) {
						this.$router.push({
							path: '/person',
							query: {
								itemid: 2
							}
						})
					} else if (type == 2) {
						this.$router.push({
							path: '/person',
							query: {
								itemid: 4
							}
						})
					}
				} else {
					// this.$message({
					// 	message: '请先登录',
					// 	type: 'error'
					// })
					this.$store.commit('setDialogVisibleLogin', true);
				}

			},
			goToPage(type) {
				// 0 --->推荐返现
				if (type == 0) {
					this.$router.push({
						path: '/recommendedCashBack',
					})
					goTop()
				} else if (type == 1) { //1--->配音充值
					this.$router.push({
						path: '/recharge',
					})
					goTop()
				} else if (type == 2) { //2--->积分好礼
					this.$router.push({
						path: '/integral',
					})
					goTop()
				} else if (type == 3) {
					if (this.loginFlag) {
						this.$router.push({
							path: '/person?itemid=4',
						})
						goTop()
					} else {
						// this.$message({
						// 	message: '请先登录',
						// 	type: 'error',
						// })
						this.$store.commit('setDialogVisibleLogin', true);
					}

				} else if (type == 4) { //-->消费记录
					this.$router.push({
						path: '/person?itemid=3',
					})
					goTop()
				} else if (type == 5) { //-->cdk
					this.$router.push({
						path: '/person?itemid=4',
					})
					goTop()
				} else if (type == 6) { //-->账号信息
					this.$router.push({
						path: '/person?itemid=5',
					})
					goTop()
				} else if (type == 7) { //-->创作工具
					var that = this;
					this.$post('/Index/getKfCodeImg', {
						ename: 'wxkefu_img'
					}).then(res => {
						that.keFuDialogThree_dialogVisible = true;
						that.kefuObj3 = {
							type: 1,
							image: res.data.code
						}
					})

				} else if (type == 8) { //-->商务合作
					var that = this;
					this.$post('/Index/getKfCodeImg', {
						ename: 'swkefu_img'
					}).then(res => {
						that.keFuDialogThree_dialogVisible = true;
						that.kefuObj3 = {
							type: 2,
							image: res.data.code
						}
					})

				} else if (type == 9) { //-->人工客服
					var that = this;
					this.$post('/Index/getKfCodeImg', {
						ename: 'kf_code'
					}).then(res => {
						that.keFuDialogThree_dialogVisible = true;
						that.kefuObj3 = {
							type: 3,
							image: res.data.code
						}
					})

				}
			},
			goAbout(parentIndex, menuid) {
				this.$router.push({
					path: '/about',
					query: {
						parentIndex,
						menuid
					}
				})
				goTop();
			},
			handleSelect(key, keyPath) {
				this.$router.push(key)
			},
			loginDialogFn() {
				this.setDialogVisibleLogin(true);
				// this.$store.commit('dialogVisible_login',true)
				// this.$prototype.dialogVisible_login = true;
			},
			changeTextColor(val) {
				this.textColor = val
			},
			handleScroll() {
				let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
				if (top >= 600) {
					this.textColor = "#333";
					this.iconColor = "#333";
					this.fixed = "fixed3";
					this.logoSrc = require("@/assets/image/index/logo.png");
					this.$emit('changeElImage_logoIndex', 1)
				} else {
					if (this.$route.query.userid || window.location.href.split('/#/')[1] == '' || window.location.href
							.includes('source')) {
						this.textColor = "#fff";
						this.fixed = "fixed2";
						this.$emit('changeElImage_logoIndex', 0)
					} else {
						this.fixed = "fixed1";
						this.textColor = "#333";
						this.$emit('changeElImage_logoIndex', 1)
					}

					this.iconColor = "#000"
					this.logoSrc = require("@/assets/image/index/logo2.png");
				}
			},
			loginSuccessFn() {
				this.loginFlag = localStorage.getItem("loginFlag");
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
			}
		},
		mounted() {
			if (this.$route.query.userid || window.location.href.split('/#/')[1] == '' || window.location.href.includes(
					'source')) {
				this.fixed = 'fixed';
				this.ishome = 1;
				this.textColor = "#fff";
				this.$emit('changeElImage_logoIndex', 0)
			} else {
				this.fixed = '';
				this.ishome = 0;
				this.textColor = "#333";
				this.$emit('changeElImage_logoIndex', 1)
			}
			window.addEventListener("scroll", this.handleScroll);
			this.loginSuccessFn()
			if (this.$route.query.userid && !localStorage.getItem('loginFlag')) {
				this.userid = this.$route.query.userid
				// this.$prototype.dialogVisible_login=true;
				this.setDialogVisibleLogin(true);
			}
			var that = this;
			if (localStorage.getItem('loginFlag')) {
				this.$post('/MemberInfo/newNum', {}).then(res => {
					// that.$store.state.newsNum = res.data
					Vue.set(that.$store.state, 'newsNum', res.data);
				})
			}
			document.getElementsByClassName('customclass')[0].style.borderRadius = '20px'
		}
	};
</script>
<style>
	el-dialog {
		overflow-y: scroll;
	}
</style>
<style scope>
	.kefuBox3 .el-dialog {
		border-radius: 20px !important;
	}

	.el-menu-demo>.el-menu-item:hover {
		background-color: none !important;
	}

	.img_bottom_Box:hover .img_ul {
		height: 266px;
		padding: 10px 20px;
	}

	.img_ul {
		transition: all .3s;
		background-color: #fff;
		width: 160px;
		border-radius: 10px;
		box-sizing: border-box;
		transform: translateX(-50%);
		position: absolute;
		top: 60px;
		left: 50%;
		box-shadow: 0 4px 19px 7px rgba(0, 0, 0, .1);
		height: 0;
		overflow: hidden;
	}

	.img_ul li {
		line-height: 40px;
		text-align: center;
		border-bottom: 1px solid #e4e4e4;
		cursor: pointer;
	}

	.img_ul li:hover {
		color: rgb(255, 20, 20);
	}

	.btn-login .btn-Box {
		border: 1px solid rgba(255, 255, 255, .9);
		cursor: pointer;
		font-size: 14px;
		color: #333;
		width: 100px;
		height: 36px;
		border-radius: 4px;
		margin-top: 13px;
		line-height: 34px;
		text-align: center;
	}

	.btn-login .showTips {
		text-align: center;
	}

	/* .btn-login .btn-Box:hover+.showTips{
		display: none;
	} */
	.btn-login .btn-Box:hover {
		background: linear-gradient(90deg, #ff504e, #ff2d2d) !important;
		border: none !important;
	}

	.btn-login .btn-Box:hover a,
	.btn-login .btn-Box:hover span {
		color: #fff !important;
	}

	.el-dialog__body {
		background-color: #fff;
		border-radius: 20px !important;
	}

	.kefuBox .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}

	.el-dialog__wrapper {
		z-index: 9999 !important;
		/* 修改为更高的层级 */
	}

	.openContent {
		background: #4f80f6;
		border-radius: 20px;
		text-align: center;
		padding: 10px
	}

	.el-menu--horizontal>.el-menu-item:hover {
		color: #ff0000 !important;
		background-color: rgba(0, 0, 0, 0.03) !important;
	}

	@import "../assets/css/0731103.css";
	@import "../assets/css/b4e8d76.css";
	@import "../assets/css/ed3fb55.css";
	@import "../assets/iconfont/iconfont.css";

	#HeaderBox {
		width: 100%;
		height: 60px;
		min-width: 1350px;
	}

	.HeaderBox1 {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 9;
		margin: 0 auto;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
		/* animation-name: example;
		animation-duration: 2s; */
	}

	.HeaderBox2 {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 9;
		margin: 0 auto;
		/* animation-name: example;
		animation-duration: 2s; */
		top: 0px !important;
		background-color: transparent;
	}

	.HeaderBox3 {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 9;
		margin: 0 auto;
		/* animation-name: example;
		animation-duration: 2s; */
		top: 0px !important;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
	}

	@keyframes example {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	#Header {
		width: 100%;
		margin: 0 auto;
		min-width: 1350px;
		max-width: 1450px;
		box-sizing: border-box;
		background: white;
		/* position: absolute;
		top: 0px;
		left: 0;
		right: 0;
		z-index: 9; */
		display: flex;
		justify-content: center;
	}

	#Header el-image {
		width: 10%;
	}

	#Header .user-info {
		width: 34%;
		display: flex;
	}

	#Header .user-info {
		margin-right: 14px;
	}

	#Header el-menu {
		width: 65%;
		margin: 0 2%;
	}

	#Header .el-dropdown {
		color: #fff
	}

	a:-webkit-any-link {
		text-decoration: none;
	}

	.userInfoImage {
		margin: 10px 0;
		border-radius: 50%;
		cursor: pointer;
	}
</style>
