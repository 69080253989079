import { render, staticRenderFns } from "./dubbingSearch.vue?vue&type=template&id=b54f319c&scoped=true"
import script from "./dubbingSearch.vue?vue&type=script&lang=js"
export * from "./dubbingSearch.vue?vue&type=script&lang=js"
import style0 from "./dubbingSearch.vue?vue&type=style&index=0&id=b54f319c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b54f319c",
  null
  
)

export default component.exports