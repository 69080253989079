<template>
	<div id="content" class="t_de_content">
		<div class="t_de_content_top">
			<div class="t_de_content_top_left">
				<div class="detail" :style="{ 'background-image': 'url(' + cardBg + ')' }">
					<!-- <div class="detail"> -->
					<div class="imgBox">
						<img class="teacher_img" :src="teacherInfo.headimgurl" />
						<span v-if="teacherInfo.on_line == 1" class="login"></span>
						<span v-else class="nologin"></span>
					</div>
					<div class="jianjie">
						<p class="jianjieTit">{{teacherInfo.nickname}}</p>
						<!-- <p class="jianjie_small">({{teacherInfo.speciality}})</p> -->
						<p class="chakan" :class="showTagFlag ? '':show">查看包含的内容 <i @click="showTag()"
								class="iconfont iconright"></i></p>
						<ul class="jianjie_type">
							<!-- <li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li>
							<li>大气浑厚</li> -->
							{{teacherInfo.speciality}}
						</ul>
					</div>
					<div class="connectBtn" @click="showKefu(teacherInfo)">
						<svg t="1693274299441" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6083" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z" fill="#ffffff" p-id="6084"></path><path d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z" fill="#ffffff" p-id="6085"></path></svg>
						在线联系
					</div>
				</div>
				<div class="jianjie_contentBox">
					<p class="jianjie_contentTit" style="font-size: 16px;">
						<span
							style="color: red;font-size: 20px;">￥{{teacherInfo.price.replace('.00','')}}</span>/100字&nbsp;&nbsp;&nbsp;
						<span style="color: red;font-size: 20px;">￥{{teacherInfo.min_price.replace('.00','')}}</span>/起配
					</p>
					<p class="jianjie_content">专业配音老师，及时交付交货及时!</p>
					<ul class="typeList">
						<li><i class="iconfont icon-duigou1"></i>1天交货</li>
						<li><i class="iconfont icon-duigou1"></i>{{wordCount}}字</li>
						<li><i class="iconfont icon-duigou1"></i>校对</li>
					</ul>
					<p class="jianjie_contentTit">字数</p>
					<div class="wordBox">
						<p class="wordCount">请输入所需字数</p>
						<div class="inputWord">
							<span style="margin-right: 10px;">字数</span>
							<!-- <el-input  v-model="wordCount" placeholder="请输入内容"></el-input> -->

							<el-input-number size="mini" v-model="wordCount" @change="wordChange" :min="1">
							</el-input-number>

							<el-tooltip placement="top" effect="light">
								<div slot="content" v-html="countMsg">

								</div>
								<span style="cursor: pointer">字数计算说明<i class="el-icon-warning"
										style="color: orange;"></i></span>
							</el-tooltip>

						</div>
						<p class="price" style='min-width: 300rpx;'>
							￥{{(wordCountPrice*teacherInfo.price < teacherInfo.min_price ? teacherInfo.min_price : wordCountPrice*teacherInfo.price) - selCdkItem.dec_price}}元</p>
					</div>
				</div>
			</div>
			<div class="t_de_content_top_right">
				<p class="detailTit">订单明细</p>
				<div class="datailPrice">
					<span class="leftTit">单价：</span>
					<p class="right" style="font-size: 12px;">
						<span
							style="color: red;font-weight: bold;font-size: 16px;">￥{{teacherInfo.price.replace('.00','')}}</span>/100字

						<span
							style="color: red;font-weight: bold;font-size: 16px;margin-right: 4px;">￥{{teacherInfo.min_price.replace('.00','')}}</span>/起配
					</p>
				</div>
				<div class="datailPrice">
					<span class="leftTit">字数：</span>
					<p class="right">{{wordCount}}字</p>
				</div>
				<hr />
				<div class="datailPrice">
					<span class="leftTit">总计：</span>
					<p class="right">{{(wordCountPrice*teacherInfo.price < teacherInfo.min_price ? teacherInfo.min_price : wordCountPrice*teacherInfo.price) - selCdkItem.dec_price}}元</p>
				</div>
				<div class="datailPrice">
					<span class="leftTit">交货时间：</span>
					<p class="right">1天交付</p>
				</div>
				<div class="xiadanBtn" @click="selPayTypeBoxFn()">
					立即下单&nbsp;&nbsp;&nbsp;&nbsp;(￥{{(wordCountPrice*teacherInfo.price < teacherInfo.min_price ? teacherInfo.min_price : wordCountPrice*teacherInfo.price) - selCdkItem.dec_price}}元)
				</div>
				<p style="font-size:12px;text-align: center;">支持正规发票开具</p>
				<!-- <div style="display:flex;align-items: center;justify-content: center;">
					<img style="margin-right: 40px; cursor: pointer;" @click="payTeacher(1)" src="../../assets/image/dubbingteacher/zhifubao.png"/>
					<img style=" cursor: pointer;" @click="payTeacher(2)" src="../../assets/image/dubbingteacher/wx.png"/>
				</div> -->
			</div>
		</div>
		<div class="listBox">
			<div class="listTit">
				<p>声音列表</p>
				<ul style="max-width: 70%;flex: 1;flex-wrap: wrap;justify-content: end;">
					<li :class='selLabelindex==-1?"activeLi":""' @click="selLabelDef(-1)">全部</li>
					<li :class='selLabelindex==index?"activeLi":""' @click="selLabelDef(index)"
						v-for='(item,index) in labelArr'>{{item.name}}</li>
				</ul>
			</div>
			<ul class="songList">
				<template v-for="(item,index) in audioSongList">
					<li>
						<div class="leftBox" style="text-align: left">
							<template v-if="audioFlag==true && audioId==item.id&& $store.state.musicLoading">
								<i class="iconfont icon-jiazai" @click="changeAudioSrc2(item)"
									style="margin-right: 20px;width: 16px;height: 16px;"></i>
							</template>
							<template v-else-if="audioFlag==true && audioId==item.id">
								<i class="iconfont icon-zanting3" @click="changeAudioSrc2(item)"
									style="margin-right: 20px;width: 16px;height: 16px;"></i>
							</template>
							<template v-else>
								<i class="iconfont icon-bofang1" @click="changeAudioSrc2(item)"
									style="margin-right: 20px;width: 16px;height: 16px;"></i>
							</template>

							<p class="audioTit text-overflow">{{item.title}}</p>
						</div>
						<div class="rightBox">
							<div class="btn-box">
								<div class="btn-collect-box">
									<div class="btn-trail-box">
										<!-- <a :href="item.audioSrc" download><i class="iconfont icon-yunxiazai_o"></i> </a> -->
										<i class="iconfont icon-yunxiazai_o" @click="handleDown(item)"></i>
										<div class="tip-top">下载音频</div>
									</div>
									<div class="btn-trail-box">
										<!-- <a :href="item.audioSrc" download><i class="iconfont icon-yunxiazai_o"></i> </a> -->
										<i class="iconfont iconfenxiang2" @click="onCopy($event,item.audio, teacherInfo.nickname)"></i>
										<div class="tip-top">分享音频</div>
									</div>
									<div class="btn-trail-box">
										<i v-if='item.is_collect==0' class="iconfont icon-shoucang_quxiaoshoucang"
											@click="changeLikeIconfont_collect(item)"></i>
										<i v-else class="iconfont icon-shoucang_shoucang"
											@click="changeLikeIconfont_collect(item)"></i>

										<div class="tip-top">{{item.is_collect==1 ? '取消收藏':'收藏音频'}}</div>
										<!-- <i class="iconfont icon-fenxiang" @click="onCopy($event,item.audio)"></i>
										<div class="tip-top">立即转发</div> -->
									</div>
									<!-- <div class="btn-trail-box">
										<template v-if="!item.likeFlag">
											<i class="iconfont icon-shoucang_quxiaoshoucang"></i>
											<div class="tip-top">添加收藏</div>
										</template>
										<template v-if="item.likeFlag">
											<i class="iconfont icon-shoucang_shoucang"></i>
											<div class="tip-top">取消收藏</div>
										</template>
									</div> -->

								</div>


							</div>
							<p class="audioTime">{{item.audioTime}}</p>
						</div>
					</li>
				</template>
			</ul>
			<div class="paging-box qt-pagination">
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
						:page-size="page.limit" :current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>
			</div>
		</div>
		<div class="tuijianBox copyright-music-Box">
			<h3 class="tuijianTit">为你推荐</h3>
			<ul class="case-list">
				<template v-for="(item) in audioTuiJianList">
					<li>
						<a href="javaScript:void(0)" @click="goDubbingTeacher(item.id)" class="cover">
							<div class="peoDetail">
								<div class="imgBox" style="min-width: 52px;">
									<img :src="item.headimgurl" />
									<span v-if="item.on_line == 1" class="login"></span>
									<span v-else class="nologin"></span>
								</div>
								<div class="peoName">
									<el-tooltip placement="top">
										<template #content> {{item.nickname}}</template>
										<p class="peoName_name">{{item.nickname}}</p>
									</el-tooltip>
									<p>销量：{{item.volume}}</p>
								</div>
								<button @click.stop="showKefu(item)">
									<svg t="1693274299441" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6083" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z" fill="#ffffff" p-id="6084"></path><path d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z" fill="#ffffff" p-id="6085"></path></svg>
									在线联系
								</button>
							</div>
							<p class="audioDetailP">{{item.blurb}}</p>
							<i v-if="item.remenFlag==1" class="iconfont icon-tuijian"></i>
							<!-- <i class="iconfont icon-remen"></i> -->
						</a>
						<div class="peoCaseBox">
							<ul>
								<template v-for="(audio,audioindex) in item.audio">
									<li>
										<p>{{audio.title}}</p>
										<div style="display: flex;">
											<i v-if="audioFlag==true && audioId==audio.id && $store.state.musicLoading"
												class="iconfont icon-jiazai"
												@click="changeAudioSrc(item,audioindex)"></i>
											<i v-else-if="audioFlag==true && audioId==audio.id"
												class="iconfont icon-zanting3"
												@click="changeAudioSrc(item,audioindex)"></i>
											<i v-else class="iconfont icon-bofang1"
												@click="changeAudioSrc(item,audioindex)"></i>
											<i class="iconfont icon-fenxiang" @click="onCopy($event,audio.audio, item.nickname)"></i>
										</div>
									</li>
								</template>
							</ul>
							<div class="peoPriceBox">
								<div class="peoPrice">
									<p><span class="redFont"
											style="font-size: 16px;">￥{{item.price.replace('.00','')}}/</span><span>100字</span>
									</p>
									<p><span class="redFont"
											style="font-size: 16px;">￥{{item.min_price.replace('.00','')}}/</span><span>起配</span>
									</p>
								</div>
								<i v-if="item.is_collect==1" :class="likeIconfontShouCang"
									@click="changeLikeIconfont(item)"></i>
								<i v-else :class="likeIconfont" @click="changeLikeIconfont(item)"></i>
							</div>
						</div>
					</li>
				</template>
			</ul>

		</div>

		<el-dialog :visible.sync="selPayTypeBox" top='35vh' class="selPayTypeBox" @close='closeDef'>
			<div style="height: 100px;display: flex;background-color:#f7d79e;padding:20px 20px;box-sizing: border-box;">
				<img :src="userInfo.headimgurl"
					style="width: 60px;height: 60px;border-radius: 30px;margin-right: 20px;" />
				<div style="flex:1;">
					<p style='font-size:18px;font-weight:600'>{{userInfo.user_name}}</p>
					<p
						style='font-weight:600;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;'>
						扫码下单，支持开具发票。
						<span style="color:#a56a00;cursor: pointer;"
							@click="cdkListBox((wordCountPrice*teacherInfo.price < teacherInfo.min_price ? teacherInfo.min_price : wordCountPrice*teacherInfo.price)-selCdkItem.dec_price)"><i
								class="iconfont icon-gerenzhongxin-duihuanzhongxin"
								style='margin-left:10px;'></i>优惠券</span>
					</p>
				</div>
			</div>
			<div class="payBottom">
				<div class="leftBox">
					<p class="payP">选择支付方式</p>
					<p :class="['methodsP',{'selected':payMethods ==0 }]" @click="changePayMethods(0)">
						<img src="../../assets/image/dubbingteacher/wx.png" />
						<img src="../../assets/image/dubbingteacher/zhifubao.png" />
						<span>线上付款</span>
					</p>
					<p :class="['methodsP',{'selected':payMethods ==1 }]" @click="changePayMethods(1)">
						<!-- <img src="../../assets/image/dubbingteacher/zhifubao.png" /> -->
						<img src="../../assets/image/dubbingteacher/yueIcon.png" alt="">
						<span>余额支付</span>
					</p>

				</div>
				<div class="rightBox">
					<p class="priceP">应付金额：<span>{{(wordCountPrice*teacherInfo.price < teacherInfo.min_price ? teacherInfo.min_price : wordCountPrice*teacherInfo.price) - selCdkItem.dec_price}}</span>元
					</p>
					<div class='chargeBox' v-if='payMethods == 0'>
						<div class="oneCharge">
							<iframe :srcdoc="alipayForm.zfb" frameborder="0" marginwidth="0" marginheight="0"
								scrolling="no" width="150" height="150">
							</iframe>
							<!-- <img v-show="islogin" style="margin-right: 40px; cursor: pointer;" @click="payDef(1)" src="../../assets/image/dubbingteacher/zhifubao.png"/> -->
							<p>支付宝支付</p>

						</div>
						<div class="oneCharge">
							<div id="qrCode" ref="qrCodeDiv" style="margin: 0 auto;width: 150px;height: 150px;"></div>
							<!-- <img v-show="islogin" style="margin-right: 40px; cursor: pointer;" @click="payDef(1)" src="../../assets/image/dubbingteacher/zhifubao.png"/> -->
							<p>微信支付</p>

						</div>
					</div>
					<div class='chargeBox' v-if='payMethods == 1'>
						<div style="text-align: center;">
							<div style="line-height: 80px;">使用余额支付</div>
							<button @click="payTeacher(3)"
								style="width: 100px;height: 40px;border: 1px solid #f2f2f2;background-color: #eb3637;color: white">立即下单</button>
						</div>
					</div>
					<div class='chargeBox' v-if='payMethods == 2'>
						<p style="line-height: 80px;">对公转账/汇款账号：12345698900877</p>
					</div>
				</div>

			</div>
		</el-dialog>

		<el-dialog :visible.sync="showcdkListBox" width="900" :modal-append-to-body='false'>
			<div>
				<div style="text-align: center;font-size: 20px;font-weight: bold;color: #eb3637;padding-bottom: 10px;">
					我的优惠券</div>
				<el-table :data="usedList" height="250" border :header-cell-style="{'text-align':'center'}"
					:cell-style="{'text-align':'center'}" style="width: 100%">
					<el-table-column prop="dec_price" label="金额" width="100"> </el-table-column>
					<el-table-column prop="man_price" label="使用条件" width="100"> </el-table-column>
					<el-table-column prop="time" label="到期日期" width="200"></el-table-column>
					<el-table-column prop="stauts" label="状态">
						<template slot-scope="scope">
							<el-button size="mini" @click="selCdk(scope.$index, scope.row)"
								style="background-color: #eb3637;color: white;">使用</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import goTop from '../../utils/goTop.js'
	import copyText from "@/utils/copyText";
	import QRCode from "qrcodejs2"
	var qrcode = null;
	var timer = null
	export default {
		components: {
			teacherDialog
		},
		data() {
			return {
				payMethods: 0,
				teacherInfo: {},

				audioTeacherId: "",
				audioId: "",
				wordNum: 1,
				audioFlag: false,
				pagePrev: true,
				pageNext: false,
				totalPage: 1000,
				showTagFlag: false,
				show: '',
				payDialogVisible: false,
				payImg: '',
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				page: {
					currentPage: 1,
					limit: 10,
				},
				wordCount: 1,
				wordCountPrice: 1,
				audioTuiJianList: [],
				audioSongList: [],
				selPayTypeBox: false,
				labelArr: [],
				tagsList: [],
				selLabelindex: -1,
				kefuBoxShow: false,
				kefuObj: {

				},
				alipayForm: {
					wx: '',
					zfb: ''
				},
				userInfo: {
					headimgurl: '',
					user_name: ''
				},
				showcdkListBox: false,

				usedList: [], //优惠券列表
				selCdkItem: {
					dec_price: 0
				},
				countMsg: '',
				teacher_bg_image: '',
				cardBg: ''
			}
		},
		beforeRouteLeave(to, from, next) {
			// 执行一些操作，比如关闭弹窗
			this.closeDef()

			// 调用 next() 方法继续完成路由跳转
			next()
		},
		watch:{
		    $route(){
		      this.getAdByTag()
		      if (localStorage.getItem("userInfo")) {
		      	this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
		      }
		      this.audioTeacherId = this.$route.query.audioTeacherId
		      this.bus.$on("changeStatus", (val, flag) => {
		      	this.changeStatus(val, flag)
		      });
		      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null && localStorage.getItem(
		      		'token') != undefined) {
		      	this.islogin = true
		      }
		      this.getTeacherInfo()
		      this.getrecommend()
		      this.getTeacherAaudio()
		      this.getShowCate()
		      this.getConfigValue()
		      this.getCardBg()
		    }
		},
		mounted() {
			this.getAdByTag()
			if (localStorage.getItem("userInfo")) {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
			}
			this.audioTeacherId = this.$route.query.audioTeacherId
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
			if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null && localStorage.getItem(
					'token') != undefined) {
				this.islogin = true
			}
			this.getTeacherInfo()
			this.getrecommend()
			this.getTeacherAaudio()
			this.getShowCate()
			this.getConfigValue()
			this.getCardBg()
		},
		methods: {
			getCardBg() {
				this.$post('/ad/background', ).then(res => {
					console.log(res)
					this.cardBg = res.data.background
				})
			},
			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'teacher_bg_image'
				}).then(res => {
					console.log(res)
					this.teacher_bg_image = res.data[0].ad_pic
				})
			},
			getConfigValue() {
				this.$post('/Index/getConfigValue', {
					name: 'price_tips'
				}).then(res => {
					this.countMsg = res.data.replace(/\r\n/g, '<br />')
				})
			},

			// 收藏
			changeLikeIconfont_collect(item) {
				console.log(item)
				let data = {
					id: item.id,
					type: 3,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: res.mess
						})
						this.getTeacherAaudio()
					}
				})
			},
			selCdk(e, data) {
				console.log('/*********************')
				console.log(e, data)
				this.selCdkItem = data
				this.showcdkListBox = false
				if (this.payMethods == 0) {
					this.payTeacher(1)
					// this.payTeacher(1)
				}
			},
			cdkListBox(price) {
				let data = {
					price: price
				}
				this.$post('/Order/coupon', data).then(res => {
					this.showcdkListBox = true
					this.usedList = res.data
				})
			},
			changePayMethods(index) {
				this.payMethods = index;
				// if(index==0){
				// 	this.payTeacher(1); //支付宝
				// 	this.payTeacher(2); //weixin 
				// }
				if (index == 0) {
					this.$nextTick(function() {
						this.bindQRCode(this.alipayForm.wxUrl);
					});
				}

			},
			selPayTypeBoxFn() {
				// if (!this.islogin) {
				// 	this.$message({
				// 		message: '请先登录~',
				// 		type: 'error'
				// 	});
				// 	return;

				// }
				this.payTeacher(1); //支付宝
				// this.payTeacher(2); //weixin 
				// let data = {
				// 	type:type,
				// 	active:this.rechargeList[this.activeListIndex].id
				// }
				// this.$post('/Order/payRecharge',data).then(res=>{
				// 	if(type == 1){
				// 		console.log(res.data)
				// 		this.alipayForm.zfb = res.data;

				// 	}else{
				// 		timer = setInterval(()=>{
				// 			this.selectOrder(res.data.order_id)
				// 		},1000)
				// 		// this.payDialogVisible = true
				// 		this.$nextTick(function () {
				// 			this.bindQRCode(res.data.url);
				// 		})
				// 	}
				// })
			},
			bindQRCode(data) {
				console.log(this.$refs.qrCodeDiv)
				if (qrcode != null) {
					this.$refs.qrCodeDiv.innerHTML = ''
				}
				qrcode = new QRCode(this.$refs.qrCodeDiv, {
					text: data,
					width: 150,
					height: 150,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})
			},
			wordChange(e) {
				if (this.wordCount % 100 > 0) {
					this.wordCountPrice = Number(parseInt(this.wordCount / 100) + 1)
				} else {
					this.wordCountPrice = Number(this.wordCount / 100)
				}
				// 
			},
			showKefu(item) {
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data;
						this.kefuObj.headimgurl = item.headimgurl;
						this.kefuObj.nickname = item.nickname;
						this.kefuObj.on_line = item.on_line;
						this.kefuBoxShow = true
					})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }

			},
			selLabelDef(index) {
				this.tagsList = []
				this.selLabelindex = index
				if (index != -1) {
					this.tagsList.push(this.labelArr[index])
				} else {
					this.tagsList.push({
						id: '',
						name: ''
					})
				}

				this.getTeacherAaudio()
			},
			// 获取分类筛选标签
			getShowCate() {
				let data = {
					tid: this.audioTeacherId
				}
				this.$post('/Label/teacher_lable', data).then(res => {
					console.log(res)
					this.labelArr = res.data
				})
			},

			closeDef() {
				this.selCdkItem = {
					dec_price: 0
				}
				console.log('关闭定时器')
				clearInterval(timer)
			},

			// 购买
			payTeacher(type) {
				if (this.wordCountPrice * this.teacherInfo.price < this.teacherInfo.price) {
					this.$message({
						message: this.teacherInfo.min_price + '元起配，未到起配金额',
						type: 'error'
					})
					return false
				}
				// this.$confirm('是否确认购买?', '提示', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	type: 'success'
				// }).then(() => {
				let data = {
					type: type,
					number: this.wordCountPrice,
					teacher: this.teacherInfo.id,
					coupon_id: this.selCdkItem.dec_price == 0 ? '' : this.selCdkItem.id
				}
				this.$post('/Order/payTeacher', data).then(res => {
					console.log(res);
					this.selPayTypeBox = true;
					if (type == 1) {
						timer = setInterval(() => {
							this.selectOrder(res.data.wxpay.order_id)
						}, 2000)
						this.alipayForm.wxUrl = res.data.wxpay.url;
						this.alipayForm.zfb = res.data.zfbpay
						this.$nextTick(function() {
							this.bindQRCode(res.data.wxpay.url);
						})
					} else {
						this.selPayTypeBox = false
						this.$message({
							message: res.mess,
							type: 'success'
						})
					}
				})
			},
			selectOrder(id) {
				let data = {
					orderid: id
				}
				this.$post('/Order/selectOrder', data).then(res => {
					console.log(res)
					if (res.data == 1) {
						this.selPayTypeBox = false
						this.$message({
							type: 'success',
							message: '支付成功'
						})
					}
				})
			},
			// 获取老师详情
			getTeacherInfo() {
				let data = {
					id: this.audioTeacherId
				}
				this.$post('/Teacher/teacherInfo', data).then(res => {
					console.log(res)
					this.teacherInfo = res.data
				})
			},
			// 获取老师音频
			getTeacherAaudio() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					labelidStr.push(this.tagsList[i].id)
				}
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					id: this.audioTeacherId,
					label: labelidStr[0],
				}
				this.$post('/Teacher/teacherAaudio', data).then(res => {
					console.log(res)
					this.audioSongList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= this.totalPage / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			// 获取推荐列表
			getrecommend() {
				let data = {}
				this.$post('/Teacher/recommend', data).then(res => {
					console.log(res)
					this.audioTuiJianList = res.data
				})
			},
			payDialogFn(imgSrc) {
				this.payDialogVisible = true;
				this.payImg = 'require(' + imgSrc + ')'
			},
			showTag() {
				if (this.showTagFlag) {
					this.show = 'show'
				} else {
					this.show = ''
				}
				this.showTagFlag = !this.showTagFlag;
			},
			onCopy(event, audioSrc, nickname) {
				copyText(event, nickname+audioSrc)
			},
			// 配音老师详情页
			goDubbingTeacher(audioId) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: audioId
					}
				})
				// 获取当前老师信息，，，获取推荐列表
				// 
				goTop()
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getTeacherAaudio()
			},
			//下载音频
			handleDown(audio) {
				// 使用获取到的blob对象创建的url
				const filePath = audio.audio // mp3的地址
				fetch(filePath).then(res => res.blob()).then(blob => {
					const a = document.createElement('a')
					document.body.appendChild(a)
					a.style.display = 'none'
					// 使用获取到的blob对象创建的url
					const url = window.URL.createObjectURL(blob)
					a.href = url
					// 指定下载的文件名
					a.download = audio.title + '.mp3'
					a.click()
					document.body.removeChild(a)
					// 移除blob对象的url
					window.URL.revokeObjectURL(url)
				})
			},
			// 收藏、取消收藏
			changeLikeIconfont(item) {
				let data = {
					id: item.id,
					type: 1,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					this.getrecommend()
				})
			},
			changeAudioSrc(audioDetail, index) {
				console.log(audioDetail.audio[index].id)
				var audioInfo = {};
				audioInfo.id = audioDetail.audio[index].id;
				audioInfo.audioSrc = audioDetail.audio[index].audio;
				audioInfo.audioImage = audioDetail.headimgurl
				audioInfo.audioName = audioDetail.audio[index].title;
				audioInfo.audioPeoName = this.teacherInfo.nickname;
				audioInfo.audioPeoId = this.teacherInfo.id;
				// 音乐信息存缓存
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeAudioSrc2(audioDetail) {
				console.log(audioDetail)
				var audioInfo = {};
				audioInfo.id = audioDetail.id;
				audioInfo.audioSrc = audioDetail.audio;
				audioInfo.audioImage = this.teacherInfo.headimgurl
				audioInfo.audioName = audioDetail.title;
				audioInfo.audioPeoName = this.teacherInfo.nickname;
				// 音乐信息存缓存
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeStatus(val, flag) {
				// 获取播放的id,总状态
				console.log("teacherDetail-----", val, flag)
				this.audioId = val;
				this.audioFlag = flag
			},
			handleChange() {

			}
		}
	}
</script>

<style scoped>
	.copyright-music-Box .case-list li .cover .peoDetail .peoName {
		margin: 0 2% 0 2%;
		flex: 1;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.copyright-music-Box .case-list li .cover .peoDetail .peoName .peoName_name {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	/deep/.el-dialog {
		width: 600px;
		border-radius: 20px !important;
	}

	/deep/.selPayTypeBox .el-dialog__body {
		padding: 0 !important;
		overflow: hidden;
		background-color: transparent;
	}

	.selPayTypeBox .payBottom {
		margin-top: 20px;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
	}

	.selPayTypeBox .payBottom .leftBox {
		margin-right: 50px;
	}

	.selPayTypeBox .payBottom .leftBox .methodsP {
		margin: 20px 0;
		width: 150px;
		height: 50px;
		border-radius: 10px;
		border: 1px solid #dbdbdb;
		line-height: 50px;
		/* text-align: center; */
		padding-left: 10px;
		box-sizing: border-box;
	}

	.selPayTypeBox .payBottom .leftBox .methodsP.selected {
		border: 1px solid #398ffe;
	}

	.selPayTypeBox .payBottom .leftBox .methodsP img {
		width: 20px;
		height: 20px;
		margin: 0 5px;
	}

	.selPayTypeBox .payBottom .rightBox {
		flex: 1;
	}

	.selPayTypeBox .payBottom .rightBox .priceP {
		text-align: center;
	}

	.selPayTypeBox .payBottom .rightBox .priceP span {
		color: #e30000;
		font-size: 24px;
		font-weight: 600;
		margin: 0 5px;
	}

	.selPayTypeBox .payBottom .rightBox .chargeBox {
		display: flex;
		justify-content: center;
		margin-top: 20px;

	}

	.selPayTypeBox .payBottom .rightBox .chargeBox .oneCharge {
		margin-right: 20px;
	}

	.selPayTypeBox .payBottom .rightBox .chargeBox .oneCharge p {
		text-align: center;
	}

	@import "../../assets/css/ed3fb55.css";

	.icon-duigou1 {
		color: #ff1414
	}

	.t_de_content .t_de_content_top .imgBox .login {
		width: 15px;
		height: 15px;
		border-radius: 10px;
		right: 50px;
		bottom: 40px
	}

	.t_de_content .t_de_content_top .imgBox .nologin {
		width: 15px;
		height: 15px;
		border-radius: 10px;
		right: 50px;
		bottom: 40px
	}

	.t_de_content,
	.listBox,
	.tuijianBox {
		width: 1400px;
		margin: 20px auto;

	}

	.tuijianBox {
		border-radius: 10px;
		background-color: #fff;
		margin-bottom: 60px;
	}

	.t_de_content .t_de_content_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 500px;
		margin: 0 auto;
	}

	.t_de_content .t_de_content_top_left {
		flex: 1;
		margin-right: 1%;
		background-color: #fff;
		border-radius: 10px;
		padding: 30px 40px;
		box-sizing: border-box;
		height: 500px;
	}

	.t_de_content .t_de_content_top_right {
		min-width: 350px;
		width: 25%;
		background-color: #fff;
		border-radius: 10px;
		padding: 30px 40px;
		box-sizing: border-box;
		height: 500px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.t_de_content .t_de_content_top_right hr {
		border: 1px solid #f5f5f5;
	}

	.t_de_content .t_de_content_top_right .detailTit {
		font-size: 20px;
		font-weight: 600;
	}

	.t_de_content .t_de_content_top_right .datailPrice {
		margin: 10px 0;
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.t_de_content .t_de_content_top_right .xiadanBtn {
		width: 100%;
		height: 50px;
		border-radius: 5px;
		background-color: #eb3637;
		color: #fff;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		margin-top: 30px;
		transition: all .3s;
		cursor: pointer;
	}

	.t_de_content .t_de_content_top_right .xiadanBtn:hover {
		transform: scale(1.03);
	}

	.t_de_content .t_de_content_top_right .datailPrice .leftTit {
		width: 30%;
		max-width: 100px;
	}

	.t_de_content .t_de_content_top_right .datailPrice .el-input-number,
	.t_de_content .t_de_content_top_right .datailPrice .right {
		width: 70%;
		text-align: end;
	}

	.t_de_content .t_de_content_top_right .datailPrice .right .redFont {
		color: #ff1a19;
		font-size: 16px;
		font-weight: 600;
	}

	.t_de_content .t_de_content_top_left .detail {
		/* background-image: url(''); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 10px 0;
		box-sizing: border-box;
	}

	.t_de_content .t_de_content_top_left .jianjie {
		flex: 1;
		color: #fff
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjieTit {
		font-size: 20px;
		font-weight: 600;
		margin: 10px 0;
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_small {
		margin: 5px 0;
	}

	.t_de_content .t_de_content_top_left .jianjie .chakan {
		font-size: 12px;
	}

	.t_de_content .t_de_content_top_left .jianjie .chakan>i {
		font-size: 12px;
		display: inline-block;
		transform: rotate(90deg);
		cursor: pointer;
		transition: all .3s;
	}

	.t_de_content .t_de_content_top_left .jianjie .chakan.show>i {
		transform: rotate(-90deg);
	}

	.t_de_content .t_de_content_top_left .jianjie .chakan.show+.jianjie_type {
		height: auto;
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_type {
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		width: 100%;
		height: 0px;
		margin-top: 10px;
		transition: all .3s;
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_type li {
		margin-right: 10px;
		height: 30px;
		line-height: 20px;
		border-radius: 5px;
		border: 1px solid #ccc;
		color: #ccc;
		padding: 5px;
		margin-bottom: 5px;
		font-size: 12px;
		cursor: default;
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_type li:hover {
		color: #f84949
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_price {
		display: flex;
		margin-top: 10px;
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_price p {
		margin-right: 20px;
	}

	.t_de_content .t_de_content_top_left .jianjie .jianjie_price p span {
		color: #ff9000;
		font-size: 20px;
		font-weight: 600;
	}

	.t_de_content .t_de_content_top_left .iconBox .iconfont {
		float: right;
		cursor: pointer;
	}

	.t_de_content .t_de_content_top_left .iconBox .icon-shoucang_shoucang {
		color: #f84949
	}

	.t_de_content .t_de_content_top_left .iconBox .iconfont:hover {
		color: #f84949
	}

	.t_de_content .t_de_content_top_left .jianjie_content {
		width: 65%;
		color: #333;
		line-height: 20px;
		font-size: 14px;

	}

	.t_de_content .t_de_content_top_left .typeList {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px solid #f5f5f5;
	}

	.t_de_content .t_de_content_top_left .typeList li {
		width: 50%;
		line-height: 30px;
		height: 30px;
	}

	.t_de_content .t_de_content_top_left .jianjie_contentTit {
		font-size: 20px;
		font-weight: 600;
		margin: 10px 0;
	}

	.t_de_content .t_de_content_top_left .wordBox {
		display: flex;
		align-items: center;
	}

	.t_de_content .t_de_content_top_left .wordBox .wordCount {
		width: 60%;
	}

	.t_de_content .t_de_content_top_left .wordBox .inputWord {
		width: 50%;
		display: flex;
		align-items: center;
	}

	.t_de_content .t_de_content_top_left .wordBox .inputWord .el-input {
		width: 100px;
		margin-left: 10px;
	}

	.t_de_content .t_de_content_top_left .wordBox .price {
		width: 10%;
		margin-left: 20px;
	}

	.t_de_content .t_de_content_top_left .wordBox .t_de_content .t_de_content_top_left .detail .connectBtn {
		width: 100px;
		height: 40px;
		background-color: #f84949;
		border-radius: 5px;
		color: #fff;
		text-align: center;
		line-height: 40px;
		margin: 30px 40px;
		cursor: pointer;
		transition: all .3s;
	}

	.connectBtn {
		width: 100px;
		height: 40px;
		background-color: #f84949;
		border-radius: 5px;
		color: #fff;
		text-align: center;
		line-height: 40px;
		margin: 30px 40px;
		cursor: pointer;
		transition: all .3s;
	}

	.t_de_content .t_de_content_top_left .detail .connectBtn:hover {
		transform: scale(1.05);
	}

	.connectBtn:hover {
		transform: scale(1.05);
	}

	.t_de_content .t_de_content_top_left .detail .teacher_img {
		width: 100px;
		height: 100px;
		border-radius: 60px;
		border: 3px solid #fff;
		margin: 30px 40px;
		transition: all .3s;
		cursor: pointer;
	}

	.t_de_content .t_de_content_top_left .detail .teacher_img:hover {
		transform: scale(1.05);
	}

	.t_de_content .listBox {
		border-radius: 10px;
		background-color: #fff;
		margin-top: 20px;
	}

	.t_de_content .listBox .listTit {
		padding: 0 40px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
	}

	.t_de_content .listBox .listTit p {
		line-height: 100px;
		font-size: 20px;
		font-weight: 600;
		width: auto !important;
	}

	.t_de_content .listBox .listTit ul {
		display: flex;

	}

	.t_de_content .listBox .listTit ul li {
		margin-left: 30px;
		padding: 5px 10px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.t_de_content .listBox .listTit ul li.activeLi,
	.t_de_content .listBox .listTit ul li:hover {
		color: #fff;
		background-color: #eb3637;
		border-radius: 5px;

	}

	.t_de_content .listBox .songList {
		padding: 0 40px;
		box-sizing: border-box;
	}

	.t_de_content .listBox .songList li {
		height: 70px;
		border-bottom: 1px solid #f5f5f5;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.t_de_content .listBox .songList li .leftBox {
		width: 84%;
		display: flex;
	}

	.t_de_content .listBox .songList li .leftBox i {
		cursor: pointer;
	}

	.t_de_content .listBox .songList li .leftBox .audioTit {
		width: calc(100% - 50px);
	}

	.t_de_content .listBox .songList li .rightBox {
		width: 15%;
		display: flex;
	}

	.t_de_content .listBox .songList li .rightBox .btn-box {
		margin-right: 30px;
	}

	.t_de_content .listBox .songList li .rightBox .btn-box .btn-collect-box {
		display: flex;

	}

	.t_de_content .listBox .songList li .rightBox .btn-box i {
		margin: 0 10px;
		cursor: pointer;
		font-size: 16px;
	}

	.t_de_content .listBox .songList li .rightBox .btn-box i:hover,
	.t_de_content .listBox .songList li .rightBox .btn-box i.icon-shoucang_shoucang {
		color: #eb3637
	}

	.t_de_content .listBox .songList li .rightBox .btn-box .btn-trail-box {
		position: relative;
	}

	.t_de_content .listBox .songList li .rightBox .btn-box .btn-trail-box>.tip-top {
		display: none;
	}

	.t_de_content .listBox .songList li .rightBox .btn-box .btn-trail-box:hover>.tip-top {
		display: block
	}

	.t_de_content .listBox .songList li .rightBox .audioTime {
		color: #999999;
	}

	.tuijianBox {
		padding: 0 40px;
		box-sizing: border-box;
	}

	.tuijianBox.sound-content .con-main {
		width: 100% !important;
	}

	.tuijianBox .tuijianTit {
		height: 80px;
		line-height: 80px;
		font-size: 20px;
		font-weight: 600;
	}

	.copyright-music-Box .case-list {
		width: 100% !important;
		margin: 0px auto 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}

	/* .copyright-music-Box .case-list>li {
		width: 310px;
	} */
	.copyright-music-Box .case-list>li {
		width: auto !important;
	}

	@media only screen and (max-width: 1680px) {

		.t_de_content_top,
		.t_de_content .listBox,
		.tuijianBox {
			width: 1200px;
		}

		.t_de_content .listBox .songList li .leftBox {
			width: 80%;
		}

		.t_de_content .listBox .songList li .rightBox {
			width: 18%;
		}

		/* .copyright-music-Box .case-list>li {
			width: auto !important; 
		} */
		.copyright-music-Box .case-list {
			grid-template-columns: repeat(4, 1fr);
		}

		.copyright-music-Box .case-list li:nth-of-type(5) {
			display: none;
		}
	}

	@media only screen and (max-width:1440px) {

		.t_de_content_top,
		.t_de_content .listBox,
		.tuijianBox {
			width: 1000px
		}

		.t_de_content .listBox .songList li .rightBox {
			width: 22% !important;
		}

		.copyright-music-Box .case-list {
			grid-template-columns: repeat(3, 1fr);
		}

		.copyright-music-Box .case-list li:nth-of-type(4),
		.copyright-music-Box .case-list li:nth-of-type(5) {
			display: none;
		}

		/* .copyright-music-Box .case-list>li {
			width: 288px;
		} */
	}

	/* /deep/.dialogBox {
		background: #4f80f6;
		border-radius: 20px;
		text-align: center;
	} */
	.dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}

	/deep/.dialogBox {
		border-radius: 50px 40px;
	}
</style>
