<template>
  <div>
    <div class="banner" :style="{ background: 'url(' + bgimg + ') 50%/cover' }">
      <div class="peoDetailBox">
        <img :src="userInfo.headimgurl" />
        <div class="infoBox">
          <p>{{ userInfo.user_name }}</p>
          <!-- <p>ID2345</p> -->
        </div>
      </div>
    </div>
    <div class="con-main">
      <ul class="leftMenu">
        <template v-for="item in menuList">
          <li
            :class="{ activeLi: activeMenuId == item.itemid }"
            @click="goPageFn(0, item.itemid)"
          >
            <p>
              <template v-if="item.itemname == '我的消息'">
                <svg
                  t="1684459411732"
                  style="font-size: 16px; margin-right: 10px; color: deeppink"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2416"
                  width="200"
                  height="200"
                >
                  <path
                    d="M544 161.536a330.666667 330.666667 0 0 1 298.666667 329.130667h-0.341334c0.213333 1.493333 0.341333 2.986667 0.341334 4.565333v219.434667h39.68a32 32 0 0 1 0 64h-212.053334a160 160 0 0 1-316.586666 0H141.909333a32 32 0 1 1 0-64h39.424v-219.434667c0-1.578667 0.128-3.072 0.341334-4.565333H181.333333a330.666667 330.666667 0 0 1 298.666667-329.130667V128a32 32 0 1 1 64 0v33.536z m-298.666667 553.130667h533.333334v-219.434667c0-1.578667 0.128-3.072 0.341333-4.565333h-0.341333a266.666667 266.666667 0 1 0-533.333334 0h-0.341333c0.213333 1.493333 0.341333 2.986667 0.341333 4.565333v219.434667z m359.765334 64H418.901333a96 96 0 0 0 186.197334 0z"
                    fill="#111111"
                    p-id="2417"
                  ></path>
                </svg>
              </template>
              <template v-if="item.itemid == 10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  t="1690866610110"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="3963"
                  style="font-size: 16px; margin-right: 10px; color: deeppink"
                  width="200"
                  height="200"
                >
                  <path
                    d="M511.5 575.617c-75.605 0-146.685-29.442-200.145-82.903-53.461-53.461-82.903-124.54-82.903-200.145s29.442-146.684 82.903-200.145c53.46-53.46 124.54-82.902 200.145-82.902s146.684 29.442 200.145 82.902c53.46 53.461 82.902 124.54 82.902 200.145s-29.442 146.685-82.902 200.145c-53.461 53.461-124.541 82.903-200.145 82.903z m0-502.095c-120.784 0-219.048 98.264-219.048 219.047S390.716 511.617 511.5 511.617c120.783 0 219.047-98.265 219.047-219.048S632.282 73.522 511.5 73.522zM73 1021c-17.673 0-32-14.327-32-32 0-63.165 12.378-124.459 36.791-182.177 23.573-55.732 57.313-105.779 100.283-148.749s93.017-76.71 148.75-100.283C384.542 533.378 445.834 521 509 521c75.987 0 151.447 18.636 218.223 53.893 15.629 8.252 21.609 27.61 13.357 43.238-8.253 15.629-27.612 21.607-43.239 13.357C639.74 601.075 574.612 585 509 585c-222.767 0-404 181.233-404 404 0 17.673-14.327 32-32 32z m899.866-169.645H690.838c-17.673 0-32-14.327-32-32s14.327-32 32-32h282.028c17.673 0 32 14.327 32 32s-14.327 32-32 32zM831.853 992.37c-17.673 0-32-14.327-32-32V678.342c0-17.673 14.327-32 32-32s32 14.327 32 32V960.37c0 17.673-14.328 32-32 32z"
                    fill="#111111"
                    p-id="3964"
                  />
                </svg>
              </template>
              <i
                v-if="
                  item.itemname != '我的消息' && item.itemname != '邀请名单'
                "
                class="iconfont"
                :class="item.icon"
              ></i>
              {{ item.itemname }}
            </p>
          </li>
        </template>
      </ul>
      <div class="rightContent">
        <p
		        v-if="aiBuddingUserInfo.vipExpireTime"
          style="
            padding: 10px 0 10px 10px;
            background: #f5f5f5;
            font-weight: bold;
            font-size: 16px;
          "
        >
          AI配音会员过期时间：{{
            formatDateTime(aiBuddingUserInfo.vipExpireTime)
          }}
        </p>
        <div v-if="activeMenuId == 0">
          <ul class="contentUl">
            <li>
              <p>
                钱包余额：<span>{{ moneyObj.wallet }}</span
                >元
              </p>
              <div class="btnBox">
                <button @click="goPageFn(0, 3)">钱包明细</button>
              </div>
            </li>
            <li>
              <p>
                我的收益：<span>{{ moneyObj.yj_wallet }}</span
                >元
              </p>
              <div class="btnBox">
                <button @click="tixianDialogFlag = true">提现</button>
                <button @click="goPageFn(0, 8)">收益明细</button>
              </div>
            </li>
            <li>
              <p>
                当前积分：<span>{{ moneyObj.integral }}</span>
              </p>
              <div class="btnBox">
                <button @click="goPageFn(1)">立即兑现</button>
                <button @click="goPageFn(0, 9)">兑换明细</button>
              </div>
            </li>
          </ul>
          <!-- <ul class="contentUl">
						<li>
							<p><span>{{moneyObj.coupon}}</span>张</p>
							<div class="btnBox">
								<p>优惠券</p>
							</div>
						</li>
					</ul> -->
          <div class="testBox">
            <p class="testTit">积分任务</p>
            <ul class="testUl">
              <li v-for="item in taskListlist">
                <img src="../../assets/image/person/liulan.png" />
                <div class="jifenBox">
                  <p>
                    {{ item.task_name }}<span>+{{ item.integral }}分</span>
                  </p>
                  <button @click="tofinish(item.id)" v-if="item.cate_id == 1">
                    {{ item.done == 0 ? "去完成" : "已完成" }}
                  </button>
                  <button
                    @click="tofinish(item.id)"
                    v-if="item.cate_id == 2 && item.id != 6"
                  >
                    去完成<span>{{ item.done }}/{{ item.number }}</span>
                  </button>
                  <button
                    @click="tofinish(item.id)"
                    v-if="item.cate_id == 2 && item.id == 6"
                  >
                    签到<span>{{ item.done }}/{{ item.number }}</span>
                  </button>
                  <button @click="tofinish(item.id)" v-if="item.cate_id == 3">
                    去完成
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="activeMenuId == 1">
          <collect :menuList="menuList[1].children"></collect>
        </div>
        <div v-if="activeMenuId == 2">
          <message></message>
        </div>
        <div v-if="activeMenuId == 3">
          <record></record>
        </div>
        <div v-if="activeMenuId == 4">
          <cdk :menuList="menuList[4].children"></cdk>
        </div>
        <div v-if="activeMenuId == 5">
          <accountInformation></accountInformation>
        </div>
        <div v-if="activeMenuId == 8">
          <syrecord></syrecord>
        </div>
        <div v-if="activeMenuId == 10">
          <invite></invite>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改个人信息"
      :visible.sync="changeInfoDialog"
      width="30%"
      style="min-width: 350px"
    >
      <infoDialog :changeInfoDialog.sync="changeInfoDialog"></infoDialog>
    </el-dialog>
    <el-dialog
      :title="'可提现金额：' + totalMoney + ' 元'"
      :visible.sync="tixianDialogFlag"
      width="50%"
      style="min-width: 650px"
      custom-class="tixianBox"
      @close="resetForm()"
    >
      <tixianDialog
        ref="tixianDialog"
        :tixianDialogFlag.sync="tixianDialogFlag"
        @update="updeaeDef"
        :totalMoney="totalMoney"
      >
      </tixianDialog>
    </el-dialog>
  </div>
</template>

<script>
import goTop from "../../utils/goTop.js";
import infoDialog from "../../components/person/infoDialog";
import tixianDialog from "../../components/person/tixianDialog";
import collect from "../../components/person/collect";
import message from "../../components/person/message";
import record from "../../components/person/record";
import syrecord from "../../components/person/syrecord";
import cdk from "../../components/person/cdk";
import accountInformation from "../../components/person/accountInformation";
import invite from "../../components/person/invite.vue";
import { mapActions, mapGetters } from "vuex";
import formatDateTime from "../../utils/func";
export default {
  components: {
    infoDialog,
    tixianDialog,
    collect,
    message,
    record,
    cdk,
    accountInformation,
    syrecord,
    invite,
  },
  data() {
    return {
      userInfo: {
        headimgurl: "",
      },
      activeMenuId: 0,
      itemIndex: 0,
      tixianDialogFlag: false,
      changeInfoDialog: false,
      totalMoney: 10,
      menuList: [
        {
          itemname: "个人中心",
          itemid: 0,
          icon: "icon-gerenzhongxin-duihuanzhongxin",
        },
        {
          itemname: "我的收藏",
          itemid: 1,
          icon: "icon-shoucang_shoucang",
          children: [
            {
              itemname: "主播收藏",
            },
            {
              itemname: "案例收藏",
            },
            {
              itemname: "音频收藏",
            },
          ],
        },
        {
          itemname: "我的消息",
          itemid: 2,
          icon: "icon-fuwu",
        },
        {
          itemname: "配音订单",
          itemid: 3,
          icon: "icon-fapiaokaiju",
        },
        {
          itemname: "CDK兑换",
          itemid: 4,
          icon: "icon-gerenzhongxin-duihuanzhongxin",
          children: [
            {
              itemname: "未使用",
            },
            {
              itemname: "已使用",
            },
          ],
        },
        {
          itemname: "账号信息",
          itemid: 5,
          icon: "icon-bangzhuzhongxin",
        },
        {
          itemname: "邀请返现",
          itemid: 6,
          icon: "icon-gerenzhongxin-duihuanzhongxin",
        },
        {
          itemname: "配音充值",
          itemid: 7,
          icon: "icon-wodezhifuzhanghu",
        },
        {
          itemname: "收益明细",
          itemid: 8,
          icon: "icon-fapiaokaiju",
        },
        {
          itemname: "邀请名单",
          itemid: 10,
          icon: "icon-fapiaokaiju",
        },
      ],
      taskListlist: [],
      moneyObj: {},
      bgimg: [],
    };
  },
  computed: {
    ...mapGetters(["aiBuddingUserInfo"]),
  },
  methods: {
    formatDateTime,
    ...mapActions(["getAiUserInfo"]),
    getAdByTag() {
      this.$post("/Ad/getAdByTag", {
        tag: "member_banner",
      }).then((res) => {
        console.log(res);
        this.bgimg = res.data[0].ad_pic;
      });
    },

    getAccountInfo() {
      this.$post("/MemberInfo/accountInfo", {}).then((res) => {
        this.moneyObj = res.data;
      });
    },
    updeaeDef() {
      this.tixianDialogFlag = false;
      this.getAccountInfo();
    },
    tofinish(id) {
      console.log(id);
      sessionStorage.setItem("taskid", id);
      switch (id) {
        case 1: //浏览老师
          this.$router.push({
            path: "/dubbingTeacher",
          });
          goTop();
          break;
        case 2: //收藏老师
          this.$router.push({
            path: "/dubbingTeacher",
          });
          goTop();
          break;
        case 3: //收藏音频
          this.$router.push({
            path: "/dubbingCase",
          });
          goTop();
          break;
        case 4: //成功下单
          this.$router.push({
            path: "/dubbingTeacher",
          });
          goTop();
          break;
        case 5: //完善资料
          this.$router.push({
            path: "/person?itemid=5",
          });
          goTop();
          break;
        case 6: //每日签到
          if (sessionStorage.getItem("taskid")) {
            this.$toTask();
            setTimeout(() => {
              this.getTaskList();
            }, 1000);
          }
          break;
        case 8: //邀请好友
          this.$router.push({
            path: "/recommendedCashBack",
          });
          goTop();
          break;
        default:
          break;
      }
    },
    getTaskList() {
      this.$post("/IntegralShop/getTaskList", {}).then((res) => {
        this.taskListlist = res.data;
      });
    },
    goPageFn(type, itemid) {
      if (type == 1) {
        //兑换积分
        this.$router.push({
          path: "/integral",
        });
        goTop();
      } else {
        if (itemid == 6) {
          this.$router.push({
            path: "/recommendedCashBack",
          });
        } else if (itemid == 9) {
          this.$router.push({
            path: "/integral?ismx=1",
          });
        } else if (itemid == 7) {
          this.$router.push({
            path: "/recharge",
          });
        } else {
          this.$router.push({
            path: "/person",
            query: {
              itemid,
            },
          });
        }

        goTop();
      }
    },
    resetForm() {
      this.$refs.tixianDialog.resetForm();
    },
    changeInfoFn() {
      this.changeInfoDialog = true;
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo);

    if (this.$route.query.itemid) {
      this.activeMenuId = this.$route.query.itemid;
    } else {
      this.activeMenuId = 0;
    }
    this.getTaskList();
    this.getAdByTag();
    this.getAccountInfo();
    this.getAiUserInfo();
  },
  watch: {
    $route: {
      //监听路由变化，路由改变触发函数
      handler: function (oldValue, newValue) {
        this.activeMenuId = this.$route.query.itemid;
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style scoped>
.con-main .leftMenu > li.activeLi:before {
  content: "";
  position: absolute;
  height: 40px;
  width: 4px;
  background-color: #e83233;
}

.con-main .leftMenu > li.activeLi > p,
.con-main .leftMenu > li:hover > p {
  color: #e83233;
}

/deep/ .tixianBox .el-dialog__header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: left;
  color: #fff;
  padding-left: 70px;
  box-sizing: border-box;
  background-image: linear-gradient(to right, #fa210d, #f6857d);
  display: flex;
  align-content: center;
}

/deep/ .tixianBox .el-dialog__header span {
  color: #fff;
}

.banner {
  height: 200px;
  /* background: url('../../assets/image/person/banner.png') 50%/cover; */
}

.banner .peoDetailBox {
  display: flex;
  align-items: center;
  width: 1400px;
  margin: 0 auto;
}

.banner .peoDetailBox img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}

.banner .peoDetailBox .infoBox {
  margin-right: 20px;
}

.banner .peoDetailBox .infoBox p:nth-of-type(1) {
  font-weight: 600;
  line-height: 30px;
  color: #fff;
}

.banner .peoDetailBox .infoBox p:nth-of-type(2) {
  font-size: 14px;
  color: #fff;
}

.con-main {
  width: 1400px;
  margin: 50px auto;
  overflow: hidden;
}

.con-main .leftMenu {
  float: left;
  width: 20%;
  max-width: 200px;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
}

.con-main .leftMenu > li {
  cursor: pointer;
  margin-top: 10px;
}

.con-main .leftMenu > li > p {
  font-size: 18px;
  font-weight: 600;
  padding: 0px 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
}

.con-main .leftMenu .iconfont {
  margin-right: 10px;
}

.con-main .leftMenu > li > .secondMenuList > .secondMenu {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
}

.con-main .leftMenu > li > .secondMenuList > .secondMenu.activeMenu {
  color: #fff;
  background-color: #ee3a3b;
}

.con-main .leftMenu .secondMenuList .secondMenu:hover,
.con-main .rightContent .menuList > li:hover {
  color: #ee3a3b;
}

.con-main .leftMenu li:hover svg path,
.con-main .leftMenu .activeLi svg path {
  fill: #ee3a3b;
}

.con-main .rightContent {
  width: calc(80% - 20px);
  float: left;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
}

.con-main .rightContent .menuList {
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.con-main .rightContent .menuList > li {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  margin: 0 30px 0 0;
  cursor: pointer;
}

.con-main .rightContent .menuList > li.activeLi {
  color: #fff;
  background-color: #ff1414;
  border-radius: 5px;
}

.con-main .rightContent .contentUl {
  display: flex;
  align-content: center;
  justify-content: space-between;
  background-color: #f8f8fa;
  padding: 0 0 20px 0;
}

.con-main .rightContent .contentUl li {
  width: 32%;
  border-radius: 10px;
  padding: 40px 50px;
  box-sizing: border-box;
}

.con-main .rightContent .contentUl li p {
  font-size: 22px;
}

.con-main .rightContent .contentUl li p span {
  color: #ff1414;
  font-size: 28px;
}

.con-main .rightContent .contentUl li .btnBox {
  margin-top: 30px;
  display: flex;
}

.con-main .rightContent .contentUl li .btnBox button {
  border-radius: 5px;
  width: 100px;
  height: 40px;
  background-color: #e54437;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}

.con-main .rightContent .contentUl li:nth-of-type(1) {
  background-image: url("../../assets/image/person/yuee.png");
  background-size: 100% 100%;
}

.con-main .rightContent .contentUl li:nth-of-type(2) {
  background-image: url("../../assets/image/person/jifen.png");
  background-size: 100% 100%;
}

.con-main .rightContent .contentUl li:nth-of-type(3) {
  background-image: url("../../assets/image/person/quan.png");
  background-size: 100% 100%;
}

.con-main .rightContent .contentUl li:nth-of-type(4) {
  background-image: url("../../assets/image/person/quan.png");
  background-size: 100% 100%;
}

.con-main .rightContent .contentUl li .btnBox p {
  font-size: 20px;
  color: #999999;
}

.con-main .rightContent .testBox {
  border-radius: 10px;
}

.con-main .rightContent .testBox .testTit {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #f5f5f5;
}

.con-main .rightContent .testBox .testUl {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.con-main .rightContent .testBox .testUl li {
  padding: 20px 30px;
  display: flex;
  align-content: center;
  border: 1px solid #f1f1f1;
  margin-right: 10px;
  margin-bottom: 10px;
}

.con-main .rightContent .testBox .testUl li img {
  margin-right: 20px;
  width: 64px;
  height: 64px;
}

.con-main .rightContent .testBox .testUl li .jifenBox p span {
  color: 999999;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 100;
}

.con-main .rightContent .testBox .testUl li .jifenBox p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.con-main .rightContent .testBox .testUl li .jifenBox button {
  padding: 10px 15px;
  border: 1px solid #999;
  border-radius: 5px;
}

.con-main .rightContent .testBox .testUl li .jifenBox button span {
  color: #cfcfcf;
  margin-left: 5px;
}

@media only screen and (max-width: 1680px) {
  .con-main {
    width: 1200px;
  }

  .con-main .rightContent .testBox .testUl {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .con-main .rightContent .testBox .testUl li {
    padding: 20px 10px;
  }

  .banner .peoDetailBox {
    width: 1200px;
  }
}

@media only screen and (max-width: 1440px) {
  .con-main {
    width: 1000px;
  }

  .con-main .rightContent .testBox .testUl li {
    padding: 20px 10px;
  }

  .con-main .rightContent .testBox .testUl {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .con-main .case-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .banner .peoDetailBox {
    width: 1000px;
  }
}
</style>
