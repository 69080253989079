<template>
	<div class="info-small">
		<div class="head" @click="select">
			<img :src="itemData.profilePhoto" alt="">
			<span v-if="itemData.speakerType === 1">情绪</span>
		</div>
		<div class="info" @click="select">
			<p class="name">
				<span>{{itemData.nickName}}</span>
				<img v-if="itemData.svip" src="@/assets/image/aiDubbing/svip-icon.png" alt="">
			</p>
			<p class="tips">{{itemData.introduce}}</p>
			<p class="size">{{itemData.style.split(',').length}}种情绪</p>
		</div>
		<!--	播音人详情弹窗  -->
		<transition name="el-fade-in-linear">
			<dubbing-info-modal v-if="showModal" :buddingData="itemData" @close="close"/>
		</transition>
	</div>
</template>

<script>
import dubbingInfoModal from "@/components/aiDubbing/dubbingInfoModal.vue";
import {getSpeakerById} from "@/request/aiDubbing";
import {mapMutations} from "vuex";

export default {
	components: {dubbingInfoModal},
	props:{
		itemData:{
			type:Object,
			default:()=>{}
		}
	},
	data() {
		return {
			showModal: false,
			details:null,
		}
	},
	methods: {
		...mapMutations(['setCurrentSelectDubbing']),
		async select(){
			//this.showModal = true
			this.getDetails()
		},
		close(e){
			this.showModal = false
			e.stopPropagation()
		},
		async getDetails() {
			try {
				const { data } = await getSpeakerById({ id: this.itemData.id });
				this.details = data;
				if (!this.details.id) return;
				this.setCurrentSelectDubbing(this.details);
			} catch (e) {
				console.log(e);
			}
		},
	}
}
</script>

<style scoped lang="scss">
.info-small{
	background: #bf2e37;
	border-radius: 8px;
	padding: 15px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	max-height: 87px;
	cursor: pointer;
	&:hover{
		background: linear-gradient(270deg, #ff504e, #ff2d2d);
	}
}
.head{
	position: relative;
	width: 50px;
	height: 50px;
	border: 1px solid #fff;
	border-radius: 50%;
	margin-right: 12px;
	cursor: pointer;
	img{
		width: 50px;
		height: 50px;
		border-radius: 100%;
	}
	span{
		position: absolute;
		top: -8px;
		left: -8px;
		background: #ff0000;
		font-size: 10px;
		padding: 1px 2px;
		border-radius: 2px;
	}
}
.info{
	display: flex;
	flex-direction: column;
	.name{
		margin-bottom: 3px;
		span{
			font-weight: bold;
			margin-right: 8px;
		}
		img{
			width: 28px;
			height: 14px;
		}
	}
	.tips{
		font-size: 12px;
		margin-bottom: 3px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.size{
		font-size: 12px;
	}
}
</style>