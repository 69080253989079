<template>
  <div class="ai-dubbing">
    <div class="class-select">
      <div class="search-a">
        <dubbing-search
          @search="inputSearch"
          @searchChange="searchChange"
          @searchList="searchList"
        />
      </div>
      <div class="class-list">
        <dubbing-class
          ref="classList"
          @selectClass="selectClass"
          :selectData="form"
        />
      </div>
    </div>
    <div style="background: #f8f8fa; padding-bottom: 50px">
      <div class="dubbing-list">
        <div class="tips">
          <p>
            为您找到 <span>{{ dataTotal }}</span> 个发音人，共
            <span>{{ totalStyle }}</span> 种风格
          </p>
          <div class="sort-btn">
            <span
              :class="{ active: this.form.orderType === 1 }"
              @click="sortSet(1)"
              >默认排序</span
            >
            <span
              :class="{ active: this.form.orderType === 2 }"
              @click="sortSet(2)"
              >热门排序</span
            >
            <span
              :class="{ active: this.form.orderType === 3 }"
              @click="sortSet(3)"
              >最多收藏</span
            >
          </div>
        </div>
        <div class="list">
          <template v-if="voiceList.length === 0">
            <el-skeleton
              v-for="item in form.pageSize"
              :key="item"
              style="
                width: 282px;
                border-radius: 10px;
                margin-bottom: 16px;
                margin-right: 12px;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.04);
              "
              animated
            >
              <template slot="template">
                <el-skeleton-item
                  variant="rect"
                  style="width: 282px; height: 154px"
                />
                <div
                  style="
                    padding: 12px;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <el-skeleton-item
                      variant="p"
                      style="width: 48px; height: 22px; margin-right: 10px"
                    />
                    <el-skeleton-item
                      variant="p"
                      style="width: 48px; height: 22px; margin-right: 10px"
                    />
                    <el-skeleton-item
                      variant="p"
                      style="width: 48px; height: 22px"
                    />
                  </div>
                  <el-skeleton-item
                    variant="p"
                    style="width: 30px; height: 22px"
                  />
                </div>
                <div
                  style="
                    padding: 14px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <el-skeleton-item
                    variant="p"
                    style="width: 100px; height: 30px"
                  />
                  <el-skeleton-item
                    variant="p"
                    style="width: 30px; height: 30px"
                  />
                </div>
              </template>
            </el-skeleton>
          </template>
          <dubbing-list
            v-else
            v-for="item of voiceList"
            :key="item.id"
            :itemData="item"
          />
        </div>
        <div class="pagination">
          <pagination
            ref="pagination"
            :totalPage="totalPage"
            :limit="form.pageSize"
            @changePage="changePage"
          />
        </div>
      </div>
    </div>
    <!--	播音人详情弹窗-这里值用与分享来的链接展示主播  -->
    <transition name="el-fade-in-linear">
      <dubbing-info-modal
        v-if="showModal"
        :buddingData="shareitem"
        @close="showModal = false"
      />
    </transition>
  </div>
</template>

<script>
import dubbingSearch from "./components/dubbingSearch.vue";
import dubbingClass from "./components/dubbingClass.vue";
import dubbingList from "./components/dubbingList.vue";
import { getAIdubbingAuth, voicePageList } from "@/request/aiDubbing";
import Pagination from "@/components/pagination.vue";
import { mapActions } from "vuex";
import dubbingInfoModal from "@/components/aiDubbing/dubbingInfoModal.vue";

export default {
  components: {
    dubbingInfoModal,
    Pagination,
    dubbingSearch,
    dubbingClass,
    dubbingList,
  },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 25,
        orderType: 1,
      },
      totalPage: 0,
      dataTotal: 0,
      totalStyle: 0,
      voiceList: [],
      showModal: false,
      shareitem: {},
    };
  },
  async mounted() {
    //await this.getToken();
    try{
	    await this.getAiUserInfo();
    }catch (e) {
	    console.log(e)
    }
    await this.searchData();
    // 获取路由参数
    const { id } = this.$route.query;
    if (id) {
      this.shareitem = { id };
      this.showModal = true;
    }
  },
  methods: {
    ...mapActions(["getAiUserInfo"]),
    inputSearch(text) {
      if (text === "") {
        delete this.form.keyword;
      } else {
        this.form.keyword = text;
      }
      this.searchData();
    },
    searchChange(text) {
      if (text === "") {
        delete this.form.keyword;
      } else {
        this.form.keyword = text;
      }
    },
    searchList(data) {
      if (data.list && data.list.length) {
        this.$refs.classList.clearSelect();
        this.dataTotal = data.total;
        this.totalStyle = data.totalStyle;
        this.totalPage = data.pages;
        this.voiceList = data.list;
      }
    },
    sortSet(type) {
      this.form.pageNum = 1;
      this.$refs.pagination.current = 1;
      this.$set(this.form, "orderType", type);
      this.searchData();
    },
    async getToken() {
      try {
        const { data } = await getAIdubbingAuth();
        if (data.token) {
          localStorage.setItem("Authorization", data.token);
          await this.searchData();
        }
      } catch (e) {
        console.log(e);
      }
    },
    selectClass({ mainItem: mainClass, classItem: childClass }) {
      this.form.pageNum = 1;
      this.$refs.pagination.current = 1;
      switch (mainClass.type) {
        case 1:
          if (childClass.value === "") {
            delete this.form.scopesName;
            break;
          }
          this.form.scopesName = childClass.value;
          break;
        case 2:
          if (childClass.value === "") {
            delete this.form.language;
            break;
          }
          this.form.language = childClass.value;
          break;
        case 3:
          if (childClass.value === "") {
            delete this.form.style;
            break;
          }
          this.form.style = childClass.value;
          break;
        case 4:
          if (childClass.value === "") {
            delete this.form.timbreType;
            break;
          }
          this.form.timbreType = childClass.value;
          break;
        case 5:
          if (childClass.value === "") {
            delete this.form.gender;
            break;
          }
          this.form.gender = childClass.value;
          break;
        case 6:
          if (childClass.value === "") {
            delete this.form.svip;
            break;
          }
          this.form.svip = childClass.value;
          break;
      }
      this.searchData();
    },
    async searchData() {
      try {
        this.voiceList = []
        const { data } = await voicePageList(this.form);
        if (data && data.list) {
          this.dataTotal = data.total;
          this.totalStyle = data.totalStyle;
          this.totalPage = data.pages;
          this.voiceList = data.list;
        }
      } catch (e) {
        console.log(e);
      }
    },
    changePage(page) {
      this.form.pageNum = page;
      this.searchData();
    },
  },
};
</script>

<style scoped lang="scss">
.ai-dubbing {
}
.class-select {
  //background: url("@/assets/image/aiDubbing/search-bg.png") no-repeat center;
  background: linear-gradient(#fee9e9, #ffffff 50%);
  background-size: 100% 100%;
  padding: 0 20px;
}
.search-a {
  padding: 60px 0;
}
.class-list {
  display: flex;
  justify-content: center;
  padding: 0 0 50px;
}
.dubbing-list {
  padding: 45px 20px 0;
  max-width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;

  .tips {
    display: flex;
    align-items: center;
    p {
      flex: 1;
      font-size: 14px;
      margin-bottom: 36px;
      color: #010101;
      span {
        font-size: 22px;
        color: #fd2c01;
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
  }
}
.sort-btn {
  span {
    padding: 0 14px;
    display: inline-block;
    height: 36px;
    background: #fff;
    border: 1px solid #bfbfbf;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    border-radius: 6px;
    margin-left: 16px;
    cursor: pointer;

    &.active {
      color: #fff;
      border: 1px solid #ed4f42;
      background: #ed4f42;
	    &:hover{
		    background: linear-gradient(270deg, #ff504e, #ff2d2d);
	    }
    }
  }
}
.pagination {
  padding-top: 30px;
}
</style>
