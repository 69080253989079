import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store/store"
// import Home from "@/views/text.vue";
// 配音老师
import dubbingTeacher from "@/views/dubbingTeacher/dubbingTeacher.vue";
// 配音老师详情
import teacherDetail from "@/views/dubbingTeacher/teacherDetail.vue";
// 样音试听
import sampleAudition from "@/views/sampleAudition/sampleAudition.vue";
// 配音案例
import dubbingCase from "@/views/dubbingCase/dubbingCase.vue";
// 人工翻译
import humanTranslation from "@/views/humanTranslation/humanTranslation.vue";
// 配音方案
import dubbingScheme from "@/views/dubbingScheme/dubbingScheme.vue";
//配音方案详情
import schemeDetail from "@/views/dubbingScheme/schemeDetail.vue";
// 关于我们
import about from "@/views/about/about.vue";
// 推荐返现
import recommendedCashBack from '@/views/recommendedCashBack/recommendedCashBack.vue'
// 配音充值
import recharge from '@/views/recharge/recharge.vue'
// 积分好礼
import integral from '@/views/integral/integral.vue'
// 积分兑换详情
import integralDetail from '@/views/integral/integralDetail.vue'
// 个人中心
import person from '@/views/person/person.vue'
// AI配音
import aiDubbing from "@/views/aiDubbing/index.vue"
import buddingEditor from "@/views/aiDubbing/buddingEditor.vue"
import editorPage from "@/views/aiDubbing/editorPage.vue"
import soundList from "@/views/aiDubbing/soundList.vue";
import draftList from "@/views/aiDubbing/draftList.vue";
import compoundList from "@/views/aiDubbing/compoundList.vue";
const originalPush = VueRouter.prototype.push

// 页面跳转，路径重复不报错
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/dubbingTeacher",
        name: "dubbingTeacher",
        component: dubbingTeacher
    },
    {
        path: "/dubbingTeacher/teacherDetail",
        name: "teacherDetail",
        component: teacherDetail
    },
    {
        path: "/dubbingCase",
        name: "dubbingCase",
        component: dubbingCase,
    },
    {
        path: "/sampleAudition",
        name: "sampleAudition",
        component: sampleAudition,
    },
    {
        path: "/humanTranslation",
        name: "humanTranslation",
        component: humanTranslation,
    },
    {
        path: "/dubbingScheme",
        name: "dubbingScheme",
        component: dubbingScheme,
    },
    {
        path: "/dubbingScheme/schemeDetail",
        name: "schemeDetail",
        component: schemeDetail,
    },
    {
        path: "/about",
        name: "about",
        component: about,
    },
    {
        path: "/recommendedCashBack",
        name: "recommendedCashBack",
        component: recommendedCashBack,
    },
    {
        path: "/recharge",
        name: "recharge",
        component: recharge,
    },
    {
        path: "/integral",
        name: "integral",
        component: integral,
    },
    {
        path: "/integral/integralDetail",
        name: "integralDetail",
        component: integralDetail,
    },
    {
        path: "/person",
        name: "person",
        component: person,
    },
    {
        path: "/aiDubbing",
        name: "aiDubbing",
        component: aiDubbing,
    },
    {
        path: "/buddingEditor",
        name: "buddingEditor",
        component: buddingEditor,
        children: [
            {
                path: "/",
                name: "editorPage",
                component: editorPage,
                meta: {
                    keepAlive: true
                }
            },
            {
                path: "/soundList",
                name: "soundList",
                component: soundList,
            },
            {
                path: "/draftList",
                name: "draftList",
                component: draftList,
            },
            {
                path: "/compoundList",
                name: "compoundList",
                component: compoundList,
            }
        ]
    },
];

const router = new VueRouter({
    routes,
});
router.afterEach((to, from) => {
    if(to.path){
        store.commit('setCurrentPath', to.path)
    }
    if (from.path == '/sampleAudition' || from.path == '/dubbingCase' || from.path == '/dubbingTeacher') {
        console.log('路由跳转完成', from);
        sessionStorage.removeItem('keyword');
    }
    // 执行事件
});

export default router;