<template>
  <div class="py-select" :style="{top: `${top}px`, left:`${left}px`}" @click.stop>
    <span>指定发音</span>
    <span class="item" v-for="(item,index) of pinyinList" :key="index" @click="selectPinYin(item)">{{item}}</span>
    <span class="custom" @click="showCustom = !showCustom">自定义发音</span>
    <div class="custom-form" v-if="showCustom">
      <p class="title">自定义发音</p>
      <p class="input-item">
        <span>拼音</span>
        <input type="text" v-model="py"/>
        <span>声调</span>
        <select v-model="tone">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </p>
      <div class="tips">
        <p>1,2,3,4为标准声调，5为轻声调</p>
        <p>例、记载(zǎi),系统可能读作记载(zài).可以自定义拼音中输入zai,在声调中选择3</p>
        <p>例、干(gān)净,系统可能读作干(gàn)净.可以自定义拼音中输入gan在声调中选择1</p>
      </div>
      <p class="btns">
        <editor-button bg-color="#ccc" style="margin-right: 10px" @click="cancel">取 消</editor-button>
        <editor-button bg-color="#fdeded" @click="confirm">确 定</editor-button>
      </p>
    </div>
  </div>
</template>

<script>
import Toast from 'vue-toast-mobile';
require("vue-toast-mobile/lib/index.css")
import EditorButton from "@/components/Editor/editorButton.vue";

export default {
  components: {EditorButton},
  props:{
    top: {
      type:Number,
      default:0
    },
    left: {
      type:Number,
      default:0
    },
    pinyinList:{
      type: Array,
      default:()=>[]
    }
  },
  data(){
    return {
      showCustom:false,
      selectPy:'',
      py:'',
      tone:''
    }
  },
  methods:{
    cancel(){
      this.$emit('cancel')
    },
    confirm(){
      if(!this.py || !this.tone) return
      const check = this.isPossiblePinyin(this.py)
      if(!check) {
        Toast('不是正确的拼音')
        return
      }
      this.$emit('selectPy', `${this.py} ${this.tone}`)
    },
    selectPinYin(item){
      this.selectPy = item
      this.$emit('selectPy', item)
    },
    isPossiblePinyin(str) {
      const pinyinRegex = /^[a-zA-Züéèáòóúìíǔùēēńňǖǘǚǜāáǎàēéěèīíǐìōóǒòūúǔùūǖǘǚǜüāáǎàēéěèīíǐìōóǒòūúǔùǖǘǚǜv]+$/;
      return pinyinRegex.test(str);
    }
  }
}
</script>

<style scoped>
.py-select{
  position: fixed;
  background: #fff;
  padding: 15px 8px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ddd;
  min-width: 280px;
  font-size: 14px;
  z-index: 10;
}
.item{
  padding: 5px 10px;
  background: #ddd;
  border-radius: 3px;
  margin: 0 10px;
  cursor: pointer;
}
.custom{
  background: #fdeded;
  padding: 5px 10px;
  margin-left: 50px;
  cursor: pointer;
  border-radius: 3px;
}
.custom-form{
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.title{
  margin-bottom: 10px;
  font-weight: bold;
}
.input-item{
  display: flex;
  align-items: center;
}
.input-item span{
  padding-right: 10px;
}
.input-item input,.input-item select{
  margin-right: 20px;
  border: 1px solid #666;
  width: 70px;
  height: 20px;
  border-radius: 3px;
}
.input-item select{
  height: 25px;
}

.tips{
  margin-top: 10px;
  font-size: 12px;
}
.tips p{
  line-height: 20px;
  color: #666;
}
.btns{
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
</style>