<template>
	<div class="openContent">
			<p style="font-size: 26px;color: #fff;">扫码添加配音客服微信</p>
			<p style="font-size: 36px;color: #fff;font-weight: bold;margin-top: 10px;">配音就是要找对人</p>
			<img style="width: 240px;height: 240px;background: #fff; margin-top: 20px;" :src="kefuObj.code" alt="">
			<div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
				<div style="text-align: center;">
					<img style="width: 40px;height: 40px;" src="../../public/img/icons/openIcon01.png" alt="">
					<p style="font-size: 16px;color: #fff;font-weight: bold;margin-top: 6px;">客服1V1</p>
				</div>
				<div style="text-align: center;">
					<img style="width: 40px;height: 40px;" src="../../public/img/icons/openIcon02.png" alt="">
					<p style="font-size: 16px;color: #fff;font-weight: bold;margin-top: 6px;">免费试音</p>
				</div>
				<div style="text-align: center;">
					<img style="width: 40px;height: 40px;" src="../../public/img/icons/openIcon03.png" alt="">
					<p style="font-size: 16px;color: #fff;font-weight: bold;margin-top: 6px;">多重优惠</p>
				</div>
				<div style="text-align: center;">
					<img style="width: 40px;height: 40px;" src="../../public/img/icons/openIcon04.png" alt="">
					<p style="font-size: 16px;color: #fff;font-weight: bold;margin-top: 6px;">全天在线</p>
				</div>
			</div>
	</div>
</template>

<script>
	export default{
		props:['kefuObj'],
		data(){
			return{
				
			}
		},
		
	}
</script>

<style>
	.customclass {
	  border-radius: 20px !important;
	  padding: 0 !important;
	  background-color: transparent !important;
	  border:none !important;
	}
	.el-dialog__wrapper {
	  z-index: 9999 !important;  /* 修改为更高的层级 */
	}
	.kefuBox .el-dialog__body {
	  border-radius: 20px !important;
	  padding: 0 !important;
	  background-color: transparent;
	}
</style>