<template>
	<div id="content" class="content sound-content">
		<div class="sound-top-box">
			<div class="sound-top">
				<!-- <div class="page-bread-crumbs">
					<i class="iconfont icondingwei"></i>
					<a href="/" class="crumb link nuxt-link-active">首页</a>
					<a href="javaScript:voide(0)" class="crumb link nuxt-link-active">配音案例</a>
				</div> -->

				<div class="filter-and-sort-wrap">
					<div class="filter-and-sort-item" v-for="(item,index) in labelList" :key='index'>
						<div :class="labelListHeight[index].class" ref='filter_item'>
							<a aria-current="page" @click="sellabel2(item,index)" class="item-name"
								:class="selTrTypeList[index].length>0?'':' nuxt-link-exact-active nuxt-link-active active'"
								style="border-radius: 10px;">{{item.name}}</a>
							<a class="item-name" style="border-radius: 10px;" @click="sellabel(label,index)"
								:class="JSON.stringify(tagsList).indexOf(JSON.stringify(label))>=0?' nuxt-link-exact-active nuxt-link-active active':''"
								v-for='label in item.child'>{{label.name}}</a>
						<!-- 	<span style='position: absolute;
								right: 0px;top:0px;bottom: 0px;margin: auto;cursor: pointer;color:#ea4335'
								@click='changeHeight(index)'>更多</span> -->
						</div>
					</div>

					<div class="filter-and-sort-item">
						<div class="item-name-box">
							<a href="avaScript:voide(0)" aria-current="page" style="border-radius: 10px;"
								:class="orderType == 0 ?'item-name nuxt-link-exact-active nuxt-link-active active' :'item-name nuxt-link-exact-active'"
								@click="orderType = 0;getAudioCase()">综合排序</a>
							<a href="avaScript:voide(0)" style="border-radius: 10px;" class="item-name"
								:class="orderType == 1?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 1;getAudioCase()">热门</a>
							<a href="avaScript:voide(0)" style="border-radius: 10px;" class="item-name"
								:class="orderType == 2?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 2;getAudioCase()">最新</a>
							<a href="avaScript:voide(0)" style="border-radius: 10px;" class="item-name"
								:class="orderType == 3?'nuxt-link-exact-active nuxt-link-active active':''"
								@click="orderType = 3;getAudioCase()">价格</a>
							<div class="teachOnline">
								<!-- -->
								<div style="margin-right: 5px;">当前在线</div>
								<el-switch v-model="on_line" @change='onlineChange' active-color="#ff4949"
									inactive-color="#ccc">
								</el-switch>
							</div>
						</div>
						<!-- <div class="other-entr main-cen">
							在线老师
							<el-switch v-model="on_line" @change='onlineChange' active-color="#ff4949"
								inactive-color="#ccc">
							</el-switch>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="selected-tags-wrap-Box">
			<div class="selected-tags-wrap flex">
				<div class="leftBox">
					<p>
						为您找到{{totalPage}}个配音案例
					</p>
					<el-tag class="text-overflow" v-for="(tag,index) in tagsList" :key="tag.id" closable
						:type="tag.type" @close='delTag(index)'>
						{{tag.name}}
					</el-tag>
				</div>
				<button class="btn-clear" @click="delAllDef()">清空标签</button>
			</div>
		</div>
		<div class="con-main">
			<div class="sound-main authorize pr">
				<ul class="case-list">
					<template v-for="(item,index) in caseList">
						<li>
							<a href="javaScript:void(0)" class="cover" @mouseenter="startVideo(index)"
								@mouseleave="stopVideo(index)" :style="{'background-image':'url(' + item.img + ')'}">
								<!-- <video :src="item.video" @click="playVideoSrc=item.video;videoBox=true"
									ref="videoPlayer" muted="muted" loop="loop" class="album-video"
									style="opacity: 1;"></video> -->
								<video :src="item.video" @click="playAudio(item.video)" ref="videoPlayer" muted="muted" preload="none"
									loop="loop" class="album-video"></video>
							</a>
							<a href="javaScript:void(0)" class="title">
								<p class="company text-overflow">{{item.name}}</p>
								<!-- <p class="title text-overflow">{{item.name}}</p> -->
							</a>
							<div class="music">
								<p class="text-overflow" @click="gotoTeacherDetail(item)">配音老师：
									<a href="javaScript:void(0)">{{item.nickname}}</a>
								</p>
								<i @click="shoucangDef(item)"
									:class="item.is_collect == 0?likeIconfont:likeIconfontShouCang"></i>
							</div>
							<div class="make-box">
								<button class="btn-download" @click="showKefu(item)">做同款</button>
							</div>
						</li>
					</template>
				</ul>
			</div>
			<div class="paging-box qt-pagination" v-if='totalPage >0'>
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
						:page-size="page.limit" :current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>


			</div>
			<div style="text-align: center;" v-else-if='totalPage == 0'>
				<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
				<p style="text-align: center;color: #999;">暂无符合条件的筛选结果，请尝试更改筛选条件</p>
			</div>
		</div>

		<el-dialog :visible.sync="videoBox" @close='videoClose' class="videoDialog" width="55.5%" height='50%'>
			<video :src="playVideoSrc" loop="loop" autoplay controls class="album-video"
				style="width: 100%;display: block;height: 550px;background-color: #000;"
				ref='videoPlayer_dialog'></video>
		</el-dialog>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
		<rightAside :asideItemShowFlag='$store.state.asideItemShowFlag'></rightAside>
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import goTop from '../../utils/goTop.js'
	import rightAside from "@/components/index/rightAside.vue";
	export default {
		components: {
			teacherDialog,
			rightAside
		},
		data() {
			return {
				audio_idClass: 'footer-wavesurfer tran',
				play_button_wrapper_i: 'iconfont iconweibiaoti-2',
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				pagePrev: true,
				pageNext: false,
				totalPage: null,
				page: {
					currentPage: 1,
					limit: 25,
				},
				labelList: [],
				labelListHeight: [],
				caseList: [],
				tagsList: [], //选中的标签列表
				keyword: '',
				on_line: false,
				orderType: 0,
				videoBox: false,
				playVideoSrc: '',
				kefuObj: {
					qq: '',
					wx: ''
				},
				kefuBoxShow: false,
				selTrTypeList: []
			}
		},
		mounted() {

			if (sessionStorage.getItem('keyword')) {
				this.keyword = sessionStorage.getItem('keyword')
				this.tagsList.push({
					id: 0,
					name: this.keyword
				})
			}
			this.getLable()
		},
		methods: {
			playAudio(video) {
				this.playVideoSrc = video;
				this.videoBox = true;
				var that = this;
				setTimeout(function() {
					console.log(that.$refs, that.$refs.videoPlayer_dialog);
					that.$refs, that.$refs.videoPlayer_dialog.player.muted(false);
					that.$refs, that.$refs.videoPlayer_dialog.player.play();
				}, 10)

				// this.$refs.videoPlayer.player.muted(false);
				// this.$refs.videoPlayer.player.play();
			},
			gotoTeacherDetail(item) {
				console.log(item.teacher_id);
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: item.teacher_id
					}
				})
				goTop();
			},
			showKefu(item) {
				console.log(item);
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data;
						this.kefuObj.headimgurl = item.headimgurl;
						this.kefuObj.nickname = item.nickname;
						this.kefuObj.on_line = item.on_line;
						this.kefuBoxShow = true
					})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }

			},
			delAllDef() {
				this.selTrTypeList = []
				for (let i = 0; i < this.labelList.length; i++) {
					this.selTrTypeList.push([])
				}
				this.tagsList = [];
				this.getAudioCase()
			},
			videoClose() {
				this.playVideoSrc = ''
			},
			onlineChange(e) {
				console.log(e)
				this.on_line = e
				this.page.currentPage = 1
				this.getAudioCase()
			},
			// 收藏
			shoucangDef(item) {
				let data = {
					id: item.id,
					type: 2,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					if (sessionStorage.getItem('taskid') && item.is_collect == 0) {
						this.$toTask()
					}
					this.getAudioCase()
				})
			},

			sellabel2(item, index) {
				this.page.currentPage = 1;
				for (let i = 0; i < item.child.length; i++) {
					for (let k = 0; k < this.tagsList.length; k++) {
						if (JSON.stringify(item.child[i]).indexOf(JSON.stringify(this.tagsList[k])) >= 0) {
							this.selTrTypeList[index].splice(0, 1)
							this.tagsList.splice(k, 1)
						}
					}
				}
				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				if (typeids != '') {
					typeids = '?typeLis=' + typeids
				}
				const newUrl = '#' + this.$route.path + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)
				this.getAudioCase()
			},
			// 选择标签
			sellabel(item, index) {
				this.page.currentPage = 1;
				if (this.selTrTypeList[index].length > 0) {
					for (let i = 0; i < this.tagsList.length; i++) {
						if (this.tagsList[i].id == this.selTrTypeList[index][0]) {
							this.tagsList.splice(i, 1)
						}
					}
					this.selTrTypeList[index] = []
					this.selTrTypeList[index].push(item.id)
					this.tagsList.push(item)
				} else {
					this.selTrTypeList[index].push(item.id)
					this.tagsList.push(item)
				}

				// console.log(item)
				// let isDel = 0

				// for(let i = 0;i<this.tagsList.length;i++){
				// 	if(item.id == this.tagsList[i].id){
				// 		this.selTrTypeList[index].splice(0,1)
				// 		this.tagsList.splice(i,1)
				// 		isDel = 1
				// 	}
				// }
				// this.page.currentPage = 1
				// if(isDel == 0){
				// 	this.selTrTypeList[index].push(1)
				// 	this.tagsList.push(item)
				// }

				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				const newUrl = '#' + this.$route.path + '?typeLis=' + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)

				this.getAudioCase()
			},
			// 删除标签
			delTag(index) {
				sessionStorage.removeItem('keyword')
				this.keyword = ''
				console.log(index)


				for (let i = 0; i < this.labelList.length; i++) {
					if (JSON.stringify(this.labelList[i]).indexOf(JSON.stringify(this.tagsList[index])) >= 0) {
						this.selTrTypeList[i].splice(0, 1)
					}
				}


				this.tagsList.splice(index, 1)


				let typeids = ''
				for (let i = 0; i < this.tagsList.length; i++) {
					if (i == 0) {
						typeids += this.tagsList[i].id
					} else {
						typeids = typeids + '_' + this.tagsList[i].id
					}
				}
				const newUrl = '#' + this.$route.path + '?typeLis=' + typeids
				console.log(newUrl)
				window.history.replaceState('', '', newUrl)

				this.getAudioCase()
			},
			getAllLable() {
				this.$post('/Label/allList', {}).then(res => {
					this.alllabelList = res.data

					console.log(window.location.href)
					let newtagsList = []
					if (window.location.href.indexOf('typeLis=') >= 0) {
						let typeLisStr = window.location.href.split('typeLis=')[1]
						if (typeLisStr != '') {
							if (typeLisStr.indexOf('_') == -1) {
								newtagsList.push({
									id: parseInt(typeLisStr),
									name: ''
								})
							} else {
								let typeArr = typeLisStr.split('_')
								for (let i = 0; i < typeArr.length; i++) {
									newtagsList.push({
										id: parseInt(typeArr[i]),
										name: ''
									})
								}
							}
						}
					}

					for (let i = 0; i < this.alllabelList.length; i++) {
						for (let k = 0; k < newtagsList.length; k++) {
							if (this.alllabelList[i].id == newtagsList[k].id) {
								newtagsList[k].name = this.alllabelList[i].name
							}
						}
					}
					this.tagsList = newtagsList

					for (let i = 0; i < this.labelList.length; i++) {
						for (let k = 0; k < this.labelList.length; k++)
							if (JSON.stringify(this.labelList[i]).indexOf(JSON.stringify(this.tagsList[k])) >= 0) {
								this.selTrTypeList[i].push(1)
							}
					}
					console.log(this.tagsList)
					this.getAudioCase()
				})
			},
			// 获取分类
			getLable() {
				var that = this;
				this.$post('/Label/index', {}).then(res => {
					console.log(res)
					this.labelList = res.data
					for (let i = 0; i < res.data.length; i++) {
						this.selTrTypeList.push([]);
						var obj = {
							height: '56px',
							class: 'item-name-box'
						}
						that.labelListHeight.push(obj);
					}
					setTimeout(function() {
						for (var i = 0; i < that.$refs.filter_item.length; i++) {
							if (that.$refs.filter_item[i].offsetWidth >= 1200) {
								console.log(i)
								that.labelListHeight[i].class = 'item-name-box item-name-box-hidden'
							} else {
								that.labelListHeight[i].class = 'item-name-box'
							}
						}
					}, 10)
					if (window.location.href.indexOf('typeLis=') >= 0) {
						this.getAllLable()
					} else {
						this.getAudioCase()
					}
				})
			},
			changeHeight(index) {
				console.log(index)
				this.labelListHeight[index].height = this.labelListHeight[index].height == "56px" ? "auto" : "56px";
				this.labelListHeight = JSON.parse(JSON.stringify(this.labelListHeight));
			},
			// 获取配音案例
			getAudioCase() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					if (this.tagsList[i].id != 0) {
						labelidStr.push(this.tagsList[i].id)
					}
				}
				console.log(11111, this.keyword)
				console.log(labelidStr)
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					label: labelidStr,
					keyword: this.keyword,
					on_line: this.on_line ? 1 : 0,
					order: this.orderType
				}
				this.$post('/Audio/audioCase', data).then(res => {
					console.log(res)
					this.caseList = res.data.list
					this.totalPage = res.data.count;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= this.totalPage / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}

				})
			},
			// 开始播放视频
			startVideo(index) {
				var that = this;
				this.$nextTick(function() {
					that.$refs.videoPlayer[index].play();
				})
			},
			stopVideo(index) {
				var that = this;
				setTimeout(() => {
					that.$refs.videoPlayer[index].pause();
					that.$refs.videoPlayer[index].currentTime = 0;
				}, 10)
			},
			gotoCaseDetail(caseId) {
				this.$router.push({
					path: '/dubbingScheme/schemeDetail',
					query: {
						caseId,
						iscase: 1
					}
				})
				goTop();
			},
			// 
			goMore() {
				this.$router.push({
					path: '/dubbingCase',
				})
				goTop();
			},
			// 配音老师详情页
			goDubbingTeacher(audioId) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: audioId
					}
				})
				goTop();
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getAudioCase()
			},
			changeLikeIconfont(flag, audio_Id) {
				console.log(flag, audio_Id)
				// 调用收藏接口，列表接口
			},

		}
	}
</script>

<style scoped>
	::-webkit-scrollbar{
		height: 5px;
	}
	.sound-content .con-main .case-list>li>.cover{
		background-color: transparent !important;
	}
	.item-name-box span {
		display: none;
	}

	.item-name-box-hidden span {
		display: block;
	}

	.filter-and-sort-item {
		position: relative;
		padding-right: 50px;
		box-sizing: border-box;
		overflow: hidden;
	}
	.teachOnline {
		margin-top: 3px;
		display: flex;
	}
	/deep/.videoDialog .el-dialog__body {
		padding: 0 !important;
	}

	/deep/.videoDialog .el-dialog__body video {
		display: block;
	}

	/* .dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	} */

	/* /deep/.dialogBox {
		border-radius: 50px 40px;
	} */
</style>
<style scoped>
	@import "../../assets/css/0731103.css";
	@import "../../assets/css/7de01d2-dT2.css";
	@import "../../assets/css/5774750-dT3.css";

	/deep/.el-dialog {
		background-color: transparent;
	}

	.sound-content .con-main {
		margin: 0 auto !important;
	}

	.sound-content .con-main .case-list>li>.cover {
		padding: 0;
	}

	.authorize {
		padding: 0;
	}

	.music i.icon-shoucang_shoucang {
		color: #ea4335 !important
	}

	.authorize {
		background-color: transparent !important;
	}

	.sound-content .con-main .case-list>li>.cover {}
</style>
