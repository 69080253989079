<template>
  <div class="select-handle">
    <p class="title">批量替换多音字 <span>第 {{nextIndex+1}} 处/共 {{totalMatches}} 处</span></p>
    <div class="btach-text">
      <input type="text" class="input-text" placeholder="请输入单个要替换的多音字" v-model="seachText"/>
      <span @click="searchTextHandle">查找</span>
      <span @click="switchText('previous')">上一个</span>
      <span @click="switchText('next')">下一个</span>
    </div>
    <template v-if="pinyinList.length && totalMatches">
      <div class="py-tab">
        <span :class="{active: tabActiveIndex === 0}" @click="tabTap(0)">选择拼音</span>
        <span :class="{active: tabActiveIndex === 1}" @click="tabTap(1)">自定义发音</span>
      </div>
      <div class="py-list" v-if="tabActiveIndex === 0">
        <span class="py-item" v-for="(item,index) of pinyinList" :key="index" :class="{active: selectPy===item}" @click="selectPyItem(item)">{{item}}</span>
      </div>
      <div class="custom-form" v-if="tabActiveIndex === 1">
        <p class="input-item">
          <span>拼音</span>
          <input type="text" v-model="py"/>
          <span>声调</span>
          <select v-model="tone">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </p>
        <div class="tips">
          <p>1,2,3,4为标准声调，5为轻声调</p>
          <p>例、记载(zǎi),系统可能读作记载(zài).可以自定义拼音中输入zai,在声调中选择3</p>
          <p>例、干(gān)净,系统可能读作干(gàn)净.可以自定义拼音中输入gan在声调中选择1</p>
        </div>
      </div>
    </template>
    <div class="btns">
      <span @click="hideSeach">取消</span>
      <span @click="replaceText">替换</span>
      <span @click="replacePYAll">全部替换</span>
    </div>
  </div>
</template>

<script>
import Toast from 'vue-toast-mobile';
require("vue-toast-mobile/lib/index.css")
import pinyin from "pinyin";

export default {
  name: 'batchReplacementModal',
  props:{
    nextIndex: {
      type: Number,
      default: 0
    },
    totalMatches: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      seachText:'',
      pinyinList: [],
      selectPy:'',
      tabActiveIndex:0,
      py:'',
      tone:'',
    }
  },
  methods: {
    tabTap(index){
      this.tabActiveIndex = index
    },
    selectPyItem(py){
      this.selectPy = py
    },
    searchTextHandle(){
      if(!this.seachText) return
      if(this.seachText.length > 1){
        Toast('请输入单个汉字')
        return
      }
      const getPY = pinyin(this.seachText, {heteronym: true})
      this.$emit('searchTextHandle', this.seachText)
      if (getPY && getPY.length) {
        this.pinyinList = getPY[0]
      }
    },
    switchText(text){
      this.$emit('switchTextHandle', text)
    },
    hideSeach(){
      this.$emit('hideSeach')
    },
    replaceText(){
     if(this.tabActiveIndex === 0){
       if(!this.selectPy){
         Toast('请选择要替换的拼音')
         return
       }
       this.$emit('replacePY', this.selectPy, this.seachText)
     }
     if(this.tabActiveIndex === 1){
       if(!this.py || !this.tone){
         Toast('请输入拼音和声调')
         return
       }
       this.$emit('replacePY', `${this.py} ${this.tone}`, this.seachText)
     }
    },
    replacePYAll(){
      if(this.tabActiveIndex === 0){
        if(!this.selectPy){
          Toast('请选择要替换的拼音')
          return
        }
        this.$emit('replacePYAll', this.selectPy, this.seachText)
      }
      if(this.tabActiveIndex === 1){
        if(!this.py || !this.tone){
          Toast('请输入拼音和声调')
          return
        }
        this.$emit('replacePYAll', `${this.py} ${this.tone}`, this.seachText)
      }
    }
  }
}
</script>

<style scoped>
.select-handle{
  width: 400px;
  background: #fff;
  position: absolute;
  top:65px;
  left: 430px;
  z-index: 99;
  border-radius: 6px;
  box-shadow: 0 0 5px #CD9044;
  padding: 20px 20px;
}
.title{
  font-size: 14px;
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 6px;
  border-bottom: 1px solid #f39c12;;
}
.title span{
  flex: 1;
  text-align: right;
  color: #333;
  font-size: 12px;
}
.btach-text{
  display: flex;
  margin-bottom: 10px;

}
.btach-text input{
  flex: 1;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #f39c12;
  padding:0 10px;
  user-select: none;
  outline: none;
  font-size: 14px;
}
.btach-text span{
  background: #f39c12;
  padding: 0 10px;
  margin-left: 10px;
  border-radius: 6px;
  color: #fff;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
}
.transform-text{
  display: flex;
  margin-bottom: 10px;

}
.transform-text input{
  flex: 1;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #f39c12;
  padding:0 10px;
  user-select: none;
  outline: none;
  font-size: 14px;
}
.transform-text span{
  height: 36px;
  border: 1px solid #f39c12;
  border-radius: 6px;
  margin-left: 10px;
  line-height: 36px;
  font-size: 12px;
  padding: 0 10px;

}

.transform-text span:hover{
  background: #f39c12;
  color: #fff;
  cursor: pointer;
}
.btns{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0;
  margin-top: 20px;
}
.btns span{
  font-size: 14px;
  padding: 8px 20px;
  background: #f39c12;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;

}
.btns span:nth-child(1){
  background: none;
  border: 1px solid #ccc;
  color: #000;
}
.btns span:nth-child(1):hover{
  background: #ccc;
}
.btns span:nth-child(2){
  margin: 0 10px;
  border: 1px solid #f39c12;
  background: none;
  color: #000;
}

.py-list{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.py-list .py-item{
  padding: 5px 10px;
  background: #fff;
  color: #000;
  border-radius: 6px;
  margin-right: 10px;
  border: 1px solid #999;
  cursor: pointer;
}
.py-list .py-item.active{
  background: #f39c12;
  color: #fff;
  border: 1px solid #f39c12;
}
.py-list .py-item:hover{
  background: #f39c12;
  color: #fff;
  border: 1px solid #f39c12;
}
.py-tab{
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #f39c12;
}
.py-tab span{
  flex: 1;
  background: #fff;
  padding: 6px 0;
  text-align: center;
  color: #000;
  font-size: 14px;
}
.py-tab span.active{
  background: #f39c12;
  color: #fff;
}

.custom-form{
  padding-top: 10px;
}
.custom-title{
  margin-bottom: 10px;
  font-weight: bold;
}
.input-item{
  display: flex;
  align-items: center;
}
.input-item span{
  padding-right: 10px;
}
.input-item input,.input-item select{
  margin-right: 20px;
  border: 1px solid #666;
  width: 70px;
  height: 20px;
  border-radius: 3px;
}
.input-item select{
  height: 25px;
}

.tips{
  margin-top: 10px;
  font-size: 12px;
}
.tips p{
  line-height: 20px;
  color: #666;
}
</style>