<template>
	<div class="integralDetail">
		<div class="page-bread-crumbs">
			<i class="iconfont icondingwei"></i>
			<a href="/" class="crumb link nuxt-link-active">首页</a>
			<a href="javaScript:voide(0)" @click="goToIntegral()" class="crumb link nuxt-link-active">积分商城</a>
			<a href="javaScript:voide(0)" class="crumb link nuxt-link-active">{{integralDetailInfo.goods_name}}</a>
		</div>
		<div class="topBox">
			<div class="imageBox">
				<el-image style="height: 425px;width: 100%;margin: 0 auto;border-radius: 10px;"
					:src="integralDetailInfo.img"></el-image>
			</div>
			<div class="topBox_right">
				<div class="infoBox">
					<p class="duihuanTit">{{integralDetailInfo.goods_name}}</p>
					<p class="grayBox duihuanjifen">
						兑换所需积分：<span>{{integralDetailInfo.integral}}</span>积分
					</p>
					<p class="kucun">今日库存：{{integralDetailInfo.stock}}件</p>
					<button class="duihuanBtn" @click="dialogVisible = true">立即兑换</button>
					<p class="duihuanInfo">
						兑换说明：商品数量有限，兑完为止
					</p>
				</div>
				<p class="xuzhiTit">兑换须知:</p>
				<p class="grayBox duihuanxuzhi">
					{{integralDetailInfo.tips}}
				</p>
			</div>
		</div>
		
		
		<el-dialog
		  title="提示"
		  :visible.sync="dialogVisible"
		  width="30%"
		  :before-close="handleClose">
		  <span>是否确认兑换？</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="duihuanFn()">确 定</el-button>
		  </span>
		</el-dialog>
		
		<div class="bottomBox">
			<p v-html="integralDetailInfo.detail"></p>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				integralDetailId:'',
				dialogVisible:false,
				integralDetailInfo:{}
			}
		},
		methods:{
			
			goodsInfo(){
				let data  = {
					id:this.integralDetailId
				}
				this.$post('/IntegralShop/goodsInfo',data).then(res=>{
					this.integralDetailInfo = res.data
				})
			},
			// 返回积分
			goToIntegral(){
				this.$router.push({
					path: '/integral',
				})
			},
			duihuanFn(){
				let data  = {
					id:this.integralDetailId
				}
				this.$post('/IntegralShop/exchange',data).then(res=>{
					this.dialogVisible = false 
					this.$message({
					    message: '兑换成功',
					    type: 'success'
					});
				})
				
			}
		},
		mounted(){
			this.integralDetailId = this.$route.query.integralDetailId;
			this.goodsInfo()
			console.log(this.integralDetailId);
		}
	}
</script>

<style scoped>
	.page-bread-crumbs,.topBox,.bottomBox{
		width: 1400px;
		margin: 20px auto;
	}
	.topBox,.bottomBox{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		display: flex;
		align-items: center;
	}
	.bottomBox{
		padding:40px 20px;
		display: block;
	}
	.topBox .topBox_right{
		flex: 1;
		margin-left: 30px;
	}
	 .topBox .imageBox {
		padding: 5px;
		background-color: #f4f4f4;
		border-radius: 10px;
	}
	/deep/ .topBox .imageBox .el-image__inner {
		transition: all .3s;
		cursor: pointer;
	}
	
	/deep/ .topBox .imageBox .el-image__inner:hover {
		transform: scale(1.03);
	}
	.topBox .topBox_right .infoBox .duihuanTit{
		line-height: 50px;
		font-size: 22px;
		font-weight: 600;
		margin-bottom:20px ;
	}
	.topBox .topBox_right .grayBox{
		background-color:#f8f8fa ;
		border-radius: 10px;
		padding:20px 30px ;
	}
	.topBox .topBox_right .infoBox .duihuanjifen{
		color:#999999;
		font-size: 14px;
	}
	.topBox .topBox_right .infoBox .duihuanjifen span{
		color:#fd4340;
		font-size: 20px;
		font-weight: 600;
		margin: 0 5px;
	}
	.topBox .topBox_right .infoBox .kucun{
		margin: 20px 0 30px 30px;
		color:#999999;
		font-size: 14px;
	}
	.topBox .topBox_right .infoBox .duihuanBtn{
		width:240px ;
		height: 70px;
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		line-height: 70px;
		margin: 0 0 0 20px;
		background-color: #fd4340;
		border-radius: 10px;
		text-align: center;
		transition: all .3s;
	}
	.topBox .topBox_right .infoBox .duihuanBtn:hover{
		transform: scale(1.03);
	}
	.topBox .topBox_right .infoBox .duihuanInfo{
		margin: 20px 0px 30px 20px;
		color:#999999;
		font-size: 14px;
	}
	.topBox .topBox_right .infoBox{
		border-bottom: 1px solid #f5f5f5;
	}
	.topBox .topBox_right .xuzhiTit{
		font-size: 18px;
		font-weight: 600;
		line-height: 60px;
		height: 60px;
	}
	.bottomBox .infoTit{
		font-size: 20px;
		font-weight: 600;
		
	}
	.bottomBox .infoSecondTit{
		font-size: 18px;
		font-weight: 600;
		margin: 20px 0;
	}
	.bottomBox ul li{
		margin: 10px 0;
	}
	@media only screen and (max-width: 1680px) {
		.page-bread-crumbs,.topBox,.bottomBox {
			width: 1200px;
		}
	}
	
	@media only screen and (max-width:1440px) {
		.page-bread-crumbs,.topBox,.bottomBox {
			width: 1000px
		}
	}
</style>