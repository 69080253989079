<template>
	<div id="content2" ref="center">
		<div class="banner" :style="{'background': 'url('+bannerImg+') 50%/cover'}">
			<!-- <p class="bannerTit" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">
				甄选<span>200+</span>顶级声优</p>
			<p class="bannerP" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">只为一次满意的交付</p> -->
			<!-- <div>
				<button data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine" @click="connectFn()">马上参与</button>
			</div> -->
		</div>
		<div class="recharge" v-if="rechargeList.length>0">
			<div class="tit">充值最高可额外获得10%</div>
			<ul class="rechargeList">
				<template v-for="(item,index) in rechargeList">
					<li :class="activeListIndex ==index ? 'activeRechargeList':''" @click="changeRecharge(index)">
						<p class="firstP">充值{{item.money}}元</p>
						<p class="secondP">送<span>{{item.give}}</span>元</p>
					</li>
				</template>


			</ul>
			<div class="rechargeBox">
				<div class="payBox">
					<div class="pay">
						<button @click="loginDialogFn()" v-show="!islogin">立即登录</button>
						<!-- <img src=""/> -->
						<div v-show="islogin" id="qrCode" ref="qrCodeDiv" style="margin: 0 auto;"></div>
						<!-- <img v-show="islogin" style=" cursor: pointer;" @click="payDef(2)" src="../../assets/image/dubbingteacher/wx.png"/> -->
						<p>微信支付</p>
					</div>
					<div class="centerBox">
						<p class="firstP">应付金额</p>
						<p class="secondP">￥<span>{{rechargeList[activeListIndex].price}}</span></p>
					</div>
					<div class="pay">
						<button @click="loginDialogFn()" v-show="!islogin">立即登录</button>
						<iframe v-show="islogin" :srcdoc="alipayForm" frameborder="0" marginwidth="0" marginheight="0"
							scrolling="no" width="150" height="150">
						</iframe>
						<!-- <img v-show="islogin" style="margin-right: 40px; cursor: pointer;" @click="payDef(1)" src="../../assets/image/dubbingteacher/zhifubao.png"/> -->
						<p>支付宝支付</p>
					</div>
				</div>
			</div>
		</div>
		<div class="peiyinBox">
			<div class="peiyin">
				<div class="tit">真人配音</div>
				<ul class="peiyiUl">
					<template v-for="item in peiyinList">
						<li>
							<img :src="item.p_pic" />
							<div class="content">
								<p class="peiyinTit">{{item.p_name}}</p>
								<p class="peiyinContent">{{item.p_cont}}</p>
							</div>
						</li>
					</template>
				</ul>
			</div>
		</div>
		<div class="voiceBox">
			<div class="tit">为1000万用户提供配音服务</div>
			<div class="secondTit">众多知名企业选择声探配音</div>
			<div class="allLogoBox" style="width: 100%;margin: 10px auto;">
				<template v-for="item in custPicList1">
					<a :href='item.ad_url' target="_blank">
						<el-image fit="contain" :src="item.ad_pic"></el-image>
					</a>
				</template>
			</div>
			<div class="allLogoBox" style="width: 100%;margin: 10px auto;">
				<template v-for="item in custPicList2">
					<a :href='item.ad_url' target="_blank">
						<el-image fit="contain" :src="item.ad_pic"></el-image>
					</a>
				</template>
			</div>
			<div class="allLogoBox" style="width: 100%;margin:10px auto;">
				<template v-for="item in custPicList3">
					<a :href='item.ad_url' target="_blank">
						<el-image fit="contain" :src="item.ad_pic"></el-image>
					</a>
				</template>
			</div>

		</div>
		<div class="problemBox">
			<div class="problem">
				<div class="tit">常见问题及回答</div>
				<ul class="problemUl">
					<template v-for="(item,index) in problemList">
						<li class="animated fadeInLeft">
							<p class="peiyinTit text-overflow">{{item.c_name}}</p>
							<p class="peiyinContent text-overflow3">{{item.c_cont}}</p>
						</li>
					</template>
				</ul>
			</div>
		</div>
		<!-- <el-dialog  title="验证码登录" :visible.sync="dialogVisible" width="30%" style="min-width: 350px;">
			<loginDialog></loginDialog>
		</el-dialog> -->
		<el-dialog :visible.sync="joinVisible" width="30%" center style="min-width: 350px;">
			<img :src="require('@/assets/image/recharge/wx.jpg')" />
		</el-dialog>

		<el-dialog :visible.sync="payDialogVisible" width="30%" center @close='closeDef'>
			<div style='text-align: center;display: flex;'>
				<div style="font-size: 24px;padding-bottom: 20px">
					<br>
					<span>微信扫码支付</span><br><br>
					<span style='color:red;font-size: 32px;'>￥{{rechargeList[activeListIndex].price}}</span>
				</div>
				<!-- <div id="qrCode" ref="qrCodeDiv" style="margin: 0 auto;"></div> -->
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import loginDialog from "../../components/loginDialog.vue"
	import QRCode from "qrcodejs2"
	var qrcode = null
	var timer = null
	export default {
		data() {
			return {
				islogin: false,
				// 动画是否出现
				dialogVisible: false,
				joinVisible: false,
				activeListIndex: 0,
				rechargeList: [],
				payDialogVisible: false,
				peiyinList: [],
				problemList: [],
				alipayForm: '', // 支付宝form
				custPicList1: [],
				custPicList2: [],
				custPicList3: [],
				bannerImg: ''
			}
		},
		components: {
			loginDialog
		},
		mounted() {
			this.getRecharge();
			this.getAdByTag2()
			this.getAdByTag();
			this.getPeiyinList();
			this.getProblemList();
			if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null && localStorage.getItem(
					'token') != undefined) {
				this.islogin = true
			}

		},
		beforeRouteLeave(to, from, next) {
			// 执行一些操作，比如关闭弹窗
			this.closeDef()

			// 调用 next() 方法继续完成路由跳转
			next()
		},
		methods: {
			getPeiyinList(){
				var that=this;
				this.$post('/introduce/people', {
				}).then(res => {
					that.peiyinList = res.data
				})
			},
			getProblemList(){
				var that=this;
				this.$post('/introduce/commonproblem', {
				}).then(res => {
					that.problemList = res.data
				})
			},
			getAdByTag2() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_chongzhi_banner'
				}).then(res => {
					this.bannerImg = res.data[0].ad_pic
				})
			},
			getAdByTag() {
				this.$post('/Ad/getAdByTag', {
					tag: 'pc_customer_banner'
				}).then(res => {
					console.log('**********************')
					console.log(res)
					for (let i = 0; i < res.data.length; i++) {
						if (i < 8) {
							this.custPicList1.push(res.data[i])
						} else if (i < 16) {
							this.custPicList2.push(res.data[i])
						} else if (i < 24) {
							this.custPicList3.push(res.data[i])
						}
					}

				})
			},
			closeDef() {
				console.log('关闭定时器')
				clearInterval(timer)
			},
			bindQRCode(data) {
				console.log('微信二维码地址---->', data);
				if (qrcode != null) {
					this.$refs.qrCodeDiv.innerHTML = ''
				}
				qrcode = new QRCode(this.$refs.qrCodeDiv, {
					text: data,
					width: 150,
					height: 150,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})
			},
			payDef(type) {
				let data = {
					type: type,
					active: this.rechargeList[this.activeListIndex].id
				}
				if (localStorage.getItem('loginFlag')) {
					this.$post('/Order/payRecharge', data).then(res => {
						if (type == 1) {
							console.log(res.data)
							this.alipayForm = res.data
							return false
						} else {
							timer = setInterval(() => {
								this.selectOrder(res.data.order_id)
							}, 1000)
							// this.payDialogVisible = true
							this.$nextTick(function() {
								this.bindQRCode(res.data.url);
							})
						}
					})
				}
			},
			selectOrder(id) {
				let data = {
					orderid: id,
					type: 1
				}
				this.$post('/Order/selectOrder', data).then(res => {
					// console.log(res)
					if (res.data == 1) {
						this.payDialogVisible = false
						this.$message({
							type: 'success',
							message: '支付成功'
						})
					}
				})
			},
			getRecharge() {
				this.$post('/Order/getRecharge', {}).then(res => {
					this.rechargeList = res.data
					this.payDef(1)
					this.payDef(2)
				})
			},

			connectFn() {
				this.joinVisible = true
			},
			loginDialogFn() {
				this.$store.commit('setDialogVisibleLogin', true);
				// this.dialogVisible = true
			},
			changeRecharge(index) {
				this.activeListIndex = index
				console.log(index)
				this.payDef(1)
				this.payDef(2)
			},
			scrollAnimation() {
				console.log(document.documentElement.scrollTop)
			}
		}
	}
</script>

<style scoped>
	.el-dialog {
		border-radius: 20px;
	}

	/deep/ .el-dialog__body {
		background-color: #fff;
		border-radius: 20px !important;
	}

	.voiceBox .allLogoBox /deep/ img {
		cursor: pointer;
		transition: all .3s;
	}

	.voiceBox .allLogoBox /deep/ img:hover {

		transform: translateY(-10px);
	}

	@font-face {
		font-family: 'al-Bold';
		src: url('../../assets/fonts/al-Bold.ttf');
	}

	.banner {
		height: 400px;
		padding: 70px 400px 50px;
		box-sizing: border-box;
	}

	.recharge,
	.peiyin,
	.voiceBox,
	.problem {
		width: 1400px;
		margin: 20px auto;
	}

	.banner .bannerTit {
		font-family: 'al-Bold';
		font-size: 50px;
		color: #fff;
	}

	.banner .bannerTit span {
		color: #fa3f3f;
	}

	.banner .bannerP {
		font-size: 18px;
		color: #fff;
		margin: 20px 0 40px;
	}

	.banner button {
		width: 120px;
		height: 40px;
		background-color: #fa3f3f;
		line-height: 40px;
		color: #fff;
		border-radius: 5px;
		animation: joinUs .5s linear .5s infinite normal;
	}

	@keyframes joinUs {
		0% {
			transform: scale(1);
		}

		50% {
			transform: scale(1.03);
		}

		100% {
			transform: scale(1);
		}
	}

	.tit {
		line-height: 100px;
		font-size: 30px;
		font-weight: 600;
		text-align: center;
	}

	.rechargeList {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.rechargeList li {
		background-image: url('../../assets/image/recharge/rechargeImg.png');
		background-size: 100% 100%;
		width: 30%;
		padding: 60px 0 50px;
		cursor: pointer;
		margin: 10px 0;
	}

	.rechargeList li.activeRechargeList {
		background-image: url('../../assets/image/recharge/checked.png');
	}

	.rechargeList li .firstP,
	.rechargeList li .secondP {
		text-align: center;
		font-size: 22px;
		line-height: 50px;
	}

	.rechargeList li .secondP span {
		font-size: 45px;
		font-weight: 600;
		margin: 0 5px;

	}

	.rechargeBox {
		background-color: #fff;
		border-radius: 10px;
		margin: 50px 0;
		padding: 50px 0;
	}

	.rechargeBox .payBox {
		margin: 0 auto;
		width: 60%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.rechargeBox .payBox .pay button {
		width: 150px;
		height: 150px;
		border-radius: 10px;
		line-height: 150px;
		background-color: #fc4439;
		color: #fff;
		font-weight: 600;
		font-size: 22px;
	}

	.rechargeBox .payBox .pay {
		text-align: center;
	}

	.rechargeBox .payBox .pay img {
		width: 80px;
		height: 80px;
		border-radius: 10px;
	}

	.rechargeBox .payBox .pay p {
		text-align: center;
		margin: 10px 0;
	}

	.rechargeBox .payBox .centerBox .firstP,
	.rechargeBox .payBox .centerBox .secondP {
		font-weight: 600;
		font-size: 22px;
		line-height: 35px;
	}

	.rechargeBox .payBox .centerBox .secondP {
		color: #ff0000
	}

	.rechargeBox .payBox .centerBox .secondP span {
		font-weight: 600;
		font-size: 26px;
	}

	.peiyinBox,
	.problemBox {
		background-color: #fff;
	}

	.peiyinBox .peiyiUl {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.peiyinBox .peiyiUl li {
		width: 48%;
		margin: 30px 0;
		display: flex;
	}

	.peiyinBox .peiyiUl li img {
		width: 72px;
		height: 72px;
		margin-right: 20px;
		cursor: pointer;
		transition: all .3s;
	}

	.peiyinBox .peiyiUl li img:hover {
		transform: translateY(-5px);
	}

	.peiyinBox .peiyiUl li>.content {
		flex: 1;
		cursor: pointer;
	}

	.peiyinBox .peiyiUl li>.content:hover .peiyinTit,
	.peiyinBox .peiyiUl li>.content:hover .peiyinContent {
		color: #ff0000
	}

	.peiyinBox .peiyiUl li>.content>.peiyinTit {
		font-size: 20px;
		font-weight: 600;
	}

	.peiyinBox .peiyiUl li>.content>.peiyinContent {
		margin-top: 10px;
		font-size: 14px;
	}

	.voiceBox .secondTit {
		color: #484849;
		margin-top: -30px;
		line-height: 30px;
		text-align: center;
	}

	.voiceBox .allLogoBox {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.problemBox {
		padding: 0 0 20px 0;
	}

	.problemBox .problemUl {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.problemBox .problemUl li {
		width: 48%;
		margin: 30px 0;
		padding: 30px 80px;
		border-radius: 10px;
		box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
		cursor: pointer;
		border: 1px solid #fff;
		height: 150px;
	}

	.problemBox .problemUl li:hover {
		border: 1px solid #fa3f3f;
		box-shadow: 0 2px 6px 0 rgb(255 0 0 / 10%);
	}

	.problemBox .problemUl li:hover .peiyinTit {
		color: #fa3f3f
	}

	.problemBox .problemUl li .peiyinTit {
		font-size: 24px;
		font-weight: 600;
	}

	.problemBox .problemUl li .peiyinContent {
		margin-top: 10px;
	}

	/deep/ .el-image__inner {
		width: 160px;
		height: 80px;
	}

	/deep/ .el-dialog__body img {
		width: 300px;
		display: block;
		margin: 0 auto;
	}

	/deep/ .el-dialog--center {
		border-radius: 20px;
	}

	@media only screen and (max-width: 1680px) {

		.recharge,
		.peiyin,
		.voiceBox,
		.problem {
			width: 1200px;
		}

		.banner {
			padding: 50px 200px;
		}
	}

	@media only screen and (max-width: 1440px) {

		.recharge,
		.peiyin,
		.voiceBox,
		.problem {
			width: 1000px;
		}

		.banner {
			padding: 50px 200px;
		}
	}
</style>
