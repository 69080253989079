<template>
<div class="pause-select" :style="{top: `${top}px`, left:`${left}px`}" @click.stop>
   <p
       v-for="(item,index) of generateSpeechList"
       :key="index"
       @click="generateSpeechSelect(item,index)"
   >{{`[读法${index+1}]`}} &nbsp;&nbsp;{{item.speak}}</p>
</div>
</template>

<script>
export default {
  props:{
    top: {
      type:Number,
      default:0
    },
    left: {
      type:Number,
      default:0
    },
    generateSpeechList:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return {

    }
  },
  methods:{
    generateSpeechSelect(item,index){
      this.$emit('generateSpeechSelect', item, `读法${index+1}`)
    }
  }
}
</script>

<style scoped>
.pause-select{
  position: fixed;
  background: #fff;
  padding: 15px 8px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ddd;
  font-size: 14px;
  max-width: 420px;
  z-index: 10;
}
p{
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}
p:hover{
  background: #ddd;
}
</style>