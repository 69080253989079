import Vue from 'vue'
import Vuex from 'vuex'
import {getUserInfo} from "@/request/aiDubbing";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        userinfo: null,
		dialogVisible_login:false,
		newsNum:null,
		asideItemShowFlag:true,
		musicLoading:false,
        //
        currentPath:'',
        // AI配音
        aiBuddingUserInfo: {},
        currentSelectDubbing: {}, // 当前选择使用的AI播音
    },
    getters: {
        token: state => state.token,
        userinfo: state => {
            let userinfo = state.userinfo
            if(!userinfo){
                userinfo = JSON.parse(localStorage.getItem('userInfo'))
            }
            return userinfo
        },
		dialogVisible_login: state => state.dialogVisible_login,
		newsNum: state => state.newsNum,
		asideItemShowFlag: state => state.asideItemShowFlag,
        musicLoading: state => state.musicLoading,
        //
        currentPath: state => state.currentPath,
        aiBuddingUserInfo: state => {
            let aiBuddingUserInfo = state.aiBuddingUserInfo || {}
            if(!aiBuddingUserInfo.id){
                aiBuddingUserInfo = JSON.parse(localStorage.getItem('aiBuddingUserInfo'))
                if(aiBuddingUserInfo && aiBuddingUserInfo.id) state.aiBuddingUserInfo = aiBuddingUserInfo
            }
            return aiBuddingUserInfo || {}
        },
       currentSelectDubbing: state => {
            let currentSelectDubbing = state.currentSelectDubbing || {}
            if(!currentSelectDubbing.id){
                currentSelectDubbing = JSON.parse(localStorage.getItem('currentSelectDubbing'))
                if(currentSelectDubbing && currentSelectDubbing.id) state.currentSelectDubbing = currentSelectDubbing
            }
            return currentSelectDubbing || {}
       },
        canGo: state => {
            if(!state.aiBuddingUserInfo) return false
            if(state.aiBuddingUserInfo.vip !== 1 && state.aiBuddingUserInfo.vip !== 2) return false
            if(state.aiBuddingUserInfo.vip === 2) return true
            if(state.aiBuddingUserInfo.vip === 1 && state.currentSelectDubbing.svip === 0) return true
            return false
        },
   },
    mutations: {
		setMusicLoading(state, musicLoading){
			 state.musicLoading = musicLoading
		},
        token(state, token) {
            state.token = token
        },
        userInfo(state, userinfo) {
            state.userinfo = userinfo
        },
        loginSuccess(state,token){
            state.token = token
        },
		setDialogVisibleLogin(state,dialogVisible_login){
			  state.dialogVisible_login = dialogVisible_login
		},
		setNewsNum(state,newsNum){
			 state.newsNum = newsNum
		},
		setAsideItemShowFlag(state,value){
			 state.asideItemShowFlag = value
		},
        setCurrentPath(state, path){
            state.currentPath = path;
        },
        setAiBuddingUserInfo(state, aiBuddingUserInfo){
            if(!aiBuddingUserInfo){
                localStorage.removeItem('aiBuddingUserInfo')
                state.aiBuddingUserInfo = {}
            }else{
                state.aiBuddingUserInfo = aiBuddingUserInfo
                localStorage.setItem('aiBuddingUserInfo', JSON.stringify(aiBuddingUserInfo))
            }
        },
        setCurrentSelectDubbing(state, currentSelectDubbing){
            if(!currentSelectDubbing){ // 清空
                localStorage.removeItem('currentSelectDubbing')
                state.currentSelectDubbing = {}
            }else{ // 设置
                state.currentSelectDubbing = currentSelectDubbing
                localStorage.setItem('currentSelectDubbing', JSON.stringify(currentSelectDubbing))
                localStorage.removeItem('dubbingConfig')
            }
        },
        aiUserLogout(state){
            state.aiBuddingUserInfo = null
            state.userinfo = null
            localStorage.removeItem('aiBuddingUserInfo')
            localStorage.removeItem('Authorization')
        },
    },
    actions: {
        async getAiUserInfo({commit}, showLogin){
            const token = localStorage.getItem('Authorization')
            if(!token) {
                if(showLogin) {
                    commit('setDialogVisibleLogin', true)
                }
                console.log()
                return Promise.reject('未登录')
            }
            try{
                const {data} = await getUserInfo()
                if(data && data.id){
                    commit('setAiBuddingUserInfo', data)
                    return Promise.resolve(data)
                }else{
                    return Promise.reject('获取用户信息失败')
                }
            }catch (e) {
                return Promise.reject(e)
            }
        }
    }
})