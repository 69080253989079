<template>
	<div class="authorize" id='authorize'>
		<div class="title-wrap">
			<div class="title">
				<p>配音视频案例<span>·</span>Service case</p>
				<button type="button" @click="selLabelDef(-1)" :class="selLabelindex==-1?'activity':''">全部</button>
				<button type="button" @click="selLabelDef(index)" :class="selLabelindex==index?'activity':''"
					v-for='(item,index) in labelArr'>{{item.name}}</button>
			</div>
			<a href="javaScript:void(0)" class="more" @click="goMore()">查看更多 &gt;&gt;</a>
		</div>
		<ul class="case-list">
			<template v-for="(item,index) in caseList">
				<li>
					<a href="javaScript:void(0)" class="cover" @mouseenter="startVideo(index)"
						@mouseleave="stopVideo(index)"
						:style="{'background-image':'url(' + item.img + ')'}">
						<video :src="item.video" @click="playAudio(item.video)" ref="videoPlayer" muted="muted" preload="none"
							loop="loop" class="album-video"></video>
					</a>
					<a href="javaScript:void(0)" class="title">
						<p class="title text-overflow" style="font-weight: bold;color: #000;font-size: 18px;">
							{{item.name}}</p>
					</a>
					<div class="music">
						<p class="text-overflow" @click="goDubbingTeacher(item.teacher_id)">配音老师：
							<a href="javaScript:void(0)">{{item.nickname}}</a>
						</p>
						<i @click="shoucangDef(item)" style='color:red'
							:class="item.is_collect == 0?likeIconfont:likeIconfontShouCang"></i>
					</div>
					<div class="make-box">
						<button class="btn-download" @click.stop="showKefu(item)">做同款</button>
					</div>
				</li>
			</template>
		</ul>
		<el-dialog :visible.sync="videoBox" @close='videoClose' class="videoDialog" width="55.5%" height='50%'>
			<!-- <video :src="playVideoSrc" muted="muted" loop="loop" autoplay controls class="album-video" style="width: 100%;"></video> -->
			<video :src="playVideoSrc" loop="loop" autoplay controls class="album-video" style="width: 100%;display: block;height: 550px;background-color: #000;"
				ref='videoPlayer_dialog'></video>
		</el-dialog>

		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="450px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
	</div>
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import goTop from '../../utils/goTop.js'

	export default {
		components: {
			teacherDialog
		},
		data() {
			return {
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				caseList: [],
				tagsList: [],
				selLabelindex: -1,
				labelArr: [],
				videoBox: false,
				kefuObj: {
					qq: '',
					wx: ''
				},
				kefuBoxShow: false,
				showCaseBox: false
			}
		},
		mounted() {
			this.getAudioCase()
			this.getShowCate()

			window.addEventListener('resize', this.onResize);
		},
		methods: {
			playAudio(video) {
				this.playVideoSrc = video;
				this.videoBox = true;
				var that = this;
				setTimeout(function() {
					console.log(that.$refs, that.$refs.videoPlayer_dialog);
					that.$refs, that.$refs.videoPlayer_dialog.player.muted(false);
					that.$refs, that.$refs.videoPlayer_dialog.player.play();
				}, 10)

				// this.$refs.videoPlayer.player.muted(false);
				// this.$refs.videoPlayer.player.play();
			},
			// onResize() {
			// 	console.log('页面变化')
			// 	if (document.body.clientWidth < 1750) {
			// 		this.showCaseBox = false
			// 	} else {
			// 		this.showCaseBox = true
			// 	}
			// },
			showKefu(item) {
				console.log(item)
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data;
						this.kefuObj.headimgurl = item.headimgurl;
						this.kefuObj.nickname = item.nickname;
						this.kefuObj.on_line=item.on_line;
						this.kefuBoxShow = true
					})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }

			},
			videoClose() {
				this.playVideoSrc = ''
			},
			selLabelDef(index) {
				this.selLabelindex = index
				this.tagsList = []
				if (index != -1) {
					this.tagsList.push(this.labelArr[index])
				}
				this.getAudioCase()
			},
			// 获取分类筛选标签
			getShowCate() {

				let data = {
					limit: 5
				}
				this.$post('/Label/showCates', data).then(res => {
					console.log(res)
					this.labelArr = res.data
				})
			},
			// 收藏
			shoucangDef(item) {
				let data = {
					id: item.id,
					type: 2,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					if (sessionStorage.getItem('taskid') && item.is_collect == 0) {
						this.$toTask()
					}
					this.getAudioCase()
				})
			},
			// 获取配音案例
			getAudioCase() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					labelidStr.push(this.tagsList[i].id)
				}
				console.log(labelidStr)
				let data = {
					page: 1,
					limit: 5,
					label: labelidStr,
					on_line: 1
				}
				this.$post('/Audio/audioCase', data).then(res => {
					this.caseList = res.data.list
				})
			},
			// 开始播放视频
			startVideo(index) {
				var that = this;
				this.$nextTick(function() {
					that.$refs.videoPlayer[index].play();
				})
			},
			stopVideo(index) {
				var that = this;
				setTimeout(() => {
					that.$refs.videoPlayer[index].pause();
					that.$refs.videoPlayer[index].currentTime = 0;
				}, 10)
			},
			gotoCaseDetail(caseId) {
				this.$router.push({
					path: '/dubbingScheme/schemeDetail',
					query: {
						caseId,
						iscase: 1
					}
				})
				goTop();
			},
			// 
			goMore() {
				this.$router.push({
					path: '/dubbingCase',
				})
				goTop();
			},
			// 配音老师详情页
			goDubbingTeacher(audioId) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: audioId
					}
				})
				goTop();
			},
		}
	}
</script>

<style scoped>
	.authorize .case-list>li .album-video{
		opacity: 0;
	}
	/deep/.videoDialog .el-dialog__body{
		padding: 0 !important;
	}
	/deep/.videoDialog .el-dialog__body video{
		display: block;
	}
	@media screen and (max-width: 1680px) {

		.authorize .case-list li:nth-of-type(5){
			display: none;
		}
	}

	@media screen and (max-width: 1440px) {

		.authorize .case-list li:nth-of-type(4),
		.authorize .case-list li:nth-of-type(5) {
			display: none;
		}
	}

	/deep/.el-dialog {
		background-color: transparent;
		border: none;

	}

	/* 	/deep/.el-dialog__body{
		padding: 0;
	} */
	.dialogBox /deep/ .el-dialog__wrapper .el-dialog__body {
		border-radius: 20px !important;
		padding: 0 !important;
		background-color: transparent;
	}
</style>
