<template>
	<div class="dialogBox">
		<div class="tit" v-if='kefuObj.type == 1'>
			添加客服微信，<span style="color:#eb3637">免费</span>领取
		</div>
		<div class="tit" v-if='kefuObj.type == 2'>
			添加商务微信，<span style="color:#eb3637">即时</span>回复
		</div>
		<div class="tit" v-if='kefuObj.type == 3'>
			添加人工微信，<span style="color:#eb3637">全天</span>守候
		</div>
		<div class="tit" v-if='kefuObj.type == 4'>
			专注多语种翻译，<span style="color:#eb3637">1V1</span>沟通更高效
		</div>
		<div class="imageBox">
			<img :src='kefuObj.image' />
		</div>
		<p class="p1">微信扫一扫，沟通更有效</p>
	</div>
</template>

<script>
	export default {
		props: ['kefuObj']
	}
</script>

<style scoped>
	.tit {
		text-align: center;
		font-size: 26px;
		font-weight: 600;
		color:#333
	}
	.imageBox {
		width: 200px;
		height: 200px;
		padding: 20px;
		border-radius: 20px;
		box-sizing: border-box;
		background-color: #f5f5f5;
		margin: 20px auto;
	}

	.p1 {
		color: #8c8c8c;
		font-size: 22px;
		font-weight: 600;
		text-align: center;
	}

	.p2 {
		color: #a8a8a8;
		font-size: 18px;
		text-align: center;
		margin-top: 10px;
	}
</style>
