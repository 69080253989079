<template>
	<div class="rightContentBox sound-content">
		<ul class="menuList">
			<template v-for="(item,index) in menuList">
				<li :class="activeLiIndex == index ?'rightItemActive':''" @click="changeActiveLiIndex(index)">
					{{item.itemname}}
				</li>
			</template>
		</ul>
		<!-- .sound-content .con-main .case-list>li -->
		<div class="content con-main" v-if="activeLiIndex == 0">
			<ul class="case-list">
				<template v-for="(item,index) in audioList">
					<li>
						<a href="javaScript:void(0)" @click="goDubbingTeacher(item.id)" class="cover">
							<div class="peoDetail">
								<div class="imgBox">
									<img :src="item.headimgurl" />
									<span v-if="item.on_line == 1" class="login"></span>
									<span v-else class="nologin"></span>
								</div>
								<div class="peoName">
									<el-tooltip placement="top">
										<template #content> {{item.nickname}}</template>
										<p class="peoName_name">{{item.nickname}}</p>
									</el-tooltip>
									<p>销量：{{item.volume}}</p>
								</div>
								<button  @click.stop="showKefu(item)">
									<svg t="1693274299441" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6083" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z" fill="#ffffff" p-id="6084"></path><path d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z" fill="#ffffff" p-id="6085"></path></svg>
								在线联系
							</button>
							</div>
							<p class="audioDetailP">{{item.blurb}}</p>
							<i v-if="item.remenFlag==1" class="iconfont icon-tuijian"></i>
							<!-- <i class="iconfont icon-remen"></i> -->
						</a>
						<div class="peoCaseBox">
							<ul>
								<template v-for="(audio,audioindex) in item.audio">
									<li>
										<p>{{audio.title}}</p>
										<div style="display: flex;">
											<i v-if="audioFlag==true && audioId==audio.id&& $store.state.musicLoading"
												class="iconfont icon-jiazai"
												@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item.headimgurl,item.nickname)"></i>
											<i v-else-if="audioFlag==true && audioId==audio.id"
												class="iconfont icon-zanting3"
												@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item.headimgurl,item.nickname)"></i>
											<i v-else class="iconfont icon-bofang1"
												@click="changeAudioSrc(item,audio.id,audio.title,audio.audio,item.headimgurl,item.nickname)"></i>
											<i class="iconfont icon-fenxiang" @click="onCopy($event,audio.audio)"></i>
										</div>
									</li>
								</template>
							</ul>
							<div class="peoPriceBox">
								<div class="peoPrice">
									<p><span class="redFont"
											style="font-size: 16px;">￥{{parseInt(item.price)}}/</span><span>100字</span>
									</p>
									<p><span class="redFont"
											style="font-size: 16px;">￥{{parseInt(item.min_price)}}/</span><span>起配</span>
									</p>
								</div>
								<i v-if="item.is_collect==1" :class="likeIconfontShouCang"
									@click="changeLikeIconfont(item,1)"></i>
								<i v-else :class="likeIconfont" @click="changeLikeIconfont(item,1)"></i>
							</div>
						</div>
					</li>
				</template>
			</ul>
		</div>
		<div class="sound-main authorize pr" v-if="activeLiIndex == 1">
			<ul class="case-list">
				<template v-for="(item,index) in caseList">
					<li>
						<a href="javaScript:void(0)" class="cover" @mouseenter="startVideo(index)"
							@mouseleave="stopVideo(index)"
							:style="{'background-image':'url(' + item.img + ')'}">
							<video :src="item.video" @click="playAudio(item.video)" ref="videoPlayer" muted="muted" preload="none"
								loop="loop" class="album-video"></video>
						</a>
						<a href="javaScript:void(0)" class="title">
							<p class="company text-overflow">{{item.name}}</p>
							<!-- <p class="title text-overflow">{{item.name}}</p> -->
						</a>
						<div class="music">
							<p class="text-overflow"  @click="goDubbingTeacher(item.teacher_id)">配音老师：
								<a href="javaScript:void(0)">{{item.nickname}}</a>
							</p>
							<i @click="shoucangDef(item)" style="color: red;"
								:class="item.is_collect == 0?likeIconfont:likeIconfontShouCang"></i>
						</div>
						<div class="make-box">
							<button class="btn-download" @click="showKefu(item)">做同款</button>
						</div>
					</li>
				</template>
			</ul>
		</div>
		<div class="sound-main authorize pr" v-if="activeLiIndex == 2" style="padding-top: 10px;">
			<div id='songList' v-for="(item,index) in caseList">
				<div style="width: 5%;">{{index+1}}</div>
				<div style="display: flex;width: 50%;">
					<template v-if="audioFlag==true && audioId==item.id&& $store.state.musicLoading">
						<i class="iconfont icon-jiazai"style="width: 16px;height: 16px;" 
							@click="changeAudioSrc(item,item.id,item.title,item.audio,item.headimgurl,item.nickname)"></i>
					</template>
					<template v-else-if="audioFlag==true && audioId==item.id">
						<i class="iconfont icon-zanting3"style="width: 16px;height: 16px;" 
							@click="changeAudioSrc(item,item.id,item.title,item.audio,item.headimgurl,item.nickname)"></i>
					</template>
					<template v-else>
						<i class="iconfont icon-bofang1"style="width: 16px;height: 16px;" 
							@click="changeAudioSrc(item,item.id,item.title,item.audio,item.headimgurl,item.nickname)"></i>
					</template>
					<p style="margin-right: 10px;margin-left: 10px;">{{item.title}}</p>
				</div>
				<div style="display: flex;width: 30%;margin-left: 30%;">
					<div class="btn-trail-box" style="padding-right: 20px;">
						<i class="iconfont icon-yunxiazai_o" @click="handleDown(item)"></i>
					</div>
					<div class="btn-trail-box" style="padding-right: 20px;">
						<i class="iconfont iconfenxiang2" @click="onCopy($event,item.audio)"></i>
					</div>
					<div class="btn-trail-box" style="padding-right: 20px;">
						<i class="iconfont iconyishoucang" :style="{'color': item.is_collect==1?'red':''}"
							@click="changeLikeIconfont(item,3)"></i>
					</div>
					<p class="fontSize_14 color_999 text-overflow teach_nickname" @click="toTeach(item.uid)">
						{{item.nickname}}
					</p>
					<!-- <p class="fontSize_14 color_999 text-overflow">{{item.label}}</p> -->
				</div>
			</div>
		</div>
		<div class="sound-main authorize pr" v-if="activeLiIndex == 3">
			<ul class="case-list teacher-list">
				<div class="teachDom" v-for="(item,index) in caseList" :key="index" @click="toSingle(item.id)">
				<div class="teacher-left">
					<!-- <div class='labelBox'>情感主播</div> -->
					<img class="headimg" :src="item.avatar" alt="">
				</div>
				<div class="teacher-right">
					<div class="t-name">
						<div>{{ item.name }}</div>
						<div @click.stop="collectAnchor(item)">
							<i v-if="item.is_collect == 1" class="el-icon-star-on is-collect" style="float: right;"></i>
							<i v-else class="el-icon-star-off" style="float: right;" ></i>
						</div>
					</div>
					<div class="t-intro">{{ item.intro }}</div>
					<div class="t-bottom">
						<div class="t-bootom-heat">
							<img src='../../assets/image/dubbingAI/reduIcon2.png' style="width: 11px;height: 15px;margin-right: 4px;">
							<span>{{ item.heat }}</span>
						</div>
						<!-- <i class="el-icon-video-play" style="float: right;font-size: 20px;"></i> -->
					</div>
				</div>
			</div>
			</ul>
		</div>
		<div class="paging-box qt-pagination" v-if='totalPage >0'>
			<div class="el-pagination is-background">
				<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
					:page-size="page.limit" :current-page.sync="page.currentPage">
				</el-pagination>
				<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
					<span class="suffix" style="padding: 0 10px">上一页</span>
				</button>
				<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
					<span class="suffix" style="padding: 0 10px">下一页</span>
				</button>
			</div>


		</div>
		<div style="text-align: center;" v-else-if='totalPage == 0'>
			<img src="../../assets/image/common/noCollect.svg" style="width: 500px;" />
			<p style="text-align: center;color: #999;margin-bottom: 20px;">暂无收藏内容，快去收藏吧</p>
		</div>
		<el-dialog :visible.sync="videoBox" @close='videoClose' class="videoDialog" width="55.5%" height='50%'>
			<video :src="playVideoSrc" loop="loop" autoplay controls class="album-video" style="width: 100%;display: block;height: 550px;background-color: #000;"
				ref='videoPlayer_dialog'></video>
		</el-dialog>
		<el-dialog custom-class="dialogBox" :visible.sync="kefuBoxShow" width="450px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
	</div>
</template>

<script>
	import teacherDialog from "@/components/teacherDialog.vue"
	import goTop from '../../utils/goTop.js'
	import copyText from "@/utils/copyText";
	export default {
		data() {
			return {
				totalPage: null,
				page: {
					currentPage: 1,
					limit: 8,
				},
				pagePrev: true,
				pageNext: false,

				activeLiIndex: 0,
				audioList: [],
				audioId: '',
				audioFlag: false,
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				caseList: [],
				kefuBoxShow: false,
				kefuObj:{},
				videoBox:false
			}
		},
		components: {
			teacherDialog
		},
		props: [
			"menuList"
		],
		mounted() {
			console.log(this.$props.menuList);
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
			this.getMyCollect()
		},
		methods: {
			/**
			 * 收藏主播
			 */
			 collectAnchor({id}) {
				this.$post('/Anchor/collect', {anchor_id: id}).then(res => {
					this.page.currentPage = 1
					this.getMyCollect()
				})
			},
			playAudio(video) {
				this.playVideoSrc = video;
				this.videoBox = true;
				var that = this;
				setTimeout(function() {
					console.log(that.$refs, that.$refs.videoPlayer_dialog);
					that.$refs, that.$refs.videoPlayer_dialog.player.muted(false);
					that.$refs, that.$refs.videoPlayer_dialog.player.play();
				}, 10)
			
				// this.$refs.videoPlayer.player.muted(false);
				// this.$refs.videoPlayer.player.play();
			},
			videoClose() {
				this.playVideoSrc = ''
			},
			showKefu(item) {
				console.log(item)
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data;
						this.kefuObj.headimgurl = item.headimgurl;
						this.kefuObj.nickname = item.nickname;
						this.kefuObj.on_line = item.on_line;
						this.kefuBoxShow = true
					})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }
			
			},
			toTeach(id) {
				console.log(id)
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: id
					}
				})
				goTop()
			},
			getMyCollect() {
				if (this.activeLiIndex == 3) {
					let data = {
						page: this.page.currentPage,
						page_size: this.page.limit,
					}
					this.$post('/Matching/member_anchor',data).then(res => {
						this.caseList = res.data.list
						this.totalPage = res.data.count
					})
					return 
				}
				let data = {
					page: this.page.currentPage,
					limit: this.page.limit,
					type: this.activeLiIndex + 1
				}
				this.$post('/MemberInfo/myCollect', data).then(res => {
					console.log(res)
					this.totalPage = res.data.count
					if (this.activeLiIndex == 0) {
						this.audioList = res.data.list
					} else {
						this.caseList = res.data.list
					}
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= this.totalPage / this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			//下载音频
			handleDown(audio) {
				// 使用获取到的blob对象创建的url
				const filePath = audio.audio // mp3的地址
				fetch(filePath).then(res => res.blob()).then(blob => {
					const a = document.createElement('a')
					document.body.appendChild(a)
					a.style.display = 'none'
					// 使用获取到的blob对象创建的url
					const url = window.URL.createObjectURL(blob)
					a.href = url
					// 指定下载的文件名
					a.download = audio.title + '.mp3'
					a.click()
					document.body.removeChild(a)
					// 移除blob对象的url
					window.URL.revokeObjectURL(url)
				})
			},
			// 收藏
			shoucangDef(item) {
				let data = {
					id: item.id,
					type: 2,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					if (sessionStorage.getItem('taskid') && item.is_collect == 0) {
						this.$toTask()
					}
					this.getMyCollect()
				})
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				this.getMyCollect()
			},
			// 收藏
			changeLikeIconfont(item, type) {
				let data = {
					id: item.id,
					type: type,
					state: item.is_collect == 0 ? 1 : 0
				}
				this.$post('/MemberInfo/collect', data).then(res => {
					console.log(res)
					console.log(sessionStorage.getItem('taskid'), item.is_collect)
					if (sessionStorage.getItem('taskid') && item.is_collect == 0) {
						this.$toTask()
					}

					this.getMyCollect()
				})
			},
			gotoCaseDetail(caseId) {
				this.$router.push({
					path: '/dubbingScheme/schemeDetail',
					query: {
						caseId
					}
				})
				goTop();
			},
			// 开始播放视频
			startVideo(index) {
				var that = this;
				this.$nextTick(function() {
					that.$refs.videoPlayer[index].play();
				})
			},
			stopVideo(index) {
				var that = this;
				setTimeout(() => {
					that.$refs.videoPlayer[index].pause();
					that.$refs.videoPlayer[index].currentTime = 0;
				}, 10)
			},
			// 配音老师详情页
			goDubbingTeacher(audioId) {
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: audioId
					}
				})
				goTop();
			},
			changeActiveLiIndex(index) {
				this.activeLiIndex = index
				this.getMyCollect()
			},
			onCopy(event, audioSrc) {
				copyText(event, audioSrc)
			},
			changeAudioSrc(audioDetail, id, name, src, image, nickname) {
				console.log(audioDetail)
				var audioInfo = {};
				audioInfo.id = id;
				audioInfo.audioSrc = src;
				audioInfo.audioImage = image
				audioInfo.audioName = name;
				audioInfo.audioPeoName = nickname;
				audioInfo.audioPeoId=audioDetail.id;
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeStatus(val, flag) {
				console.log("dubbingTeacher___________", val, flag)
				this.audioId = val;
				this.audioFlag = flag
			}
		}
	}
</script>

<style scoped>
	.authorize .case-list>li .album-video{
		opacity: 0;
	}
	.videoDialog /deep/.el-dialog .el-dialog__body {
		padding: 0 !important;
	}
	/deep/.el-dialog .el-dialog__body video {
		display: block;
	}
	/deep/ .el-dialog{
		border-radius: 20px;
	}
	.copyright-music-Box .case-list li .cover .peoDetail .peoName {
		margin: 0 0% 0 2%;
		flex: 1;
	}
	.sound-content .con-main .case-list li .cover .peoDetail .peoName{
		margin: 0 0% 0 2%;
		flex: 1;
	}
	.copyright-music-Box .case-list li .cover .peoDetail .peoName .peoName_name {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.teach_nickname {
		cursor: pointer;
	}

	.teach_nickname:hover {
		color: #eb3637;
		font-weight: bold;
	}

	.btn-trail-box {
		cursor: pointer;
	}

	#songList {
		display: flex;
		background-color: #f2f2f2;
		padding: 10px;
		width: 100%;
		border-radius: 4px;
	}

	.authorize {
		padding: 0 20px 20px;
	}

	.sound-content .con-main,
	.sound-content .con-main .case-list {
		width: 100% !important;
	}

	.sound-content .con-main .case-list {
		margin: 0;
	}

	.authorize .case-list,
	.copyright-music-Box .case-list {
		grid-template-columns: repeat(3, 1fr);
	}

	.sound-content .con-main {
		margin: 0;
	}

	.rightContentBox .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		overflow: hidden;
	}

	.rightContentBox .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		color: #8c8c8c
	}

	.rightContentBox .menuList>li.rightItemActive {
		color: #2c3e50
	}

	.rightContentBox .menuList>li.rightItemActive:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 4px;
		left: 10%;
		bottom: -15px;
		background-color: #e83233;
	}

	.content {
		padding: 20px;
		box-sizing: border-box;
	}

	.sound-content .con-main,
	.authorize .case-list,
		{
		/* width: 100%; */
	}

	.sound-content .con-main .case-list>li,
	.authorize .case-list>li {
		width: auto;
	}

	.sound-content .con-main .case-list,
	.authorize .case-list  {
		grid-template-columns: repeat(4, 1fr);
		width: 100%;
	}
	.authorize .case-list>li {
		margin-bottom: 20px;
	}

	@media only screen and (max-width: 1680px) {

		/* .sound-content .con-main .case-list>li,
		.authorize .case-list>li {
			width: 285px;
		} */
		.sound-content .con-main .case-list,
		.authorize .case-list {
			grid-template-columns: repeat(3, 1fr)  !important;
		}
		.sound-content .con-main,
		.sound-content .con-main .case-list,
		.authorize .case-list {
			width: 100% !important;
		}
	}

	@media only screen and (max-width: 1440px) {
		.sound-content .con-main .case-list li .cover .peoDetail .peoName {
			width: 200px;
			max-width: 200px;
			margin: 0 0% 0 2%;
		}
		.sound-content .con-main .case-list,
		.authorize .case-list {
			grid-template-columns: repeat(2, 1fr)  !important;
		}

		.sound-content .con-main .case-list>li,
		.authorize .case-list>li {
			width: 360px;
		}

		.sound-content .con-main,
		.sound-content .con-main .case-list,
		.authorize .case-list,
			{
			width: 100% !important;
		}
	}
	
</style>
<style lang="scss" scoped>
	.teacher-list {
		grid-gap: 26px;
	}
	.teachDom{
			display: flex;
			height: 145px;
			padding: 25px 15px;
			background-color: #811b33;
			color: white;
			border-radius: 10px;
			text-align: left;font-size: 14px;align-items: center;
			position: relative;transition: all 0.2s; top: 0;cursor: pointer;
			&:hover {
				top:-10px;
				box-shadow: 0 0 15px #ccc;
			}
			.teacher-left {
				flex-shrink: 1;
				// .labelBox{
				// 	background: linear-gradient(90deg, #FF6766, #E52E2F);position: absolute;padding: 2px 10px;font-size: 12px;left: -2px;
				// 	top: 10px;
				// }
				.headimg{
					width: 60px;
					height: 60px;
					border: 1px solid white;
					border-radius: 50%;
				}
			}
			.teacher-right {
				overflow: hidden;
				display: flex;
				flex-flow: column;
				justify-content: center;
				flex: 1;
				margin-left: 16px;
				.t-name{
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 18px;
					font-weight: bold;
				}
				.t-intro {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding: 10px 0;
					font-size: 12px;
				}
				.t-bottom {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.t-bootom-heat {
						display: flex;
						align-items: baseline;
					}
				}
			}
		}
</style>
