<template>
	<div class="el-pagination is-background">
		<el-pagination background layout="pager" :page-count="totalPage" @current-change='currentChange'
		               :page-size="limit" :current-page="current">
		</el-pagination>
		<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
			<span class="suffix" style="padding: 0 10px">上一页</span>
		</button>
		<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
			<span class="suffix" style="padding: 0 10px">下一页</span>
		</button>
	</div>
</template>

<script>
export default {
	props:{
		totalPage:{
			type:Number,
			default:0
		},
		limit:{
			type:Number,
			default:10
		},
	},
	data(){
		return {
			current:1
		}
	},
	computed:{
		pagePrev(){
			return this.current === 1
		},
		pageNext(){
			return this.current === this.totalPage
		}
	},
	methods:{
		changePageData(type){
			if(type === 1){
				if(this.current === this.totalPage) return
				this.current = this.current + 1
			}else{
				if(this.current === 1) return
				this.current = this.current - 1
			}
			this.$emit('changePage', this.current)
		},
		currentChange(val){
			this.current = val
			this.$emit('changePage', this.current)
		}
	}
}
</script>

<style scoped lang="scss">

</style>