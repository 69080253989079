import axios from "axios"; // 引入axios
import store from "../store/store";
import router from "../router/index";
import { Message, Loading } from "element-ui";

// 环境切换
let baseUrl = "";
if (process.env.NODE_ENV == "development") {
  baseUrl = "/aiApi";
} else if (process.env.NODE_ENV == "debug") {
  baseUrl = "/aiApi";
} else if (process.env.NODE_ENV == "production") {
  baseUrl = "/aiApi";
}

const aiAxios = axios.create({
  timeout: 10000,
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

// 请求拦截器
aiAxios.interceptors.request.use(
  (config) => {
    var token = localStorage.getItem("Authorization");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Message.error({ message: "请求错误!" });
    return error;
  }
);

// 响应拦截器
aiAxios.interceptors.response.use(
  (response) => {
    console.log("response", response);
    const resData = response.data;
    if (resData.code === 500) {
      Message.error({ message: resData.msg || "服务异常，请稍后再试。" });
      return Promise.reject(response);
    }
    if (resData.code === 401 || resData.code === 403) {
      Message.error({
        message:
          resData.code === 401
            ? "您暂未登录，请登录后使用！"
            : "授权过期，请重新登录。",
      });
      localStorage.removeItem("token");
      store.commit("loginSuccess", null);
      store.commit("setDialogVisibleLogin", true);
      store.commit('aiUserLogout', true);
      return Promise.reject(response);
    }
    return Promise.resolve(resData);
  },
  // 服务器状态码不是200的情况
  (error) => {
    return Promise.reject(error.response);
  }
);

export default aiAxios;
