<template>
	<div class="rightContentBox">
		<ul class="menuList">
			<li class="rightItemActive">
				邀请名单
			</li>
		</ul>
		<div class="content">
			<p class="recordHeader">
				<span style="width: 50%;">邀请人姓名</span>
				<span  style="width: 20%;">邀请人注册时间</span>
			</p>
			<ul class="recordList">
				<template v-for="item in recordList">
					<li class="recordLi">
						<div class="recordContent">
							<div style="width: 50%;" class="detail">
								<div class="rightBox">
									<p class="text-overflow" style="width: 100%;">{{item.user_name}}</p>
								</div>
							</div>
							<p style="width: 20%;" class="deliveryTime">{{item.regtime}}</p>
						</div>
					</li>
				</template>
			</ul>
			<div class="paging-box qt-pagination">
				<div class="el-pagination is-background">
					<el-pagination background layout="pager" :total="totalPage" @current-change='changePageData(0)'
						:current-page.sync="page.currentPage">
					</el-pagination>
					<button type="button" :disabled="pagePrev" class="btn-prev" @click="changePageData(-1)">
						<span class="suffix" style="padding: 0 10px">上一页</span>
					</button>
					<button type="button" :disabled="pageNext" class="btn-next" @click="changePageData(1)">
						<span class="suffix" style="padding: 0 10px">下一页</span>
					</button>
				</div>
			
			
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				totalPage: 0,
				page: {
					currentPage: 1,
					limit: 10,
				},
				pagePrev: true,
				pageNext: false,
				recordList:[]
			}
		},
		mounted() {
			this.getConsumeLog()
		},
		methods:{
			getConsumeLog(){
				let data = {
					page:this.page.currentPage,
					limit:10
				}
				this.$post('/MemberInfo/inviteList',data).then(res=>{
					console.log(res)
					this.totalPage = res.data.count
					this.recordList = res.data.list;
					if (this.page.currentPage == 1) {
						this.pagePrev = true
					} else {
						this.pagePrev = false
					}
					if (this.page.currentPage >= this.totalPage /  this.page.limit) {
						this.pageNext = true
					} else {
						this.pageNext = false
					}
				})
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				
				this.getConsumeLog()
			},
		}
	}
</script>

<style scoped>
	.rightContentBox .menuList {
		height: 60px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		overflow: hidden;
	}
	
	.rightContentBox .menuList>li {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		box-sizing: border-box;
		margin: 0 30px 0 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		color: #8c8c8c
	}
	
	.rightContentBox .menuList>li.rightItemActive {
		color: #2c3e50
	}
	
	.rightContentBox .menuList>li.rightItemActive:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 4px;
		left: 10%;
		bottom: -15px;
		background-color: #e83233;
	}
	.recordList{
	}
	.recordList .recordLi{
		padding: 0 20px;
		border:1px solid #e1e1e1;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
		margin-top: 10px;
	}
	.recordList .recordTit{
		height: 40px;
		display: flex;
		align-items: center;
		border-bottom:1px dashed #e1e1e1;
	}
	.recordList .recordTit span{
		margin: 0 20px;
	}
	.recordContent{
		display: flex;
		align-items: center;
		padding: 30px 10px ;
	}
	.recordContent .detail{
		display: flex;
		align-items: center;
	}
	.recordContent .detail img{
		width: 140px;
		border-radius: 10px;
		margin-right: 20px;
	}
	.recordContent .detail .rightBox{
		width: calc(100% - 160px);
	}
	.recordContent .detail .rightBox ul{
		display: flex;
		align-items: center;
		margin-top: 10px;
		flex-wrap: wrap;
	}
	.recordContent .detail .rightBox ul li{
		margin-right:10px;
		background-color: #ebebeb;
		padding: 2px 5px;
		color:#a6a6a6;
		margin-bottom: 5px;
	}
	.recordLi .recordContent .optionsBox .el-button{
		padding: 5px 10px;
	}
	.recordLi .recordContent .optionsBox .el-button.el-button--default{
		margin-left: 0px !important;
	}
	.el-range-editor.el-input__inner:active,.el-range-editor.el-input__inner:focus{
		border-color: #DCDFE6 !important;
	}
	.el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner{
		border-color: #DCDFE6 !important;
	}
	.el-button--primary,.el-button--primary:hover{
		background-color: #e83233;
		border: none;
		margin-left: 50px;
	}
	.el-button--default:hover,.el-button--default:focus,.el-button--default:active{
		background-color: transparent !important;
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}
	.content .el-input, .content .el-textarea{
		width: 30%;
		margin-left: 20px;
	}
	.el-button{
		margin-top: 20px;
	}
	.el-button--primary, .el-button--primary:hover{
		margin-left: 0px;
	}
	.recordHeader{
		padding: 0 20px;
		box-sizing: border-box;
		margin-top: 20px;
		height: 40px;
		display:flex;
		align-items: center;
		background-color: rgba(232, 168, 170, 0.2);
	}
</style>