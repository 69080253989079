<template>
	<div class="college-wrap module" id="fanganBox">
		<div class="title-wrap">
			<div class="title">
				<p>配音方案<span>·</span>PROGRAMME</p>
				<button type="button" @click="selLabelDef(-1)" :class="selLabelindex==-1?'activity':''" >全部</button>
				<button type="button" @click="selLabelDef(index)" :class="selLabelindex==index?'activity':''" v-for='(item,index) in labelArr'>{{item.name}}</button>
			</div> 
			<a href="javaScript:viod(0)" @click="goMore()" class="more">查看更多 &gt;&gt;</a>
		</div>
		<div class="course-list">
			<template v-for="item in caseList">
			<a href="javaScript:viod(0)" class="course-item" @click="gotoCaseDetail(item.id)">
				<div class="course-cover">
					<img class="img-cover" :src="item.img">
				</div>
				<p class="course-title text-overflow">{{item.name}}</p>
				<div class="course-subtitle-box flex">
					<p class="course-subtitle text-overflow3">{{item.blurb}}</p>
				</div>
			</a>
			</template>
		</div>
	</div>
	

</template>

<script>
	import goTop from '../../utils/goTop.js'
	export default{
		data(){
			return{
				caseList:[],
				selLabelindex:-1,
				tagsList:[],
				labelArr:[]
			}
		},
		mounted() {
			this.getkind()
			this.getShowCate()
		},
		methods:{
			
			selLabelDef(index){
				this.selLabelindex = index
				this.tagsList = []
				if(index != -1){
					this.tagsList.push(this.labelArr[index])
				}
				this.getkind()
			},
			// 获取分类筛选标签
			getShowCate(){
				
				let data = {
					limit:5
				}
				this.$post('/Label/showSorts',data).then(res=>{
					console.log(res)
					this.labelArr = res.data
				})
			},
			
			// 获取配音方案
			getkind(){
				let labelidStr = []
				for(let i = 0;i<this.tagsList.length;i++){
					labelidStr.push(this.tagsList[i].id)
				}
				let data = {
					page:1,
					limit:10,
					label:labelidStr[0]?labelidStr[0]:''
				}
				this.$post('/Audio/kind',data).then(res=>{
					console.log(res)
					this.caseList = res.data.list
				})
			},
			// 进入更多
			goMore(){
				this.$router.push({
				    path: '/dubbingScheme',
				})
				goTop()
			},
			// 配音老师详情页
			gotoCaseDetail(caseId){
				this.$router.push({
				    path: '/dubbingScheme/schemeDetail',
				    query: {
				        caseId
				    }
				})
				goTop();
			},
		}
	}
</script>

<style>
	.title button{
		padding: 10px;border-radius: 4px;margin-left: 10px
	}
	.activity{
		background-color: #ea4335;
		color: white
	}
	.title button:hover{
		background-color: #ea4335;
		color: white
	}
	@media screen and (max-width: 1680px){
		.college-wrap .course-list a:nth-of-type(10),.college-wrap .course-list a:nth-of-type(9){
		    display: none;
		}
	}
	@media screen and (max-width: 1440px){
		.college-wrap .course-list a:nth-of-type(8),.college-wrap .course-list a:nth-of-type(7){
		    display: none ;
		}
	}
</style>
