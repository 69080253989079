<template>
	<div id="content" class="content sound-content">
		<div class="sound-top-box bannner">
			<el-carousel trigger="click" height="300px">
			    <el-carousel-item v-for="(item, index) in lunboList" :key="index">
			        <img :src="item.ad_pic" class="bannerImg"/>
			    </el-carousel-item>
			</el-carousel>
		</div>
		<div class="con-main">
			<ul class="case-list">
				<li v-for='(item,index) in list' :key="index">
					<img :src="item.logo" class="titLogo"/>
					<p class="tit">{{item.name}}</p>
					<p class="price">最低&nbsp;&nbsp;&nbsp;<span>{{item.price}}</span></p>
					<div class="contentBox">
						<p v-for="(serve, serveIndex) in item.serve" :key="serveIndex">
							<img src="../../assets/image/humanTranslation/dui.png" />
							<span>{{serve}}</span>
						</p>
					</div>
					<p class="price">{{item.blurb}}</p>
					<button @click="showKefu">在线联系</button>
				</li>
			</ul>
		</div>
		<el-dialog :visible.sync="keFuDialogThree_dialogVisible" width="480px" :show-close='false'
			class="kefuBox3"  :modal-append-to-body='false'>
			<keFuDialogThree :dialogVisible_content.sync="keFuDialogThree_dialogVisible" :kefuObj='kefuObj3'></keFuDialogThree>
		
		</el-dialog>
	</div>
</template>

<script>
	import goTop from '../../utils/goTop.js'
	import keFuDialogThree from "@/components/keFuThree.vue"
	export default {
		components: {
			keFuDialogThree
		},
		data() {
			return {
				list:[],
				lunboList:[],
				kefuBoxShow:false,
				kefuObj:{},
				keFuDialogThree_dialogVisible:false,
				kefuObj3: {
					type:'',
					image:''
				},
			}
		},
		mounted(){
			this.getTranslate()
			this.getAdByTag()
		},
		methods:{
			showKefu(){
				var that=this;
				this.$post('/Index/getKfCodeImg', {ename:'onekefu_img'}).then(res => {
					console.log(res)
					that.keFuDialogThree_dialogVisible=true;
					that.kefuObj3={
						type:4,
						image:res.data.code
					}
				})
			},
			getTranslate(){
				this.$post('/Audio/translate',{}).then(res=>{
					console.log(res)
					this.list = res.data
				})
			},
			getAdByTag(){
				this.$post('/Ad/getAdByTag',{tag:'pc_translate_banner'}).then(res=>{
					console.log(res)
					this.lunboList = res.data
				})
			},
			
			// 配音老师详情页
			gotoCaseDetail(caseId){
				this.$router.push({
				    path: '/dubbingScheme/schemeDetail',
				    query: {
				        caseId
				    }
				})
				goTop();
			},
			
		}
	}
</script>

<style scoped>
	
	.content{
		padding: 0 !important;
	}
	.bannner{
		height: 300px;
		margin-top: 0;
	}
	.sound-content .sound-top-box{
		padding: 0;
	}
	/deep/.el-carousel__item .bannerImg{
		width: 100%;
		height: 100%;
		display: block;
	}
	.con-main{
		width: 1400px;
	}
	.con-main .case-list{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	}
	.sound-content .con-main .case-list{
		margin: 50px 0 0 0;
	}
	.sound-content .con-main .case-list>li{
		width: 335px;
		height: 480px;
		padding: 30px 40px;
		box-sizing: border-box;
	}
	.con-main .case-list li .titLogo{
		width: 50px;
		height: 50px;
		display: block;
		margin: 0 auto;
		transition: all .3s;
		cursor: pointer;
	}
	.con-main .case-list li .titLogo:hover{
		transform: translateY(-5px);
	}
	.con-main .case-list li .tit{
		font-size: 20px;
		font-weight: 600;
		margin: 20px 0;
		text-align: center;
	}
	.con-main .case-list li .price{
		color:#666666;
		font-size: 14px;
		text-align: center;
	}
	.con-main .case-list li .price span{
		color:#fa3f3f;
		font-size: 18px;
	}
	.con-main .case-list li .contentBox{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin: 30px 0;
	}
	.con-main .case-list li .contentBox>p{
		width:48%;
		display: flex;
		align-items: center;
		margin: 10px 0;
	}
	.con-main .case-list li .contentBox>p>img{
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
	.con-main .case-list li button{
		width: 100%;
		max-width: 250px;
		height: 50px;
		border-radius: 25px;
		background-color: #fa3f3f;
		color:#fff;
		text-align: center;
		line-height: 50px;
		margin-top: 30px;
		transition: all .3s;
	}
	.con-main .case-list li button:hover{
		transform: scale(1.03);
	}
	@media only screen and (max-width: 1680px) {
		.con-main {
			width: 1200px;
		}
		.con-main .case-list{
			grid-template-columns: repeat(3, 1fr);
		}
		.sound-content .con-main .case-list>li{
			width: 385px;
		}
	}
	
	@media only screen and (max-width:1440px) {
		.con-main {
			width: 1000px
		}
		.sound-content .con-main .case-list>li{
			width: 320px;
		}
		.con-main .case-list{
			grid-template-columns: repeat(3, 1fr);
		}
	}
</style>
