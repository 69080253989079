<template>
	<div>
		<audio :src="audioSrc" ref="audio" autoplay></audio>
	</div>
</template>

<script>

import {defineComponent} from "vue";
import {audition} from "@/request/aiDubbing";

export default {
	props:{
		aSrc:{
			type:String,
			default:''
		}
	},
	data(){
		return {
			audioSrc:''
		}
	},
	watch:{
		aSrc(){
			if(!this.aSrc) return
			this.audioSrc = this.aSrc
		}
	},
	mounted() {
		this.$refs.audio.onended = this.audioEnded
		this.$refs.audio.onplaying = this.audioPalying
	},
	methods:{
		async getAudio(queryParams){
			try{
				if(!queryParams.speakerId) return
				const {data} = await audition(queryParams)
				if(data && data.voiceUrl) {
					this.audioSrc = data.voiceUrl
				}
			}catch (e) {
				console.log(e)
			}
		},
		stopAudio(){
			this.audioSrc = ''
		},
		audioEnded(e){
			this.audioSrc = ''
			this.$emit('playEnd')
		},
		audioPalying(){
			this.$emit('playing')
		}
	}
}
</script>

<style scoped>

</style>