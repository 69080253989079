<template>
	<div class="dubbing-search" @click="toolShow=false">
		<input type="text" v-model="searchText" placeholder="发音人名称，编号，领域，年龄，性别，语言，情感风格等进行搜索" @keydown.enter="search" @input="searchChange">
		<div class="search-icon" @click.stop="openSearchTool(0)">
			<img src="@/assets/image/aiDubbing/lj.png" alt="">
		</div>
		<div class="search-icon" @click.stop="openSearchTool(1)">
			<img src="@/assets/image/aiDubbing/yf.png" alt="">
		</div>
		<div class="search-btn" @click.stop="search">
			<img src="@/assets/image/aiDubbing/search.png" alt="">
			<span>声音搜索</span>
		</div>
		<!--		-->
		<div data-v-4d54375d="" data-v-010bc507="" class="voice-search-popper" v-if="toolShow" @click.stop>
			<div data-v-4d54375d="" class="header">
				<div data-v-4d54375d="" class="title">
					<span data-v-4d54375d="">听声识人</span>
					<span data-v-4d54375d="" class="title-intro">根据音视频中的声音搜索相似音色的配音师 </span>
				</div>
				<div data-v-4d54375d="" class="close">
					<i data-v-4d54375d="" class="iconfont moyin-bt_close_gray"></i>
				</div>
			</div>
			<div data-v-4d54375d="" class="content">
				<div data-v-4d54375d="" class="el-tabs el-tabs--top">
					<div class="el-tabs__header is-top">
						<div class="el-tabs__nav-wrap is-top">
							<div class="el-tabs__nav-scroll">
								<div role="tablist" class="el-tabs__nav is-top" style="transform: translateX(0px);">
									<div class="el-tabs__active-bar is-top" :style="`width: 70px; transform: translateX(${tabActive===0?0:99}px);`"></div>
									<div
											id="tab-link"
											aria-controls="pane-link"
											role="tab"
											aria-selected="true"
											tabindex="0"
											class="el-tabs__item is-top"
											:class="{'is-active': tabActive===0}" @click.stop="tabActive=0"
									>
										短视频链接
									</div>
									<div
											id="tab-file"
											aria-controls="pane-file"
											role="tab"
											tabindex="-1"
											class="el-tabs__item is-top"
											:class="{'is-active': tabActive===1}"
											@click.stop="tabActive=1"
									>
										音视频文件
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="el-tabs__content">
						<div v-show="tabActive === 0" data-v-4d54375d="" role="tabpanel" id="pane-link" aria-labelledby="tab-link" class="el-tab-pane">
							<div data-v-4d54375d="" class="link-box">
								<div data-v-4d54375d="" class="el-textarea" style="width: 100%;">
									<textarea v-model="url" autocomplete="off" placeholder="目前支持抖音、快手、西瓜视频链接" class="el-textarea__inner" style="min-height: 60px; height: 60px;"></textarea>
								</div>
								<div data-v-4d54375d="" class="button-box" style="bottom: 5px">
									<div data-v-4d54375d="" class="button disabled" @click="url = ''"> 清空 </div>
									<div data-v-4d54375d="" class="button" @click="pasteText">粘贴</div>
								</div>
							</div>
						</div>
						<div v-show="tabActive === 1" data-v-4d54375d="" role="tabpanel" id="pane-file" aria-labelledby="tab-file" class="el-tab-pane" style="">
							<div data-v-4d54375d="" class="link-box">
								<div data-v-4d54375d="" class="upload-icon">
									<i data-v-4d54375d="" class="iconfont moyin-icon_up"></i>
								</div>
								<p data-v-4d54375d="" class="file-size-tip"> {{fileName?fileName:"点击上传音视频文件，大小限制30M"}} </p>
								<input data-v-4d54375d="" type="file" accept="audio/wav,audio/mp3" class="videoFile_input" @input="fileInput">
							</div>
						</div>
					</div>
				</div>
				<div data-v-4d54375d="" class="search-button myBtn">
					<button data-v-4d54375d="" type="button" @click.stop="toolSearch">
						<span>{{pipeiStatus?'匹配中':'开始搜索'}}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {searchByFilePage} from "@/request/aiDubbing";

export default {
	data(){
		return {
			searchText:"",
			inputTimer:null,
			tabActive:0,
			url:'',
			fileName:"",
			file:null,
			toolShow:false,
			pipeiStatus:false,
		}
	},
	mounted() {
		document.addEventListener('click', this.pageClick)
	},
	beforeDestroy() {
		document.removeEventListener('click', this.pageClick)
	},
	methods:{
		openSearchTool(index){
			this.tabActive = index
			this.toolShow = !this.toolShow
		},
		pageClick(e){
			this.toolShow = false
		},
		search(){
			this.toolShow = false
			this.$emit('search', this.searchText)
		},
		searchChange(){
			if(this.inputTimer) return
			this.inputTimer = setTimeout(()=>{
				this.$emit('searchChange', this.searchText)
				if(this.inputTimer){
					clearTimeout(this.inputTimer)
					this.inputTimer = null
				}
			},500)
		},
		async pasteText(){
			try {
				const str = await navigator.clipboard.readText();
				if(str){
					this.url = str
				}
			} catch (err) {
				console.error('Failed to read clipboard contents:', err);
			}
		},
		async toolSearch(){
			if(this.pipeiStatus) return
			this.pipeiStatus = true
			const params = {}
			if(this.tabActive === 0){
				params.url = this.url
			}
			if(this.tabActive === 1){
				if(!this.file) return
				params.file = this.file
			}
			this.searchText = ''
			try{
				const {data} = await searchByFilePage(params)
				if(data && data.list){
					this.$emit('searchList', data)
				}else{
					this.$message.warning('未找到匹配的配音')
				}
				this.pipeiStatus = false
			}catch (e) {
				console.log(e)
				this.pipeiStatus = false
			}
		},
		// 获取文件，上传文件
		fileInput(e){
			const file = e.target.files[0]
			if(file){
				this.file = file
				this.fileName = file.name
			}else{
				this.file = null
			}
		}
	}
}
</script>

<style scoped lang="scss">
.dubbing-search{
	margin: 0 auto;
	display: flex;
	align-items: center;
	box-shadow: 0 0 5px 0 #F74847;
	max-width: 900px;
	background: #fff;
	border-radius: 6px;
	position: relative;
}
input{
	flex: 1;
	height: 58px;
	background: none;
	border: none;
	padding: 0 20px;
}
.search-icon{
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background: #f2f2f2;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
	cursor: pointer;
	transition: all .3s ease-in-out;
	&:hover{
		background: #e7e5e5;
	}
}
.search-icon img{
	width: 24px;
	height: 24px;
}
.search-btn{
	display: flex;
	background: #F74847;
	height: 58px;
	flex: 0 0 166px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 14px;
	&:hover{
		background: linear-gradient(270deg, #ff504e, #ff2d2d);
	}
	img{
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
	span{
		color: #fff;
		font-size: 16px;
	}
}
.voice-search-popper[data-v-4d54375d] {
	width: 420px;
	height: 340px;
	z-index: 2023;
	background: #fff;
	position: absolute;
	bottom: -350px;
	right:-100px;
	border-radius: 10px;
	box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
}

.voice-search-popper .header[data-v-4d54375d] {
	padding: 28px 28px 12px;
	border-bottom: 1px solid #e5e5e5;
	display: flex
}

.voice-search-popper .header .title[data-v-4d54375d] {
	flex: 1;
	width: 160px;
	height: 22px;
	font-size: 16px;
	font-weight: 400;
	color: #333;
	line-height: 22px
}

.voice-search-popper .header .title-intro[data-v-4d54375d] {
	width: 216px;
	height: 16px;
	font-size: 12px;
	color: #999;
	line-height: 16px;
	margin-left: 5px
}

.voice-search-popper .header .close[data-v-4d54375d] {
	width: 20px;
	height: 20px;
	font-size: 20px;
	line-height: 20px;
	margin: 0
}

.voice-search-popper .content[data-v-4d54375d] {
	padding: 27px 28px 28px
}

.voice-search-popper .content[data-v-4d54375d] .el-tabs .el-tabs__header {
	margin-bottom: 16px
}

.voice-search-popper .content[data-v-4d54375d] .el-tabs .el-tabs__nav-wrap:after {
	background: transparent
}

.voice-search-popper .content[data-v-4d54375d] .el-tabs .el-tabs__active-bar {
	background-color: #F74847
}

.voice-search-popper .content[data-v-4d54375d] .el-tabs .el-tabs__item {
	padding: 0 14px;
	height: 26px;
	font-size: 14px;
	font-weight: 400;
	color: #333;
	line-height: 14px
}

.voice-search-popper .content[data-v-4d54375d] .el-tabs .is-top:nth-of-type(2) {
	padding-left: 0
}

.voice-search-popper .content[data-v-4d54375d] .el-tabs .is-active {
	color: #F74847
}

.voice-search-popper .content .link-box[data-v-4d54375d] {
	width: 354px;
	height: 108px;
	background: #edf2f5;
	border-radius: 8px;
	border: 2px dashed #ccc;
	box-sizing: border-box;
	padding: 16px;
	position: relative
}

.voice-search-popper .content .link-box[data-v-4d54375d] .el-textarea__inner {
	background: transparent;
	padding: 0;
	font-size: 14px;
	font-weight: 400;
	color: #333;
	line-height: 20px;
	border: none;
	resize: none;
	outline: none;
	margin-bottom: 5px;
}

.voice-search-popper .content .link-box[data-v-4d54375d] .el-textarea__inner::placeholder {
	color: #bbb
}

.voice-search-popper .content .link-box .button-box[data-v-4d54375d] {
	position: absolute;
	right: 12px;
	bottom: 12px;
	width: 100%;
	height: 22px
}

.voice-search-popper .content .link-box .button[data-v-4d54375d] {
	height: 22px;
	font-size: 14px;
	font-weight: 400;
	color: #F74847;
	line-height: 22px;
	margin-left: 18px;
	float: right;
	cursor: pointer
}

.voice-search-popper .content .link-box .disabled[data-v-4d54375d] {
	color: #999
}

.voice-search-popper .content .link-box .upload-icon[data-v-4d54375d] {
	width: fit-content;
	margin: 7px auto 0
}

.voice-search-popper .content .link-box .upload-icon .moyin-icon_up[data-v-4d54375d] {
	font-size: 25px;
	color: #F74847
}

.voice-search-popper .content .link-box .upload-item[data-v-4d54375d] {
	margin-top: -4px
}

.voice-search-popper .content .link-box .file-name[data-v-4d54375d] {
	font-size: 12px;
	font-weight: 400;
	color: #333;
	line-height: 18px
}

.voice-search-popper .content .link-box .reupload[data-v-4d54375d] {
	position: absolute;
	right: 12px;
	bottom: 12px;
	width: 48px;
	height: 16px;
	font-size: 12px;
	font-weight: 400;
	color: #F74847;
	line-height: 16px;
	z-index: 2;
	cursor: pointer
}

.voice-search-popper .content .link-box .reupload .videoFile_input[data-v-4d54375d] {
	cursor: pointer
}

.voice-search-popper .content .link-box .file-size-tip[data-v-4d54375d] {
	height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #bbb;
	line-height: 20px;
	margin-top: 12px;
	text-align: center
}

.voice-search-popper .content .link-box .videoFile_input[data-v-4d54375d] {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer
}

.voice-search-popper .content .searching[data-v-4d54375d] {
	display: inline-block;
	margin-top: 26px;
	width: 354px
}

.voice-search-popper .content .searching .search-tip[data-v-4d54375d] {
	height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #333;
	line-height: 20px;
	margin-top: 22px;
	text-align: center
}

.voice-search-popper .content .search-button[data-v-4d54375d] {
	margin: 30px auto 0
}

.voice-search-popper .content .myBtn[data-v-4d54375d] {
	width: 104px;
	height: 36px;
	background: #F74847;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	line-height: 36px;
	text-align: center;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0;
	border: none;
	&:hover{
		background: linear-gradient(270deg, #ff504e, #ff2d2d);
	}
}

.voice-search-popper .content .cancel-search[data-v-4d54375d] {
	border: 1px solid #dcdfe6;
	background: #fff;
	color: #333;
	line-height: 34px;
}
</style>