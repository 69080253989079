<template>
	<div class="info-modal" @click.stop>
		<div class="modal">
			<img class="close" src="../../assets/image/aiDubbing/close-icon.png" alt="" @click.stop="close">
			<div class="user">
				<div class="user-head">
					<div class="head">
						<img class="head-img" :src="currentZB.profilePhoto || currentSelectDubbing.profilePhoto" alt="">
					</div>
				</div>
				<div class="user-tips">
					<div class="name">
						<span class="text">{{currentZB.nickName || currentSelectDubbing.nickName}}</span>
					</div>
					<p class="to">{{currentZB.introduce || currentSelectDubbing.introduce}}</p>
				</div>
			</div>
			<div class="tab">
				<div class="tab-item" :class="{active: tabActive === 0}" @click="tabActive = 0">
					<img src="@/assets/image/aiDubbing/vipIcon0.png" alt="">
					<span>普通会员</span>
				</div>
				<div class="tab-item" :class="{active: tabActive === 1}" @click="tabActive = 1">
					<img src="@/assets/image/aiDubbing/vipIcon1.png" alt="">
					<span>高级会员</span>
				</div>
			</div>
			<div class="vip-price">
				<div class="price-item" :class="{active: selectId === item.id}" v-for="item of vipInfo.priceList" :key="item.id" @click="selectPrice(item)">
					<p class="vip-time">{{item.name}}</p>
					<p class="price-months"><span>￥{{item.amount}}</span><span>{{item.price}}元/月</span></p>
				</div>
			</div>
			<div class="equity">
				<p class="title">高级会员可享权益</p>
				<div class="eq-list">
					<div class="eq-item" v-for="item of vipInfo.benefitsList">
						<img :src="item.icon" alt="">
						<span class="eq-type">{{item.title}}</span>
						<p class="eq-tips">
							<span class="active"> {{item.description.substring(0,3)}}</span>
							<span>{{item.description.substring(3)}}</span>
						</p>
					</div>
				</div>
			</div>
			<div class="pay">
				<!--	wx pay	-->
				<div class="pay-qrcode" v-if="checked === 1" id="myQrCode"  v-loading="qrcodeLoading"  element-loading-text="加载中" ref="myQrCode"></div>
				<!--	ali	pay	-->
				<iframe
						v-if="checked === 2"
						class="pay-qrcode-ali"
						v-loading="qrcodeLoading"
						element-loading-text="加载中"
						:srcdoc="qrData.urlCode"
						frameborder="0"
						marginwidth="0"
						marginheight="0"
						scrolling="no"
						width="120"
						height="120">
				</iframe>
				<div class="pay-methods">
					<div class="methods">
						<p class="price"><span>￥</span>{{selectItem.amount}}</p>
						<div class="select-pla">
							<p :class="{active: checked === 1}">
								<el-radio class="check-box" v-model="checked" :label="1">
									<img src="@/assets/image/aiDubbing/wx.png" alt="">
									<span>微信扫码支付</span>
								</el-radio>
							</p>
							<p :class="{active: checked === 2}">
								<el-radio class="check-box" v-model="checked" :label="2">
									<img src="@/assets/image/aiDubbing/zhifubao.png" alt="">
									<span>支付宝扫码支付</span>
								</el-radio>
							</p>
						</div>
					</div>
					<div class="protocol">
						<el-checkbox class="check-box" v-model="checked3"></el-checkbox>
						<span>我已阅读并同意</span>
						<a href="#" target="_blank">《用户协议》</a>
					</div>
				</div>
				<div class="explain-text">
					<p class="title">规则说明</p>
					<p>1.多次购买会员，有效期叠加</p>
					<p>2.购买后立即生效，不可退款</p>
				</div>
			</div>

		</div>
  </div>
</template>

<script>
import {aliCreateOrder, getPriceList, queryOrder, wxCreateOrder} from "@/request/aiDubbing";
import QRCode from 'qrcodejs2';
import {mapActions, mapGetters} from "vuex";

export default {
	props:{
		currentZB:{
			type:Object,
			default:()=>{}
		}
	},
	data(){
		return {
			tabActive: 1,
			priceList: [],
			selectId: null,
			selectItem: {},
			checked: 1,
			checked3:true,
			qrcodeLoading:false,
			qrData:{},
			orderTimer:null,
		}
	},
	mounted() {
		this.getData()
	},
	watch:{
		selectId(){
			if(!this.qrcodeLoading) this.createOrder()
		},
		checked(){
			if(!this.qrcodeLoading) this.createOrder()
		}
	},
	computed:{
		...mapGetters(['currentSelectDubbing']),
		vipInfo(){
			if(!this.priceList.length) return {}
			this.selectId = this.priceList[this.tabActive].priceList[0].id
			this.selectItem = this.priceList[this.tabActive].priceList[0]
			return this.priceList[this.tabActive]
		}
	},
	methods:{
		...mapActions(['getAiUserInfo']),
		close(){
			this.$emit('close')
		},
		selectPrice(priceItem){
			this.selectId = priceItem.id
			this.selectItem = priceItem
		},
		async getData(){
			try{
				const {data} = await getPriceList()
				this.priceList = data
			}catch (e) {
				console.log(e)
			}
		},
		async createOrder(){
			try{
				this.qrcodeLoading = true
				const params = {
					priceId: this.selectItem.id,
					amount: this.selectItem.amount
				}
				this.qrData = {}
				if(this.checked  === 1){ // 微信
					const {data} = await wxCreateOrder(params)
					this.qrData = data
					this.generateCode(this.qrData.urlCode)
				}
				if(this.checked === 2){ // 支付宝
					const {data} = await aliCreateOrder(params)
					this.qrData = data
				}
				this.qrcodeLoading = false
				this.checkOrder()
			}catch (e) {
				this.qrcodeLoading = false
			}
		},
		generateCode(code) {
			document.getElementById("myQrCode").innerHTML = ""
			try {
				new QRCode("myQrCode", {
					width: 200, //宽度
					height: 200, // 高度
					text: code
				});
			} catch (e) {
				console.log(e);
			}
		},
		checkOrder(){
			try{
				if(this.orderTimer){
					clearInterval(this.orderTimer)
					this.orderTimer = null
				}
				this.orderTimer = setInterval(async ()=>{
					const {data} = await queryOrder({orderId: this.qrData.order_no})
					if(data.status === 1){
						clearInterval(this.orderTimer)
						this.orderTimer = null
						this.$message({
							message: '支付成功',
							type: 'success'
						})
						await this.getAiUserInfo()
						this.close()
					}
					if(data.status === 2 || data.status === 3){
						clearInterval(this.orderTimer)
						this.orderTimer = null
						await this.createOrder()
						this.$message({
							message: '支付失败',
							type: 'success'
						})
					}
				}, 5000)
			}catch (e) {
				console.log(e)
			}
		}
	},
	beforeDestroy() {
		if(this.orderTimer){
			clearInterval(this.orderTimer)
			this.orderTimer = null
		}
	}
}
</script>

<style scoped lang="scss">
.info-modal{
	position: fixed;
	top:0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 99;
	background: rgba(0,0,0,.5);
	display: flex;
	align-items: center;
	justify-content: center;
}
.modal{
	width: 720px;
	border-radius: 5px;
	box-sizing: border-box;
	position: relative;
	background: #fff;
	overflow: hidden;
}
.close{
	position: absolute;
	width: 14px;
	height: 14px;
	right: 20px;
	top: 20px;
	cursor: pointer;
}
.user{
	background: linear-gradient(#fdc4c4, #FFFFFF 95%);
	display: flex;
	align-items: center;
	padding: 30px 20px 0;
	.user-head{

		.head{
			width: 88px;
			height: 88px;
			border: 1px solid #fff;
			border-radius: 50%;
			position: relative;
			margin-right: 20px;
			.head-img{
				width: 100%;
				height: 100%;
				border-radius: 100%;
			}
			.vip{
				width: 44px;
				height: 20px;
				position: absolute;
				left: 50%;
				margin-left: -22px;
				bottom: -6px;
			}
		}

	}
	.user-tips{
		flex: 1;
		.name{
			display: flex;
			align-items: center;
			margin-bottom: 14px;
		}
		.text{
			font-size: 20px;
			color: #333;
			font-weight: bold;
			margin-right: 10px;
		}
		.type{
			width: 62px;
			flex: 0 0 62px;
			height: 20px;
			background: #FF6766;
			color: #fff;
			font-size: 12px;
			text-align: center;
			line-height: 20px;
			margin-right: 10px;
			border-radius: 2px;
		}
		.col,.share{
			width: 14px;
			height: 14px;
		}
		.share{
			width: 10px;
			height: 10px;
			margin-left: 6px;
		}
	}
	.to{
		font-size: 13px;
	}
}
.tab{
	display: flex;
	margin: 16px 0 0;
	background: #fff;
	.tab-item{
		flex: 1;
		display: flex;
		justify-content: center;
		height: 50px;
		align-items: center;
		cursor: pointer;
		&.active{
			background: #fdebeb;
		}
		img{
			margin-right: 4px;
			width: 22px;
			height: 18px;
		}
		span{
			color: #333333;
		}
	}
}
.vip-price{
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	margin: 12px 0;
	box-sizing: border-box;
	.price-item{
		flex: 0 0 210px;
		height: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px solid #F2F2F2;
		border-radius: 10px;
		box-sizing: border-box;
		&.active{
			border: 1px solid #E93A3A;
		}
		.vip-time{
			color: #5F6FFF;
		}
		.price-months{
			font-size: 12px;
			color: #666666;
			span:nth-child(1){
				font-size: 30px;
				color: #000000;
				padding-right: 4px;
				font-weight: bold;
			}
		}
	}
}
.equity{
	margin: 0 20px 10px;
	background: #811B33;
	border-radius: 10px;
	padding: 10px;
	.title{
		font-size: 15px;
		color: #FFFFFF;
		margin-bottom: 10px;
	}
	.eq-list{
		display: flex;
		justify-content: space-between;
	}
	.eq-item{
		display: flex;
		flex-direction: column;
		align-items: center;
		img{
			width: 40px;
			height: 40px;
			margin-bottom: 6px;
		}
		.eq-type{
			font-size: 13px;
			color: #FFFFFF;
			margin-bottom: 2px;
		}
		.eq-tips{
			font-size: 12px;
			color: #fff;
			.active{
				color: #F7A4B7;
			}
		}
	}
}
.pay{
	display: flex;
	align-items: center;
	border: 1px solid #F2F2F2;
	border-radius: 10px;
	margin: 0 20px 20px;
	padding: 20px;
	.pay-qrcode{
		flex: 0 0 120px;
		height: 120px;
		margin-right: 20px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.pay-qrcode-ali{
		flex: 0 0 120px;
		height: 120px;
		margin-right: 20px;
	}
	.pay-methods{
		flex: 1;
		.price{
			color: #F94B4B;
			font-size: 30px;
			font-weight: bold;
			margin-bottom: 12px;
			span{
				font-size: 16px;
			}
		}
		.select-pla{
			margin-bottom: 10px;
			p{
				display: flex;
				align-items: center;
				margin-bottom: 7px;
				&.active{
					span{
						color: #F94B4B;
					}
				}
			}
			.check-box{
				margin-right: 4px;
			}
			img{
				width: 18px;
				height: 18px;
				margin-right: 3px;
			}
			span{
				color: #333;
			}
		}
	}
	.protocol{
		display: flex;
		align-items: center;
		font-size: 13px;
		.check-box{
			margin-right: 4px;
		}
		a{
			color: #00a1d6;
		}
	}
	.explain-text{
		border-left: 1px solid #F2F2F2;
		padding: 20px 0 20px 20px;
		color: #666;
		.title{
			margin-bottom: 5px;
		}
	}
}
</style>