<template>
  <div class="info-modal" @click="close">
    <transition name="el-fade-in-linear">
      <div class="modal" v-if="details.id" @click.stop>
        <img
          class="close"
          src="@/assets/image/aiDubbing/close-icon.png"
          alt=""
          @click.stop="close"
        />
        <div class="user">
          <div class="user-head">
            <div class="head">
              <img class="head-img" :src="details.profilePhoto" alt="" />
              <img
                v-if="details.svip === 1"
                class="vip"
                src="@/assets/image/aiDubbing/svip-icon.png"
                alt=""
              />
            </div>
          </div>
          <div class="user-tips">
            <div class="name">
              <span class="text">{{ details.nickName }}</span>
              <span class="type" v-if="details.styleList.length > 1"
                >情感主播</span
              >
              <i
                class="col"
                :class="`${
                  details.collectFlag
                    ? 'el-icon-star-on active'
                    : 'el-icon-star-off'
                }`"
                @click="addConcernSpeaker"
              ></i>
              <img
                @click="share"
                class="share"
                src="@/assets/image/aiDubbing/share.png"
                alt=""
              />
            </div>
            <p class="to">{{ details.introduce }}</p>
          </div>
          <div class="nums">
            <p class="title">播放量</p>
            <p class="num">
              <img src="@/assets/image/aiDubbing/hot-icon.png" alt="" />
              {{
                details.playerCount < 10000
                  ? "1w"
                  : (details.playerCount / 10000).toFixed(1) + "万"
              }}
            </p>
          </div>
        </div>
        <div class="qg">
          <p class="tips">{{details.audition}}</p>
          <div class="list">
            <div class="item" v-for="(item, index) of styleList" :key="index">
              <span
                class="icon"
                :class="{ play: index === playIndex }"
                @click="play(item, index)"
                :style="{ background: item.rgb }"
              >
                <span>{{ item.label.substring(0, 1) }}</span>
                <i class="el-icon-loading"></i>
              </span>
              <span class="text">{{ item.label }}</span>
            </div>
          </div>
        </div>
        <p class="now-go">
          <span @click="toEditor"><img src="@/assets/image/aiDubbing/erji-icon.png" alt="">立即配音</span>
          <span class="buy-vip-btn" @click="buVIP"><img src="@/assets/image/aiDubbing/buy-icon.jpg" alt="">购买声音</span>
        </p>
	      <p class="open-vip">成为{{details.svip?'SVIP超级':'VIP'}}会员,可以免费使用ta的声音</p>
        <template
          v-if="details.caseWorkVoList && details.caseWorkVoList.length"
        >
          <h4 class="case-title">作品案例</h4>
          <div class="case-list">
            <div
              class="case-item"
              v-for="item of details.caseWorkVoList"
              :key="item.id"
              @click="toLink(item.caseUrl)"
            >
              <img :src="item.casePic" alt="" />
              <span :title="item.caseTitle"
                >{{ item.caseTitle }}{{ item.caseDescription }}</span
              >
            </div>
          </div>
        </template>
      </div>
    </transition>
    <!-- 试听情绪	-->
    <audio-play ref="audioPlay" @playEnd="playEnd" />
  </div>
</template>

<script>
import { concernSpeaker, getSpeakerById } from "@/request/aiDubbing";
import { copyTextToClipboard, getRandomColorRGB } from "@/utils/func";
import {mapActions, mapMutations} from "vuex";
import AudioPlay from "@/components/aiDubbing/audioPlay.vue";
import collect from "@/assets/image/aiDubbing/xx-icon.png";
import collectActive from "@/assets/image/aiDubbing/xx-icon-active.png";
import openVipModal from "@/components/aiDubbing/openVipModal.vue"

export default {
  components: { AudioPlay, openVipModal},
  props: {
    buddingData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      details: {},
      playIndex: null,
      collect,
      collectActive,
	    buyVipShow:false,
    };
  },
  mounted() {
    this.getDetails();
  },
  computed: {
    styleList() {
      return this.details.styleList.map((item) => {
        return {
          label: item,
          rgb: this.getRandomColorRGB(),
        };
      });
    },
  },
  methods: {
    ...mapMutations(["setCurrentSelectDubbing"]),
	  ...mapActions(['getAiUserInfo']),
	  async buVIP(){
			try{
				await this.getAiUserInfo(true);
				this.$parent.buyVipShow = true;
				this.code()
			}catch (e) {
				this.close();
			}
	  },
    getRandomColorRGB,
    toLink(url) {
      window.open(url);
    },
    share() {
      const shareUrl = `${window.location.origin}/#/aiDubbing?id=${this.details.id}`;
      console.log(shareUrl);
      copyTextToClipboard(shareUrl);
      this.$message.success("链接复制成功，去分享吧~");
    },
    async addConcernSpeaker() {
      try {
        await this.getAiUserInfo(true);
        this.details.collectFlag = !this.details.collectFlag;
        const { data, msg } = await concernSpeaker({
          speakerId: this.details.id,
        });
        if (data) {
          //await this.getDetails()
          //this.$message.success('收藏成功')
        } else {
          this.$message.warning(msg);
        }
      } catch (e) {
        console.log(e);
      }
    },
    playEnd() {
      this.playIndex = null;
    },
    toEditor() {
      if (!this.details.id) return;
      this.setCurrentSelectDubbing(this.details);
      this.close();
      this.$router.push("/buddingEditor");
    },
    close() {
      this.$emit("close");
    },
    async getDetails() {
      try {
        const { data } = await getSpeakerById({ id: this.buddingData.id });
        this.details = data;
      } catch (e) {
        console.log(e);
      }
    },
    play(item, index) {
      if (this.playIndex != null) {
        this.playIndex = null;
        this.$refs.audioPlay.stopAudio();
        return;
      }
      this.playIndex = index;
      this.$refs.audioPlay.getAudio({
        speakerId: this.details.id,
        style: item.label,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.info-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
}
.modal {
  width: 650px;
  background: linear-gradient(#fdc4c4, #ffffff 65%);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
.close {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  cursor: pointer;
}
.user {
  display: flex;
  align-items: center;
  padding-top: 10px;
  .user-head {
    .head {
      width: 88px;
      height: 88px;
      border: 1px solid #fff;
      border-radius: 50%;
      position: relative;
      margin-right: 20px;
      .head-img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
      .vip {
        width: 44px;
        height: 20px;
        position: absolute;
        left: 50%;
        margin-left: -22px;
        bottom: -6px;
      }
    }
  }
  .user-tips {
    flex: 1;
    .name {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }
    .text {
      font-size: 20px;
      color: #333;
      font-weight: bold;
      margin-right: 10px;
    }
    .type {
      height: 20px;
      background: #ff6766;
      color: #fff;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      margin-right: 10px;
      border-radius: 2px;
      padding: 0 10px;
    }
    .col,
    .share {
      width: 14px;
      height: 14px;
      color: #666;
      cursor: pointer;
    }
    .col {
      &.active {
        color: #ff6766;
      }
    }
    .share {
      width: 10px;
      height: 10px;
      margin-left: 6px;
    }
  }
  .to {
    font-size: 13px;
    color: #333;
  }
}
.nums {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .title {
    font-size: 12px;
    color: #333;
    margin-bottom: 14px;
  }
  .num {
    color: #666;
    font-size: 12px;
    img {
      position: relative;
      top: -2px;
	    width: 18px;
	    height: 18px;
    }
  }
}
.qg {
  background: #811b33;
  margin-top: 20px;
  border-radius: 10px;
  padding: 14px;
  .tips {
    font-size: 12px;
    color: #fff;
    margin-bottom: 18px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 24px;
      margin-bottom: 20px;
    }
    .icon {
      width: 26px;
      height: 26px;
      color: #fff;
      background: #cd9044;
      border-radius: 50%;
      text-align: center;
      line-height: 26px;
      font-size: 12px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      i {
        display: none;
      }
      &.play {
        span {
          display: none;
        }
        i {
	        display: inline-block;
          font-size: 20px;
        }
      }
    }
  }
  .text {
    font-size: 12px;
    color: #fff;
  }
}
.now-go {
  margin: 20px 0 10px;
  text-align: center;
  user-select: none;
  span {
    cursor: pointer;
    display: inline-block;
    width: 192px;
    height: 48px;
    background: #ed4f42;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    line-height: 48px;
    border-radius: 4px;
	  transition: transform .08s ease-in;
	  &:hover{
		  transform: scale(1.05);
	  }
	  img{
		  width: 22px;
		  height: 22px;
		  margin-right: 8px;
	  }
  }
	.buy-vip-btn{
		background: none;
		border: 1px solid #ed4f42;
		color: #ed4f42;
		margin-left: 20px;
		img{
			width: 18px;
			height: 18px;
			position: relative;
			top: -2px;
			margin-right: 8px;
		}
	}
}
.open-vip {
  text-align: center;
  color: #666666;
  margin-bottom: 10px;
}
.case-title {
  font-size: 16px;
  margin-bottom: 10px;
}
.case-list {
  display: flex;
  .case-item {
    display: flex;
    flex-direction: column;
    width: 75px;
    margin-right: 14px;
	  cursor: pointer;
    img {
      width: 75px;
      height: 133px;
      border-radius: 5px;
      margin-bottom: 4px;
    }
    span {
      font-size: 10px;
      text-align: center;
      color: #666;
      width: 75px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /* 控制显示的行数 */
    }
  }
}
</style>
