<template>
	<div class="dubbing-item">
		<div class="top" @click="buddingModalShow">
			<div class="head">
				<img class="head-img" :src="item.profilePhoto" alt="">
				<img v-if="item.svip === 1" class="svip-img" src="@/assets/image/aiDubbing/svip-icon.png" alt="">
			</div>
			<div class="info">
				<h4>{{item.nickName}}</h4>
				<p class="style">{{item.scopeName}}</p>
				<p class="size">{{item.styleCount}}种风格</p>
			</div>
			<div class="speaker-type" v-if="item.style.split(',').length > 1">
				<span>情绪主播</span>
				<img src="@/assets/image/aiDubbing/mack-icon.png" alt="">
			</div>
		</div>
		<div class="tips">
			<i v-if="showScrollT" class="el-icon-arrow-left" @click="scrollLeft"></i>
			<p :style="{whiteSpace: showMore ? 'normal' : 'nowrap', padding: showScrollT?'12px 0':'12px'}" ref="styleP">
				<span v-if="item.language">{{item.language}}</span>
				<span v-if="gender">{{gender}}</span>
				<template v-if="item.style !== '默认'">
					<span v-for="item of item.style.split(',')">{{item}}</span>
				</template>
			</p>
<!--			<span class="more" :class="{active: showMore}"  @click="showMore = !showMore"><img src="@/assets/image/aiDubbing/check-more.png" alt=""></span>-->
			<i  v-if="showScrollT" class="el-icon-arrow-right" @click="scrollRight"></i>
		</div>
		<div class="hot">
			<div class="num">
				<img src="@/assets/image/aiDubbing/hot-icon.png" alt="">
				<!--		使用量，不足一万直接显示，超过一万以万为单位显示		-->
				<span>{{ item.playerCount>10000? (item.playerCount/10000).toFixed(1) + 'w' : '1w'}}</span>
			</div>
			<div class="col">
				<img v-if="item.collectFlag" src="@/assets/image/aiDubbing/dubbing1.png" alt="" @click="addConcernSpeaker">
				<img  v-else src="@/assets/image/aiDubbing/like.png" alt="" @click="addConcernSpeaker">
			</div>
		</div>
		<!--	播音人详情弹窗  -->
		<transition name="el-fade-in-linear">
		<dubbing-info-modal v-if="showModal" :buddingData="item" @close="showModal=false"/>
		</transition>
		<!--	VIP  -->
		<transition name="el-fade-in-linear">
			<open-vip-modal v-if="buyVipShow" @close="buyVipShow=false" :currentZB="itemData"/>
		</transition>
	</div>
</template>

<script>
import dubbingInfoModal from "@/components/aiDubbing/dubbingInfoModal.vue";
import {concernSpeaker} from "@/request/aiDubbing";
import {mapActions, mapGetters} from "vuex";
import openVipModal from "@/components/aiDubbing/openVipModal.vue";

export default {
	components: {openVipModal, dubbingInfoModal},
	props:{
		itemData:{
			type:Object,
			default:()=>{}
		}
	},
	data(){
		return {
			showMore: false,
			showModal: false,
			showScrollT: false,
			buyVipShow: false,
		}
	},
	computed:{
		item(){
			return this.itemData
		},
		gender(){
			if(!this.item.gender) return ''
			return this.item.gender===1?'男声':'女声'
		},
		...mapGetters(['aiBuddingUserInfo']),
		listData(){
			return this.item.style.split(',')
		},
	},
	mounted() {
		this.showScroll()
	},
	methods:{
		...mapActions(['getAiUserInfo']),
		showScroll(){
			this.$nextTick(()=>{
				const styleP = this.$refs.styleP
				console.log(styleP.scrollWidth, styleP.clientWidth)
				if(styleP.scrollWidth > styleP.clientWidth){
					this.showScrollT = true
				}else{
					this.showScrollT = false
				}
			})
		},
		buddingModalShow(){
			this.showModal = true
		},
		async addConcernSpeaker(){
			try{
				await this.getAiUserInfo(true)
				if(this.aiBuddingUserInfo && this.aiBuddingUserInfo.id) this.item.collectFlag = !this.item.collectFlag
				const {data, msg} = await concernSpeaker({
					speakerId: this.item.id
				})
				if(data){
					//await this.getDetails()
					//this.$message.success('收藏成功')
				}else{
					this.$message.warning(msg)
				}
			}catch (e) {
				console.log(e)
			}
		},
		scrollRight(){
			const styleP = this.$refs.styleP
			if(styleP.scrollWidth > styleP.clientWidth){
				styleP.scrollLeft = styleP.scrollLeft + 100
			}else{
				styleP.scrollLeft = 0
			}
		},
		scrollLeft(){
			const styleP = this.$refs.styleP
			if(styleP.scrollLeft >= 0){
				if(styleP.scrollLeft > 100){
					styleP.scrollLeft = styleP.scrollLeft - 100
				}else{
					styleP.scrollLeft = 0
				}
			}else{
				styleP.scrollLeft = 0
			}
		}
	}
}
</script>

<style scoped lang="scss">
.dubbing-item{
	width: 282px;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 16px;
	margin-right: 12px;
	cursor: pointer;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
	background: #fff;
	position: relative;
	top: 0;
	transition: top .15s linear;
	&:hover{
		top: -6px
	}
}
.top{
	display: flex;
	align-items: center;
	padding: 20px 20px 34px;
	background: linear-gradient(137deg, rgba(254, 52, 6, 0.4) 0%, rgba(255, 255, 255, .6) 38%, rgba(254, 52, 6, 0.35) 100%);
	position: relative;
	.speaker-type{
		position: absolute;
		top:10px;
		right: -2px;
		width: 61px;
		height: 20px;
		img{
			position: absolute;
			top:0;
			right: 0;
			width: 100%;
			height: 100%;
		}
		span{
			position: relative;
			z-index: 1;
			font-size: 12px;
			display: block;
			text-align: center;
			line-height: 20px;
			color: #fff;
		}
	}
	.head{
		flex: 0 0 88px;
		position: relative;
		margin-right: 26px;
	}
	.head-img{
		width: 88px;
		height: 88px;
		border-radius: 100%;
	}
	.svip-img{
		position: absolute;
		bottom: -8px;
		left: 50%;
		width: 44px;
		height: 20px;
		margin-left: -22px;
	}
	.info{
		h4{
			font-size: 20px;
			color: #333;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.style{
			font-size: 14px;
			color: #666;
			margin-bottom: 10px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			min-height: 36px;
		}
		.size{
			font-size: 14px;
			color: #666;
		}
	}
}
.tips{
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	border-bottom: 1px solid #EEEEEE;
	box-sizing: border-box;
	padding: 0 5px;
	p{
		flex: 1;
		padding: 12px;
		white-space: nowrap;
		overflow-x: auto;
		span{
			padding: 0 12px;
			display: inline-block;
			height: 22px;
			line-height: 22px;
			background: #F4F4F4;
			border-radius: 2px;
			font-size: 12px;
			color: #666;
			margin-right: 12px;
			//margin-bottom: 12px;
		}
	}
	i{
		flex: 0 0 24px;
		height: 24px;
		cursor: pointer;
		border-radius: 100%;
		position: relative;
		z-index: 1;
		background: #fff;
		text-align: center;
		line-height: 24px;
		&:hover{
			background: rgb(253, 204, 193);
			color: #fff;
		}
	}
	.more{
		padding: 0 12px;
		height: 22px;
		display: inline-block;
		margin-top: 12px;
		cursor: pointer;
		transition: all .2s;
		&.active{
			transform: rotate(180deg);
		}
		img{
			width: 12px;
			height: 8px;
		}
	}
}
.hot{
	display: flex;
	align-items: center;
	padding: 14px 20px;
	background: #fff;
	.num{
		flex: 1;
		display: flex;
		img{
			width: 18px;
			height: 18px;
			position: relative;
			top: -3px;
		}
		span{
			font-size: 12px;
			color: #9E9E9E;
		}
	}
	.col{
		background: #ED4F42;
		flex: 0 0 28px;
		height: 28px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		img{
			width: 100%;
			height: 100%;
		}
	}
}
</style>

<style lang="scss">
.dubbing-item .tips p{
	&::-webkit-scrollbar {
		display: none;
	}
	&::-webkit-scrollbar-horizontal {
		display: none;
	}
}
</style>