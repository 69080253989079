import Vue from 'vue'
import Clipboard from 'clipboard'
 
function clipboardSuccess() {
    Vue.prototype.$message({
        message: '复制成功',
        type: 'success',
		customClass: 'message-zindex',
        duration: 1500
    })
}
 
function clipboardError() {
    Vue.prototype.$message({
        message: '复制失败',
		customClass: 'message-zindex',
        type: 'error'
    })
}
 
export default function handleClipboard(event,text) {
    const clipboard = new Clipboard(event.target, {
        text: () => text
    })
    clipboard.on('success', () => {
        clipboardSuccess()
        clipboard.destroy()
    })
    clipboard.on('error', () => {
        clipboardError()
        clipboard.destroy()
    })
    clipboard.onClick(event)
}