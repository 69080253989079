<!-- 地址选择 & 省市区三级联动 -->
<template>
  <el-form ref="form" :model="form" label-width="120px">
    <el-row>
      <el-select v-model="form.province" placeholder="请选择省" @change="changePro">
        <el-option v-for="item in addressData" :key="item.id" :label="item.pro_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="form.city" placeholder="请选择市" @change="changeCity">
        <el-option v-for="item in cityData" :key="item.id" :label="item.city_name" :value="item.id">
        </el-option>
      </el-select>
    </el-row>
    <!-- <h3>测试当前选中地址：{{result}}</h3> -->
  </el-form>
</template>
 
<script>
// import address from "@/utils/address.json"; //全国省市区街道数据
export default {
  data() {
    return {
      //  省数据
      addressData: [],
      //  市数据
      cityData: [],
    };
  },
  props: {
    // 街道数据
    form: {
      province: "",
      // 市
      city: ""
    }
  },
  created() {
	this.$post('/Address/getpro',{}).then(res=>{
		this.addressData = res.data
		var pattern = new RegExp("[\u4E00-\u9FA5]+");
		if(this.form.province && !pattern.test(this.form.province)){
			console.log('***********************')
			console.log(this.form.province)
			this.$post('/Address/getcity',{pro_id:this.form.province}).then(res=>{
			this.cityData = res.data
			let proData = this.addressData.filter(item => item.id == this.form.province)
			this.form.province = proData[0].pro_name

			let cityData = this.cityData.filter(item => item.id == this.form.city)
			this.form.city = cityData[0].city_name
			})
		}
		
	})
	
	
   
  },
	methods: {
		reset() {
		  this.form = {
			// 省
			province: "",
			// 市
			city: ""
		  }
		},
		// 省份更改
		changePro(e) {
			this.$emit('getAddress',{pro_id:e})
			this.form.city = ''
			this.$post('/Address/getcity',{pro_id:e}).then(res=>{
				this.cityData = res.data
			})
		},
		// 市更改
		changeCity(e) {
			this.$emit('getAddress',{city_id:e})
		},
	},
};
</script>
 
<style rel="stylesheet/scss" scoped>
.el-row {
  display: inline;
}
 
.el-row {
  display: inline-flex;
  flex: auto;
 
  .el-select {
    margin: 0 20px 0 0;
    width: 122px;
  }
}
</style>