<template>
  <div class="select-handle">
    <p class="title">批量替换文字 <span>第 {{nextIndex+1}} 处/共 {{totalMatches}} 处</span></p>
    <div class="btach-text">
      <input type="text" class="input-text" placeholder="请输入要替换的文字" v-model="seachText"/>
      <span @click="searchTextHandle">查找</span>
    </div>
    <div class="transform-text">
      <input class="input-text" v-model="replaceStr" placeholder="替换文字"/>
      <span @click="switchText('previous')">上一个</span>
      <span @click="switchText('next')">下一个</span>
    </div>
    <div class="btns">
      <span @click="hideSeach">取消</span>
      <span @click="replaceText">替换</span>
      <span @click="replaceAll">全部替换</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'batchReplacementModal',
  props:{
    nextIndex: {
      type: Number,
      default: 0
    },
    totalMatches: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      seachText:'',
      replaceStr:''
    }
  },
  methods: {
    searchTextHandle(){
      if(!this.seachText) return
      this.$emit('searchTextHandle', this.seachText)
    },
    switchText(text){
      this.$emit('switchTextHandle', text)
    },
    hideSeach(){
      this.$emit('hideSeach')
    },
    replaceText(){
      if(!this.replaceStr) return
      this.$emit('replaceText', this.replaceStr)
    },
    replaceAll(){
      if(!this.seachText) return
      if(!this.replaceStr) return
      this.$emit('replaceAll', this.replaceStr)
    }
  }
}
</script>

<style scoped>
.select-handle{
  width: 400px;
  background: #fff;
  position: absolute;
  top:65px;
  left: 430px;
  z-index: 99;
  border-radius: 6px;
  box-shadow: 0 0 5px #CD9044;
  padding: 20px 20px 0;
}
.title{
  font-size: 14px;
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
}
.title span{
  flex: 1;
  text-align: right;
  color: #333;
  font-weight: normal;
  font-size: 12px;
}
.btach-text{
  display: flex;
  margin-bottom: 10px;

}
.btach-text input{
  flex: 1;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #f39c12;
  padding:0 10px;
  user-select: none;
  outline: none;
  font-size: 14px;
}
.btach-text span{
  background: #f39c12;
  padding: 0 20px;
  margin-left: 10px;
  border-radius: 6px;
  color: #fff;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
}
.transform-text{
  display: flex;
  margin-bottom: 10px;

}
.transform-text input{
  flex: 1;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #f39c12;
  padding:0 10px;
  user-select: none;
  outline: none;
  font-size: 14px;
}
.transform-text span{
  height: 36px;
  border: 1px solid #f39c12;
  border-radius: 6px;
  margin-left: 10px;
  line-height: 36px;
  font-size: 12px;
  padding: 0 10px;

}

.transform-text span:hover{
  background: #f39c12;
  color: #fff;
  cursor: pointer;
}
.btns{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 20px;

}
.btns span{
  font-size: 14px;
  padding: 8px 20px;
  background: #f39c12;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;

}
.btns span:nth-child(1){
  background: none;
  border: 1px solid #ccc;
  color: #000;
}
.btns span:nth-child(1):hover{
  background: #ccc;
}
.btns span:nth-child(2){
  margin: 0 10px;
  border: 1px solid #f39c12;
  background: none;
  color: #000;
}
</style>