<template>
	<div class="info-small">
		<div class="head" @click="showModal = true">
			<img class="head-img" :src="info.profilePhoto" alt="">
			<span  v-if="info.style.split(',').length > 1">情绪主播</span>
			<img v-if="info.svip === 1" class="vip" src="@/assets/image/aiDubbing/zsvip-icon.png" alt="">
		</div>
		<div class="info">
			<p class="name">
				<span>{{info.nickName}}</span>
				<template v-if="isLogin">
					<img v-if="info.collectFlag" src="@/assets/image/aiDubbing/xx-icon-active.png" alt="" @click="addConcernSpeaker">
					<img v-else src="@/assets/image/aiDubbing/xx-icon.png" alt="" @click="addConcernSpeaker">
				</template>
			</p>
			<p class="tips">{{info.scopeName}}</p>
			<p class="size">
				<img class="hot" src="@/assets/image/aiDubbing/hot-icon.png" alt="">
				<span>{{info.playerCount<1000?info.playerCount:(info.playerCount/10000).toFixed(1) +'w'}}</span>
				<button @click="showModal = true">立即配音</button>
			</p>
		</div>
		<!--	播音人详情弹窗  -->
		<dubbing-info-modal v-if="showModal" :buddingData="itemData" @close="showModal=false"/>
	</div>
</template>

<script>
import dubbingInfoModal from "@/components/aiDubbing/dubbingInfoModal.vue";
import {concernSpeaker} from "@/request/aiDubbing";
import {mapGetters} from "vuex";

export default {
	components: {dubbingInfoModal},
	props:{
		itemData:{
			type:Object,
			default:()=>{}
		}
	},
	data() {
		return {
			showModal: false,
		}
	},
	computed:{
		...mapGetters(['aiBuddingUserInfo']),
		info(){
			return this.itemData
		},
		isLogin(){
			if(!this.aiBuddingUserInfo || !this.aiBuddingUserInfo.id) return false
			return true
		},
	},
	methods: {
		concernSpeaker,
		async addConcernSpeaker(){
			try{
				this.info.collectFlag = !this.info.collectFlag
				const {data, msg} = await concernSpeaker({
					speakerId: this.info.id
				})
				if(data){
					//this.$emit('reload')
				}else{
					this.$message.warning(msg)
				}
			}catch (e) {
				console.log(e)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.info-small{
	width: 280px;
	height: 160px;
	background: linear-gradient(137deg, rgba(254, 52, 6, 0.7) 0%, rgba(255, 255, 255, .7) 38%, rgba(254, 52, 6, 0.6) 100%);
	border-radius: 8px;
	padding: 30px 20px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #fff;
	position: relative;
	top:0;
	transition: top 0.15s linear;
	&:hover{
		top: -6px;
	}
}
.head{
	position: relative;
	width: 88px;
	height: 88px;
	border-radius: 100%;
	flex: 0 0 88px;
	border: 1px solid #fff;
	margin-right: 12px;
	cursor: pointer;
	.head-img{
		width: 88px;
		height: 88px;
		border-radius: 100%;
	}
	.vip{
		position: absolute;
		right:0;
		bottom:0;
	}
	span{
		position: absolute;
		top: -27px;
		left: -20px;
		background: rgba(129, 27, 51, 0.6);
		font-size: 12px;
		width: 60px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		border-radius: 2px;
	}
}
.info{
	flex: 1;
	display: flex;
	flex-direction: column;
	.name{
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span{
			flex: 1;
			font-size: 18px;
			font-weight: bold;
			margin-right: 8px;
			color: #333;
		}
		img{
			width: 18px;
			height: 18px;
			cursor: pointer;
		}
	}
	.tips{
		font-size: 12px;
		margin-bottom: 13px;
		height: 32px;
		// 超出两行显示...
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: #666;
	}
	.size{
		font-size: 12px;
		display: flex;
		align-items: center;
		.hot{
			width: 15px;
			height: 15px;
			position: relative;
			top: -2px;
			margin-right: 2px;
		}
		span{
			flex: 1;
			color: #666;
		}
		.play{
			width: 16px;
			height: 16px;
			cursor: pointer;
			filter: brightness(0);
		}
		button{
			border: 1px solid #FD2C01;
			color: #FD2C01;
			padding: 2px 4px;
			border-radius: 2px;
			&:hover{
				background: #FD2C01;
				color: #fff;
			}
		}
	}
}
</style>