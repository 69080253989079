<template>
  <div class="sound-list">
    <div class="class-list">
      <div style="flex: 1; margin-right: 100px; display: flex">
        <dubbing-class @selectClass="selectClass" />
      </div>
      <div class="btn" @click="myColletr">
        <img src="@/assets/image/aiDubbing/sound-file.png" alt="" />
        <span>{{ checkMy?'返回声音列表':"我的音色库" }}</span>
      </div>
    </div>
    <div class="dubbing-list">
			<!--	 元素骨架   -->
	    <template v-if="voiceList.length === 0 && noDate === false">
		    <el-skeleton animated style="width: 280px; height: 160px; " v-for="(item,index) in form.pageSize" :key="index">
			    <template slot="template">
				    <div style="width: 280px; height: 160px;  border-radius: 8px; display: flex; background: #f2f2f2; align-items: center; padding: 0 20px">
					    <el-skeleton-item variant="circle" style="width: 88px; height: 88px; background: #ece8e8; border-radius: 100%"></el-skeleton-item>
					    <div style="flex: 1; padding-left: 12px; display: flex; flex-direction: column; justify-content: center; padding-top: 30px">
						    <p style="margin-bottom: 20px; display: flex; justify-content: space-between">
							    <el-skeleton-item variant="text" style="width: 50px; height: 24px; background: #ece8e8;"></el-skeleton-item>
							    <el-skeleton-item variant="image" style="width: 24px; height: 24px; background: #ece8e8;"></el-skeleton-item>
						    </p>
						    <el-skeleton-item variant="text" style="width: 100%; height: 16px; background: #ece8e8;"></el-skeleton-item>
						    <p style="margin-top: 20px; display: flex; justify-content: space-between">
							    <el-skeleton-item variant="text" style="width: 40px; height: 24px; background: #ece8e8; margin-bottom: 20px"></el-skeleton-item>
							    <el-skeleton-item variant="button" style="width: 50px; height: 24px; background: #ece8e8; margin-bottom: 20px"></el-skeleton-item>
						    </p>
					    </div>
				    </div>
			    </template>
		    </el-skeleton>
	    </template>
			<!--	列表数据    -->
      <template v-else>
	      <dubbing-item
			      v-for="item of voiceList"
			      :key="item.id"
			      :itemData="item"
			      @reload="searchData"
	      />
      </template>
    </div>
    <div class="pagination">
      <pagination
        ref="pagination"
        :totalPage="totalPage"
        :limit="form.pageSize"
        @changePage="changePage"
      />
    </div>
  </div>
</template>

<script>
import dubbingClass from "@/views/aiDubbing/components/dubbingClass.vue";
import dubbingItem from "@/views/aiDubbing/components/dubbingItem.vue";
import {colletrPage, voicePageList} from "@/request/aiDubbing";
import Pagination from "@/components/pagination.vue";

export default {
  components: { Pagination, dubbingClass, dubbingItem },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 25,
      },
      totalPage: 0,
      dataTotal: 0,
      totalStyle: 0,
      voiceList: [],
	    noDate:false,
	    checkMy:false,
    };
  },
  mounted() {
    this.searchData();
  },
  methods: {
	  async myColletr(){
			if(this.checkMy){
				await this.searchData()
				return
			}
			try{
				this.checkMy = true
				this.form.pageNum = 1
				const {data} = await colletrPage(this.form)
				if(data && data.list){
					this.dataTotal = data.total
					this.totalPage = data.pages
					this.voiceList = data.list.map(item=>{
						item.collectFlag=1
						return item
					})
					if(data.list && data.list.length === 0) {
						this.noDate = true
					}else{
						this.noDate = false
					}
				}
			}catch (e) {
				console.log(e)
			}
	  },
    async searchData() {
      try {
				this.voiceList = []
        this.checkMy = false
        const { data } = await voicePageList(this.form);
        if (data && data.list) {
          this.dataTotal = data.total;
          this.totalStyle = data.totalStyle;
          this.totalPage = data.pages;
          this.voiceList = data.list;
					if(data.list && data.list.length === 0) {
						this.noDate = true
					}else{
						this.noDate = false
					}
        }
      } catch (e) {
        console.log(e);
      }
    },
    selectClass({ mainItem: mainClass, classItem: childClass }) {
      this.form.pageNum = 1;
      this.$refs.pagination.current = 1;
      switch (mainClass.type) {
        case 1:
          if (childClass.value === "") {
            delete this.form.scopesName;
            break;
          }
          this.form.scopesName = childClass.value;
          break;
        case 2:
          if (childClass.value === "") {
            delete this.form.language;
            break;
          }
          this.form.language = childClass.value;
          break;
        case 3:
          if (childClass.value === "") {
            delete this.form.style;
            break;
          }
          this.form.style = childClass.value;
          break;
        case 4:
          if (childClass.value === "") {
            delete this.form.timbreType;
            break;
          }
          this.form.timbreType = childClass.value;
          break;
        case 5:
          if (childClass.value === "") {
            delete this.form.integer;
            break;
          }
          this.form.integer = childClass.value;
          break;
	      case 6:
		      if (childClass.value === "") {
			      delete this.form.svip;
			      break;
		      }
		      this.form.svip = childClass.value;
		      break;
      }
      this.searchData();
    },
    changePage(page) {
      this.form.pageNum = page;
      this.searchData();
    },
  },
};
</script>

<style scoped lang="scss">
.sound-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  min-width: 1200px;
}
.class-list {
  display: flex;
  .btn {
    cursor: pointer;
    flex: 0 0 130px;
    margin-right: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ed4f42;
    border-radius: 2px;
    img {
      width: 19px;
      height: 17px;
      margin-right: 3px;
    }
    span {
      color: #fff;
    }
  }
}
.dubbing-list {
  flex: 1;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
	align-content: flex-start;
  & > div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
</style>
