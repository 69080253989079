<template>
	<div id="content" class="content sound-content">
		<div class="sound-top-box bannner" :style="{ backgroundImage: `url(${detailInfo.back_img})`}">
			<p class="bannerTit" data-aos="fade-right">{{detailInfo.name}}</p>
			<p class="bannerP" data-aos="fade-right">{{detailInfo.blurb}}</p>
		</div>
		<div id="content" class="t_de_content">
			<div class="t_de_content_top" style='padding-top: 30px;' v-html="detailInfo.detail">

			</div>
			<div class="listBox">
				<div class="listTit">
					<p>{{detailInfo.name}}样音</p>
					<ul>
						<li :class='selLabelindex==-1?"activeLi":""' @click="selLabelDef(-1)">全部</li>
						<li :class='selLabelindex==index?"activeLi":""' @click="selLabelDef(index)"
							v-for='(item,index) in labelArr'>{{item.name}}</li>
					</ul>
				</div>
				<ul class="songList" v-if="audioSongList.length > 0">
					<template v-for="(item,index) in audioSongList">
						<li v-if="index%2==0">
							<div class="leftBox">
								<template v-if="audioFlag==true && audioId==audioSongList[index].id&& $store.state.musicLoading">
									<i class="iconfont icon-jiazai" style="margin-right: 20px;width: 16px;"
										@click="changeAudioSrc2(audioSongList[index])"></i>
								</template>
								<template v-else-if="audioFlag==true && audioId==audioSongList[index].id">
									<i class="iconfont icon-zanting3" style="margin-right: 20px;width: 16px;"
										@click="changeAudioSrc2(audioSongList[index])"></i>
								</template>
								<template v-else>
									<i class="iconfont icon-bofang1" style="margin-right: 20px;width: 16px;" @click="changeAudioSrc2(audioSongList[index])"></i>
								</template>
								<p class="audioTit text-overflow">{{audioSongList[index].title}}</p>
								<p class="audioType text-overflow" @click="toTeach(audioSongList[index])">
									{{audioSongList[index].nickname}}</p>
							</div>
							<div class="rightBox" v-if="audioSongList[index+1]">
								<template v-if="audioFlag==true && audioId==audioSongList[index+1].id&& $store.state.musicLoading">
									<i class="iconfont icon-jiazai" style="margin-right: 20px;width: 16px;height: 16px;"
										@click="changeAudioSrc2(audioSongList[index+1])"></i>
								</template>
								<template v-else-if="audioFlag==true && audioId==audioSongList[index+1].id">
									<i class="iconfont icon-zanting3" style="margin-right: 20px;width: 16px;height: 16px;"
										@click="changeAudioSrc2(audioSongList[index+1])"></i>
								</template>
								<template v-else>
									<i class="iconfont icon-bofang1" style="margin-right: 20px;width: 16px;height: 16px;"
										@click="changeAudioSrc2(audioSongList[index+1])"></i>
								</template>
								<p class="audioTit text-overflow">{{audioSongList[index+1].title}}</p>
								<p class="audioType text-overflow" @click="toTeach(audioSongList[index+1])">
									{{audioSongList[index+1].nickname}}</p>
							</div>
						</li>
					</template>
				</ul>
				<div style="text-align: center;" v-else-if='audioSongList.length == 0' >
					<img src="../../assets/image/common/noCollect.svg" style="width: 300px;"/>
					<p style="text-align: center;color: #999;line-height: 50px;">暂无符合条件的筛选结果，请尝试更改筛选条件</p>
				</div>
			</div>
			<div class="sound-main authorize pr">
				<div class="listTit">
					<h3 class="tit">{{detailInfo.name}}老师推荐</h3>
					<ul>
						<li :class='selLabelindex_teacher==-1?"activeLi":""' @click="selLabelDefTeacher(-1)">全部</li>
						<li :class='selLabelindex_teacher==index?"activeLi":""' @click="selLabelDefTeacher(index)"
							v-for='(item,index) in labelArr'>{{item.name}}</li>
					</ul>
				</div>
				<ul class="case-list" v-if='caseList.length > 0'>
					<template v-for="(item,index) in caseList">
						<li>
							<a href="javaScript:void(0)" class="cover" @mouseenter="startVideo(index)"
								@mouseleave="stopVideo(index)"
								:style="{'background-image':'url(' + item.img + ')'}">
								<video :src="item.video" @click="playAudio(item.video)" ref="videoPlayer" muted="muted" preload="none"
									loop="loop" class="album-video"></video>
							</a>
							<a href="javaScript:void(0)" class="title">
								<p class="company text-overflow">{{item.name}}</p>
								<!-- <p class="title text-overflow">{{item.caseName}}</p> -->
							</a>
							<div class="music" >
								<p class="text-overflow" @click="goDubbingTeacher(item.teacher_id)">配音老师：
									<a href="javaScript:void(0)">{{item.nickname}}</a>
								</p>
								<i v-if="item.is_collect==1" :class="likeIconfontShouCang"
									@click="changeLikeIconfont(item,1)"></i>
								<i v-else :class="likeIconfont" @click="changeLikeIconfont(item,1)"></i>
							</div>
							<div class="make-box">
								<button class="btn-download" @click="showKefu(item)">做同款</button>
							</div>
						</li>
					</template>
				</ul>
				<div style="text-align: center;" v-else-if='caseList.length == 0' >
					<img src="../../assets/image/common/noCollect.svg" style="width: 300px;"/>
					<p style="text-align: center;color: #999;line-height: 50px;">暂无符合条件的筛选结果，请尝试更改筛选条件</p>
				</div>
			</div>
			<div class="tuijianBox copyright-music-Box">
				<h3 class="tuijianTit">为你推荐</h3>
				<ul class="case-list">
					<template v-for="(item) in audioTuiJianList">
						<li>
								<a href="javaScript:void(0)" class="cover"
									>
								<div class="peoDetail">
									<div class="imgBox"  @click="goDubbingTeacher(item.id)">
										<img :src="item.headimgurl" />
										<span v-if="item.on_line" class="login"></span>
										<span v-else class="nologin"></span>
									</div>
									<div class="peoName"  @click="goDubbingTeacher(item.id)">
										<el-tooltip placement="top">
											<template #content> {{item.nickname}}</template>
											<p class="peoName_name">{{item.nickname}}</p>
										</el-tooltip>
										<p>销量：{{item.volume}}</p>
									</div>
									<button @click="showKefu(item)">
										<svg t="1693274299441" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6083" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M322.953846 1024c-5.907692 0-11.815385-1.969231-17.723077-3.938462-13.784615-5.907692-21.661538-19.692308-21.661538-35.446153v-147.692308C110.276923 762.092308 0 610.461538 0 441.107692 0 196.923077 230.4 0 512 0s512 196.923077 512 441.107692c0 240.246154-222.523077 435.2-498.215385 441.107693l-179.2 133.907692c-7.876923 5.907692-15.753846 7.876923-23.630769 7.876923zM512 78.769231C273.723077 78.769231 78.769231 242.215385 78.769231 441.107692c0 143.753846 100.430769 273.723077 257.969231 330.83077 15.753846 5.907692 25.6 19.692308 25.6 37.415384V905.846154l126.030769-94.523077c5.907692-5.907692 15.753846-7.876923 23.630769-7.876923 238.276923 0 433.230769-163.446154 433.230769-362.338462C945.230769 242.215385 750.276923 78.769231 512 78.769231z" fill="#ffffff" p-id="6084"></path><path d="M486.4 699.076923c-141.784615 0-259.938462-88.615385-313.107692-228.430769l92.553846-33.476923c37.415385 102.4 120.123077 165.415385 218.584615 165.415384v96.492308z" fill="#ffffff" p-id="6085"></path></svg>
									在线联系
								</button>
								</div>
								<p class="audioDetailP"  @click="goDubbingTeacher(item.id)">{{item.blurb}}</p>
								<i v-if="item.remenFlag==1" class="iconfont icon-tuijian"></i>
								<!-- <i class="iconfont icon-remen"></i> -->
							</a>
							<div class="peoCaseBox">
								<ul>
									<template v-for="audio in item.audio">
										<li>
											<p>{{audio.title}}</p>
											<div style="display: flex;">
												<i v-if="audioFlag==true && audioId==audio.id&& $store.state.musicLoading"
													class="iconfont icon-jiazai"
													@click="changeAudioSrc(item,audio,item.headimgurl,item)"></i>
												<i v-else-if="audioFlag==true && audioId==audio.id"
													class="iconfont icon-zanting3"
													@click="changeAudioSrc(item,audio,item.headimgurl,item)"></i>
												<i v-else class="iconfont icon-bofang1"
													@click="changeAudioSrc(item,audio,item.headimgurl)"></i>
												<i class="iconfont icon-fenxiang"
													@click="onCopy($event,audio.audio, item.nickname)"></i>
											</div>
										</li>
									</template>
								</ul>
								<div class="peoPriceBox">
									<div class="peoPrice">
										<p><span class="redFont"
												style="font-size: 16px;">￥{{item.price.replace('.00','')}}/</span><span>100字</span>
										</p>
										<p><span class="redFont"
												style="font-size: 16px;">￥{{item.min_price.replace('.00','')}}/</span><span>起配</span>
										</p>
									</div>
									<i v-if="item.is_collect==1" :class="likeIconfontShouCang"
										@click="changeLikeIconfont(item,0)"></i>
									<i v-else :class="likeIconfont" @click="changeLikeIconfont(item,0)"></i>
								</div>
							</div>
						</li>
					</template>
				</ul>

			</div>

		</div>
		<el-dialog :visible.sync="videoBox" @close='videoClose' class="videoDialog" width="55.5%" height='50%'>
			<video :src="playVideoSrc" loop="loop" autoplay controls class="album-video" style="width: 100%;display: block;height: 550px;background-color: #000;"
				ref='videoPlayer_dialog'></video>
		</el-dialog>
		<el-dialog custom-class="dialogBox2" :visible.sync="kefuBoxShow" width="480px" :modal-append-to-body='false'>
			<teacherDialog :dialogVisible_content.sync="kefuBoxShow" :kefuObj='kefuObj'></teacherDialog>
		</el-dialog>
	</div>
</template>
<script>
	import goTop from '../../utils/goTop.js'
	import copyText from "@/utils/copyText";
	import teacherDialog from "@/components/teacherDialog.vue"
	export default {
		data() {
			return {
				likeIconfont: 'iconfont icon-shoucang_quxiaoshoucang',
				likeIconfontShouCang: 'iconfont icon-shoucang_shoucang',
				detailInfo: {},
				audioTeacherId: "",
				audioId: "",
				wordNum: 1,
				audioFlag: false,
				pagePrev: true,
				pageNext: false,
				totalPage: 1000,
				page: {
					currentPage: 1,
					limit: 10,
				},
				audioTeacherDeatil: {
					audioTeacherId: 1,
					price: 25,
					start: 30,
					teacherName: "Jom",
				},
				caseList: [],
				audioTuiJianList: [],
				audioSongList: [],
				labelArr: [],
				selLabelindex: -1,
				selLabelindex_teacher:-1,
				tagsList: [],
				tagsList_teacher:[],
				videoBox: false,
				playVideoSrc: '',
				kefuBoxShow:false,
				kefuObj:{}
			}
		},
		components: {
			teacherDialog,
		},
		mounted() {
			this.audioTeacherId = this.$route.query.audioTeacherId
			console.log(this.$route.query)
			this.bus.$on("changeStatus", (val, flag) => {
				this.changeStatus(val, flag)
			});
			if (this.$route.query.iscase == 1) {
				this.getcaseInfo()
			} else {
				this.getkindInfo()
			}
			this.getrecommend()
			this.getShowCate()

			this.getAudiodemo()
			this.getAudioCase()
		},
		methods: {
			playAudio(video) {
				this.playVideoSrc = video;
				this.videoBox = true;
				var that = this;
				setTimeout(function() {
					console.log(that.$refs, that.$refs.videoPlayer_dialog);
					that.$refs, that.$refs.videoPlayer_dialog.player.muted(false);
					that.$refs, that.$refs.videoPlayer_dialog.player.play();
				}, 10)

				// this.$refs.videoPlayer.player.muted(false);
				// this.$refs.videoPlayer.player.play();
			},
			toTeach(item) {
				console.log(item.uid)
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: item.uid
					}
				})
				goTop()
			},
			videoClose() {
				this.playVideoSrc = ''
			},
			// 获取分类筛选标签
			getShowCate() {
				let data = {
					limit: 5
				}
				this.$post('/Label/showCates', data).then(res => {
					console.log(res)
					this.labelArr = res.data
				})
			},

			selLabelDef(index) {
				this.tagsList = []
				this.selLabelindex = index
				if (index != -1) {
					this.tagsList.push(this.labelArr[index])
				} else {
					this.tagsList = []
				}

				this.getAudiodemo()
			},
			selLabelDefTeacher(index) {
				this.tagsList = []
				this.selLabelindex_teacher = index
				if (index != -1) {
					this.tagsList_teacher.push(this.labelArr[index])
				} else {
					this.tagsList_teacher = []
				}
			
				this.getAudioCase()
			},
			// 获取样音
			getAudiodemo() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList.length; i++) {
					labelidStr.push(this.tagsList[i].id)
				}
				let data = {
					page: 1,
					limit: 8,
					label: labelidStr,
					keyword: '',
					on_line: 0,
					order: 1,
					case_id:this.$route.query.caseId,
				}
				this.$post('/Audio/demo', data).then(res => {
					console.log('***********')
					console.log(res)
					this.audioSongList = res.data.list
				})
			},
			// 获取配音案例
			getAudioCase() {
				let labelidStr = []
				for (let i = 0; i < this.tagsList_teacher.length; i++) {
					labelidStr.push(this.tagsList_teacher[i].id)
				}
				let data = {
					page: 1,
					limit: 5,
					label: labelidStr[labelidStr.length-1],
					on_line: 1,
					case_id:this.$route.query.caseId,
				}
				console.log(data)
				this.$post('/Audio/audioCase', data).then(res => {
					this.caseList = res.data.list
				})
			},
			// 获取推荐列表
			getrecommend() {
				let data = {
					page: 1,
					limit: 5,
				}
				this.$post('/Teacher/recommend', data).then(res => {
					console.log(res)
					this.audioTuiJianList = res.data
				})
			},
			// 获取配音方案详情
			getkindInfo() {
				let data = {
					id: this.$route.query.caseId
				}
				this.$post('/Audio/kindInfo', data).then(res => {
					console.log(res)
					this.detailInfo = res.data
				})
			},
			getcaseInfo() {
				let data = {
					id: this.$route.query.caseId
				}
				this.$post('/Audio/caseInfo', data).then(res => {
					console.log(res)
					this.detailInfo = res.data
				})
			},

			onCopy(event, audioSrc,nickname) {
				copyText(event, nickname+audioSrc)
			},
			// 开始播放视频
			startVideo(index) {
				var that = this;
				this.$nextTick(function() {
					that.$refs.videoPlayer[index].play();
				})
			},
			stopVideo(index) {
				this.$refs.videoPlayer[index].pause();
				this.$refs.videoPlayer[index].currentTime = 0;
			},
			gotoCaseDetail(caseId) {
				// this.$router.push({
				//     path: '/dubbingScheme/schemeDetail',
				//     query: {
				//         caseId
				//     }
				// })
				goTop();
			},
			// 配音老师详情页
			goDubbingTeacher(audioId) {
				console.log(audioId)
				this.$router.push({
					path: '/dubbingTeacher/teacherDetail',
					query: {
						audioTeacherId: audioId
					}
				})
				// 获取当前老师信息，，，获取推荐列表
				goTop()
			},
			showKefu(item) {
				// if (localStorage.getItem('loginFlag')) {
					this.$post('/Index/getcKfCode', {}).then(res => {
						this.kefuObj = res.data
						this.kefuBoxShow = true;
						if (item) {
							this.kefuObj.nickname = item.nickname;
							this.kefuObj.headimgurl = item.headimgurl;
							this.kefuObj.on_line = item.on_line;
						}
						console.log(item)
					})
				// } else {
				// 	this.$store.commit('setDialogVisibleLogin', true);
				// }
			
			},
			// 切换分页
			changePageData(count) {
				// console.log("999");
				this.page.currentPage = this.page.currentPage + count;
				if (this.page.currentPage == 1) {
					this.pagePrev = true
				} else {
					this.pagePrev = false
				}
				if (this.page.currentPage >= this.totalPage / 10) {
					this.pageNext = true
				} else {
					this.pageNext = false
				}
			},
			//下载音频
			handleDown(audio) {
				var src = audio.audioSrc
				if (!src) {
					return
				}
				let fileName = audio.audioName || '' // 文件名
				this.axios({
					method: 'get',
					url: src,
					responseType: 'blob',
					headers: {
						'content-type': 'audio/mpeg'
					},
				}).then((res) => {
					let blobType = 'application/force-download' // 设置blob请求头
					let blob = new Blob([res.data], {
						type: res.data.type
					}) // 创建blob 设置blob文件类型 data 设置为后端返回的文件(例如mp3,jpeg) type:这里设置后端返回的类型 为 mp3
					let downa = document.createElement('a') // 创建A标签
					let href = window.URL.createObjectURL(blob) // 创建下载的链接
					downa.href = href // 下载地址
					downa.download = fileName // 下载文件名
					document.body.appendChild(downa)
					downa.click() // 模拟点击A标签
					document.body.removeChild(downa) // 下载完成移除元素
					window.URL.revokeObjectURL(href) // 释放blob对象
				}).catch(function(error) {
					console.log(error)
				})

				// let link = document.createElement('a');
				// link.style.display = 'none';
				// link.href = audio.audioSrc;
				// link.setAttribute('download', audio.audioName);
				// document.body.appendChild(link);
				// link.click();
			},
			// 收藏、取消收藏
			changeLikeIconfont(item, type) {
				console.log(item)
				let data = {
					id: item.id,
					type: 1,
					state: item.is_collect == 0 ? 1 : 0
				}
				if (type == 1) {
					data.type = 2;
					this.$post('/MemberInfo/collect', data).then(res => {
						console.log(res)
						this.getAudioCase()
					})

				} else {
					this.$post('/MemberInfo/collect', data).then(res => {
						console.log(res)
						this.getrecommend()
					})
				}



			},
			changeAudioSrc(item, audioDetail, img) {
				var audioInfo = {};
				audioInfo.id = audioDetail.id;
				audioInfo.audioSrc = audioDetail.audio;
				audioInfo.audioImage = img
				audioInfo.audioName = audioDetail.title;
				audioInfo.audioPeoName = item.nickname;
				audioInfo.audioPeoId = item.id;
				// 音乐信息存缓存
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioDetail);
			},
			changeAudioSrc2(audioDetail) {
				console.log(audioDetail)
				var audioInfo = {};
				audioInfo.id = audioDetail.id;
				audioInfo.audioSrc = audioDetail.audio;
				audioInfo.audioImage = audioDetail.headimgurl
				audioInfo.audioName = audioDetail.title;
				audioInfo.audioPeoName = audioDetail.nickname;
				audioInfo.audioPeoId = audioDetail.uid;
				sessionStorage.setItem('audioDetail', JSON.stringify(audioInfo))
				this.bus.$emit("getAudioSrc", audioInfo);
			},
			changeStatus(val, flag) {
				// 获取播放的id,总状态
				console.log("teacherDetail-----", val, flag)
				this.audioId = val;
				this.audioFlag = flag
			},
			handleChange() {

			}

		}
	}
</script>

<style scoped>
	.authorize .case-list>li .album-video{
		opacity: 0;
	}
	.copyright-music-Box .case-list li .cover .peoDetail .peoName {
		
		margin: 0 2% 0 2%;
		flex: 1;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.copyright-music-Box .case-list li .cover .peoDetail .peoName .peoName_name {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.t_de_content .listBox .songList li .audioType:hover {
		font-weight: 600;
		color: #eb3637;
	}

	/deep/.el-dialog {
		background-color: transparent;
		border: none;

	}

	.videoDialog /deep/.el-dialog .el-dialog__body {
		padding: 0 !important;
	}
	/deep/.el-dialog .el-dialog__body video {
		display: block;
	}

	.t_de_content .listBox .listTit ul li.activeLi,
	.t_de_content .listBox .listTit ul li:hover,
		{
		color: #fff;
		background-color: #eb3637;
		border-radius: 5px;

	}

	.content {
		padding: 0 !important;
	}

	.sound-main .music i.icon-shoucang_shoucang {
		color: #ea4335 !important
	}

	.sound-main {
		background-color: #fff !important;
		padding: 20px 0 0 0;
		border-radius: 10px;
	}

	.sound-main .listTit {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.sound-main .listTit ul {
		display: flex;
	}

	.sound-main .listTit ul li {
		margin-left: 30px;
		padding: 5px 10px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.sound-main .listTit ul li.activeLi,
	.sound-main .listTit ul li:hover{
		color: #fff;
		background-color: #eb3637;
		border-radius: 5px;

	}
	.sound-main .con-main .case-list>li>.cover {
	}

	.bannner {
		height: 300px;
		background: url(../../assets/image/index/banner-bg.jpg) 50%/cover;
		padding: 70px 400px 50px;
		margin-top: 0;
	}

	.bannner .bannerTit {
		font-size: 50px;
		font-weight: 600;
		color: #fff;
		font-style: oblique;

	}

	.bannner .bannerP {
		font-size: 16px;
		color: #f0f0f0;
		margin-top: 20px;
	}

	.t_de_content,
	.listBox,
	.tuijianBox,
	.authorize,
	.sound-main {
		width: 1400px;
		margin: 20px auto;

	}

	.tuijianBox {
		border-radius: 10px;
		background-color: #fff;
		margin-bottom: 60px;
	}

	.t_de_content .t_de_content_top {
		margin: 0 auto;
		background-color: #fff;
		border-radius: 10px;
		padding: 0px 40px 20px;
	}

	.t_de_content .t_de_content_top .contentBox {
		display: flex;
		justify-content: space-between;
	}

	.t_de_content .t_de_content_top .contentBox .contentBox_left {
		width: 65%;
		color: #747474;
	}

	.t_de_content .t_de_content_top .contentBox .contentBox_left p {
		margin-bottom: 20px;
	}

	.t_de_content .t_de_content_top .contentBox img {
		width: 30%;
		border-radius: 10px;
		transition: all .3s;
		cursor: pointer;
	}

	.t_de_content .t_de_content_top .contentBox img:hover {
		transform: scale(1.02);
	}

	.t_de_content .listBox {
		border-radius: 10px;
		background-color: #fff;
		margin-top: 20px;
	}

	.t_de_content .listBox .listTit {
		padding: 0 40px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
	}

	.t_de_content .listBox .listTit p {
		line-height: 100px;
		font-size: 20px;
		font-weight: 600;
		text-align: left;
		width: auto !important; 
	}

	.t_de_content .listBox .listTit ul {
		display: flex;

	}

	.t_de_content .listBox .listTit ul li {
		margin-left: 30px;
		padding: 5px 10px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.t_de_content .listBox .listTit ul li.activeLi,
	.t_de_content .listBox .listTit ul li:hover {
		color: #fff;
		background-color: #eb3637;
		border-radius: 5px;

	}

	.t_de_content .listBox .songList {
		padding: 0 40px;
		box-sizing: border-box;
	}

	.t_de_content .listBox .songList li {
		height: 70px;
		border-bottom: 1px solid #f5f5f5;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.t_de_content .listBox .songList li:hover {
		background-color: transparent !important;
	}

	.t_de_content .listBox .songList li .leftBox,
	.t_de_content .listBox .songList li .rightBox {
		width: 48%;
		height: 70px;
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 0 10px;
	}

	.t_de_content .listBox .songList li .leftBox:hover,
	.t_de_content .listBox .songList li .rightBox:hover {
		background-color: #f5f5f5
	}

	.t_de_content .listBox .songList li i {
		margin-right: 20px;
		cursor: pointer;
	}

	.t_de_content .listBox .songList li .audioTit {
		width: 40%;
		margin-right: 40px;
		text-align: left;
	}

	.t_de_content .listBox .songList li .audioType {
		width: 40%;
		color: #999999
	}

	.tuijianBox,
	.sound-main {
		padding: 0 40px;
		box-sizing: border-box;
	}

	.tuijianBox.sound-content .con-main {
		width: 100% !important;
	}

	.tuijianBox .tuijianTit,
	.t_de_content .t_de_content_top .tit,
	.sound-main .tit {
		height: 80px;
		line-height: 80px;
		font-size: 20px;
		font-weight: 600;
	}

	.copyright-music-Box .case-list,
	.sound-main .case-list {
		width: 100% !important;
		margin: 0px auto 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}

	.copyright-music-Box .case-list>li,
	.sound-main .case-list>li {
		width: 243px;
	}

	@media only screen and (max-width: 1680px) {

		.t_de_content_top,
		.t_de_content .listBox,
		.tuijianBox,
		.sound-main {
			width: 1200px;
		}

		.copyright-music-Box .case-list>li,
		.sound-main .case-list>li {
			width: 260px;
		}

		.copyright-music-Box .case-list>li:nth-of-type(5),
		.sound-main .case-list>li:nth-of-type(5) {
			display: none;
		}
	}

	@media only screen and (max-width:1440px) {

		.t_de_content_top,
		.t_de_content .listBox,
		.tuijianBox,
		.sound-main {
			width: 1000px
		}

		.copyright-music-Box .case-list,
		.sound-main .case-list {
			grid-template-columns: repeat(3, 1fr);
		}

		.copyright-music-Box .case-list>li,
		.sound-main .case-list>li {
			width: 288px;
		}

		.copyright-music-Box .case-list>li:nth-of-type(5),
		.copyright-music-Box .case-list>li:nth-of-type(4),
		.sound-main .case-list>li:nth-of-type(4),
		.sound-main .case-list>li:nth-of-type(5) {
			display: none;
		}
	}
</style>
