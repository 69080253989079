<template>
  <div class="editor-page">
    <div class="page-header">
      <div class="work-title">
        <p class="name">
          配音名称：{{ draftName || "未命名配音" }}
          <span
            v-if="draftId && draftName"
            class="create-new"
            @click="createNew"
            >新建配音</span
          >
        </p>
        <p>已保存到云端 {{ saveFontNum }}字</p>
      </div>
      <div class="right-tools">
        <div class="btn trial-listening" @click="trialListening">
          <i
            v-if="getLoading"
            class="el-icon-loading"
            style="margin-right: 3px; position: relative; top: 1px"
          ></i>
          <template v-else>
            <img
              v-if="aSrc"
              src="@/assets/image/aiDubbing/stop.png"
              style="width: 15px; height: 15px"
              alt=""
            />
            <img
              v-else
              src="@/assets/image/aiDubbing/start-icon.png"
              style="width: 7px; height: 11px"
              alt=""
            />
          </template>
          <span>{{ aSrc ? "停止" : "试听" }}</span>
        </div>
        <div
          class="btn compound"
          @click="toCompound"
          v-loading="downloadLoading"
        >
          <img
            src="@/assets/image/aiDubbing/hc-icon.png"
            style="width: 17px; height: 17px"
            alt=""
          />
          <span>合成配音</span>
        </div>
        <el-popover
          placement="top"
          width="200"
          trigger="click"
          popper-class="popper-class"
        >
          <div style="display: flex; flex-direction: column">
            <p style="margin-bottom: 10px">请选择下载音频格式:</p>
            <div
              style="display: flex; flex-wrap: nowrap; justify-content: center"
            >
              <el-button @click="downloadAudio('mp3')">MP3</el-button>
              <el-button @click="downloadAudio('wav')">WAV</el-button>
            </div>
          </div>
          <div slot="reference" class="btn" v-loading="downloadLoading">
            <img src="@/assets/image/aiDubbing/ej-icon.png" alt="" />
            <span>下载音频</span>
          </div>
        </el-popover>
        <div
          class="btn"
          @click="downloadAudio('mp4')"
          v-loading="downloadLoading"
        >
          <img src="@/assets/image/aiDubbing/sp-cion.png" alt="" />
          <span>下载视频</span>
        </div>
        <div
          class="btn"
          @click="downloadAudio('srt')"
          v-loading="downloadLoading"
        >
          <img
            src="@/assets/image/aiDubbing/zm-icon.png"
            style="width: 17px; height: 17px"
            alt=""
          />
          <span>下载字幕</span>
        </div>
      </div>
    </div>
    <div class="editor">
      <tts-editor ref="ssmlEditor" :editorHeight="`${ttsEditorHeight}px`" />
    </div>
    <!--	播音员选择	-->
    <budding-modal
      ref="buddingModal"
      :showBigModal="showBigModal"
      :trialListeningStatus="audoPlaying"
      @switchEmotion="switchEmotion"
    />
    <!--	开通VIP  -->
    <open-vip-modal
      v-if="openVipShow"
      :currentZB="{}"
      @close="openVipShow = false"
    />
    <!-- 试听配音	-->
    <audio-play
      ref="audioPlay"
      @playEnd="playEnd"
      @playing="playing"
      :aSrc="aSrc"
    />
  </div>
</template>

<script>
import ttsEditor from "@/components/Editor/ttsEditor.vue";
import buddingModal from "@/components/aiDubbing/buddingModal.vue";
import openVipModal from "@/components/aiDubbing/openVipModal.vue";
import usingStatistics from "@/views/aiDubbing/components/usingStatistics.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  concernSpeaker,
  draftAddOrUpdate,
  draftGetById,
  getSpeakerById,
  speakerSsml,
  voicePageList,
} from "@/request/aiDubbing";
import AudioPlay from "@/components/aiDubbing/audioPlay.vue";
import { download } from "@/request/http";
import { downloadFile } from "@/utils/func";

export default {
  components: {
    AudioPlay,
    openVipModal,
    ttsEditor,
    buddingModal,
    usingStatistics,
  },
  data() {
    return {
      openVipShow: false,
      aSrc: "",
      currentSSML: "",
      draftId: null,
      draftName: "",
      saveFontNum: 0,
      autoSaveTimer: null,
      getLoading: false,
      downloadLoading: false,
      showBigModal: false,
      audoPlaying: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentSelectDubbing",
      "userinfo",
      "aiBuddingUserInfo",
      "canGo",
    ]),
    ttsEditorHeight() {
      // 获取窗口高度，减去100PX
      return window.innerHeight - 200;
    },
  },
  mounted() {
    if (!this.currentSelectDubbing.id) {
      this.getAIList();
    }
    this.autoSaveTimer = setInterval(() => {
      this.saveSSML();
    }, 3000);
    this.getAiUserInfo();
  },
  activated() {
    // 获取路由参数
    const { id } = this.$route.query;
    if (id) {
      this.draftId = id;
      this.getDraftDetail();
    } else {
      this.draftId = null;
      this.draftName = "";
      this.saveFontNum = 0;
      this.$refs.ssmlEditor.setHtml("");
      this.showBigModal = true;
    }
    if (!this.currentSelectDubbing.id) {
      this.getAIList();
    }
  },
  methods: {
    ...mapActions(["getAiUserInfo"]),
    ...mapMutations(["setCurrentSelectDubbing"]),
    switchEmotion() {
      this.playEnd();
    },
    async getAIList() {
      try {
        const { data } = await voicePageList({
          pageNum: 1,
          pageSize: 25,
        });
        if (data && data.list) {
          if (data.list && data.list.length) {
            const aiId = data.list[0].id;
            const { data: aiData } = await getSpeakerById({
              id: aiId,
            });
            if (aiData.id) {
              this.setCurrentSelectDubbing(aiData);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    createNew() {
      this.draftId = null;
      this.draftName = "";
      this.saveFontNum = 0;
      this.$refs.ssmlEditor.setHtml("");
    },
    async getDraftDetail() {
      try {
        const { data } = await draftGetById({ id: this.draftId });
        if (data.content) {
          this.$refs.ssmlEditor.setHtml(data.content);
          setTimeout(() => {
            const text = this.$refs.ssmlEditor.getText();
            this.draftName = text.substring(0, 10);
          }, 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async toCompound() {
      try {
        await this.getAiUserInfo(true);
        if (!this.canGo) {
          this.openVipShow = true;
          return;
        }
        await this.downloadAudio("", true);
      } catch (e) {
        console.log(e);
      }
    },
    playEnd() {
      this.audoPlaying = false;
      this.aSrc = "";
      this.$refs.audioPlay.stopAudio();
    },
    playing() {
      this.audoPlaying = true;
    },
    async trialListening() {
      try {
        if (this.aSrc) {
          this.playEnd();
          return;
        }
        this.$refs.buddingModal.playEnd();
        await this.getAiUserInfo(true);
        if (!this.canGo) {
          this.openVipShow = true;
          return;
        }
        if (this.getLoading) return;
        this.getLoading = true;
        const ssml = this.$refs.ssmlEditor.getSSML();
        const text = this.$refs.ssmlEditor.getText();
        console.log("ssmltext", text);
        if (text) {
          const audioConfig = localStorage.getItem("dubbingConfig");
          let config = {};
          if (audioConfig) {
            config = JSON.parse(audioConfig);
          }
          const params = {
            speakerId: this.currentSelectDubbing.id,
            content: ssml,
          };
          if (config.style) params.style = config.style;
          if (config.speed) params.speed = config.speed;
          if (config.volume) params.volume = config.volume;
          if (config.pitch) params.pitch = config.pitch;
          const { data, msg } = await speakerSsml(params);
          console.log(data, msg);
          if (data === "BIZ_ERROR") {
            this.openVipShow = true;
            return;
          }
          if (data.voiceUrl) {
            this.aSrc = data.voiceUrl;
          } else {
            this.$message.warning(msg || "配音失败");
          }
        } else {
          this.$message.warning("请输入内容");
        }
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
        console.log(e);
        if (e.data && e.data.data && e.data.data === "BIZ_ERROR") {
          this.openVipShow = true;
          return;
        }
      }
    },
    async downloadAudio(type = "mp3", synthetize = false) {
      try {
        await this.getAiUserInfo(true);
        if (!this.canGo) {
          this.openVipShow = true;
          return;
        }
        if (this.downloadLoading) {
          this.$message.warning("操作进行中，请等待...");
          return;
        }
        this.downloadLoading = true;
        const ssml = this.$refs.ssmlEditor.getSSML();
        const text = this.$refs.ssmlEditor.getText();
        if (ssml && text) {
          const audioConfig = localStorage.getItem("dubbingConfig");
          let config = {};
          if (audioConfig) {
            config = JSON.parse(audioConfig);
          }
          const params = {
            speakerId: this.currentSelectDubbing.id,
            content: ssml,
            style: config.style || "默认",
            scopesName: this.currentSelectDubbing.scopesList.join(","),
          };
          if (config.style) params.style = config.style;
          if (config.speed) params.speed = config.speed;
          if (config.volume) params.volume = config.volume;
          if (config.pitch) params.pitch = config.pitch;
          if (synthetize) params.synthetize = 1;
          if (type === "mp3" || type === "wav") params.audioType = type;
          const { data, msg } = await speakerSsml(params);
          this.downloadLoading = false;
          if (data === "BIZ_ERROR") {
            this.openVipShow = true;
            return;
          }
          if (synthetize) {
            return this.$message.success("已添加到您的合成列表，请到“我的合成”中查看");
          }
          const fileName = text.substring(0, 10);
          if (type === "srt") {
            if (data.srtUrl) {
              downloadFile(
                data.srtUrl,
                `${fileName}.${type}` || `配音.${type}`
              );
            } else {
              this.$message.warning(msg || "获取失败");
            }
            return;
          }
          if (data.voiceUrl) {
            downloadFile(
              data.voiceUrl,
              `${fileName}.${type}` || `配音.${type}`
            );
          } else {
            this.$message.warning(msg || "获取失败");
          }
        } else {
          this.downloadLoading = false;
          this.$message.warning("请输入内容");
        }
      } catch (e) {
        console.log(e);
        this.downloadLoading = false;
        if (e.data && e.data.data && e.data.data === "BIZ_ERROR") {
          this.openVipShow = true;
          return;
        }
      }
    },
    async saveSSML() {
      if (!this.aiBuddingUserInfo || !this.aiBuddingUserInfo.id) return;
      const html = this.$refs.ssmlEditor.getHtml();
      if (!html) return;
      if (html === this.currentSSML) return;
      try {
        this.currentSSML = html;
        const text = this.$refs.ssmlEditor.getText();
        const audioConfig = localStorage.getItem("dubbingConfig");
        let config = {};
        if (audioConfig) {
          config = JSON.parse(audioConfig);
        }
        const params = {
          content: html,
          title: text.substring(0, 10),
          language: this.currentSelectDubbing.languageList.join(","),
          style: config.style || "默认",
          scopesName: this.currentSelectDubbing.scopesList.join(","),
          speakerId: this.currentSelectDubbing.id,
          speakerName: this.currentSelectDubbing.nickName,
        };
        if (this.draftId) params.id = this.draftId;
        const { data } = await draftAddOrUpdate(params);
        if (data.id) {
          this.draftId = data.id;
          this.draftName = text.substring(0, 10);
          this.saveFontNum = text.length;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  beforeDestroy() {
    if (this.autoSaveTimer) {
      clearInterval(this.autoSaveTimer);
      this.autoSaveTimer = null;
    }
  },
};
</script>

<style scoped lang="scss">
.editor-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page-header {
  display: flex;
  .work-title {
    flex: 1;
    padding-top: 22px;
    color: #666;
    .name {
      color: #333;
      margin-bottom: 10px;
    }
    .create-new {
      color: #ffffff;
      border: 1px solid #ed4f42;
      padding: 4px 6px;
      border-radius: 2px;
      margin-left: 10px;
      cursor: pointer;
      background: #ed4f42;
    }
  }
  .right-tools {
    display: flex;
    padding-top: 25px;
    padding-right: 20px;
    .btn {
      width: 118px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #666666;
      border-radius: 2px;
      margin-left: 12px;
      cursor: pointer;
      img {
        margin-right: 4px;
        width: 19px;
        height: 16px;
      }
      &.trial-listening {
        background: #ed4f42;
        border: 1px solid #ed4f42;
        color: #fff;
      }
      &.compound {
        border: 1px solid #ed4f42;
        color: #ed4f42;
      }
    }
  }
}
.editor {
  flex: 1;
  margin-top: 20px;
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
}
</style>
<style>
.el-popover {
  z-index: 98 !important;
}
</style>
