import { render, staticRenderFns } from "./collect.vue?vue&type=template&id=090039cf&scoped=true"
import script from "./collect.vue?vue&type=script&lang=js"
export * from "./collect.vue?vue&type=script&lang=js"
import style0 from "./collect.vue?vue&type=style&index=0&id=090039cf&prod&scoped=true&lang=css"
import style1 from "./collect.vue?vue&type=style&index=1&id=090039cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "090039cf",
  null
  
)

export default component.exports