<template>
  <div class="dubbing-editer" @click="setDefaultEvent">
<!--		<button @click="getSSML">获取SSML</button>-->
    <!--  工具栏  -->
    <div class="editer-tools">
      <div v-for="item of tools" :key="item.id" class="tools-item" @mouseenter="menuHover(item)"
           @mouseleave="menuHoverLeave(item)" @click.stop="tapTool(item)">
        <img :src="item.icon" alt="" class="tool-icon">
        <span class="tool-name">{{ item.name }}</span>
        <!--   批量替换     -->
        <div v-if="item.id === 6 && replacementMenuShow" class="replacement-menu" @mouseenter="menuHover(item)">
          <p @click="batchText">批量替换文字</p>
          <p @click="batchPY">批量替换多音字</p>
        </div>
        <!--    更多    -->
        <div v-if="item.id === 8 && moreMenuShow" class="replacement-menu" style="bottom: -40px"
             @mouseenter="menuHover(item)">
          <p @click="pyReference">{{ pyReferenceStatus ? "关闭" : '开启' }}多音字参考</p>
        </div>
      </div>
    </div>
    <!--  文本编辑器  -->
    <div
        :style="{height:editorHeight}"
        class="editor-container"
    >
      <p v-if="!currentContent" class="default-text">{{placeholder}}</p>
      <div
          id="editor"
          ref="editor"
          class="editor"
          contenteditable="true"
          @click="eventListener"
      >
      </div>
    </div>
    <!-- 多音字拼音选择  -->
    <pinyin-select
        v-if="pinyinSelectshow"
        :left="left"
        :pinyinList="pinyinList"
        :top="top"
        @cancel="pinyinSelectshow=false"
        @selectPy="selectPy"
    />
    <!--  停顿时间选择  -->
    <pause-select
        v-if="pauseSelectShow"
        :left="left"
        :top="top"
        @selectPause="selectPause"
    />
    <!--  数字符号  -->
    <generate-speech-select
        v-if="generateSpeechShow"
        :generateSpeechList="generateSpeechList"
        :left="left"
        :top="top"
        @generateSpeechSelect="generateSpeechSelect"
    />
    <!--  局部变速  -->
    <variable-speed
        v-if="variableSpeedShow"
        :left="left"
        :top="top"
        @setSpeedValue="setSpeedValue"
    />
    <!--  发音转换  -->
    <voice-convert
        v-if="voiceConvertShow"
        :left="left"
        :selelct-text="currentSelectText"
        :top="top"
        @close="voiceConvertShow=false"
        @voiceConvertConfirm="voiceConvertConfirm"
    />
    <!--  批量替换文字  -->
    <batch-replacement-modal
        v-if="BatchReplacementModalShow"
        :nextIndex="nextIndex"
        :totalMatches="totalMatches"
        @hideSeach="hideSeach"
        @replaceAll="replaceAll"
        @replaceText="replaceText"
        @searchTextHandle="searchTextHandle"
        @switchTextHandle="switchTextHandle"
    />
    <!--  批量替换多音字  -->
    <batch-replacement-py-modal
        v-if="BatchReplacementPYModalShow"
        :nextIndex="nextIndex"
        :totalMatches="totalMatches"
        @hideSeach="hideSeach"
        @replacePY="replacePY"
        @replacePYAll="replacePYAll"
        @searchTextHandle="searchTextHandle"
        @switchTextHandle="switchTextHandle"
    />
  </div>
</template>

<script>
import pinyin from "pinyin";
import {v4 as uuidv4} from "uuid"
import Toast from 'vue-toast-mobile';
require("vue-toast-mobile/lib/index.css")
import {generateSpeechText, validateInput} from "@/components/Editor/utils";
import pinyinSelect from "@/components/Editor/pinyinSelect.vue";
import pauseSelect from "@/components/Editor/pauseSelect.vue"
import GenerateSpeechSelect from "@/components/Editor/generateSpeechSelect.vue";
import VariableSpeed from "@/components/Editor/variableSpeed.vue";
import VoiceConvert from "@/components/Editor/voiceConvert.vue";
import BatchReplacementModal from "@/components/Editor/batchReplacementModal.vue";
import BatchReplacementPyModal from "@/components/Editor/batchReplacementPYModal.vue";

export default {
  name: "TtsEditor",
  components: {
    BatchReplacementModal,
    VariableSpeed,
    GenerateSpeechSelect,
    pinyinSelect,
    pauseSelect,
    VoiceConvert,
    BatchReplacementPyModal
  },
  props: {
    // 编辑器高度，默认300px
    editorHeight: {
      type: [String],
      default: '400px'
    },
    placeholder:{
      type: String,
      default: '请输入内容...'
    }
  },
  data() {
    return {
      tools: [
        {id: 0, name: "多音字", icon: require("../../assets/image/ttsEditor/p2-topicon1.png")},
        {id: 1, name: "停顿", icon: require("../../assets/image/ttsEditor/p2-topicon2.png")},
        {id: 2, name: "数字/符号", icon: require("../../assets/image/ttsEditor/p2-topicon3.png")},
        {id: 3, name: "局部变速", icon: require("../../assets/image/ttsEditor/p2-topicon4.png")},
        {id: 4, name: "段落停顿", icon: require("../../assets/image/ttsEditor/p2-topicon5.png")},
        {id: 5, name: "发音转换", icon: require("../../assets/image/ttsEditor/p2-topicon6.png")},
        {id: 6, name: "批量替换", icon: require("../../assets/image/ttsEditor/p2-topicon7.png")},
        // {id: 7, name: "背景音乐", icon: require("../../assets/bubbing/p2-topicon8.png")},
        {id: 8, name: "更多", icon: require("../../assets/image/ttsEditor/p2-topicon9.png")},
        {id: 9, name: "清空内容", icon: require("../../assets/image/ttsEditor/p2-topicon10.png")},
        {id: 10, name: "撤回", icon: require("../../assets/image/ttsEditor/p2-topicon11.png")},
        {id: 11, name: "回撤", icon: require("../../assets/image/ttsEditor/p2-topicon12.png")},
      ],
      menuHoverTimer: null,
      replacementMenuShow: false,
      defaultTextShow: true,
      observer: null,
      undoStack: [], // 回撤栈
      redoStack: [], // 撤回栈
      currentContent: '', // 当前内容
      isUndoing: false, // 是否正在执行回撤操作
      clientX: 0, // x
      clientY: 0, // y
      currentRange: null,
      rangeList: {},
      currentSelectText: '',
      // 多音字选择
      top: 0,
      left: 0,
      pinyinSelectshow: false,
      pinyinList: [],
      currentUUID: '',
      // 停顿
      pauseSelectShow: false,
      // 数字符号
      generateSpeechList: [],
      generateSpeechShow: false,
      // 局部变速
      variableSpeedShow: false,
      // 发音转换
      voiceConvertShow: false,
      // 批量替换
      BatchReplacementModalShow: false,
      totalMatches: 0, // 匹配的总数量
      nextIndex: 0,
      // 批量替换多音字
      BatchReplacementPYModalShow: false,
      // 更多
      moreMenuShow: false,
      pyReferenceStatus: false,
    }
  },
  mounted() {
    this.nodeEventObserve()
    this.addPasteListener()
	  document.getElementById('editor').addEventListener('mousedown', this.handleMouseDown)
	  document.getElementById('editor').addEventListener('keydown', this.keydownAddListener);
  },
  methods: {
    //对外提供的方法
    getHtml() {
      return this.$refs.editor.innerHTML
    },
    setHtml(html) {
      this.currentContent = true
      this.$refs.editor.innerHTML = html
    },
	  getText(){
		  let textContent = '';
		  for (let node of this.$refs.editor.childNodes) {
			  if (node.nodeType === Node.TEXT_NODE) {
				  textContent += node.nodeValue;
			  }
		  }
		  return textContent.trim(); // 去除前后空格
	  },
    getSSML() {
      const editor = this.$refs.editor
      const nodes = editor.childNodes
	    console.log(nodes)
      let ssml = '<speak version="1.0" xml:lang="zh-CN" xmlns="http://www.w3.org/2001/10/synthesis">'
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if(node.nodeType === 3){ // 文字节点
          ssml += node.nodeValue
        }
        if(node.nodeType === 1){ // 元素节点
          const classList = Array.from(node.classList)
          // 处理拼音节点
          if(classList.includes('polyphonic')){
            const text = node.getAttribute('data-text')
            const phoneme = node.getAttribute('phoneme')
            ssml += `<p phoneme="${phoneme}">${text}</p>`
            continue
          }
          // 处理停顿/段落停顿
          if(classList.includes('pause')){
            const time = node.getAttribute('time')
            ssml += `<break time="${time}"/>`
            continue
          }
          // 数字符号
          if(classList.includes("generate-speech")){
            const text = node.getAttribute('data-text')
            const interpretAs = node.getAttribute('interpret-as')
            ssml += `<say-as interpret-as="${interpretAs}">${text}</say-as>`
            continue
          }
          // 发音转换
          if(classList.includes("voice-convert")){
            const text = node.getAttribute('data-text')
            const alias = node.getAttribute('alias')
            ssml += `<sub alias="${alias}">${text}</sub>`
            continue
          }
          // 局部变速----start
          if(classList.includes("variable-speed") && classList.includes("start")){
            const pitch = node.getAttribute('pitch')
            ssml += `<option speed="${pitch}">`
            continue
          }
          // 局部变速----end
          if(classList.includes("variable-speed") && classList.includes("end")){
            ssml += `</option>`
            continue
          }
          const text = node.getAttribute('data-text')
          if(text) ssml += text
        }
      }
      ssml += '</speak>'
	    console.log(ssml)
      return ssml
    },
    // 开启、关闭多音字参考
    pyReference() {
      const editorDom = this.$refs.editor
      this.pyReferenceStatus = !this.pyReferenceStatus
      if (this.pyReferenceStatus) {
        const node = this.getTextContent(editorDom)
        editorDom.innerHTML = node
      } else {
        const dyzDoms = editorDom.querySelectorAll('.dyz')
        if (dyzDoms.length) {
          //let done = false
          for (let i = 0; i < dyzDoms.length; i++) {
            const dyzDom = dyzDoms[i]
            const text = dyzDom.getAttribute('data-text')
            const textNode = document.createTextNode(text);
            dyzDom.replaceWith(textNode)
          }
        }
      }
    },
    hideSeach() {
      const editorDom = this.$refs.editor
      this.removeHighlight(editorDom)
      this.totalMatches = 0
      this.nextIndex = 0
      this.BatchReplacementModalShow = false
      this.BatchReplacementPYModalShow = false
    },
    searchTextHandle(searchText) {
      if (!searchText) return
      const editorDom = this.$refs.editor
      this.removeHighlight(editorDom)
      this.highlightText(editorDom, searchText)
      // 计算匹配到的文字总数量
      const matches = editorDom.querySelectorAll('.mark');
      this.totalMatches = matches.length;
      if (this.totalMatches) {
        this.selectElement(editorDom, 'next')
      }
    },
    switchTextHandle(text) {
      const editorDom = this.$refs.editor
      this.selectElement(editorDom, text)
    },
    replaceText(text) {
      if (!text) return
      const editorDom = this.$refs.editor
      const highlightedElements = editorDom.getElementsByClassName('selected');
      if (!highlightedElements.length) {
        Toast("请点击上一个或下一个选中要替换的字符")
        return
      }
      const dataText = highlightedElements[0].parentElement.attributes['data-text']
      if (dataText) {
        highlightedElements[0].parentElement.setAttribute('data-text', text)
      }
      const textNode = document.createTextNode(text);
      highlightedElements[0].parentElement.replaceChild(textNode, highlightedElements[0]);
      if (this.totalMatches) {
        this.selectElement(editorDom, 'next')
      }
    },
    replacePY(py, seachText) {
      if (!py) return
      if (!seachText) return
      const editorDom = this.$refs.editor
      // 创建包裹选中文本的span元素，并设置样式
      let span = document.createElement('span');
      span.textContent = seachText
      span.className = 'polyphonic';
      span.setAttribute('contenteditable', 'false')
      span.setAttribute('data-ssml', 'p')
      span.setAttribute('phoneme', py)
      span.setAttribute('data-text', seachText)
      const uuid = uuidv4()
      span.setAttribute('data-uuid', uuid)
      this.rangeList[uuid] = this.currentRange
      // 插入拼音气泡元素
      let innerSpan = document.createElement('span');
      innerSpan.className = 'inner-class tips-bubble tips-pinyin';
      innerSpan.setAttribute('data-uuid', uuid)
      innerSpan.innerHTML = `${py}<span class="iconfont tips-pinyin-close" data-uuid="${uuid}">&#xe6e6;</span>`
      span.appendChild(innerSpan);
      // 查找被选中的元素
      const highlightedElements = editorDom.getElementsByClassName('selected');
      if (!highlightedElements.length) {
        Toast("请点击上一个或下一个选中要替换的字符")
        return
      }
      if (highlightedElements[0].parentElement.attributes['data-text']) {
        Toast("已有标签不能包含该标签")
        return
      }
      editorDom.replaceChild(span, highlightedElements[0]);
      if (this.totalMatches) {
        this.selectElement(editorDom, 'next')
      }
    },
    replaceAll(text) {
      if (!text) return
      if (!this.totalMatches) {
        Toast("未找到需要替换的字符")
        return
      }
      let done = false
      while (!done) {
        const editorDom = this.$refs.editor
        const highlightedElements = editorDom.getElementsByClassName('mark');
        if (highlightedElements.length === 0) {
          done = true
          break;
        }
        const dataText = highlightedElements[0].parentElement.attributes['data-text']
        if (dataText) {
          highlightedElements[0].parentElement.setAttribute('data-text', text)
        }
        const textNode = document.createTextNode(text);
        highlightedElements[0].parentElement.replaceChild(textNode, highlightedElements[0]);
      }
    },
    replacePYAll(py, seachText) {
      if (!py) return
      if (!seachText) return
      let done = false
      while (!done) {
        const editorDom = this.$refs.editor
        const highlightedElements = editorDom.getElementsByClassName('mark');
        if (highlightedElements.length === 0) {
          done = true
          break;
        }
        if (highlightedElements[0].parentElement.attributes['data-text']) { // 如果已经在标签内部，跳过替换
          const textNode = document.createTextNode(seachText);
          highlightedElements[0].parentElement.replaceChild(textNode, highlightedElements[0]);
          continue;
        }
        // 创建包裹选中文本的span元素，并设置样式
        let span = document.createElement('span');
        span.textContent = seachText
        span.className = 'polyphonic';
        span.setAttribute('contenteditable', 'false')
        span.setAttribute('data-ssml', 'p')
        span.setAttribute('phoneme', py)
        span.setAttribute('data-text', seachText)
        const uuid = uuidv4()
        span.setAttribute('data-uuid', uuid)
        this.rangeList[uuid] = this.currentRange
        // 插入拼音气泡元素
        let innerSpan = document.createElement('span');
        innerSpan.className = 'inner-class tips-bubble tips-pinyin';
        innerSpan.setAttribute('data-uuid', uuid)
        innerSpan.innerHTML = `${py}<span class="iconfont tips-pinyin-close" data-uuid="${uuid}">&#xe6e6;</span>`
        span.appendChild(innerSpan);
        editorDom.replaceChild(span, highlightedElements[0]);
      }
    },
    menuHover(item) {
      if (item.id === 6) {
        clearTimeout(this.menuHoverTimer)
        this.replacementMenuShow = true
      } else {
        this.replacementMenuShow = false
      }
      if (item.id === 8) {
        clearTimeout(this.menuHoverTimer)
        this.moreMenuShow = true
      } else {
        this.moreMenuShow = false
      }
    },
    menuHoverLeave(item) {
      this.menuHoverTimer = setTimeout(() => {
        if (item.id === 6) {
          this.replacementMenuShow = false
        }
        if (item.id === 8) {
          this.moreMenuShow = false
        }
      }, 400)
    },
    tapTool(tool) {
      if (tool.id === 0) { // 多音字
        const {selectText, range, left, top} = this.getRangeAt()
        if (!selectText) {
          return
        }
        if (selectText.length > 1) {
          Toast('请选中需要设置的单个文字')
          return
        }
        this.top = top
        this.left = left
        const getPY = pinyin(selectText, {heteronym: true})
        if (getPY && getPY.length) {
          this.currentRange = range
          this.currentSelectText = selectText
          this.pinyinList = getPY[0]
          this.pinyinSelectshow = true
        }
      }
      if (tool.id === 1 || tool.id === 4) { // 停顿
        const {range} = this.getRangeAt()
        if (range) {
          this.pauseSelectShow = false
          // 创建一个新的span元素
          const span = document.createElement('span');
          span.innerHTML = '&#xe650;';
          span.className = "iconfont pause" // 可以设置样式
          span.setAttribute('data-ssml', 'break')
          span.setAttribute('time', '200ms')
          span.setAttribute('contenteditable', 'false')
          const uuid = uuidv4()
          span.setAttribute('data-uuid', uuid)
          this.rangeList[uuid] = range
          // 插入拼音气泡元素
          let innerSpan = document.createElement('span');
          innerSpan.className = 'inner-class tips-bubble tips-pause';
          innerSpan.setAttribute('data-uuid', uuid)
          innerSpan.innerHTML = `200ms<span class="iconfont tips-pause-close" data-uuid="${uuid}">&#xe6e6;</span>`
          span.appendChild(innerSpan);
          // 将span插入到光标位置
          range.insertNode(span);
          // 将光标放在span之后
          if (range.collapse) {
            range.collapse(false);
          }
          this.currentUUID = uuid
          //
          this.top = span.offsetTop + 120
          this.left = span.offsetLeft
          this.pauseSelectShow = true
        }
      }
      if (tool.id === 2) { // 数字符号
        const {selectText, top, left, range} = this.getRangeAt()
        if (!selectText) {
          Toast('请选中需要设置的文字')
          return
        }
        if (!validateInput(selectText)) {
          Toast('格式不支持')
          return
        }
        const getGenerateSpeechList = generateSpeechText(selectText)
        if (getGenerateSpeechList && getGenerateSpeechList.length) {
          this.top = top
          this.left = left
          this.currentRange = range
          this.currentSelectText = selectText
          this.generateSpeechList = getGenerateSpeechList
          this.generateSpeechShow = true
        }
      }
      if (tool.id === 3) { // 局部变速
        const {selectText, range, top, left} = this.getRangeAt()
        if (!selectText) {
          Toast('请选中需要设置的文字')
          return
        }
        this.currentRange = range
        this.currentSelectText = selectText
        this.top = top
        this.left = left
        this.variableSpeedShow = true
      }
      if (tool.id === 5) { // 发音转换
        const {selectText, range, top, left} = this.getRangeAt()
        if (!selectText) {
          Toast('请选中需要设置的文字')
          return
        }
        this.currentRange = range
        this.currentSelectText = selectText
        this.top = top
        this.left = left
        this.voiceConvertShow = true
      }
      if (tool.id === 9) { // 清空内容
        this.$refs.editor.innerText = ''
        this.currentContent = ''
      }
      if (tool.id === 10) { // 撤回
        this.undo()
      }
      if (tool.id === 11) { // 回撤
        this.redo()
      }
    },
    // 多音字用户选择拼音处理
    selectPy(py) {
      // 点击多音字转拼音标签
      if (this.currentRange && this.currentRange.classList && this.currentRange.classList.contains('dyz')) {
        const text = this.currentRange.getAttribute('data-text')
        //
        this.currentUUID = ''
        // 创建包裹选中文本的span元素，并设置样式
        let span = document.createElement('span');
        span.className = 'polyphonic';
        span.textContent = text;
        span.setAttribute('contenteditable', 'false')
        span.setAttribute('data-ssml', 'p')
        span.setAttribute('phoneme', py)
        span.setAttribute('data-text', text)
        const uuid = uuidv4()
        span.setAttribute('data-uuid', uuid)
        this.rangeList[uuid] = this.currentRange
        // 插入拼音气泡元素
        let innerSpan = document.createElement('span');
        innerSpan.className = 'inner-class tips-bubble tips-pinyin';
        innerSpan.setAttribute('data-uuid', uuid)
        innerSpan.innerHTML = `${py}<span class="iconfont tips-pinyin-close" data-uuid="${uuid}">&#xe6e6;</span>`
        span.appendChild(innerSpan);
        this.currentRange.replaceWith(span)
        this.pinyinSelectshow = false
        return
      }
      // 已有拼音标签-更换拼音
      const pinyin = document.getElementsByClassName('polyphonic')
      if (pinyin && pinyin.length && this.currentUUID) {
        let foundElement = null;
        for (let i = 0; i < pinyin.length; i++) {
          const element = pinyin[i]
          if (element.getAttribute('data-uuid') === this.currentUUID) {
            foundElement = element;
            break;
          }
        }
        if (foundElement) {
          foundElement.setAttribute('phoneme', py)
          const childSpan = foundElement.querySelector('.tips-pinyin')
          if (childSpan) {
            childSpan.innerHTML = `${py}<span class="iconfont tips-pinyin-close" data-uuid="${this.currentUUID}">&#xe6e6;</span>`
          }
          this.pinyinSelectshow = false
          this.currentUUID = ''
          return
        }
      }
      this.currentUUID = ''
      // 创建包裹选中文本的span元素，并设置样式
      const uuid = uuidv4()
      let span = document.createElement('span');
      span.className = 'polyphonic';
      span.textContent = this.currentSelectText;
      span.setAttribute('contenteditable', 'false')
      span.setAttribute('data-ssml', 'p')
      span.setAttribute('phoneme', py)
      span.setAttribute('data-text', this.currentSelectText)
      span.setAttribute('data-uuid', uuid)
      this.rangeList[uuid] = this.currentRange
      // 插入拼音气泡元素
      let innerSpan = document.createElement('span');
      innerSpan.className = 'inner-class tips-bubble tips-pinyin';
      innerSpan.setAttribute('data-uuid', uuid)
      innerSpan.innerHTML = `${py}<span class="iconfont tips-pinyin-close" data-uuid="${uuid}">&#xe6e6;</span>`
      span.appendChild(innerSpan);
      // 创建包含span的文档片段
      let fragment = this.currentRange.createContextualFragment(span.outerHTML);
      // 删除原来的内容，并插入新的文档片段
      this.currentRange.deleteContents();
      this.currentRange.insertNode(fragment);
      this.pinyinSelectshow = false
      // 删除多音字提示标签
      const pinyinDoms = document.getElementsByClassName('polyphonic')
      const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
      if(findEl && findEl.parentElement && findEl.parentElement.nodeName == 'DYZ') findEl.parentElement.replaceWith(findEl)
    },
    // 用户选择停顿时长
    selectPause(pause) {
			this.pauseSelectShow = false
      const pauseDoms = document.getElementsByClassName('pause')
      if (pauseDoms && pauseDoms.length) {
        let foundElement = null;
        for (let i = 0; i < pauseDoms.length; i++) {
          const element = pauseDoms[i]
          if (element.getAttribute('data-uuid') === this.currentUUID) {
            foundElement = element;
            break;
          }
        }
        if (foundElement) {
          foundElement.setAttribute('time', pause)
          const childSpan = foundElement.querySelector('.tips-pause')
          if (childSpan) {
            childSpan.innerHTML = `${pause}<span class="iconfont tips-pause-close" data-uuid="${this.currentUUID}">&#xe6e6;</span>`
          }

          return
        }
      }
      this.currentUUID = ''
    },
    // 用户选择读法
    generateSpeechSelect(item, readIndex) {
      const generateSpeechDoms = document.getElementsByClassName('generate-speech')
      if (generateSpeechDoms && generateSpeechDoms.length && this.currentUUID) {
        let foundElement = null;
        for (let i = 0; i < generateSpeechDoms.length; i++) {
          const element = generateSpeechDoms[i]
          if (element.getAttribute('data-uuid') === this.currentUUID) {
            foundElement = element;
            break;
          }
        }
        if (foundElement) {
          foundElement.setAttribute('interpret-as', item.interpretAs)
          const childSpan = foundElement.querySelector('.tips-generate-speech')
          if (childSpan) {
            childSpan.innerHTML = `${readIndex}<span class="iconfont tips-generate-speech-close" data-uuid="${this.currentUUID}">&#xe6e6;</span>`
          }
          this.generateSpeechShow = false
          this.currentUUID = ''
          return
        }
      }
      this.currentUUID = ''
      // 创建包裹选中文本的span元素，并设置样式
      let span = document.createElement('span');
      span.className = 'generate-speech';
      span.textContent = this.currentSelectText;
      span.setAttribute('contenteditable', 'false')
      span.setAttribute('data-ssml', 'say-as')
      span.setAttribute('interpret-as', item.interpretAs)
      span.setAttribute('data-text', this.currentSelectText)
      const uuid = uuidv4()
      span.setAttribute('data-uuid', uuid)
      this.rangeList[uuid] = this.currentRange
      // 插入元素
      let innerSpan = document.createElement('span');
      innerSpan.className = 'inner-class tips-bubble tips-generate-speech';
      innerSpan.setAttribute('data-uuid', uuid)
      innerSpan.innerHTML = `${readIndex}<span class="iconfont tips-generate-speech-close" data-uuid="${uuid}">&#xe6e6;</span>`
      span.appendChild(innerSpan);
      // 创建包含span的文档片段
      let fragment = this.currentRange.createContextualFragment(span.outerHTML);
      // 删除原来的内容，并插入新的文档片段
      this.currentRange.deleteContents();
      this.currentRange.insertNode(fragment);
      this.generateSpeechShow = false
    },
    // 局部变速设置值
    setSpeedValue(value) {
      if (this.currentUUID) { // 重新设置变速值
        const generateSpeechDoms = document.getElementsByClassName(`start-${this.currentUUID}`)
        if (generateSpeechDoms && generateSpeechDoms.length) {
          generateSpeechDoms[0].setAttribute('pitch', value)
          const childSpan = generateSpeechDoms[0].querySelector('.tips-variable-speed')
          if (childSpan) {
            childSpan.innerHTML = `速度${value}<span class="iconfont tips-variable-speed-close" data-uuid="${this.currentUUID}">&#xe6e6;</span>`
          }
        }
        this.variableSpeedShow = false
        this.currentUUID = ''
        return
      }
      //const editorDom = this.$refs.editor
      if (this.currentRange) {
        // --------------------设置新局部变速----------------------------//
        // 创建两个新的Range对象，分别表示选中区域之前和之后的范围
        const beforeRange = this.currentRange.cloneRange();
        const afterRange = this.currentRange.cloneRange();
        // 将beforeRange的结束点移动到选中区域的起点
        beforeRange.setEnd(this.currentRange.startContainer, this.currentRange.startOffset);
        // 将afterRange的起点移动到选中区域的终点
        afterRange.setStart(this.currentRange.endContainer, this.currentRange.endOffset);
        // 创建span元素
        const uuid = uuidv4()
        // 标记元素-前
        const beforeSpan = document.createElement('span')
        beforeSpan.innerHTML = '〔'
        beforeSpan.className = `iconfont variable-speed start start-${uuid}`
        beforeSpan.setAttribute('data-uuid', uuid)
        beforeSpan.setAttribute('data-ssml', 'option')
        beforeSpan.setAttribute('pitch', value)
        beforeSpan.setAttribute('contenteditable', 'false')
        //插入气泡元素
        let innerSpan = document.createElement('span')
        innerSpan.className = 'inner-class tips-bubble tips-variable-speed'
        innerSpan.setAttribute('data-uuid', uuid)
        innerSpan.innerHTML = `速度${value}<span class="iconfont tips-variable-speed-close" data-uuid="${uuid}">&#xe6e6;</span>`
        beforeSpan.appendChild(innerSpan)
        // 标记元素-后
        const afterSpan = document.createElement('span')
        afterSpan.innerHTML = '〕'
        afterSpan.className = `iconfont variable-speed end end-${uuid}`
        afterSpan.setAttribute('contenteditable', 'false')
        afterSpan.setAttribute('data-uuid', uuid)
        // 将span元素插入到文档中
        beforeRange.insertNode(beforeSpan)
        afterRange.insertNode(afterSpan)
        this.variableSpeedShow = false
        // -------------------------------------------- //
        // 查找元素，处理包含问题
        const findBeforeEl = this.findClosestElementByClassName(beforeSpan, 'variable-speed', 'before')
        const findAfterEl = this.findClosestElementByClassName(afterSpan, 'variable-speed', 'after')
        // 1-包含在另一个变速内部处理
        if (findBeforeEl && findAfterEl) {
          console.log(1)
          const beforeClssList = Array.from(findBeforeEl.classList || [])
          const afterClassList = Array.from(findAfterEl.classList || [])
          if (beforeClssList.includes('start') && afterClassList.includes('end')) { // 包含在内部
            // // 删除被包含的元素
            const contents = this.currentRange.cloneContents();
            const nodes = contents.querySelectorAll('*');
            for (let i = 0; i < nodes.length; i++) {
              const node = nodes[i];
              if (Array.from(node.classList || []).includes('variable-speed')) {
                const delUUID = node.getAttribute('data-uuid')
                if (delUUID !== uuid) {
                  const startDom = document.getElementsByClassName(`start-${delUUID}`)
                  const endDom = document.getElementsByClassName(`end-${delUUID}`)
                  if (startDom && startDom.length && endDom && endDom.length) {
                    startDom[0].remove()
                    endDom[0].remove()
                  }
                }
              }
            }
          }
          findBeforeEl.remove()
          findAfterEl.remove()
        }
        // 2- 前面无变速，后面有
        //console.log(findBeforeEl, findAfterEl)
        if (!findBeforeEl && findAfterEl) {
          console.log(2)
          const afterClassList = Array.from(findAfterEl.classList || [])
          if (afterClassList.includes('end')) { // 判断是不是当前选中包含了一半
            // 删除被包含的变速
            const getContainDom = this.getElementsBetweenWithClass(beforeSpan, afterSpan, 'variable-speed')
            let lastContainDomClone = null
            for (let i = 0; i < getContainDom.length; i++) {
              if (getContainDom.length - 1 === i) lastContainDomClone = getContainDom[i].cloneNode(true)
              getContainDom[i].remove()
            }
            // 在后面插入一个开始标记
            this.insertElement(afterSpan, lastContainDomClone, false) // 分割，在当前元素前面插入节点
          }
        }
        //3-前面有变速，后面无
        if (findBeforeEl && !findAfterEl) {
          console.log(3)
          const beforeClassList = Array.from(findBeforeEl.classList || [])
          if (beforeClassList.includes('start')) { // 判断是不是当前选中包含了一半
            // 删除被包含的变速
            const getContainDom = this.getElementsBetweenWithClass(beforeSpan, afterSpan, 'variable-speed')
            let fastContainDomClone = null
            for (let i = 0; i < getContainDom.length; i++) {
              if (i === 0) fastContainDomClone = getContainDom[0].cloneNode(true)
              getContainDom[i].remove()
            }
            // 在qianm插入一个开始标记
            this.insertElement(beforeSpan, fastContainDomClone, true) // 分割，在当前元素前面插入节点
          }
        }
        // 4-前后都无-检测删除内部的变速
        if (!findBeforeEl && !findAfterEl) {
          console.log(4)
          const containDom = this.getElementsBetweenWithClass(beforeSpan, afterSpan, 'variable-speed')
          for (let i = 0; i < containDom.length; i++) {
            containDom[i].remove()
          }
        }
      }
    },
    // 发音转换设置值
    voiceConvertConfirm(text) {
      const pinyin = document.getElementsByClassName('voice-convert')
      if (pinyin && pinyin.length && this.currentUUID) {
        let foundElement = null;
        for (let i = 0; i < pinyin.length; i++) {
          const element = pinyin[i]
          if (element.getAttribute('data-uuid') === this.currentUUID) {
            foundElement = element;
            break;
          }
        }
        if (foundElement) {
          foundElement.setAttribute('alias', text)
          const childSpan = foundElement.querySelector('.tips-voice-convert')
          if (childSpan) {
            childSpan.innerHTML = `${text}<span class="iconfont tips-voice-convert-close" data-uuid="${this.currentUUID}">&#xe6e6;</span>`
          }
          this.voiceConvertShow = false
          this.currentUUID = ''
          return
        }
      }
      this.currentUUID = ''
      // 创建包裹选中文本的span元素，并设置样式
      let span = document.createElement('span');
      span.className = 'voice-convert';
      span.textContent = this.currentSelectText;
      span.setAttribute('contenteditable', 'false')
      span.setAttribute('data-ssml', 'sub')
      span.setAttribute('alias', text)
      span.setAttribute('data-text', this.currentSelectText)
      const uuid = uuidv4()
      span.setAttribute('data-uuid', uuid)
      this.rangeList[uuid] = this.currentRange
      // 插入拼音气泡元素
      let innerSpan = document.createElement('span');
      innerSpan.className = 'inner-class tips-bubble tips-voice-convert';
      innerSpan.setAttribute('data-uuid', uuid)
      innerSpan.innerHTML = `${text}<span class="iconfont tips-voice-convert-close" data-uuid="${uuid}">&#xe6e6;</span>`
      span.appendChild(innerSpan);
      // 创建包含span的文档片段
      let fragment = this.currentRange.createContextualFragment(span.outerHTML);
      // 删除原来的内容，并插入新的文档片段
      this.currentRange.deleteContents();
      this.currentRange.insertNode(fragment);
      this.voiceConvertShow = false
    },
    // 批量替换文字
    batchText() {
      const editorDom = this.$refs.editor
      this.removeHighlight(editorDom)
      this.BatchReplacementModalShow = true
    },
    batchPY() {
      const editorDom = this.$refs.editor
      this.removeHighlight(editorDom)
      this.BatchReplacementPYModalShow = true
    },
    // editor变化监听
    nodeEventObserve() {
      this.observer = new MutationObserver(mutations => {
        // 遍历每个mutation
        mutations.forEach(mutation => {
          // 如果mutation是子节点的添加或删除
          if (mutation.type === 'childList') {
            // 如果是添加子节点
            if (mutation.addedNodes.length > 0) {
              this.currentContent = true
              // 遍历每个添加的子节点
              mutation.addedNodes.forEach(addedNode => {
                // 将添加的子节点存储到回撤栈中
                if (!this.isUndoing) {
                  this.undoStack.push({
                    type: 'childList',
                    addedNode,
                    parentNode: mutation.target
                  });
                }
              });
            }
            // 如果是删除子节点
            if (mutation.removedNodes.length > 0) {
              // 遍历每个删除的子节点
              mutation.removedNodes.forEach(removedNode => {
                // 将删除的子节点存储到回撤栈中
                if (!this.isUndoing) {
                  this.undoStack.push({
                    type: 'childList',
                    removedNode,
                    parentNode: mutation.target
                  });
                }
              });
            }
          }
          // 如果mutation是属性的改变
          else if (mutation.type === 'attributes') {
            // 将改变前的属性值存储到回撤栈中
            if (!this.isUndoing) {
              this.undoStack.push({
                type: 'attributes',
                target: mutation.target,
                attributeName: mutation.attributeName,
                oldValue: mutation.oldValue
              });
            }
          }
          // 如果mutation是字符数据的改变
          else if (mutation.type === 'characterData') {
            // 将改变前的字符数据存储到回撤栈中
            this.currentContent = this.$refs.editor.innerText
            if (!this.isUndoing) {
              this.undoStack.push({
                type: 'characterData',
                target: mutation.target,
                oldValue: mutation.oldValue
              });
            }
          }
        });
      });
      const config = {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      };
      this.observer.observe(this.$refs.editor, config);
    },
    // 撤回
    undo() {
      if (this.undoStack.length > 0) {
        this.isUndoing = true;
        const operation = this.undoStack.pop();
        if (operation.type === 'childList') {
          // 如果是添加子节点
          if (operation.addedNode) {
            // 将回撤的子节点从DOM中移除
            operation.parentNode.removeChild(operation.addedNode);
            // 将回撤的操作存储到撤回栈中
            this.redoStack.push(operation);
          }
          // 如果是删除子节点
          else if (operation.removedNode) {
            // 将回撤的子节点添加到DOM中
            operation.parentNode.insertBefore(operation.removedNode, operation.nextSibling);
            // 将回撤的操作存储到撤回栈中
            this.redoStack.push(operation);
          }
        } else if (operation.type === 'attributes') {
          // 将回撤的属性值恢复到DOM中
          operation.target.setAttribute(operation.attributeName, operation.oldValue);
          // 将回撤的操作存储到撤回栈中
          this.redoStack.push(operation);
        } else if (operation.type === 'characterData') {
          // 将回撤的字符数据恢复到DOM中
          operation.target.textContent = operation.oldValue;
          // 将回撤的操作存储到撤回栈中
          this.redoStack.push(operation);
          this.currentContent = this.$refs.editor.innerText
        }
        setTimeout(() => {
          this.isUndoing = false;
        }, 150)
      }
    },
    // 回撤
    redo() {
      if (this.redoStack.length > 0) {
        this.isUndoing = true;
        const operation = this.redoStack.pop();
        if (operation.type === 'childList') {
          // 如果是添加子节点
          if (operation.addedNode) {
            // 将撤回的子节点添加到DOM中
            operation.parentNode.insertBefore(operation.addedNode, operation.nextSibling);
            // 将撤回的操作存储到回撤栈中
            this.undoStack.push(operation);
          }
          // 如果是删除子节点
          else if (operation.removedNode) {
            // 将撤回的子节点从DOM中移除
            operation.parentNode.removeChild(operation.removedNode);
            // 将撤回的操作存储到回撤栈中
            this.undoStack.push(operation);
          }
        } else if (operation.type === 'attributes') {
          // 将撤回的属性值恢复到DOM中
          operation.target.setAttribute(operation.attributeName, operation.oldValue);
          // 将撤回的操作存储到回撤栈中
          this.undoStack.push(operation);
        } else if (operation.type === 'characterData') {
          // 将撤回的字符数据恢复到DOM中
          operation.target.textContent = operation.oldValue;
          // 将撤回的操作存储到回撤栈中
          this.undoStack.push(operation);
          this.currentContent = this.$refs.editor.innerText
        }
        setTimeout(() => {
          this.isUndoing = false;
        }, 150)
      }
    },
    addPasteListener() {
      this.$refs.editor.addEventListener('paste',  (e)=> {
        // 阻止默认粘贴行为
        e.preventDefault();
        // 获取剪切板数据
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text/plain');
				console.log(pastedData)
        if( pastedData ) this.currentContent = true
        // 插入无格式的文本
	      this.$refs.editor.innerText = pastedData.replace(/\r?\n/g, '\n')
	      this.$refs.editor.innerText = pastedData.replace(/<div>/g, '')
	      this.$refs.editor.innerText = pastedData.replace(/<\/div>/g, '')
	      //document.execCommand('insertText', false, pastedData);
      });
    },
    getRangeAt() {
      const selection = window.getSelection()
      if (!selection.rangeCount) {
        Toast('无效操作')
        return {}
      }
      const range = selection.getRangeAt(0)
      return {
        commonAncestorContainer: range.commonAncestorContainer,
        startContainer: range.startContainer,
        endContainer: range.endContainer,
        startOffset: range.startOffset,
        endOffset: range.endOffset,
        selectText: selection.toString(),
        range,
        left: this.clientX,
        top: this.clientY,
      }
    },
    setDefaultEvent() {
      this.pinyinSelectshow = false
      this.pauseSelectShow = false
      this.generateSpeechShow = false
      this.variableSpeedShow = false
      this.voiceConvertShow = false
      this.currentUUID = ''
    },
    handleMouseDown(event) {
      if (event.target.className === 'editor') {
        this.clientX = event.clientX
        this.clientY = event.clientY + 20
      }
    },
    eventListener(event) {
      this.setDefaultEvent()
      const classList = Array.from(event.target.classList)
      const uuid = event.target.getAttribute('data-uuid')
      if (classList && classList.length) {
        // 拼音-气泡再次设置
        if (classList.includes('tips-pinyin')) {
          const pinyinDoms = document.getElementsByClassName('polyphonic')
          const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
          this.currentSelectText = findEl.attributes['data-text'].value
          const getPY = pinyin(this.currentSelectText, {heteronym: true})
          if (getPY) {
            this.currentRange = findEl
            this.top = event.clientY + 38
            this.left = event.clientX - 6
            this.pinyinList = getPY[0]
            this.currentUUID = uuid
            this.pinyinSelectshow = true
            event.stopPropagation()
          }
        }
        // 拼图气泡-去除拼音设置
        if (classList.includes('tips-pinyin-close')) {
          const pinyin = document.getElementsByClassName('polyphonic')
          const findEl = this.getParentOwnTextContent(pinyin, uuid)
          if (findEl) {
            const text = findEl.attributes['data-text'].value
            findEl.parentNode.replaceChild(document.createTextNode(text), findEl);
          }
        }
        // 停顿-去除
        if (classList.includes('tips-pause-close')) {
          const pinyin = document.getElementsByClassName('pause')
          const findEl = this.getParentOwnTextContent(pinyin, uuid)
          if (findEl) {
            findEl.remove()
          }
        }
        // 停顿-再次设置
        if (classList.includes('tips-pause')) {
          const pinyinDoms = document.getElementsByClassName('pause')
          const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
          if (findEl) {
            this.top = event.clientY + 38
            this.left = event.clientX - 6
            this.currentUUID = uuid
            this.pauseSelectShow = true
            event.stopPropagation()
          }
        }
        // 数字读法-去除
        if (classList.includes('tips-generate-speech-close')) {
          const pinyin = document.getElementsByClassName('generate-speech')
          const findEl = this.getParentOwnTextContent(pinyin, uuid)
          if (findEl) {
            const text = findEl.attributes['data-text'].value
            findEl.parentNode.replaceChild(document.createTextNode(text), findEl);
          }
        }
        // 数字读法-再次设置
        if (classList.includes('tips-generate-speech')) {
          const pinyinDoms = document.getElementsByClassName('generate-speech')
          const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
          this.currentSelectText = findEl.attributes['data-text'].value
          const getPY = generateSpeechText(this.currentSelectText)
          if (getPY) {
            this.currentRange = findEl
            this.top = event.clientY + 38
            this.left = event.clientX - 6
            this.generateSpeechList = getPY
            this.currentUUID = uuid
            this.generateSpeechShow = true
            event.stopPropagation()
          }
        }
        // 局部变速-再设置
        if (classList.includes('tips-variable-speed')) {
          const pinyinDoms = document.getElementsByClassName('variable-speed')
          const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
          if (findEl) {
            this.currentRange = findEl
            this.top = event.clientY + 38
            this.left = event.clientX - 6
            this.currentUUID = uuid
            this.variableSpeedShow = true
            event.stopPropagation()
          }
        }
        // 局部变速-去除
        if (classList.includes('tips-variable-speed-close')) {
          const startDom = document.getElementsByClassName(`start-${uuid}`)
          const endDom = document.getElementsByClassName(`end-${uuid}`)
          if (startDom && startDom.length && endDom && endDom.length) {
            startDom[0].remove()
            endDom[0].remove()
          }
        }
        // 发音转换-去除
        if (classList.includes('tips-voice-convert-close')) {
          const pinyin = document.getElementsByClassName('voice-convert')
          const findEl = this.getParentOwnTextContent(pinyin, uuid)
          if (findEl) {
            const text = findEl.attributes['data-text'].value
            findEl.parentNode.replaceChild(document.createTextNode(text), findEl);
          }
        }
        // 发音转换-再设置
        if (classList.includes('tips-voice-convert')) {
          const pinyinDoms = document.getElementsByClassName('voice-convert')
          const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
          this.currentSelectText = findEl.attributes['data-text'].value
          this.currentRange = findEl
          this.top = event.clientY + 38
          this.left = event.clientX - 6
          this.currentUUID = uuid
          this.voiceConvertShow = true
          event.stopPropagation()
        }
        // 点击多音字提示
        if (classList.includes('dyz-tips')) {
          const pinyinDoms = document.getElementsByClassName('dyz')
          const findEl = this.getParentOwnTextContent(pinyinDoms, uuid)
          this.currentSelectText = findEl.getAttribute('data-text')
          const getPY = pinyin(this.currentSelectText, {heteronym: true})
          if (getPY) {
            this.currentRange = findEl
            this.top = event.clientY + 38
            this.left = event.clientX - 6
            this.pinyinList = getPY[0]
            this.currentUUID = uuid
            this.pinyinSelectshow = true
            event.stopPropagation()
          }
        }
      }
    },
    // 通过UUID查找el
    getParentOwnTextContent(elements, uuid) {
      let foundElement = null;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i]
        if (element.getAttribute('data-uuid') === uuid) {
          foundElement = element;
          break;
        }
      }
      if (foundElement) {
        return foundElement
      }
      return null
    },
    // 查找最近的指定的class的元素
    findClosestElementByClassName(startElement, className, direction) {
      let currentElement = startElement;
      let closestElement = null;

      if (direction === 'before') {
        // 查找当前元素之前的兄弟节点
        while (currentElement && currentElement.previousElementSibling) {
          currentElement = currentElement.previousElementSibling;
          if (currentElement.classList.contains(className)) {
            closestElement = currentElement;
            break;
          }
        }
      } else if (direction === 'after') {
        // 查找当前元素之后的兄弟节点
        while (currentElement && currentElement.nextElementSibling) {
          currentElement = currentElement.nextElementSibling;
          if (currentElement.classList.contains(className)) {
            closestElement = currentElement;
            break;
          }
        }
      } else {
        Toast('Invalid direction. Use "before" or "after".');
      }

      return closestElement;
    },
    // 在元素前||后插入新元素
    insertElement(referenceElement, newElement, before) {
      // 确保referenceElement有父元素
      if (referenceElement.parentNode) {
        if (before) {
          // 如果before为true，则将newElement插入到referenceElement之前
          referenceElement.parentNode.insertBefore(newElement, referenceElement);
        } else {
          // 如果before为false，则将newElement插入到referenceElement之后
          // 注意：需要将newElement插入到referenceElement的下一个兄弟节点之前
          let nextSibling = referenceElement.nextSibling;
          if (nextSibling) {
            referenceElement.parentNode.insertBefore(newElement, nextSibling);
          } else {
            // 如果没有下一个兄弟节点，则将newElement追加到父节点的子节点列表末尾
            referenceElement.parentNode.appendChild(newElement);
          }
        }
      } else {
        Toast('参考元素没有父元素，无法插入！');
      }
    },
    // 获取两个元素中间的所有同级元素
    getElementsBetweenWithClass(element1, element2, className) {
      // 获取两个元素的共同父元素
      const parentElement = document.getElementById('editor')
      if (!parentElement) {
        throw new Error('The elements do not have a common parent.');
      }
      // 获取所有子元素
      const children = Array.from(parentElement.children);
      // 找到两个元素的索引
      const index1 = children.indexOf(element1);
      const index2 = children.indexOf(element2);
      // 确保找到了这两个元素，并且它们不是同一个元素
      if (index1 === -1 || index2 === -1 || index1 === index2) {
        throw new Error('One or both of the elements were not found, or they are the same element.');
      }
      // 获取两个元素之间所有具有指定类的同级元素
      return children.slice(index1 + 1, index2).filter(child => child.classList.contains(className));
    },
    // 匹配后设置高亮
    highlightText(element, searchText) {
      const regex = new RegExp(searchText, 'gi'); // g: 全局匹配，i: 忽略大小写
      const childNodes = element.childNodes;
      childNodes.forEach(childNode => {
        if (childNode.nodeName !== 'MARK') {
          if (childNode.nodeType === 3) {
            const text = childNode.textContent;
            const matches = text.match(regex); // 查找所有匹配的文本
            if (matches && matches.length > 0) {
              const fragment = document.createDocumentFragment(); // 创建文档碎片
              let lastIndex = 0;
              matches.forEach(match => {
                const index = text.indexOf(match, lastIndex);
                const before = document.createTextNode(text.substring(lastIndex, index));
                const highlight = document.createElement('mark');
                highlight.classList.add('mark');
                highlight.setAttribute('data-text', match)
                const middle = document.createTextNode(match);
                fragment.appendChild(before);
                highlight.appendChild(middle);
                fragment.appendChild(highlight);
                lastIndex = index + match.length;
              });
              const after = document.createTextNode(text.substring(lastIndex));
              fragment.appendChild(after);
              childNode.replaceWith(fragment); // 替换文本节点
            }
          } else {
            this.highlightText(childNode, searchText);
          }
        }
      });
    },
    // 取消设置的高亮
    removeHighlight() {
      try {
        let done = false
        while (!done) {
          const editorDom = this.$refs.editor
          const highlightedElements = editorDom.getElementsByClassName('mark');
          if (highlightedElements.length === 0) {
            done = true
            break;
          }
          const textNode = document.createTextNode(highlightedElements[0].textContent);
          highlightedElements[0].parentElement.replaceChild(textNode, highlightedElements[0]);
        }
      } catch (e) {
        Toast(e.message)
      }
    },
    // 选中上一个或下一个元素
    selectElement(element, direction) {
      // 获取所有带有高亮样式的元素
      const highlightedElements = element.getElementsByClassName('mark');
      // 如果没有带有高亮样式的元素，则返回
      if (highlightedElements.length === 0) {
        return;
      }
      // 获取当前选中元素的索引
      const currentIndex = Array.from(highlightedElements).findIndex(function (element) {
        return element.classList.contains('selected');
      });
      // 如果当前没有选中元素，则默认选中第一个元素
      if (currentIndex === -1) {
        highlightedElements[0].classList.add('selected');
        return;
      }
      // 移除当前选中元素的选中样式
      highlightedElements[currentIndex].classList.remove('selected');
      // 根据传入的参数判断选择上一个或下一个元素
      if (direction === 'previous') {
        this.nextIndex = currentIndex - 1;
        if (this.nextIndex < 0) {
          this.nextIndex = highlightedElements.length - 1;
        }
      } else if (direction === 'next') {
        this.nextIndex = currentIndex + 1;
        if (this.nextIndex >= highlightedElements.length) {
          this.nextIndex = 0;
        }
      }
      // 选中上一个或下一个元素
      highlightedElements[this.nextIndex].classList.add('selected');
    },
    getTextContent(element) {
      const wrapper = document.createElement('div');
      Array.from(element.childNodes).forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
          const content = node.textContent.trim();
          if (content) {
            const spans = content.split('').map(char => {
              const py = pinyin(char, {heteronym: true})
              if (py[0] && py[0].length > 1) {
                const uuid = uuidv4()
                return `<dyz class="dyz" data-text="${char}" data-uuid="${uuid}">${char} <tip class="dyz-tips" data-uuid="${uuid}" data-text="${char}">${py[0][0]}</tip></dyz>`
              }
              return char
            });
            wrapper.innerHTML += spans.join('');
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const clone = node.cloneNode();
          clone.innerHTML = this.getTextContent(node);
          wrapper.appendChild(clone);
        }
      });
      return wrapper.innerHTML;
    },
	  keydownAddListener(e){
		  // 检查是否按下了回车键（键码为 13）
		  if (e.keyCode === 13) {
			  // 阻止默认行为（即插入 div 或 br）
			  e.preventDefault();

			  // 在光标位置插入一个 <br> 标签来表示换行
			  // 这里使用 document.execCommand 方法，但在一些现代浏览器中可能不受支持
			  // 因此，您可能需要使用更复杂的 TextRange 或 Range API 来实现
			  if (window.getSelection && window.getSelection().getRangeAt) {
				  var range = window.getSelection().getRangeAt(0);
				  range.deleteContents(); // 删除当前选中的内容（如果有）
				  var node = document.createElement("br"); // 创建一个 <br> 元素
				  range.insertNode(node); // 在光标位置插入 <br>

				  // 将光标位置移到新插入的 <br> 之后
				  range.setStartAfter(node);
				  range.collapse(true);
				  window.getSelection().removeAllRanges(); // 清除当前的选择
				  window.getSelection().addRange(range); // 添加新的选择
			  }
		  }
	  }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
	  document.getElementById('editor').removeEventListener('mousedown', this.handleMouseDown)
	  document.getElementById('editor').removeEventListener('keydown', this.keydownAddListener)
  },
}
</script>

<style>
body, html, p {
  padding: 0;
  margin: 0;
}

.dubbing-editer {
	flex: 1;
  width: 100%;
  background: #fdeded;
  border-radius: 14px;
  border: 1px solid #ed4f43;
  box-sizing: border-box;
}

.editer-tools {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ed4f43;
}

.tools-item {
  flex: 0 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.replacement-menu {
  position: absolute;
  bottom: -85px;
  left: 0;
  width: 130px;
  background: #fff;
  z-index: 9;
  border-radius: 4px;
}

.replacement-menu p {
  padding: 10px;
  font-size: 14px;
}

.replacement-menu p:hover {
  background: #ccc;
}

.tool-icon {
  width: 22px;
  height: 22px;
  margin-bottom: 12px;
}

.tool-name {
  font-size: 12px;
}

.editor-container {
  min-height: 300px;
  padding: 0 14px;
  overflow-y: auto;
  line-height: 50px;
  font-size: 15px;
  position: relative;
}

.default-text {
  color: #999;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 14px;
}

.editor {
  user-select: none;
  outline: none;
  width: 100%;
  height: 100%;
  background: none;
  padding: 10px 0;
  box-sizing: border-box;
}

.polyphonic {
  position: relative;
  display: inline-block;
  padding: 0 14px;
  margin: 0 4px;
  cursor: pointer;
  user-select: none;
}

.polyphonic::before {
  content: "「";
  color: rgb(242, 85, 85);
  position: absolute;
  left: -5px;
  bottom: 0;
}

.polyphonic::after {
  content: "」";
  color: rgb(242, 85, 85);
  position: absolute;
  right: -5px;
  bottom: -1px;
}

.inner-class {
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0 8px;
  height: 22px;
  background: #F25555;
  border-radius: 100px 100px 100px 100px;
  margin-left: 4px;
  text-decoration: none;
  font-style: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8700000047683716);
  line-height: 22px;
  display: inline-block;
  white-space: nowrap;
  z-index: 1;
  margin-bottom: 4px;
  cursor: pointer;
}

.inner-class:hover {
  z-index: 9;
  box-shadow: 0 0 3px #333;
}

.tips-pinyin-close, .tips-pause-close, .tips-generate-speech-close, .tips-variable-speed-close {
  font-size: 12px;
  margin-left: 8px;
}

.pause {
  color: #F25555;
  font-weight: bold;
  padding: 15px;
  position: relative;
}

.generate-speech {
  color: #725FE8;
  font-weight: bold;
  padding: 15px;
  position: relative;
}

.tips-generate-speech, .tips-variable-speed {
  background: #725FE8;
}

.variable-speed {
  color: #725FE8;
  font-weight: bold;
  padding: 15px;
  position: relative;
}

.voice-convert {
  color: #52CFD7;
  font-weight: bold;
  padding: 15px;
  position: relative;
}

.voice-convert:after {
  position: absolute;
  content: '';
  bottom: 8px;
  left: 10%;
  width: 80%;
  height: 3px;
  background: #52CFD7;
}

.tips-voice-convert {
  background: #52CFD7;
}

.mark {
  background-color: green;
  color: #fff;
  font-weight: bold;
  padding: 0 1px;
}

.selected {
  background-color: red;
}

.dyz {
  position: relative;
  padding: 0 10px;
}

.dyz tip {
  position: absolute;
  height: 22px;
  top: -24px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(0, 0, 0, .5);
  color: #fff;
  line-height: 22px;
  padding: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.dyz tip:hover {
  background: rgba(0, 0, 0, .8);
  box-shadow: 0 0 2px #ccc;
  z-index: 9;
}
</style>